import React from 'react';
import { Box, SimpleGrid, Heading, theme } from '@chakra-ui/react';

import useTemplateStore from '../../../stores/TemplateStore';
import useContentStore from '../../../stores/ContentStore';
import useEntitiesStore from '../../../stores/EntitiesStore';

import MarketLeftSidebarMenu from './market/MarketLeftSidebarMenu.react';
import SearchBox from './market/SearchBox.react';
import Filters from './market/Filters.react';
import TemplateThumb from './market/TemplateThumb.react';

import { ApiAuthorisationModes } from '../../../util/resources';
import { hasBlueprints, isTemplateMgm } from '../../../util/helper';
import { EntityStatuses } from '../../Table/strings';
import { getUser } from '@/util/auth/auth';

export class TemplateMarket extends React.PureComponent {
	static defaultProps = {
		currentPersonalFilter: 0,
		search_term: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			entityName: '',
		};
	}

	render() {
		if (this.props.isPublishedEntity) {
			return null;
		}

		let filteredTemplatesMetadata = applyFilters(
			this.props.templatesMetadata,
			personalFilter(this.props.currentPersonalFilter, { favouriteTemplates: this.props.favouriteTemplates }),
			categoryFilter(this.props.currentCategory),
			searchFilter(this.props.search_term),
		);

		let templatesMetadata = filteredTemplatesMetadata
			.map((temp, index) => {
				return (
					<TemplateThumb
						currentCategory={this.props.currentCategory}
						favouriteTemplates={this.props.favouriteTemplates}
						template={temp}
						key={index}
					/>
				);
			})
			.filter((item) => {
				return item !== null;
			});

		if ((!hasBlueprints() && !useContentStore.getState().content.rows.length) || isTemplateMgm()) {
			const createNew = <TemplateThumb currentCategory={this.props.currentCategory} fromScratch key="scratch-template-thumb" />;

			templatesMetadata.unshift(createNew);
		}

		return (
			<SimpleGrid
				columns="2"
				templateColumns={{ base: '4fr 8fr', md: '3fr 9fr', lg: '2fr 10fr' }}
				spacing="7"
				p="7"
				pt={10}
				maxW={2000}
				margin="0 auto"
			>
				<Box h="full">
					<MarketLeftSidebarMenu currentCategory={this.props.currentCategory} templateCategories={this.props.templateCategories} />
				</Box>
				<Box h="full" overflowY="auto">
					<Filters
						availablePersonalFilters={this.props.availablePersonalFilters}
						currentPersonalFilter={this.props.currentPersonalFilter}
					/>
					<SearchBox searchTerm={this.props.search_term} />
					{templatesMetadata.length ? (
						<SimpleGrid my={5} spacing={5} gridTemplateColumns={`repeat(auto-fill, minmax(${theme.sizes['2xs']}, 1fr))`}>
							{templatesMetadata}
						</SimpleGrid>
					) : (
						<Box my={24} textAlign="center" fontWeight="semibold" fontSize="xl">
							No templates to display for this category or filter
						</Box>
					)}
				</Box>
			</SimpleGrid>
		);
	}
}

const applyFilters = (templatesMetadata, ...filterFunctions) => {
	return filterFunctions.reduce((acc, filterFunc) => acc.filter(filterFunc), templatesMetadata);
};

const categoryFilter = (currentCategory) => {
	return (templateMetadata) => {
		if (currentCategory !== 'All categories') {
			return templateMetadata.categories.includes(currentCategory);
		} else {
			return true;
		}
	};
};

const personalFilter = (filterId, data) => {
	return (templateMetadata) => {
		switch (filterId) {
			case 0:
				return true;
			case 1:
				return data.favouriteTemplates.includes(templateMetadata.id);
			case 2:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Private;
			case 3:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Public;
			case 4:
				return templateMetadata.AuthorisationMode === ApiAuthorisationModes.Organization;
			default:
				return true;
		}
	};
};

const searchFilter = (searchTerm) => {
	return (templateMetadata) => {
		if (!searchTerm) {
			return true;
		}

		return templateMetadata.title ? templateMetadata.title.toLowerCase().includes(searchTerm.toLowerCase()) : false;
	};
};

const TemplateMarketWrapper = (props) => {
	const templateProps = useTemplateStore();
	const isPublishedEntity = useEntitiesStore((state) => state.activeEntity?.Entity.Status === EntityStatuses.active);

	return <TemplateMarket {...templateProps} {...props} isPublishedEntity={isPublishedEntity} />;
};

export default TemplateMarketWrapper;
