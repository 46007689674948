import React from 'react';

import useContentStore from '../../../../stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const RadioComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class RadioSettings extends React.PureComponent {
	render() {
		return (
			<div className="radio-settings">
				<RadioComponents
					{...this.props}
					onChange={this.onChange}
					settings={{
						placeholder: {
							active: false,
						},
						options: {
							active: true,
						},
						optionsSpacing: {
							active: true,
						},
						hiddenField: {
							active: false,
						},
						prefill: {
							active: false,
						},
						fieldGroup: {
							label: 'Radio button styles',
							settings: {
								fieldBgColor: {
									label: 'Radio button background color',
								},
								fieldFont: {
									active: false,
								},
								fieldFontSize: {
									active: true,
									label: 'Fill Size',
								},
								fieldFontColour: {
									label: 'Fill color',
								},
								fieldTextDecoration: {
									active: false,
								},
							},
						},
						labelGroup: {
							settings: {
								labelHide: {
									label: 'Hide main label',
								},
								labelPosition: {
									active: false,
								},
								labelWidth: {
									label: 'Label Height',
								},
							},
						},
						placeholderGroup: {
							active: false,
						},
					}}
				/>
			</div>
		);
	}
}

export default RadioSettings;
