import React from 'react';
import useContentStore from '../../../../stores/ContentStore';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import NumberSetting from '../../shared/settings/NumberSetting.react';
import SharedTextSettings from './SharedTextSettings.react';

class TextSettings extends React.PureComponent {
	render() {
		return <TextSettingComponents {...this.props} />;
	}
}

export const TextSettingComponents = React.memo((props) => {
	const onChange = React.useCallback(
		(data) => {
			if (props.onChange) {
				return props.onChange(data);
			}

			const settings = {
				[data.type]: data.value,
			};

			useContentStore.getState().saveElementSettings({
				address: props.address,
				settings,
			});
		},
		[props.address],
	);

	return (
		<SettingsBox>
			<NumberSetting
				type="textBorderWidth"
				onChange={onChange}
				text={props.textBorderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type="textBorderColor"
				onChange={onChange}
				selectedColor={props.textBorderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type="textBorderRadius"
				onChange={onChange}
				text={props.textBorderRadius}
				label="Border radius"
				ariaLabel="Border radius in pixels"
			/>
			<SharedTextSettings filterImmunity {...props} />
		</SettingsBox>
	);
});

export default TextSettings;
