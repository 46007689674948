import { Children, cloneElement, memo, ReactElement, useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { Operator } from './Operator';

import { Box, Button, Card, Heading } from '@chakra-ui/react';

import useContentStore from '@/stores/ContentStore';

import { createBaseCondition } from '@/components/Conditions/utils';
import { useParentSettings } from '@/components/Conditions/hooks/useParentSettings';
import MoreMenu, { MenuActionItem } from '@/components/gui/shared/MoreMenu';
import { useLogicMenuActions } from '@/components/Conditions/hooks/useMenuActions';

export interface IGroupProps {
	settings: any;
	index: number;
	parentAddress: number[];
	id: string;
	children: any;
	isLast: boolean;
	operator: string;
	type: string;
}

const Group = ({ settings, index, type, parentAddress: propAddress, children, operator, isLast }: IGroupProps) => {
	const { parentAddress, address, getParentSettings } = useParentSettings(propAddress, index);
	const { changeConditionSetting, canBeModified } = useContentStore(
		useShallow((state) => ({
			changeConditionSetting: state.changeConditionSetting,
			canBeModified: state.canBeModified,
		})),
	);

	const { handleDuplicate, handleMove, handleDelete } = useLogicMenuActions(index, parentAddress, getParentSettings, {
		type,
		operator,
		settings,
	});

	const onChange = useCallback(
		(type, value) => {
			changeConditionSetting(address, { [type]: value });
		},
		[address],
	);

	const onAddCondition = useCallback(() => {
		const tempSettings = [...settings, createBaseCondition()];
		onChange('settings', tempSettings);
	}, [settings]);

	useEffect(() => {
		if (!settings.length) {
			const parentSettings = [...getParentSettings()];
			parentSettings.splice(index, 1);
			changeConditionSetting(parentAddress, { settings: parentSettings });
		}
	}, [index, parentAddress, settings]);

	const renderChildren = () => {
		return Children.map(children, (child) => {
			return cloneElement(child, {
				parentAddress: address,
				key: child.props.id,
			});
		}) as unknown as ReactElement;
	};

	const availableActions: MenuActionItem[] = [
		{ action: () => handleMove('up'), label: 'Move up' },
		{ action: () => handleMove('down'), label: 'Move Down', noRender: isLast },
		{ action: handleDuplicate, label: 'Duplicate' },
		{ action: handleDelete, label: 'Delete' },
	];

	return (
		<>
			<Card shadow="none" variant="filled" border="1px dashed" borderColor="chakra-border-color" p={3} mb={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center" mb="3">
					<Heading variant="section" mb={1.5}>
						Group
					</Heading>
					<MoreMenu actions={availableActions} disabled={!canBeModified} />
				</Box>
				{renderChildren()}
				<Box display="flex" justifyContent="flex-end">
					<Button
						color="primary-fg"
						mt={1.5}
						variant="ghost"
						size="sm"
						onClick={onAddCondition}
						isDisabled={!canBeModified}
						data-testid={`add-condition_group-${index}`}
					>
						Add condition
					</Button>
				</Box>
			</Card>
			{operator && !isLast && <Operator value={operator} address={address} />}
		</>
	);
};

export default memo(Group);
