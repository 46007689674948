import { Box, Button, ButtonGroup, Icon, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

const MAX_PAGE_SLOTS = 7;
const EDGE_CHUNKS = Math.ceil(MAX_PAGE_SLOTS / 2);
const DEFAULT_PAGE_SIZE = 10;

const usePagination = ({ currentPage, totalPages, totalItems, pageSize = DEFAULT_PAGE_SIZE }) => {
	if (!totalPages || !currentPage) return { pageChunks: null, pageLabel: null };

	const pageChunks = () => {
		const allPages = [...Array(totalPages)].map((_, i) => i + 1);
		if (totalPages <= MAX_PAGE_SLOTS) return allPages;

		if (currentPage <= EDGE_CHUNKS) return [...allPages.slice(0, EDGE_CHUNKS + 1), '...', totalPages];
		if (currentPage + EDGE_CHUNKS >= totalPages + 1) return [1, '...', ...allPages.slice(totalPages - EDGE_CHUNKS - 1, totalPages)];

		return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
	};

	const pageLabel = () => {
		const start = Math.max(pageSize * (currentPage - 1), 0);
		const end = Math.min(pageSize * currentPage, totalItems);

		if (start + 1 === end) {
			return end;
		}
		return `${start + 1} - ${end} of ${totalItems}`;
	};
	return { pageChunks: pageChunks(), pageLabel: totalItems ? pageLabel() : null };
};

export const TablePagination = ({ currentPage, totalPages, onClick, totalItems, showPageLabel }) => {
	const { pageChunks, pageLabel } = usePagination({ currentPage, totalPages, totalItems });
	if (!totalPages || totalPages === 1 || !currentPage) return null;

	return (
		<Box display="flex" alignItems="center" justifyContent="flex-end" py={5}>
			{showPageLabel && <Text mr={5}>{pageLabel}</Text>}
			<Stack spacing={10}>
				<ButtonGroup size="sm" variant="pagination" spacing="1">
					<Tooltip label="Previous" isDisabled={currentPage === 1}>
						<IconButton
							aria-label="Previous page"
							icon={
								<Icon>
									<path d={mdiChevronLeft} />
								</Icon>
							}
							isDisabled={currentPage === 1}
							onClick={(e) => onClick(e, currentPage - 1)}
							data-testid="pagination-previous-page"
						/>
					</Tooltip>

					{pageChunks.map((item, index) => (
						<Button
							key={`${item}_${index}`}
							isActive={currentPage === item}
							isDisabled={typeof item === 'string'}
							onClick={typeof item === 'number' ? (e) => onClick(e, item) : null}
						>
							{item}
						</Button>
					))}

					<Tooltip label="Next" isDisabled={totalPages <= currentPage}>
						<IconButton
							aria-label="Next page"
							icon={
								<Icon>
									<path d={mdiChevronRight} />
								</Icon>
							}
							isDisabled={totalPages <= currentPage}
							onClick={(e) => onClick(e, currentPage + 1)}
							data-testid="pagination-next-page"
						/>
					</Tooltip>
				</ButtonGroup>
			</Stack>
		</Box>
	);
};
