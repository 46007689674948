import { Box, Skeleton, Tr, Td } from '@chakra-ui/react';

export const FormsTableLoader = () => (
	<>
		{[...Array(10)].map((_, i) => (
			<Tr h={16} key={i}>
				<Td w="4%">
					<Skeleton display="inline-block" w="5" h="5">
						CB
					</Skeleton>
				</Td>
				<Td w={['27%', '27%', '27%', '27%', '27%', '35%']}>
					<Skeleton display="inline-block">{Math.random().toString()}</Skeleton>
				</Td>
				<Td w="8%">
					<Skeleton display="inline-block">
						<Box h={4} w={8} />
					</Skeleton>
				</Td>
				<Td w="8%">
					<Skeleton display="inline-block">
						<Box h={4} w={8} />
					</Skeleton>
				</Td>
				<Td w="10%">
					<Skeleton display="inline-block">
						<Box h={4} w={16} />
					</Skeleton>
				</Td>
				<Td w={['39%', '39%', '39%', '39%', '39%', '31%']}>
					<Skeleton display="inline-block">
						<Box h={5} w={12} />
					</Skeleton>
					<Skeleton display="inline-block">
						<Box h={5} w={14} />
					</Skeleton>
					<Skeleton display="inline-block">
						<Box h={5} w={9} />
					</Skeleton>
				</Td>
				<Td w="4%" minW={12}>
					<Skeleton display="inline-block">
						<Box h={2} w={5} />
					</Skeleton>
				</Td>
			</Tr>
		))}
	</>
);
