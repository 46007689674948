import React from 'react';
import { browserHistory } from 'react-router';
import { shallow } from 'zustand/shallow';
import {
	Box,
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Alert,
	AlertDescription,
	AlertIcon,
	CloseButton,
	Tooltip,
} from '@chakra-ui/react';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiPlus, mdiMinus, mdiRestore, mdiChevronDown } from '@mdi/js';

import useContentViewStore from '../../../stores/ContentViewStore';
import useTextEditorStore from '../../../stores/TextEditorStore';
import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import useContentStore from '../../../stores/ContentStore';

import { contentViewMenuItemConstants as ViewTypes, mobileRevertTypes as RevertTypes } from '../../../util/resources';
import { addFontToStylesheets, pathPrefix } from '../../../util/helper';
import domHelper from '../../../util/domHelper';

import LeftSidebarMenu from '../../gui/layout/LeftSidebarMenu.react';
import RightSidebarMenu from '../../gui/layout/RightSidebarMenu.react';
import ContentViewMenu from '../../gui/layout/ContentViewMenu.react';
import ScriptMenu from '../../gui/layout/ScriptMenu.react';
import ScriptViewContainer from '../../gui/layout/ScriptViewContainer.react';
import AutosaveNotificationPopup from '../../gui/layout/modals/AutosaveNotificationPopup.react';

import ContentContainer from './ContentContainer.react';
import CustomDragLayer from './CustomDragLayer.react';
import CommentsMenu from './CommentsMenu.react';
import DraftsMenu from './DraftsMenu.react';

class MainBody extends React.PureComponent {
	componentDidMount() {
		this.applyAndDownloadFonts();
	}

	render() {
		if (!this.props.canBeModified) {
			return null;
		}

		const isMobileView = this.props.currentView === ViewTypes.MOBILE_VIEW;

		return (
			<div id="close-stuff" onMouseDown={this.closeStuff}>
				{this.props.customCss && <CustomCssAlert />}
				<Box>
					<CustomDragLayer />
					<Box h="calc(100vh - 3.5rem)" display="flex">
						{this.props.location.pathname === pathPrefix() && <LeftSidebarMenu />}
						{this.props.location.pathname === pathPrefix() + '/script-menu' && <ScriptViewContainer />}
						{this.props.location.pathname === pathPrefix() + '/comment-mode' && <CommentsMenu />}
						{this.props.location.pathname === pathPrefix() + '/drafts' && <DraftsMenu />}
						<Box flex={1} display="flex" flexDirection="column" zIndex={3}>
							{this.props.location.pathname === pathPrefix() && <ContentViewMenu />}

							<Box h={`calc(100vh - ${this.props.location.pathname === pathPrefix() ? 6.5 : 3.5}rem)`} display="flex">
								<MainContentArea
									backgroundImage={this.props.backgroundImage}
									backgroundRepeat={this.props.backgroundRepeat}
									bg_color={this.props.bg_color}
									overlay_color={this.props.overlay_color}
									currentView={this.props.currentView}
									closeStuff={this.closeStuff}
									location={this.props.location}
									isMobileView={isMobileView}
									renderRevertButtons={this.renderRevertButtons}
									scale={this.props.scale}
									setData={this.props.setData}
								/>
								{this.props.location.pathname === pathPrefix() && <RightSidebarMenu />}
							</Box>
						</Box>
						{this.props.location.pathname === pathPrefix() + '/script-menu' && <ScriptMenu />}
					</Box>
				</Box>
			</div>
		);
	}

	closeStuff = (e) => {
		if (
			e &&
			(!domHelper.findAncestor(e.target, 'content-container') ||
				e.target.classList.value.includes('background-image-edit-container') ||
				e.target.classList.value.includes('edit-container')) &&
			!e.target.classList.value.includes('mobile-revert-button') &&
			!domHelper.findAncestor(e.target, 'cke_float') &&
			!domHelper.findAncestor(e.target, 'SETTINGS') &&
			!domHelper.findAncestor(e.target, 'SCRIPT') &&
			!domHelper.findAncestor(e.target, 'code-editor-wrapper') &&
			!domHelper.findAncestor(e.target, 'script-view-container') &&
			!domHelper.findAncestor(e.target, 'right-settings') &&
			!domHelper.findAncestor(e.target, 'add-first-container') &&
			!e.target.classList.value.includes('comments-menu') &&
			!domHelper.findAncestor(e.target, 'comments-menu') &&
			!e.target.classList.value.includes('comments-menu-item') &&
			!e.target.classList.value.includes('mobile-revert-buttons') &&
			!domHelper.findAncestor(e.target, 'slices-settings-modal') &&
			!domHelper.findAncestor(e.target, 'mobile-revert-buttons') &&
			!domHelper.findAncestor(e.target, 'comments-menu-item') &&
			!domHelper.findAncestor(e.target, 'helper-grid-layout') &&
			!domHelper.findAncestor(e.target, 'helper-grid-element') &&
			!domHelper.findAncestor(e.target, 'left-sidebar-menu') &&
			!domHelper.findAncestor(e.target, 'MuiDialog-root') &&
			!domHelper.findAncestor(e.target, 'orientation-button') &&
			!e.target.classList.value.includes('chakra-modal__content-container') &&
			!domHelper.findAncestor(e.target, 'chakra-modal__content-container')
		) {
			const contentStore = useContentStore.getState();
			contentStore.unsetClickedElement();
			contentStore.unsetClickedRow();
			contentStore.deactivateAddStructureBoxHelper();
			contentStore.deactivateAddElementBoxHelper();
		}
	};

	renderRevertButtons = () => {
		return <RevertOptions onRevertClick={this.onRevertClick} {...this.props} />;
	};

	onRevertClick = (type) => {
		const contentStore = useContentStore.getState();

		switch (type) {
			case RevertTypes.ALL:
				contentStore.revertMobileView();
				break;

			case RevertTypes.GENERAL_SETTINGS:
				contentStore.revertGeneralSettings();
				break;

			case RevertTypes.ROW_SETTINGS:
				contentStore.revertAllRowSettings();
				break;

			case RevertTypes.ROW_ORDER:
				contentStore.revertAllRowOrder();
				break;

			case RevertTypes.SELECTED_ROW_SETTINGS:
				contentStore.revertRowSettings(this.props.activeRow);
				break;

			case RevertTypes.SELECTED_ROW_ORDER:
				contentStore.revertRowOrder(this.props.activeRow);
				break;

			case RevertTypes.SELECTED_ELEMENT_SETTINGS:
				contentStore.revertElementSettings(this.props.activeElement);
				break;

			default:
				return null;
		}
	};

	applyAndDownloadFonts = () => {
		const fonts = this.props.customFonts
			? [...useTextEditorStore.getState().custom_fonts, ...this.props.customFonts]
			: useTextEditorStore.getState().custom_fonts;
		addFontToStylesheets(fonts);
		/* istanbul ignore next */
		if (fonts && fonts.length) {
			fonts.forEach((font) => {
				useTextEditorStore.getState().addCustomFont(font);
			});
		}
	};
}

const MainContentArea = (props) => {
	const [showPercentage, setShowPercentage] = React.useState(false);
	const { currentView } = props;

	const getBackgroundImage = () => {
		if (props.isMobileView) {
			return {};
		}

		return (
			(props.backgroundImage && {
				bgRepeat: props.backgroundRepeat === 'full' ? 'no-repeat' : props.backgroundRepeat,
				bgImage: `url('${props.backgroundImage}')`,
				bgSize: props.backgroundRepeat === 'full' ? '100%' : '',
			}) ||
			{}
		);
	};

	const getBackgroundColor = () => {
		if (props.isMobileView) {
			return '';
		}
		return props.bg_color;
	};

	const onClose = () => {
		browserHistory.push(pathPrefix() + location.search);
	};

	return (
		<Box flex={1} h="100%" position="relative">
			{props.isMobileView && props.renderRevertButtons()}
			<Box
				id="main-area-container"
				h="100%"
				display="flex"
				justifyContent="center"
				overflowY="auto"
				overflowX="hidden"
				bgColor={getBackgroundColor()}
				{...getBackgroundImage()}
				onMouseDown={(e) => {
					if (e.clientX + 13 >= e.target.getBoundingClientRect().right && (!e.target.className || '').includes('element-dnd-item')) {
						e.preventDefault();
						e.stopPropagation();
					}
				}}
			>
				{(currentView === ViewTypes.DESIGN_VIEW || currentView === ViewTypes.MOBILE_VIEW) && (
					<ContentContainer closeStuff={props.closeStuff} location={props.location} />
				)}
			</Box>
			{props.location.pathname === pathPrefix() + '/drafts' && (
				<Box position="absolute" top={0} left={0} right={0} bottom={0} zIndex={10} className="drafts-drawer-overlay" onClick={onClose} />
			)}
			<Box
				position="absolute"
				bottom={5}
				left={5}
				zIndex={1200}
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				gap={1}
				onMouseEnter={() => setShowPercentage(true)}
				onMouseLeave={() => setShowPercentage(false)}
			>
				{showPercentage && <Box fontWeight="semibold">{parseInt(props.scale * 100)}%</Box>}
				<Tooltip placement="auto" label="Scale up">
					<IconButton
						aria-label="Scale up"
						variant="solid"
						icon={<Icon path={mdiPlus} />}
						size="sm"
						colorScheme="neutral"
						data-testid="scale-up"
						onClick={() => {
							const scale = props.scale + 0.1;
							if (scale < 1.1) {
								props.setData({ scale });
							}
						}}
					/>
				</Tooltip>
				<Tooltip placement="auto" label="Scale down">
					<IconButton
						aria-label="Scale down"
						variant="solid"
						icon={<Icon path={mdiMinus} />}
						size="sm"
						colorScheme="neutral"
						data-testid="scale-down"
						onClick={() => {
							const scale = props.scale - 0.1;
							if (scale > 0.4) {
								props.setData({ scale });
							}
						}}
					/>
				</Tooltip>
				<Tooltip placement="auto" label="Reset scale">
					<IconButton
						aria-label="Reset scale"
						variant="solid"
						icon={<Icon path={mdiRestore} />}
						size="sm"
						colorScheme="neutral"
						data-testid="scale-reset"
						onClick={() => {
							props.setData({ scale: 1 });
						}}
					/>
				</Tooltip>
			</Box>
			<AutosaveNotificationPopup />
		</Box>
	);
};

const RevertOptions = (props) => {
	const [isActive, setIsActive] = React.useState(false);
	const { activeElement, activeRow } = props;

	return (
		<Box position="absolute" top={5} right={5} zIndex={2}>
			<Menu computePositionOnMount>
				<MenuButton
					as={Button}
					size="sm"
					rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
					colorScheme="neutral"
					data-testid="revert-settings"
					className="mobile-revert-buttons"
				>
					Revert
				</MenuButton>
				<MenuList>
					{!activeElement && !activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.ALL)} data-testid="revert-buttons">
							Revert all changes
						</MenuItem>
					)}
					{!activeElement && !activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.GENERAL_SETTINGS)} data-testid="revert-buttons">
							Revert general settings
						</MenuItem>
					)}
					{!activeElement && !activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.ROW_ORDER)} data-testid="revert-buttons">
							Revert row orders
						</MenuItem>
					)}
					{!activeElement && !activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.ROW_SETTINGS)} data-testid="revert-buttons">
							Revert layout settings
						</MenuItem>
					)}
					{activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.SELECTED_ROW_ORDER)} data-testid="revert-buttons">
							Revert selected layout order
						</MenuItem>
					)}
					{activeRow && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.SELECTED_ROW_SETTINGS)} data-testid="revert-buttons">
							Revert selected layout settings
						</MenuItem>
					)}
					{activeElement && (
						<MenuItem onClick={() => props.onRevertClick(RevertTypes.SELECTED_ELEMENT_SETTINGS)} data-testid="revert-buttons">
							Revert selected item settings
						</MenuItem>
					)}
				</MenuList>
			</Menu>
		</Box>
	);
};

const CustomCssAlert = () => {
	const [show, setShow] = React.useState(true);

	if (!show) return null;

	return (
		<Alert
			position="fixed"
			left={0}
			right={0}
			bottom={0}
			zIndex={110}
			display="flex"
			alignItems="center"
			status="info"
			data-testid="custom-style-alert"
		>
			<AlertIcon />
			<AlertDescription>
				Style in-use is saved as CSS code, and the changes will only be visible when the form is seen in Preview.
			</AlertDescription>
			<CloseButton ml="auto" onClick={() => setShow(false)} />
		</Alert>
	);
};

const MainBodyWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return {
			scale: state.scale,
			toggleGeneralSettings: state.toggleGeneralSettings,
			setData: state.setData,
		};
	}, shallow);
	const contentData = useContentStore((state) => {
		return {
			backgroundImage: state.content.background_image,
			backgroundRepeat: state.content.background_repeat,
			bg_color: state.content.bg_color,
			overlay_color: state.content.overlay_color,
			customFonts: state.content.customFonts,
			activeElement: state.helper.clicked_element,
			activeRow: state.helper.clicked_row,
			customCss: state.content.customCss,
			canBeModified: state.canBeModified,
		};
	}, shallow);
	const { currentView } = useContentViewStore();

	return <MainBody {...leftSidebarData} {...contentData} currentView={currentView} {...props} />;
};

export default MainBodyWrapper;
