import React from 'react';

//components
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import ShadowsSettings from '../../shared/settings/ShadowsSettings.react';

import useContentStore from '../../../../stores/ContentStore';

import { SettingsBox } from '../../settingsContext/SettingsContext';

export const SharedSettingComponents = React.memo((props) => {
	return (
		<SettingsBox>
			<ColorPickerSetting
				type="background_color"
				onChange={props.onChange}
				hex={props.hex}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<ShadowsSettings
				selectedValue={
					props.box_shadow || {
						offsetx: 1,
						offsety: 1,
						blur: 10,
						spread: 1,
						color: '#000',
					}
				}
				onChange={props.onChange}
				label="Shadow settings"
			/>
		</SettingsBox>
	);
});

class SharedSettings extends React.PureComponent {
	render() {
		return (
			<div className="shared-settings">
				<SharedSettingComponents {...this.props} onChange={this.onChange} />
			</div>
		);
	}

	onChange = (data) => {
		if (this.props.onChange) {
			this.props.onChange(data);
		} else {
			let settings = {
				[data.type]: data.value,
			};

			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
		}
	};
}

export default SharedSettings;
