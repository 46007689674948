import React from 'react';
//components

import useContentStore from '@/stores/ContentStore';

import { Box } from '@chakra-ui/react';
import SharedInputSettings from './SharedInput-settings.react';

export const SingleSelectSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class SingleSelectSettings extends React.PureComponent {
	render() {
		return (
			<Box className="text-input-settings">
				<SingleSelectSettingComponents
					{...this.props}
					onChange={this.onChange}
					settings={{
						options: {
							active: true,
						},
						hiddenField: {
							active: false,
						},
						prefill: {
							active: false,
						},
					}}
				/>
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default SingleSelectSettings;
