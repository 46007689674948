import { useEffect } from 'react';

import StylingPreview from '../components/StylingPreview';
import StylingHeader from '../components/StylingHeader';
import StylingLeftSidebar from '../components/StylingLeftSidebar';

import useStylingStore from '../../../stores/StylingStore';
import { Box, CircularProgress } from '@chakra-ui/react';

export const StylingEdit = (props) => {
	const isLoading = useStylingStore((state) => state.isLoading);

	useEffect(() => {
		useStylingStore.getState().getStyle(props.params.id);
		setTimeout(() => {
			useStylingStore.getState().generatePreview();
		}, 1);
	}, []);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" h="100%">
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
			</Box>
		);
	}

	return (
		<Box h="100%">
			<StylingHeader id={props.params.id} />
			<Box display="flex" h="calc(100% - 4.5rem)" px={7} pb={7}>
				<Box display="flex" flex={1} borderRadius="lg" border="1px solid" borderColor="chakra-border-color">
					<StylingLeftSidebar />
					<StylingPreview />
				</Box>
			</Box>
		</Box>
	);
};

export default StylingEdit;
