import { memo } from 'react';

import { Box, IconButton, Input, Tooltip } from '@chakra-ui/react';

import { mdiClose, mdiDragVertical, mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '../Icon';

export const FormInputOption = ({ value, label, onChangeInput, onRemoveOptions, index, hasCustomValues }) => (
	<Box
		display="flex"
		py={2}
		alignItems="start"
		justifyContent="space-between"
		border="1px solid var(--colors-chakra-border-color)"
		borderRadius="md"
	>
		<IconButton
			aria-label="Drag"
			variant="ghost"
			colorScheme="neutral"
			color="chakra-placeholder-color"
			size="sm"
			cursor="grab"
			icon={<Icon path={mdiDragVertical} />}
		/>

		<Box display="flex" flexDirection={'column'} alignItems="center" justifyContent="space-between" gap={1} width="80%">
			<Input title="Label" placeholder="Label" w="100%" value={label} onChange={(e) => onChangeInput(e.target.value, 'label', index)} />

			{hasCustomValues && (
				<Input title="Value" placeholder="Value" w="100%" value={value} onChange={(e) => onChangeInput(e.target.value, 'value', index)} />
			)}
		</Box>

		<Tooltip label="Remove">
			<IconButton
				aria-label="Remove"
				variant="ghost"
				colorScheme="neutral"
				size="sm"
				icon={<Icon path={mdiClose} />}
				onClick={() => onRemoveOptions(index)}
			/>
		</Tooltip>
	</Box>
);

export default memo(FormInputOption);
