import { create } from 'zustand';
import { getGoogleFonts } from '../util/apiUtils/entities';
import {
	NotificationTypes,
	baseBackButtonStyles,
	baseButtonStyles,
	baseCheckboxStyles,
	baseFieldStyles,
	baseImageStyles,
	baseRadioStyles,
	baseRecaptchaStyles,
	baseSocialStyles,
	baseSpacerStyles,
	baseTextStyles,
} from '../util/resources';
import { produce } from 'immer';
import { generateGlobalStyles } from '../components/transformer/CssGenerators/CssClassGenerators';
import { createDelayedInterval, generateGuid } from '../util/helper';

import transform from '../components/transformer/transformer';

import previewJson from './jsons/previewData';
import previewJsonProd from './jsons/previewDataProd';
import previewJsonPreProd from './jsons/previewDataPreprod';

import { createGlobalStyles, deleteStyle, saveGlobalStyles, getAllStyles, getStyle, getStylesFromRsp } from '../util/apiUtils/styling';
import { showNotification } from './NotificationStore';
import { browserHistory } from 'react-router';
import { getRoute } from '../components/Table/strings';

const delayedInterval = createDelayedInterval();

const initialSettings = {
	field: baseFieldStyles,
	radio: baseRadioStyles,
	checkbox: baseCheckboxStyles,
	recaptcha: baseRecaptchaStyles,
	submitButton: baseButtonStyles,
	backButton: baseBackButtonStyles,
	spacer: baseSpacerStyles,
	text: baseTextStyles,
	social: baseSocialStyles,
	image: baseImageStyles,
};

export const getDefaultStyles = () => ({
	settings: initialSettings,
	stylesString: generateGlobalStyles(initialSettings),
	isCss: false,
});

const useStylingStore = create((set, get) => ({
	savedStyles: undefined,
	styles: getDefaultStyles(),
	attributes: {
		name: '',
	},
	isLoading: false,
	list: [],
	preview: '',
	changeStyle: (type, data) => {
		if (!get().styles.isCss) {
			set(
				produce((state) => {
					state.styles.settings[type] = { ...state.styles.settings[type], ...data };
				}),
			);

			get().generateStyleString();
		}
	},
	changeStyleString: (value) => {
		set(
			produce((state) => {
				state.styles.stylesString = value;
			}),
		);
	},
	switchToCode: () => {
		set(
			produce((state) => {
				state.styles.isCss = true;
			}),
		);
	},
	resetStyle: () => {
		const stylesString = generateGlobalStyles(get().styles.settings);
		set(
			produce((state) => {
				state.styles.stylesString = stylesString;
				state.styles.isCss = false;
			}),
		);
	},
	cancelStyle: () => {
		set(
			produce((state) => {
				state.styles.settings = state.savedStyles.settings;
				state.styles.stylesString = state.savedStyles.stylesString;
			}),
		);
	},
	generateStyleString: () => {
		const stylesString = generateGlobalStyles(get().styles.settings);
		delayedInterval(() => {
			set(
				produce((state) => {
					state.styles.stylesString = stylesString;
				}),
			);
		}, 500);
	},
	saveStyle: async (key) => {
		try {
			const rsp = await saveGlobalStyles(get().styles, key, { ...get().attributes, isCss: get().styles.isCss.toString() });
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Global styling saved successfully.' });
			browserHistory.push(getRoute.stylingList());
			// set(
			// 	produce((state) => {
			// 		state.savedStyles = state.styles;
			// 	})
			// );
		} catch (e) {
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to save global styling' });
		}
	},
	renameStyle: async ({ name, id }) => {
		try {
			const getRsp = await getStyle(id);

			let styles = JSON.parse(getRsp.data.Content.Data);

			const attributes = {};

			getRsp.data.Attributes.forEach((attr) => {
				attributes[attr.Key] = attr.Value;
			});

			attributes.name = name;

			await saveGlobalStyles(styles, id, attributes);

			const index = get().list.findIndex((item) => item.id === id);

			set(
				produce((state) => {
					state.list[index].name = name;
				}),
			);
		} catch (e) {
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to rename global styling' });
		}
	},
	duplicateStyle: async (key) => {
		try {
			const getRsp = await getStyle(key);

			let styles = JSON.parse(getRsp.data.Content.Data);

			const attributes = {};

			getRsp.data.Attributes.forEach((attr) => {
				attributes[attr.Key] = attr.Value;
			});

			attributes.name = `${attributes.name} (Copy)`;

			await createGlobalStyles(styles, generateGuid(), attributes);

			get().getAllStyles();
		} catch (e) {
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to duplicate global styling' });
		}
	},
	getAllStyles: async () => {
		set(
			produce((state) => {
				state.isLoading = get().list.length ? false : true;
			}),
		);
		try {
			const rsp = await getAllStyles();
			set(
				produce((state) => {
					state.isLoading = false;
					state.list = getStylesFromRsp(rsp);
				}),
			);
		} catch (e) {
			set(
				produce((state) => {
					state.isLoading = false;
				}),
			);
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to get styles' });
		}
	},
	deleteStyle: async (key) => {
		try {
			const rsp = await deleteStyle(key);
			// showNotification({ type: NotificationTypes.SUCCESS, text: 'Global styling deleted successfully.' });
			get().getAllStyles();
		} catch (e) {
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to delete styles' });
		}
	},
	getStyle: async (key) => {
		set(
			produce((state) => {
				state.isLoading = true;
			}),
		);

		try {
			const rsp = await getStyle(key);

			let styles = JSON.parse(rsp.data.Content.Data);

			const attributes = {};

			rsp.data.Attributes.forEach((attr) => {
				attributes[attr.Key] = attr.Value;
			});

			set(
				produce((state) => {
					state.isLoading = false;
					state.styles.settings = { ...state.styles.settings, ...styles.settings };
					state.styles.isCss = styles.isCss;
					state.styles.stylesString = styles.stylesString;
					state.savedStyles = styles;
					state.attributes = attributes;
				}),
			);
		} catch (e) {
			console.error(e);
			set(
				produce((state) => {
					state.isLoading = false;
				}),
			);
		}
	},
	createStyle: async (attributes) => {
		const styles = getDefaultStyles();
		try {
			const rsp = await createGlobalStyles(styles, generateGuid(), { ...attributes, isCss: 'false' });
			browserHistory.push(getRoute.stylingEdit('forms', rsp.data.Key));
		} catch (e) {
			console.error(e);
			showNotification({ type: NotificationTypes.ERROR, text: 'Failed to create styling' });
		}
	},
	generatePreview: () => {
		set(
			produce((state) => {
				if (import.meta.env.MODE === 'preproduction') {
					state.preview = transform(previewJsonPreProd, true);
				} else if (import.meta.env.MODE === 'production') {
					state.preview = transform(previewJsonProd, true);
				} else {
					state.preview = transform(previewJson, true);
				}
			}),
		);
	},
	setData: (data) => {
		set((state) => ({ ...state, ...data }));
	},
}));

export default useStylingStore;
