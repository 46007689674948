import React, { useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { ButtonGroup, IconButton, Text, GridItem, Tooltip } from '@chakra-ui/react';

import { CriteriaComparer } from './CriteriaComparer';
import { CriteriaValue } from './CriteriaValue';

import useContentStore from '@/stores/ContentStore';

import { ComparerTypes, LogicErrors } from '@/util/resources';
import { ConditionsCommonProps, CriteriaValueType, CriteriaProps } from '../models/model';
import { createBaseCriteria } from '@/components/Conditions/utils';
import { useParentSettings } from '../hooks/useParentSettings';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';

export interface ICriteriaProps extends ConditionsCommonProps {
	comparer: keyof typeof ComparerTypes;
	value: CriteriaValueType;
	parentAddress: number[];
}

const Criteria: React.FC<ICriteriaProps> = ({ comparer, fieldType, index, isFirst, isLast, value, ...restProps }) => {
	const { address, getParentSettings, parentAddress, getFieldOptions, errors } = useParentSettings(restProps.parentAddress, index);

	const cachedComparer = useRef(comparer);
	const { changeConditionSetting, canBeModified } = useContentStore(
		useShallow((state) => ({ changeConditionSetting: state.changeConditionSetting, canBeModified: state.canBeModified })),
	);

	const onChange = React.useCallback(
		(type, value) => {
			changeConditionSetting(address, { [type]: value });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[address],
	);

	const parentAddressValue = JSON.stringify(parentAddress);
	const onChangeParent = React.useCallback(
		(type, value) => {
			useContentStore.getState().changeConditionSetting(parentAddress, { [type]: value });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[parentAddressValue],
	);

	const onAddCriteria = React.useCallback(() => {
		const settings = [...getParentSettings()];
		settings.splice(index + 1, 0, createBaseCriteria(fieldType) as CriteriaProps);
		onChangeParent('settings', settings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldType, index, parentAddress]);

	const onRemoveCriteria = React.useCallback(() => {
		const settings = [...getParentSettings()];
		settings.splice(index, 1);
		onChangeParent('settings', settings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	useEffect(() => {
		if (comparer !== cachedComparer.current) onChange('value', '');
		cachedComparer.current = comparer;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comparer, index]);

	return (
		<>
			{!isFirst && (
				<GridItem>
					<Text display="flex" justifyContent="end" alignItems="center" h="100%">
						or
					</Text>
				</GridItem>
			)}

			<GridItem>
				<CriteriaComparer
					fieldType={fieldType}
					value={comparer}
					setValue={(value) => onChange('comparer', value)}
					disabled={!canBeModified}
					hasError={errors.includes(LogicErrors.missingComparer)}
				/>
			</GridItem>

			<GridItem>
				<CriteriaValue
					fieldType={fieldType}
					comparer={comparer}
					value={value}
					setValue={(newValue) => onChange('value', newValue)}
					options={getFieldOptions() ?? []}
					disabled={!canBeModified}
					hasError={errors.includes(LogicErrors.missingValue) || errors.includes(LogicErrors.invalidValue)}
				/>
			</GridItem>

			<GridItem pr={1}>
				<ButtonGroup
					alignItems="center"
					justifyContent="center"
					h="100%"
					colorScheme="neutral"
					size="sm"
					variant="ghost"
					style={{ userSelect: 'none' }}
				>
					<Tooltip label="Add condition" isDisabled={!canBeModified}>
						<IconButton
							aria-label="Add condition"
							onClick={onAddCriteria}
							isDisabled={!canBeModified}
							icon={<Icon path={mdiPlusCircleOutline} />}
							data-testid="criteria-add-btn"
						/>
					</Tooltip>

					{!(isFirst && isLast) && (
						<Tooltip label="Remove" isDisabled={!canBeModified}>
							<IconButton
								variant="ghostColorOnHover"
								colorScheme="danger"
								aria-label="Remove"
								onClick={onRemoveCriteria}
								isDisabled={!canBeModified}
								icon={<Icon path={mdiTrashCanOutline} />}
								data-testid="criteria-remove-btn"
							>
								delete
							</IconButton>
						</Tooltip>
					)}
				</ButtonGroup>
			</GridItem>
		</>
	);
};

export default React.memo(Criteria);
