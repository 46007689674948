import React from 'react';

class DraggableStructurePreview extends React.PureComponent {
	render() {
		let style = {
			width: 300,
			height: 50,
			border: '2px dashed grey',
		};

		return <div style={style} />;
	}
}

export default DraggableStructurePreview;
