import { create } from 'zustand';
import { getGoogleFonts } from '../util/apiUtils/entities';

const useDesignerStore = create((set, get) => ({
	getGoogleFonts: async (successCb, errorCb) => {
		try {
			const fontsResponse = await getGoogleFonts();

			if (fontsResponse && fontsResponse.data) {
				successCb && successCb(fontsResponse.data.items);
			}
		} catch (e) {
			console.error(e, true);
			errorCb && errorCb();
		}
	},
}));

export default useDesignerStore;
