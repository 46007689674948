import * as React from 'react';
import { Box, Text, FormControl, FormLabel, Switch, LinkBox, Card, CardBody, Button, Icon as ChakraIcon } from '@chakra-ui/react';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiCommentTextOutline } from '@mdi/js';
import Moment from 'moment';
import qs from 'query-string';
import { browserHistory } from 'react-router';

import useContentStore from '../../../stores/ContentStore';
import useCommentStore from '../../../stores/CommentStore';

import { findRowByUniqueId } from '../../../util/helper';
import { getUser } from '../../../util/auth/auth';

import { CommentPreview } from '../content/Row.react';

const CommentsMenuWrapper = (props) => {
	const commentProps = useCommentStore();

	return <CommentsMenu {...commentProps} {...props} />;
};

class CommentsMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showReplies: [],
		};
	}

	componentDidMount() {
		let qsConfig = qs.parse(location.search);
		if (qsConfig.commentId) {
			const comment = this.props.flatComments.find((item) => {
				return (item.id = qsConfig.commentId);
			});
			setTimeout(() => {
				if (comment && comment.status === 'unresolved') {
					this.openComments(comment);
					delete qsConfig.commentId;
					browserHistory.push(location.pathname + '?' + qs.stringify(qsConfig));
				}
			}, 100);
		}
	}

	render() {
		const userData = getUser();
		const teamMemberId = userData.sub;

		return (
			<Box
				w="sm"
				py={3}
				display="flex"
				flexDirection="column"
				gap={3}
				borderRight="1px solid"
				borderRightColor="chakra-border-color"
				backgroundColor="chakra-subtle-bg"
				data-testid={'comments-sidebar'}
			>
				<Box px={3} display="flex" alignItems="center">
					{this.props.unreadComments ? (
						<Button
							size="xs"
							variant="link"
							colorScheme="neutral"
							onClick={(e) => {
								e.stopPropagation();

								this.props.markAllCommentsAsSeen();
							}}
						>
							Mark all as read
						</Button>
					) : null}
					<FormControl width="fit-content" ml="auto" display="flex" alignItems="center">
						<FormLabel variant="small" htmlFor="show-resolved" mb="0">
							Include resolved
						</FormLabel>
						<Switch
							id="show-resolved"
							isChecked={this.props.showResolvedComments}
							data-testid="show-resolved-switch"
							onChange={() => this.props.setShowResolvedComments(!this.props.showResolvedComments)}
						/>
					</FormControl>
				</Box>
				<Box flex={1} px={3} overflowY="auto" display="flex" flexDirection="column" gap={3}>
					{this.props.comments && this.props.comments.length ? (
						this.props.comments.map((item, i) => {
							const isResolved = item.status === 'resolved';
							const isUnseen = !item.seenBy.includes(teamMemberId);

							if (!this.props.showResolvedComments && isResolved) {
								return null;
							}

							return (
								<Card
									size="sm"
									variant="outline"
									layerStyle="interactive.fill"
									key={i}
									borderColor={isUnseen ? 'primary' : 'chakra-border-color'}
									cursor={isResolved ? 'not-allowed' : 'pointer'}
									data-testid="comments-menu-item"
									className="comments-menu-item"
									onClick={() => !isResolved && this.openComments(item)}
								>
									<CardBody flexBasis={20} display="flex" gap={4}>
										<Box flex={1}>
											<Box mb={2} display="flex" alignItems="center" justifyContent="space-between" className="notranslate">
												<Text variant="strong">{item.user}</Text>
												<Text variant="small">{Moment(item.date).format(`MMM, DD HH:mm`)}</Text>
											</Box>
											<CommentPreview value={item.content.value} />
											{item.replies &&
												this.state.showReplies.includes(i) &&
												item.replies.map((reply, ri) => {
													return (
														<Box
															key={ri}
															ml={4}
															py={2}
															borderBottomWidth={ri === item.replies.length - 1 ? 0 : 1}
															borderBottomStyle="solid"
															borderBottomColor="chakra-border-color"
															data-testid="reply-menu-item"
														>
															<Box pl={2} borderLeft="3px solid" borderLeftColor="chakra-border-color">
																<Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
																	<Text variant="strong">{reply.user}</Text>
																	<Text variant="small">{Moment(reply.date).format(`MMM, DD HH:mm`)}</Text>
																</Box>
																<CommentPreview value={reply.content.value} />
															</Box>
														</Box>
													);
												})}
											<Box mt={2} display="flex" alignItems="center">
												{item.replies && item.replies.length ? (
													<Button
														size="xs"
														variant="link"
														colorScheme="neutral"
														data-testid="comments-menu-item-reply"
														onClick={(e) => {
															e.stopPropagation();

															const showReplies = [...this.state.showReplies];
															const index = showReplies.indexOf(i);

															if (index > -1) {
																showReplies.splice(index, 1);
															} else {
																showReplies.push(i);
															}

															this.setState({ showReplies });
														}}
													>
														{item.replies.length} {item.replies.length > 1 ? 'replies' : 'reply'}
													</Button>
												) : null}
												<Box ml="auto" display="flex" gap={2}>
													{isUnseen && (
														<Button
															size="xs"
															variant="link"
															colorScheme="neutral"
															onClick={(e) => {
																e.stopPropagation();

																this.props.editComment({
																	...item,
																	seenBy: [...item.seenBy, teamMemberId],
																});
															}}
														>
															Mark as read
														</Button>
													)}
													<Button
														size="xs"
														variant="link"
														colorScheme={isResolved ? 'success' : 'neutral'}
														onClick={(e) => {
															e.stopPropagation();

															const seenBy = item.seenBy.includes(teamMemberId) ? [...item.seenBy] : [...item.seenBy, teamMemberId];

															this.props.editComment({
																...item,
																status: isResolved ? 'unresolved' : 'resolved',
																seenBy,
															});

															isResolved && this.openComments(item);
														}}
													>
														{isResolved ? 'Resolved' : 'Resolve'}
													</Button>
												</Box>
											</Box>
										</Box>
									</CardBody>
								</Card>
							);
						})
					) : (
						<Text variant="subtle">No comments</Text>
					)}
				</Box>
			</Box>
		);
	}

	openComments = (item) => {
		const contentStore = useContentStore.getState();
		switch (item.type) {
			case 'row':
				const { row, index } = findRowByUniqueId(contentStore.content.rows, item.targetId);
				contentStore.setClickedRow(row.id);
				break;

			case 'element':
				contentStore.content.rows.forEach((row) => {
					row.slots.forEach((slot, slotIndex) => {
						slot.components.forEach((component, componentIndex) => {
							if (component.uniqueId === item.targetId) {
								contentStore.setClickedElement({ rowId: row.id, slot: slotIndex, component: componentIndex });
							}
						});
					});
				});
				break;
		}
	};
}

export default CommentsMenuWrapper;
