import React from 'react';

import ButtonSetting from './ButtonSetting.react';

import useModalStore from '../../../../stores/ModalStore';
import useContentStore from '../../../../stores/ContentStore';

import { mdiClose, mdiImagePlusOutline } from '@mdi/js';
import { Box } from '@chakra-ui/react';

const customStyle = { pt: 5, pb: 5, w: '100%' };
const customStyleRemove = { ...customStyle, colorScheme: 'danger' };

class ImagePickerButtons extends React.PureComponent {
	render() {
		return (
			<Box>
				<ButtonSetting
					onClick={this.openWidget}
					tooltip={this.props.label ? this.props.label : this.props.image ? 'Change image' : 'Add image'}
					label={this.props.label ? this.props.label : this.props.image ? 'Change image' : 'Add image'}
					icon={mdiImagePlusOutline}
					testId="image"
					customStyle={customStyle}
				/>
				{this.props.image && (
					<ButtonSetting
						tooltip={this.props.removeLabel || 'Remove image'}
						label={this.props.removeLabel || 'Remove image'}
						icon={mdiClose}
						testId="close"
						onClick={this.removeSelectedImage}
						customStyle={customStyleRemove}
					/>
				)}
			</Box>
		);
	}

	openWidget = () => {
		useModalStore.getState().showImagePickerModal({ ...this.props, id: Date.now() });
	};

	removeSelectedImage = () => {
		useContentStore.getState().setImage({ type: this.props.type, url: '', imageType: '', details: this.props.details });
	};
}

export default ImagePickerButtons;
