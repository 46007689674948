import React from 'react';

export default class ElementComponent extends React.PureComponent {
	setAttributeInRef(attr, value) {
		return node => {
			if (node) {
				node.setAttribute(attr, value);
			}
		};
	}

	//takes arguments as follows: setAttributesInRef({attr: 'align', value: 'center'}, {attr: 'valign', value: 'top'}...),
	setAttributesInRef(...params) {
		return node => {
			if (node) {
				for (let obj of params) {
					if (obj.attr && obj.value) {
						node.setAttribute(obj.attr, obj.value);
					} else {
						console.error(
							`setAttributesInRef() takes arguments as follows, for example: setAttributesInRef({attr: 'align', value: 'center'}, {attr: 'valign', value: 'top'}...)`
						);
					}
				}
			}
		};
	}

	// appendCustomCssAsString(cssString) {
	// 	return node => {
	// 		if (node && cssString) {
	// 			let style = node.getAttribute('style') || '';
	// 			node.setAttribute('style', style + cssString);
	// 		}
	// 	};
	// }
}
