import React from 'react';
import LandingRow from './landingPage/Row.react';

import useContentStore from '../../stores/ContentStore';

import { findRow } from '../../util/helper';

class TransformerComponent extends React.PureComponent {
	render() {
		const content = this.props.content ? this.props.content : useContentStore.getState().content;

		const groupedRows = {};

		const sortedRows = [...content.rows].sort((a, b) => a.pageIndex - b.pageIndex);

		content.rows.forEach((row, i) => {
			let generalStyles = {
				text_line_height: content.text_line_height || '1',
				font_size: content.font_size,
				font_family: content.font_family,
				color: content.color === 'transparent' ? '#000000' : content.color,
				background_image: content.background_image,
				background_repeat: content.background_repeat,
				bg_color: content.bg_color,
				title: content.title,
				borderWidth: content.borderWidth,
				borderColor: content.borderColor,
				structureWidth: content.structureWidth,
				retina_images: content.retina_images,
			};

			const lastRow = sortedRows.length - 1 === i;

			const rowComp = (
				<LandingRow
					{...row}
					key={row.id}
					lastPage={content.lastPage}
					general_styles={generalStyles}
					lastRow={lastRow}
					entityId={this.props.entityId}
				/>
			);

			if (typeof row.pageIndex !== 'undefined' && row.type !== 'pagePlaceholder') {
				if (groupedRows[row.pageIndex]) {
					groupedRows[row.pageIndex].push(rowComp);
				} else {
					groupedRows[row.pageIndex] = [rowComp];
				}
			}
		});

		let pages = [];

		for (const key in groupedRows) {
			const page = groupedRows[key].map((item) => item);

			pages = [
				...pages,
				<div
					key={`page-${key}`}
					data-page-index={key}
					className="form-page"
					style={{ display: parseInt(key, 10) > 0 ? 'none' : 'flex', opacity: parseInt(key, 10) > 0 ? 0 : 1, flexDirection: 'column' }}
				>
					{page}
				</div>,
			];
		}

		return (
			<div ref="content_html" className="transformer-content">
				{pages}
			</div>
		);
	}
}

export const renderContentRowBorder = (content, maxRows, rowPos) => {
	const borderWidth = content.borderWidth ? content.borderWidth : 0;
	const structureBorderRadius = content.structureBorderRadius ? content.structureBorderRadius : 0;
	const borderColor = content.borderColor ? content.borderColor : 'transparent';
	const borderProps = `${borderWidth}px solid ${borderColor}`;
	const defaultReturnObj = { borderWidthValue: borderWidth };
	if (borderWidth === 0 && structureBorderRadius === 0) {
		return defaultReturnObj;
	} else if (maxRows > 1 && rowPos === 0) {
		const borderRadius = structureBorderRadius
			? {
					borderTopLeftRadius: structureBorderRadius,
					borderTopRightRadius: structureBorderRadius,
				}
			: {};

		const border = borderWidth
			? {
					borderTop: borderProps,
					borderLeft: borderProps,
					borderRight: borderProps,
					borderBottom: 'none',
				}
			: {};

		return {
			...defaultReturnObj,
			...borderRadius,
			...border,
		};
	} else if (maxRows > 1 && rowPos > 0 && rowPos + 1 < maxRows) {
		const border = borderWidth
			? {
					borderTop: 'none',
					borderLeft: borderProps,
					borderRight: borderProps,
					borderBottom: 'none',
				}
			: {};

		return {
			...defaultReturnObj,
			...border,
		};
	} else if (maxRows > 1 && rowPos + 1 === maxRows) {
		const borderRadius = structureBorderRadius
			? {
					borderBottomLeftRadius: structureBorderRadius,
					borderBottomRightRadius: structureBorderRadius,
				}
			: {};

		const border = borderWidth
			? {
					borderTop: 'none',
					borderLeft: borderProps,
					borderRight: borderProps,
					borderBottom: borderProps,
				}
			: {};

		return {
			...defaultReturnObj,
			...borderRadius,
			...border,
		};
	} else if (maxRows === 1) {
		const border = borderWidth
			? {
					border: borderProps,
				}
			: {};

		const borderRadius = structureBorderRadius
			? {
					borderRadius: structureBorderRadius,
				}
			: {};

		return {
			...defaultReturnObj,
			...border,
			...borderRadius,
		};
	}
	return defaultReturnObj;
};

export default TransformerComponent;
