import {
	Box,
	Text,
	IconButton,
	InputGroup,
	Input,
	InputRightElement,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Tag,
	TagCloseButton,
	Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';

import { showNotification } from '@/stores/NotificationStore';
import { NotificationTypes } from '@/util/resources';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiPlus } from '@mdi/js';

export interface ITableTagsModalProps {
	isOpen: boolean;
	onClose: () => void;
	onAddTag: (
		e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>,
		tagValue: string,
	) => void;
	onRemoveTag: (
		e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>,
		tagValue: string,
		tagIndex: number,
	) => void;
	allTags: string[];
	tags: string[];
	inputPlaceholder?: string;
	tagsLabel?: string;
	title?: string;
	refName?: any;
	backspaceDeletesLastTag?: boolean;
}

const TableTagsModal: React.FC<ITableTagsModalProps> = ({
	isOpen,
	onClose,
	allTags,
	tags,
	title = 'Add labels',
	tagsLabel,
	onRemoveTag,
	inputPlaceholder,
	refName,
	backspaceDeletesLastTag,
	...rest
}) => {
	const [inputValue, setInputValue] = useState('');

	const onAddTag = (
		e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>,
		tagValue: string,
	) => {
		if (!tagValue.trim())
			return showNotification({
				text: `${refName?.singular ?? 'Label'} should not be empty`,
				type: NotificationTypes.ERROR,
			});

		if (tagValue.length < 3)
			return showNotification({
				text: `${refName?.plural ?? 'Labels'} must consist of 3 to 20 characters.`,
				type: NotificationTypes.ERROR,
			});

		if (tags.includes(tagValue))
			return showNotification({
				text: `${refName?.singular ?? 'Label'} already exists`,
				type: NotificationTypes.ERROR,
			});

		if (tags.length + 1 > 6)
			return showNotification({
				text: `Can't have more than 6 ${refName?.plural.toLowerCase() ?? 'labels'}`,
				type: NotificationTypes.ERROR,
			});

		setInputValue('');
		rest.onAddTag(e, tagValue);
	};

	const renderSuggestions = () => {
		const suggestions = inputValue ? allTags.filter((item) => item.includes(inputValue)) : allTags;

		if (!suggestions.length) return null;

		return (
			<Box borderRadius="sm" border="solid 2px" borderColor="chakra-border-color" mb={2.5} w="100%" maxH="44" overflow="auto">
				{suggestions.map((item, i) => {
					return (
						<Text
							alignItems="center"
							justifyContent="space-between"
							cursor="pointer"
							px={2.5}
							py={1.5}
							key={i}
							onClick={(e) => {
								if (tags.includes(item)) onRemoveTag(e, item, tags.indexOf(item));
								else onAddTag(e, item);
							}}
							data-testid={`suggestion-option-${item}`}
						>
							{item}
						</Text>
					);
				})}
			</Box>
		);
	};

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			onClose={() => {
				setInputValue('');
				onClose();
			}}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton data-testid="tags-input-modal-close" />

				<ModalBody>
					<div>
						<InputGroup size="lg" mb={1.5}>
							<Input
								size="lg"
								value={inputValue}
								autoFocus
								placeholder={inputPlaceholder}
								maxLength={20}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										onAddTag(e, inputValue);
									} else if (backspaceDeletesLastTag && !inputValue && e.keyCode === 8 && tags.length) {
										onRemoveTag(e, tags[tags.length - 1], tags.length - 1);
									}
								}}
								onChange={(e) => setInputValue(e.target.value)}
								data-testid="tags-input"
							/>

							<InputRightElement>
								<Tooltip label="Add">
									<IconButton
										aria-label="Add"
										variant="ghost"
										size="sm"
										icon={<Icon path={mdiPlus} />}
										onClick={(e) => onAddTag(e, inputValue)}
										data-testid="tags-input-add"
									/>
								</Tooltip>
							</InputRightElement>
						</InputGroup>

						{renderSuggestions()}

						<div>
							{tagsLabel && <Text mb={1.5}>{tagsLabel}</Text>}

							<Box w="100%" display="flex" flexWrap="wrap">
								{tags.map((item, i) => {
									return (
										<Tag key={i} my={0.5} mr={1.5} className="notranslate" title={item} data-testid="tags-item">
											<Text>{item}</Text>

											<TagCloseButton onClick={(e) => onRemoveTag(e, item, i)} />
										</Tag>
									);
								})}
							</Box>
						</div>
					</div>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default TableTagsModal;
