import React from 'react';
import moment from 'moment';

import { getContentHubBySearch } from '../../../../util/apiUtils/image';

import {
	Checkbox,
	CheckboxLabel,
	Select,
	SelectContainer,
	SelectOption,
	SelectOptionList,
	SelectPlaceholder,
	Loader,
	Image,
	Tags,
	Tag,
	TagText,
	TagClose,
	Button,
	Input,
	Box,
} from '@moosend/mooskin';

import { NotificationTypes } from '../../../../util/resources';

import { showNotification } from '../../../../stores/NotificationStore';
import useContentStore from '../../../../stores/ContentStore';

import { generateGuid } from '../../../../util/helper';
import { FilterComponent } from './ImageSetting.react';

const contentHubFilters = {
	equals: 'Equals',
	'starts-with': 'StartsWith',
	'ends-with': 'EndsWith',
	contains: 'Contains',
	// 'created-by': 'created_by_name',
	'created-date': 'created_on',
	filename: 'Filename',
	title: 'Title',
	'is-between': 'Between',
	'is-greater-than': 'GreaterThan',
	'is-less-than': 'LessThan',
	'does-not-equal': 'DoesNotEqual',
};

export const ContentHubImages = (props) => {
	const [loader, setLoader] = React.useState(true);
	const [imageData, setImageData] = React.useState({
		FileTypes: [],
		Items: {
			Items: [],
			TotalFilteredItems: 0,
			TotalItems: 0,
			TotalPages: 0,
		},
	});
	const [search, setSearch] = React.useState('');
	const [pagination, setPagination] = React.useState(1);
	const [showAdvanced, setShowAdvanced] = React.useState(false);
	// const [showStatusFilters, setShowStatusFilters] = React.useState(false);
	// const [statusFilters, setStatusFilters] = React.useState(props.contentHubData.filters);
	const [criteriaFilter, setCriteriaFilter] = React.useState('');
	const [comparisonFilter, setComparisonFilter] = React.useState('');
	const [valueFilter, setValueFilter] = React.useState('');
	const [secondaryValueFilter, setSecondaryValueFilter] = React.useState('');
	const [fileTypeFilters, setFileTypeFilters] = React.useState([]);
	const [customFilters, setCustomFilters] = React.useState([]);

	const debouncedSearch = useDebounce(search, 500);

	const logout = () => {
		useContentStore.getState().disconnectContentHub({ data: props.contentHubData.metadataKey, successCb: props.onDataChange });
	};

	const errorCb = (error) => {
		let errMsg;

		if (error && error.data) {
			if (error.data.ValidationErrors && error.data.ValidationErrors[0] && error.data.ValidationErrors[0].Message) {
				errMsg = error.data.ValidationErrors[0].Message;
			} else if (error.data.Message && error.data.Message.length && error.data.Code === -4001) {
				errMsg = error.data.Message;
				logout();
			} else {
				errMsg = 'Could not get images';
			}
		}
		showNotification({ type: NotificationTypes.ERROR, text: errMsg });
		setLoader(false);
		// for PLAT-4021, call logout() here if error arg matched agreement, in this case an invalid token error code or message
	};

	let successCb = (data) => {
		if (data) {
			setImageData(data);
		}
		setLoader(false);
	};

	const onInputChange = (e) => {
		const value = e.target.value;
		if ((value && !search) || (!value && search)) {
			setPagination(1);
		}
		setSearch(value);
	};

	const paginate = (pageValue) => {
		if (pageValue > 0) {
			setPagination(pageValue);
		}
	};

	const fetchImagesBySearch = () => {
		setLoader(true);
		// for PLAT-4010 add image extension filters here before sending them as payload. use fileTypeFilters
		const filters = customFilters.map((item) => {
			return item.filter;
		});
		return getContentHubBySearch(search, filters, pagination, successCb, errorCb, props.contentHubData);
	};

	React.useEffect(() => {
		fetchImagesBySearch();
	}, [debouncedSearch, customFilters, pagination]);

	const filterTags = [];

	customFilters.forEach((item) => {
		filterTags.push({ tag: item.label, onRemove: () => removeCustomFilter(item.id) });
	});

	fileTypeFilters.forEach((item) => {
		filterTags.push({ tag: item, onRemove: () => toggleFileTypeFilter(item) });
	});

	const addCustomFilter = () => {
		const label = `${criteriaFilter.replace(/-/g, ' ')} ${comparisonFilter.replace(/-/g, ' ')} ${valueFilter}${
			secondaryValueFilter ? ` and ${secondaryValueFilter}` : ''
		}`;

		const exists = customFilters.find((item) => {
			return item.label === label;
		});

		if (exists) {
			return;
		}

		const value = criteriaFilter === 'created-date' ? moment(valueFilter).format() : valueFilter;
		const secondaryValue =
			secondaryValueFilter && criteriaFilter === 'created-date' ? moment(secondaryValueFilter).format() : secondaryValueFilter;

		const customFilter = {
			label,
			id: generateGuid(),
			filter: {
				name: contentHubFilters[criteriaFilter],
				operator: contentHubFilters[comparisonFilter],
				values: secondaryValueFilter ? [value, secondaryValue] : [value],
			},
		};

		setCustomFilters([...customFilters, customFilter]);
		setValueFilter('');
		setComparisonFilter('');
		setCriteriaFilter('');
	};

	const removeCustomFilter = (id) => {
		const newCustomFilters = customFilters.filter((item) => {
			return item.id !== id;
		});

		setCustomFilters(newCustomFilters);
	};

	const toggleFileTypeFilter = (type) => {
		const exists = fileTypeFilters.find((item) => {
			return item === type;
		});

		if (exists) {
			const newFileTypeFilters = [...fileTypeFilters].filter((item) => {
				return item !== type;
			});

			setFileTypeFilters(newFileTypeFilters);
		} else {
			const newFileTypeFilters = [...fileTypeFilters];
			newFileTypeFilters.push(type);
			setFileTypeFilters(newFileTypeFilters);
		}
	};

	// PLAT-4010 Remove this as FE filtering is not needed, and use imageData.Items.Items instead of filteredImages throughout the component
	const filteredImages = fileTypeFilters.length
		? [...imageData.Items.Items].filter((item) => {
				return fileTypeFilters.includes(item.Extension);
			})
		: [...imageData.Items.Items];

	return (
		<Box position="relative" h="100%">
			<FilterComponent
				onChange={onInputChange}
				search={search}
				pagination={!imageData.Items.Items.length ? undefined : pagination}
				maxPage={imageData.Items.TotalPages}
				prev={() => paginate(pagination - 1)}
				next={() => paginate(pagination + 1)}
				disabled={loader}
			/>
			<Box d="flex" p="10px 35px 20px" flexWrap="wrap" h="88%" overflow="auto">
				<Box w="25%" pr={18}>
					<Box d="flex" align="center" mb={20}>
						<Box
							style={{
								fontWeight: 500,
								borderBottom: '2px solid #3ccdde',
								paddingBottom: 8,
							}}
						>
							Filters
						</Box>
					</Box>
					<React.Fragment>
						{imageData.FileTypes.length ? (
							<React.Fragment>
								<div style={{ display: 'flex', marginBottom: 15 }}>By filetype</div>
								{imageData.FileTypes.map((item) => {
									return (
										<Checkbox
											key={item}
											checked={fileTypeFilters.includes(item)}
											onClickCheckbox={() => toggleFileTypeFilter(item)}
											mb={10}
										>
											<CheckboxLabel fontWeight={400}>{item}</CheckboxLabel>
										</Checkbox>
									);
								})}
							</React.Fragment>
						) : null}
						<Box
							onClick={() => setShowAdvanced(!showAdvanced)}
							style={{
								alignItems: 'center',
								display: 'flex',
								marginTop: 25,
								paddingBottom: 15,
								cursor: 'pointer',
								color: showAdvanced ? '#3fbaca' : '',
							}}
						>
							Advanced
							<i className={showAdvanced ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={{ marginLeft: 5, marginTop: 3 }} />
						</Box>
						{showAdvanced && (
							<React.Fragment>
								<Select
									useBasicStyles
									mb={10}
									selectedValue={criteriaFilter}
									onChangeSelect={(e, data) => {
										setCriteriaFilter(data.value);
										setComparisonFilter('');
										setValueFilter('');
										setSecondaryValueFilter('');
									}}
								>
									<SelectContainer>
										<SelectPlaceholder>Select an option</SelectPlaceholder>
									</SelectContainer>
									<SelectOptionList>
										<SelectOption value="title">Title</SelectOption>
										<SelectOption value="filename">Filename</SelectOption>
										{/* <SelectOption value="created-by">Created by</SelectOption> */}
										<SelectOption value="created-date">Created date</SelectOption>
									</SelectOptionList>
								</Select>
								<Select
									useBasicStyles
									disabled={!criteriaFilter}
									mb={10}
									selectedValue={comparisonFilter}
									onChangeSelect={(e, data) => setComparisonFilter(data.value)}
								>
									<SelectContainer>
										<SelectPlaceholder>Select an option</SelectPlaceholder>
									</SelectContainer>
									{criteriaFilter !== 'created-date' ? (
										<SelectOptionList>
											<SelectOption value="equals">Equals</SelectOption>
											<SelectOption value="starts-with">Starts with</SelectOption>
											<SelectOption value="ends-with">Ends with</SelectOption>
											<SelectOption value="contains">Contains</SelectOption>
										</SelectOptionList>
									) : (
										<SelectOptionList>
											<SelectOption value="equals">Equals</SelectOption>
											<SelectOption value="does-not-equal">Does not equal</SelectOption>
											<SelectOption value="is-greater-than">Greater than</SelectOption>
											<SelectOption value="is-less-than">Less than</SelectOption>
											<SelectOption value="is-between">Between</SelectOption>
										</SelectOptionList>
									)}
								</Select>
								<Input
									mb={10}
									type={criteriaFilter !== 'created-date' ? 'text' : 'date'}
									value={valueFilter}
									bgColor={!criteriaFilter || !comparisonFilter ? 'backgroundColors.background' : ''}
									disabled={!criteriaFilter || !comparisonFilter}
									onChange={(e) => setValueFilter(e.target.value)}
								/>
								{comparisonFilter === 'is-between' && (
									<React.Fragment>
										<div style={{ fontSize: 14 }}>and</div>
										<Input
											my={10}
											type={'date'}
											value={secondaryValueFilter}
											bgColor={!criteriaFilter || !comparisonFilter ? 'backgroundColors.background' : ''}
											disabled={!criteriaFilter || !comparisonFilter}
											onChange={(e) => setSecondaryValueFilter(e.target.value)}
										/>
									</React.Fragment>
								)}
								<Button onClick={addCustomFilter} disabled={!criteriaFilter || !comparisonFilter || !valueFilter}>
									Add filter
								</Button>
							</React.Fragment>
						)}
					</React.Fragment>
					<Button position="absolute" bottom="20px" onClick={logout}>
						Logout
					</Button>
				</Box>
				<Box style={{ width: '75%', borderLeft: '2px solid #F9F9FA' }}>
					<Box style={{ fontSize: 12, display: 'flex', marginLeft: 18 }}>
						{imageData.Items.Items.length} of {imageData.Items.TotalItems} total images
					</Box>
					<Tags mt={10} ml={15}>
						{filterTags.map((item, i) => {
							return (
								<Tag
									my={2}
									key={i}
									borderStyle="solid"
									borderWidth="1px"
									borderColor="rgba(41, 51, 70, 0.26)"
									bgColor="backgroundColors.white"
									fontColor="fontColors.primary2"
								>
									<TagText maxW="unset">{item.tag}</TagText>
									<TagClose fontColor="fontColors.primary2" onClick={item.onRemove}>
										highlight_off
									</TagClose>
								</Tag>
							);
						})}
					</Tags>
					{loader ? (
						<Box
							style={{
								height: 480,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<LoaderComponent />
						</Box>
					) : (
						<Box style={{ marginTop: 15, display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
							{filteredImages.map((item) => {
								return (
									<CHImageComponent
										selectImage={props.selectImage}
										title={item.Title}
										filename={item.Filename}
										src={item.ThumbnailImageUrl}
										url={item.PreviewImageUrl}
										key={item.ThumbnailImageUrl}
									/>
								);
							})}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const CHImageComponent = (props) => {
	const [hovered, setHovered] = React.useState(false);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(true);
	}, [props.url]);

	const selectImage = () => {
		props.selectImage && props.selectImage(props.url);
	};

	return (
		<Box
			border="1px solid"
			borderColor="#dddddd"
			w="23%"
			h="fit-content"
			d="flex"
			direction="column"
			borderRadius={3}
			mx="1%"
			overflow="hidden"
			mb={20}
		>
			<Box onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} position="relative">
				<Image
					onLoad={() => setLoading(false)}
					d="block"
					visibility={loading ? 'hidden' : ''}
					w="fit-content"
					maxW={'100%'}
					src={props.src || props.url}
				/>
				{loading && <Loader spinnerWidth={3} position="absolute" top="50%" left="50%" mt={-15} ml={-15} size={30} />}
				{hovered && (
					<Box
						position="absolute"
						top={0}
						right={0}
						left={0}
						bottom={0}
						d="flex"
						align="center"
						justify="center"
						bgColor="rgba(0, 0, 0, 0.4)"
					>
						<Button p="9px 12px" onClick={selectImage}>
							Select
						</Button>
					</Box>
				)}
			</Box>
			{(props.title || props.filename) && (
				<Box d="flex" direction="column" p={10} align="flex-start">
					<Box title={props.title} fontSize={13} w="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
						{props.title}
					</Box>
					<Box title={props.filename} fontSize={13} w="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
						{props.filename}
					</Box>
				</Box>
			)}
		</Box>
	);
};

export const LoaderComponent = (props) => {
	return (
		<Box d="flex" align="center" justify="center">
			<Loader />
		</Box>
	);
};

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
};

export const ContentHubIcon = `<svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 14px" class="uploadcare--icon uploadcare--menu__icon">
			<path
				d="M12.7701 7.42998L10.5901 21.74L19.5501 16.57C19.6801 16.49 19.7701 16.36 19.7901 16.21L21.8501 2.71998C21.9001 2.38998 21.5501 2.14998 21.2501 2.30998L13.0101 7.06998C12.8801 7.14998 12.7901 7.27998 12.7701 7.42998V7.42998Z"
			/>
			<path
				d="M10.9501 9.40996L5.11009 12.78C4.88009 12.91 4.79009 13.21 4.92009 13.45L5.57009 14.66L2.40009 16.49C2.07009 16.68 2.07009 17.16 2.40009 17.35L9.13009 21.35V21.33L10.9601 9.39996L10.9501 9.40996Z"
				fill-opacity="0.68"
			/>
		</svg>`;
