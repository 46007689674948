import React from 'react';
import { getCorrectedHtml } from '../../../util/helper';
import { ComponentDefaults, AllComponentTypes } from '../../../util/resources';

class Text extends React.PureComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.text] };

	render() {
		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';

		const html = getCorrectedHtml(this.props.text);

		const returnComponents = [
			<div
				className={`component global-text text-component text-desktop text${id} `}
				data-component-type="text"
				key={0}
				id={id}
				dangerouslySetInnerHTML={{ __html: html }}
				// width={this.props.slotWidth}
			/>,
		];

		if (this.checkMobileChange()) {
			const mobileProps = { ...this.props, ...this.props.mobileProps };

			const mobileHtml = getCorrectedHtml(mobileProps.text);

			returnComponents.push(
				<div
					className={`global-text text-component text-mobile text${id} `}
					data-component-type="text"
					key={1}
					id={`mobile${id}`}
					dangerouslySetInnerHTML={{ __html: mobileHtml }}
				/>
			);
		}

		return returnComponents;
	}

	checkMobileChange = () => {
		if (this.props.mobileProps && this.props.mobileProps.mobileChanged) {
			return true;
		}
		return false;
	};
}

export default Text;
