import React from 'react';
import { AllComponentTypes, ComponentDefaults } from '../../../util/resources';

import {
	calculateImageWidthInSlot,
	validateUrl,
	getDesignerAlignment,
	getMaxConstraints,
	getShadowValFromProps,
} from '../../../util/helper';
import { generateImageContentUrl } from '../../../util/apiUtils/image';
import { getPadding } from '../CssGenerators/CssClassGenerators';

class Image extends React.PureComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.image] };

	render() {
		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';

		const alignment = getDesignerAlignment(this.props.alignment);

		return (
			<div
				className={`component global-image image-component image${id} lp-flex-container lp-flex-align-center lp-flex-justify-${alignment} lp-ie-flex-shrink`}
				data-component-type="image"
				data-parent-slot-type={this.props.slot_type}
			>
				<ImageWithLink {...this.props} slotWidth={this.props.slotWidth} />
			</div>
		);
	}
}

const ImageWithoutLink = (props) => {
	const id = props.id.replace(/-/g, '');

	return (
		<div>
			<img src={props.src} alt={props.alt} id={id} className={`content-image ${props.resizedClass || ''}`} height="auto" />
		</div>
	);
};

const ImageWithLink = (props) => {
	let src,
		slotWidth = props.slotWidth;

	let imageWidth = calculateImageWidthInSlot(props.resizeWidth, slotWidth);

	let imageWidthWithPadding = getMaxConstraints(props, imageWidth);

	let resized = props.resized ? null : 'not-resized';

	let linkUrl = validateUrl(props.link_url) ? props.link_url.trim() : null; //validate url here to prevent weird "[http://]" from showing next to the image in outlook.com in certain cases

	if (props.src) {
		//image width set *2 for responsive image quality
		src = generateImageContentUrl(props.src, props.imageType, imageWidthWithPadding, props.general_styles.retina_images);
	}

	const isValidURL = (str) => {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		); // fragment locator
		return !!pattern.test(str);
	};

	if (linkUrl) {
		return (
			<a
				href={linkUrl}
				target={isValidURL(linkUrl) ? '_blank' : '_self'}
				className="landing-page-image-link"
				style={{ lineHeight: '1px', textAlign: props.alignment || 'left' }}
			>
				<ImageWithoutLink {...props} src={src} resizedClass={resized} imageWidth={imageWidth} />
			</a>
		);
	} else {
		return <ImageWithoutLink {...props} src={src} resizedClass={resized} imageWidth={imageWidth} />;
	}
};

export function generateImageClass(component, slotWidth) {
	const width = calculateImageWidthInSlot(component.resizeWidth, slotWidth);
	const padding = getPadding(component);

	const widthQuery =
		component.resized && component.resizeWidth
			? `.image${component.id.replace(/-/g, '')} div {
	            max-width: ${width}px;
	        }`
			: `.image${component.id.replace(/-/g, '')} div {
	            max-width: 100%;
	        }`;

	const alignment = getDesignerAlignment(component.alignment);

	return `

    .image${component.id.replace(/-/g, '')} {
        background-color: ${component.background_color || 'transparent'};
        -webkit-box-pack: ${alignment.replace('flex-', '')};
        -ms-flex-pack: ${alignment.replace('flex-', '')};
        justify-content: ${alignment};
        ${padding}
    }

    .image${component.id.replace(/-/g, '')} a {
        -webkit-box-pack: ${alignment.replace('flex-', '')};
        -ms-flex-pack: ${alignment.replace('flex-', '')};
        justify-content: ${alignment};
    }

    .image${component.id.replace(/-/g, '')} img {
        border-radius: ${component.imageBorderRadius}px;
        border-width: ${component.imageBorderWidth}px;
        border-color: ${component.imageBorderColor};
        border-style: ${component.imageBorderWidth ? 'solid' : 'unset'};
        box-shadow: ${getShadowValFromProps(component.box_shadow)};
    }

    ${widthQuery}
    `;
}

export default Image;
