import React from 'react';
import qs from 'query-string';

import { Link, browserHistory } from 'react-router';

import { fireEvent, formatTestId, pathPrefix } from '../../../util/helper';

import { Button } from '@chakra-ui/react';

const ButtonVariants = {
	one: undefined,
	two: 'outline',
	three: 'ghost',
};

export const SimpleTopMenuItem = React.memo((props) => {
	const query = React.useMemo(() => qs.parse(location.search), [location.search]);

	const onClick = (e) => {
		e.preventDefault();

		const proceed = () => {
			const path = pathPrefix() + props.href || pathPrefix();

			props.href && browserHistory.push({ pathname: path, query });

			let closeDiv = document.getElementById('close-stuff');

			closeDiv && fireEvent(closeDiv, 'mousedown');

			if (props.onClick) {
				props.onClick(e);
			}

			e.target.blur();
		};

		proceed();
	};

	return (
		<Link
			to={{ pathname: pathPrefix() + props.href || pathPrefix(), query }}
			onClick={(e) => onClick(e)}
			data-testid={formatTestId(props.label)}
		>
			<Button variant={ButtonVariants[props.type]} {...props.buttonProps} size={props.size}>
				{props.label}
				{props.children}
			</Button>
		</Link>
	);
});

export default SimpleTopMenuItem;
