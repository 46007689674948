import '../node_modules/cropperjs/dist/cropper.css';

import qs from 'query-string';
import React from 'react';

import { NotificationTypes } from './util/resources';
import DomHelper from './util/domHelper';

import { simpleObjectEquals } from './util/helper';
import { initializeAutosaves } from './util/autosave';

import useUndoStore from './stores/UndoStore';
import { showNotification } from './stores/NotificationStore';

import useContentStore from './stores/ContentStore';
import useLeftSidebarStore from './stores/LeftSidebarStore';

import config from '../config/config';

import { ErrorMessages, WarningMessages, SuccessMessages } from '../config/messages';

import useEntitiesStore from './stores/EntitiesStore';
import { EntityStatuses } from './components/Table/strings';

export const afterInitialDataLoad = () => {
	const activeEntity = useEntitiesStore.getState().activeEntity;

	//If not
	let overlay = document.querySelector('#initial-overlay');

	overlay && (overlay.style.display = 'none');

	DomHelper.preventBackspaceNavigation();
	DomHelper.bindUndoKeyboardCombo(useUndoStore.getState().undoContentStoreState); //bind ctrl+z
	DomHelper.bindSaveKeyboardCombo(saveDraftCb); //bind ctrl+s
	//DomHelper.checkTouch(useContentStore.getState().activateTouchScreenHelper);

	if (activeEntity && activeEntity.Entity.Status !== EntityStatuses.active) {
		config.autosave_enabled && initializeAutosaves();
	}

	attachBeforeUnloadListener();
};

export const saveDraftCb = () => {
	/* istanbul ignore next */
	let successCb = () => {
		showNotification({ type: NotificationTypes.SUCCESS, text: SuccessMessages.DRAFT_SAVED });
	};

	/* istanbul ignore next */
	let errorCb = () => {
		showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.DRAFT_NOT_SAVED });
	};

	/* istanbul ignore next */
	let warningCb = () => {
		showNotification({ type: NotificationTypes.INFO, text: WarningMessages.DRAFT_NOT_SAVED });
	};

	const contentStore = useContentStore.getState();

	useLeftSidebarStore.getState().saveNewDraft(
		{
			json: contentStore.content,
			generalSettings: contentStore.general_settings,
		},
		false,
		useLeftSidebarStore.getState().allowProdSave,
		useLeftSidebarStore.getState().versions.latestDraft,
		successCb,
		errorCb,
		warningCb
	);
};

export const fetchQueryStringConfig = () => {
	let qsConfig = qs.parse(location.search);
	let conf = {};

	conf.redirectUrl = '/';
	conf.secondaryRedirectUrl = '/';
	conf.showTemplate = null;
	conf.editorType = 'inline';
	conf.entityId = qsConfig.entityId ? qsConfig.entityId : null;
	conf.hasBlueprints = true;
	// conf.isActive = qsConfig.isActive ? qsConfig.isActive : null;

	if (conf.entityId) {
		conf.fullMetadataKey = conf.entityId;
	}

	return conf;
};

//Ask for confirmation before user leaves
export const attachBeforeUnloadListener = () => {
	window.onbeforeunload = function (e) {
		let current = useContentStore.getState().content;
		let latestSave = useLeftSidebarStore.getState().versions.latestDraft.json;

		if (!simpleObjectEquals(current, latestSave)) {
			let dialogText = 'Your changes may not be saved. Are you sure you want to exit?';
			e.returnValue = dialogText;
			return dialogText;
		}
	};
};
