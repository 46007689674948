import React from 'react';

import { Input, Text, Box } from '@chakra-ui/react';

import { MultiSelectWithTags } from '@/components/gui/shared/MultiSelectWithTags';

import { ComparerTypes, FieldComponentTypes } from '@/util/resources';
import { CriteriaIsBetweenValue, CriteriaValueType } from '../models/model';

export interface CriterialValueProps {
	fieldType: keyof typeof FieldComponentTypes;
	comparer: keyof typeof ComparerTypes;
	value: CriteriaValueType;
	setValue: (newValue: CriteriaValueType) => void;
	options?: { label: string; value: string }[];
	disabled?: boolean;
	hasError?: boolean;
	placeholder?: string;
}

function getIsBetweenValue(value: CriteriaValueType): CriteriaIsBetweenValue {
	const isBetweenValue = value as CriteriaIsBetweenValue;
	const { start = '', end = '' } = typeof isBetweenValue === 'object' ? isBetweenValue : ({} as CriteriaIsBetweenValue);
	return { start, end };
}

export const CriteriaValue: React.FC<CriterialValueProps> = ({
	fieldType,
	comparer,
	value,
	setValue,
	options,
	disabled,
	hasError,
	placeholder = 'Select a value',
}) => {
	let inputType: React.HTMLInputTypeAttribute = 'text';

	if (fieldType === FieldComponentTypes.number_input) inputType = 'number';
	if (fieldType === FieldComponentTypes.date_input) inputType = 'date';

	const inputProps = React.useMemo(() => {
		if (inputType === 'date' && !disabled) {
			return {
				onClick: (e) => e.target.showPicker(),
				cursor: 'pointer',
			};
		}
		return {};
	}, [inputType, disabled]);

	if (
		[
			FieldComponentTypes.singleselect_input,
			FieldComponentTypes.radio,
			FieldComponentTypes.checkbox_group,
			FieldComponentTypes.multiselect_input,
		].includes(fieldType) &&
		[ComparerTypes.isEqualTo, ComparerTypes.isNotEqualTo].includes(comparer)
	) {
		let selectValue = value;

		if ([FieldComponentTypes.checkbox_group, FieldComponentTypes.multiselect_input].includes(fieldType))
			selectValue = Array.isArray(value) ? value : [];

		return (
			<MultiSelectWithTags
				options={options}
				placeholder={placeholder}
				value={selectValue as string | string[]}
				onChange={(data) => setValue(data)}
				optionsTitleProp="value"
				disabled={disabled}
				data-testid="criteria-value-select"
				hasError={hasError}
			/>
		);
	}

	if (
		[
			ComparerTypes.contains,
			ComparerTypes.doesNotContain,
			ComparerTypes.endsWith,
			ComparerTypes.startsWith,
			ComparerTypes.isEqualTo,
			ComparerTypes.isNotEqualTo,
			ComparerTypes.is,
			ComparerTypes.isNot,
			ComparerTypes.isGreaterThan,
			ComparerTypes.isSmallerThan,
			ComparerTypes.isBefore,
			ComparerTypes.isAfter,
		].includes(comparer)
	) {
		return (
			<Input
				type={inputType}
				value={value as string}
				isDisabled={disabled}
				isInvalid={hasError}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
				data-testid="criteria-value-text-input"
				{...inputProps}
			/>
		);
	}

	if (ComparerTypes.isBetween === comparer) {
		const isBetweenValue = getIsBetweenValue(value);

		return (
			<Box display="flex" alignItems="center" data-testid="criteria-value-multiple">
				<Input
					type={inputType}
					value={isBetweenValue.start}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue({ ...isBetweenValue, start: e.target.value })}
					isDisabled={disabled}
					isInvalid={hasError && !isBetweenValue.start}
					data-testid="criteria-value-multiple-input"
					{...inputProps}
				/>

				<Text mx={1.5}>and</Text>

				<Input
					type={inputType}
					value={isBetweenValue.end}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue({ ...isBetweenValue, end: e.target.value })}
					isDisabled={disabled}
					isInvalid={hasError && !isBetweenValue.end}
					data-testid="criteria-value-multiple-input"
					{...inputProps}
				/>
			</Box>
		);
	}

	return null;
};
