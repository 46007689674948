import { Box, MenuItem, MenuList, Menu, MenuButton, Tooltip, Avatar, MenuDivider, Text } from '@chakra-ui/react';
import React from 'react';
import { claimsKeys, getAuthClient, getUser } from '@/util/auth/auth';
import config from '../../../../config/config';
import WebStorage from '../../../util/webStorage';
import { mdiAccountCircleOutline, mdiInformationOutline, mdiLogout } from '@mdi/js';
import { Icon } from '@/components/gui/shared/Icon';
import { Link } from 'react-router';

export const AccountMenu = () => {
	const [dropdown, setDropdown] = React.useState(false);

	const auth = getAuthClient();

	const logout = () => {
		WebStorage.remove('tenant_info');
		auth.logout({ logoutParams: { returnTo: config.authentication.portal_url } });
	};

	const tenantInfo = getUser();

	const renderOptions = () => {
		return (
			<Menu isOpen={dropdown}>
				<Tooltip label={tenantInfo.email}>
					<MenuButton as={Avatar} layerStyle="interactive.fill" size="sm" name={tenantInfo.name} src={tenantInfo.picture} />
				</Tooltip>
				<MenuList>
					<Box px="3.5" py="2">
						<Text variant="strong">{tenantInfo.name}</Text>
						<Text variant="small">{tenantInfo.email}</Text>
					</Box>
					<MenuItem
						as={'a'}
						href={tenantInfo ? `${config.authentication.portal_url}/profile?organization=${tenantInfo[claimsKeys.ORG_ID]}` : ''}
						target="_blank"
						icon={<Icon path={mdiAccountCircleOutline} />}
						className="menu-item"
					>
						<Text className="portal-link">Manage account</Text>
					</MenuItem>
					<MenuDivider />
					<MenuItem icon={<Icon path={mdiLogout} />} onClick={logout} className="menu-item" data-testid="logout-menu-item">
						Log out
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	const renderDropdown = () => {
		return (
			<Box zIndex={100} display="flex" flexDirection="column">
				{renderOptions()}
			</Box>
		);
	};

	const renderDesktop = () => {
		return (
			<Box
				onClick={(e) => {
					setDropdown(true);
				}}
				position="relative"
				alignItems="center"
				display="flex"
				h="full"
				borderRadius="md"
				tabIndex={-1}
				data-testid="account-icon"
			>
				{renderDropdown()}
			</Box>
		);
	};

	return (
		<>
			{renderDesktop()}
			{dropdown && <Box position="fixed" top={0} left={0} right={0} bottom={0} onClick={() => setDropdown(false)} />}
		</>
	);
};
