import React from 'react';
import { Box, Image, Text, Button, IconButton, Spacer, Tooltip, Heading, Link as ChakraLink } from '@chakra-ui/react';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiHomeVariantOutline } from '@mdi/js';
import { getAuthClient, claimsKeys, getUser } from '../../../util/auth/auth';
import config from '../../../../config/config';
import { AccountMenu } from './AccountMenu';
import PocketNav from './PocketNav';
import { withRouter } from 'react-router';
import { getRoute } from '../strings';
import { Link } from 'react-router';
import useUserStore from '@/stores/UserStore';

export const TableTopBar = ({ noAccess }) => {
	const tenants = useUserStore((state) => state.tenants);
	const user = getUser();
	const tenantId = user[claimsKeys.TENANT_ID];
	const currentTenant = tenants.find((tenant) => tenant.id === tenantId);

	return (
		<Box as="header" layerStyle="section.topbar" flex="0 0 auto" display="flex" justifyContent="space-between" zIndex={2}>
			<Box display="flex" alignItems="center" gap="2">
				<ChakraLink href={config.xmc.dashboard_url} isExternal>
					<Image src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud" />
				</ChakraLink>
				<PocketNav />
				<Spacer mx={1} borderRight="1px solid" borderColor="gray.200" h="90%" alignSelf="center" />
				<Heading size="sm">Forms</Heading>
				{currentTenant && <Text mr="4">{currentTenant.displayName}</Text>}
				{!noAccess && (
					<>
						<TopBarButton label="Forms" path={getRoute.root('forms', 'list', 1)} isActive={location.pathname.startsWith('/forms/list')} />
						<TopBarButton
							label="Webhooks"
							path={getRoute.root('forms', 'webhook', 1)}
							isActive={location.pathname.startsWith('/forms/webhook')}
						/>
						<TopBarButton label="Styles" path={getRoute.stylingList()} isActive={location.pathname.startsWith('/forms/styling')} />
					</>
				)}
			</Box>
			<Box>
				<AccountMenu />
			</Box>
		</Box>
	);
};

const TopBarButton = withRouter((props) => {
	const { label, path, styles, isActive, router } = props;

	return (
		<Link to={path}>
			<Button variant="navigation" size="sm" isActive={isActive ? true : false} {...styles}>
				{label}
			</Button>
		</Link>
	);
});
