import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiHelpCircleOutline } from '@mdi/js';

export const Tooltip = ({ children }) => {
	return (
		<Box ml={2.5} position="absolute" top="-1px" left="100%">
			<Menu isLazy>
				<MenuButton as={IconButton} size="xs" variant="ghost" aria-label="Help" icon={<Icon path={mdiHelpCircleOutline} />} cursor="help" />

				<MenuList>
					<MenuItem
						bgColor="transparent"
						cursor="default"
						_hover={{ bgColor: 'transparent' }}
						whiteSpace="pre-wrap"
						sx={{ textWrap: 'wrap' }}
						maxW="72"
					>
						{children}
					</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
};
