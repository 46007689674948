import { Box, Heading } from '@chakra-ui/react';

import useStylingStore from '../../../stores/StylingStore';
import { useEffect, useRef } from 'react';

export const StylingPreview = (props) => {
	const iframe = useRef(null);

	const preview = useStylingStore((state) => state.preview);
	const stylesString = useStylingStore((state) => state.styles.stylesString);
	const previewWithStyles = `${preview}<style>${stylesString}</style>`;

	const writeIframe = () => {
		if (iframe.current) {
			let doc = iframe.current.contentDocument;
			if (doc) {
				doc.open();
				doc.write('<!DOCTYPE html>');
				doc.write(previewWithStyles);
				doc.close();
			}
		} else {
			setTimeout(() => {
				writeIframe();
			}, 1000);
		}
	};

	useEffect(() => {
		writeIframe();
	}, [previewWithStyles]);

	return (
		<Box flex={3} p={5} h="100%" borderLeft="1px solid" borderLeftColor="chakra-border-color">
			<Heading size="md">Preview</Heading>
			<iframe
				style={{ height: '100%', paddingBottom: 20, width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
				className="preview-iframe notranslate"
				ref={iframe}
				width={800}
				frameBorder={0}
			/>
		</Box>
	);
};

export default StylingPreview;
