import React from 'react';

//components
import PaddingSetting from '../../shared/settings/PaddingSetting.react';
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import DropDownSetting from '../../shared/settings/DropDownSetting.react';

import useContentStore from '../../../../stores/ContentStore';
import useModalStore from '../../../../stores/ModalStore';
import { AllComponentTypes, VerticalAlignOptions } from '../../../../util/resources';

import { Box } from '@chakra-ui/react';
import { SettingsBox } from '../../settingsContext/SettingsContext';

export const SharedTextSettingComponents = React.memo((props) => {
	let paddingType = (props.types && props.types.padding) || 'padding';
	let colorType = (props.types && props.types.background_color) || 'background_color';

	let colorPicker =
		(props.colorPicker && (
			<ColorPickerSetting
				type={colorType}
				onChange={props.onChange}
				selectedColor={props.background_color}
				label={props.backgroundLabel || 'Text background'}
			/>
		)) ||
		null;

	return (
		<SettingsBox blockId={props.blockId}>
			{
				<PaddingSetting
					multiPadding={props.multiPadding}
					padding={props.padding}
					type="multiPadding"
					secondaryType={paddingType}
					onChange={props.onChange}
					label="Padding"
				/>
			}
			{colorPicker}
			{props.type === AllComponentTypes.article && (
				<DropDownSetting
					type={'textAlignment'}
					onChange={props.onChange}
					selected={props.textAlignment || 'top'}
					label="Text vertical align"
					options={VerticalAlignOptions}
				/>
			)}
		</SettingsBox>
	);
});

class SharedTextSettings extends React.PureComponent {
	render() {
		return (
			<Box>
				<SharedTextSettingComponents {...this.props} onChange={this.onChange} openGoogleFontsModal={this.openGoogleFontsModal} />
			</Box>
		);
	}

	onChange = (data) => {
		if (this.props.onChange) {
			this.props.onChange(data);
			return;
		}

		let settings = {};

		if (Array.isArray(data)) {
			data.map((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		useContentStore.getState().saveElementSettings({
			address: this.props.address,
			settings,
		});
	};

	openGoogleFontsModal = () => {
		useModalStore.getState().showGoogleFontsModal({
			title: 'Select Custom Fonts',
		});
	};
}

export default SharedTextSettings;
