import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { formatTestId } from '../../../../util/helper';

import { settingsLabelStyle } from './Settings.styles';

class NumberSetting extends React.PureComponent {
	static defaultProps = {
		allowNegativeValues: false,
		disabled: false,
		min: 0,
		max: 2000,
		label: '',
		customStyle: { customRoot: null, customLabel: null, customInput: null },
	};

	render() {
		let { customRoot, customLabel, customInput } = this.props.customStyle;
		let text = typeof this.props.text !== 'undefined' ? parseInt(this.props.text, 10) : undefined;

		return (
			<FormControl
				id={`${this.props.type}-number-setting`}
				data-type={this.props.type}
				className="number-input-setting-container"
				isDisabled={this.props.disabled}
				mb={5}
				flex={1}
				{...customRoot}
			>
				<FormLabel {...settingsLabelStyle} {...customLabel}>
					{this.props.label}
				</FormLabel>

				<Input
					type="number"
					value={text}
					aria-label={this.props.ariaLabel || this.props.label}
					data-testid={`input-${formatTestId(this.props.label)}`}
					w="100%"
					{...customInput}
					onBlur={this.onBlur}
					onChange={this.onChange}
				/>
			</FormControl>
		);
	}

	onChange = (event) => {
		if (this.props.onChange) {
			let value = parseInt(event.target.value, 10);

			if (isNaN(value)) {
				value = 0;
			} else if (!this.props.validateOnBlur) {
				if (!this.props.allowNegativeValues && value < this.props.min) {
					value = this.props.min;
				} else if (this.props.max && value > this.props.max) {
					value = this.props.max;
				}
			}

			this.props.onChange({ event, type: this.props.type, value: value });
		}
	};

	onBlur = (event) => {
		if (this.props.validateOnBlur) {
			let value = this.props.text;

			if (isNaN(value)) {
				value = 0;
			} else if (!this.props.allowNegativeValues && value < this.props.min) {
				value = this.props.min;
			} else if (this.props.max && value > this.props.max) {
				value = this.props.max;
			}

			this.props.onChange && this.props.onChange({ event, type: this.props.type, value: value });
		}

		this.props.onBlur && this.props.onBlur({ event, type: this.props.type, value: this.props.text });
	};
}

export default NumberSetting;
