import React from 'react';

import Categories from './Categories.react';

export class MarketLeftSidebarMenu extends React.PureComponent {
	render() {
		return <Categories currentCategory={this.props.currentCategory} templateCategories={this.props.templateCategories} />;
	}
}

export default MarketLeftSidebarMenu;
