import React from 'react';

import useContentStore from '../../../../stores/ContentStore';

import { Box, Text } from '@chakra-ui/react';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import SharedInputSettings from './SharedInput-settings.react';
import TextEdit from '../../shared/text/TextEdit.react';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';

export const GDPRboxComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class GDPRboxSettings extends React.PureComponent {
	render() {
		return (
			<Box className="gdpr-settings">
				<SettingsBox filterImmunity>
					<Box mb={4} label="Legal text">
						<Text {...settingsLabelStyle}>Legal text</Text>

						<Box p={2.5} borderColor="chakra-border-color" borderWidth={1} borderStyle="solid" borderRadius="sm" bgColor="white">
							<TextEdit
								general_styles={this.props.general_styles}
								id={`${this.props.id}-legal`}
								text={this.props.legalText}
								onChange={this.onTextChangeLegal}
								type="legalText"
								customToolbar={`newdocument cut copy pastetext outdent indent link unlink openlink anchor numlist bullist blockquote alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor blocks fontfamily fontsize spellchecker`}
							/>
						</Box>
					</Box>
				</SettingsBox>

				<GDPRboxComponents
					{...this.props}
					onChange={this.onChange}
					settings={{
						required: {
							active: false,
						},
						placeholder: {
							active: false,
						},
						description: {
							active: false,
						},
						hiddenField: {
							active: false,
						},
						prefill: {
							active: false,
						},
						fieldGroup: {
							label: 'Checkbox styles',
							settings: {
								fieldBgColor: {
									label: 'Checkbox background color',
								},
								fieldFont: {
									active: false,
								},
								fieldFontSize: {
									active: true,
									label: 'Tick font size',
								},
								fieldFontColour: {
									label: 'Tick color',
								},
								fieldTextDecoration: {
									active: false,
								},
							},
						},
						labelGroup: {
							settings: {
								labelPosition: {
									active: false,
								},
								labelWidth: {
									label: 'Label Height',
									active: true,
								},
								labelHide: {
									active: false,
								},
							},
						},
						placeholderGroup: {
							active: false,
						},
						descriptionGroup: {
							active: false,
						},
					}}
				/>
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
	onTextChange = (value) => {
		this.onChange && this.onChange({ type: 'legalLabel', value });
	};

	onTextChangeLegal = (value) => {
		this.onChange && this.onChange({ type: 'legalText', value });
	};
}

export default GDPRboxSettings;
