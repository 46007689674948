import React from 'react';
import Cropper from 'react-cropper';

//actions
import useModalStore from '../../../../stores/ModalStore';
import useContentStore from '../../../../stores/ContentStore';

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

const CropperModalFields = {
	cropWidth: 'cropWidth',
	cropHeight: 'cropHeight',
};

class CropperModal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			imageLoaded: false,
			cropWidth: null,
			cropHeight: null,
			scale: 1,
		};
	}

	render() {
		let { show, data } = this.props;

		return (
			<Modal onClose={this.closeCropper} isOpen={show} size="xl">
				<ModalOverlay />

				<ModalContent h="fit-content" overflow="auto">
					<ModalHeader>Crop Image</ModalHeader>

					<ModalCloseButton />

					<ModalBody>
						<Cropper
							ref="cropper"
							src={data.originalCustomLogo || data.originalSrc}
							checkCrossOrigin={false}
							zoomable={false}
							guides={true}
							ready={this.cropperReady}
							cropend={this.updateFields}
							// checkOrientation={false}
						/>

						<Box display="flex" mx="auto" mt={6} w="100%" gap={4}>
							<FormControl>
								<FormLabel>Width</FormLabel>
								<Input
									type="number"
									value={this.state.cropWidth || ''}
									onChange={(e) => this.onChange(CropperModalFields.cropWidth, e.target.value)}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Height</FormLabel>
								<Input
									type="number"
									value={this.state.cropHeight || ''}
									onChange={(e) => this.onChange(CropperModalFields.cropHeight, e.target.value)}
								/>
							</FormControl>
						</Box>
					</ModalBody>

					<ModalFooter gap="2">
						<Button variant="ghost" onClick={this.closeCropper}>
							Cancel
						</Button>
						<Button onClick={this.cropImage}>Crop</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	cropImage = () => {
		let imgData = this.refs.cropper.cropper.getImageData();

		const contentStore = useContentStore.getState();

		if (this.props.data.generalSettings) {
			if (this.props.data.customLogo) {
				contentStore.cropCustomLogo({
					originalSrc: this.props.data.customLogo,
					imageType: this.props.data.customImageType,
					cropData: {
						...this.refs.cropper.cropper.getData(true),
						scale: imgData.naturalWidth / imgData.width,
					},
				});
			} else {
				contentStore.cropPromotionImage({
					originalSrc: this.props.data.originalSrc,
					imageType: this.props.data.imageType,
					cropData: {
						...this.refs.cropper.cropper.getData(true),
						scale: imgData.naturalWidth / imgData.width,
					},
				});
			}
		} else {
			contentStore.cropImage({
				address: this.props.data.address,
				originalSrc: this.props.data.originalSrc,
				type: this.props.data.imageType,
				cropData: {
					...this.refs.cropper.cropper.getData(true),
					scale: imgData.naturalWidth / imgData.width,
				},
			});
		}

		useModalStore.getState().closeCropper();

		this.setState({ imageLoaded: false, cropWidth: null, cropHeight: null });
	};

	cropperReady = () => {
		let imgData = this.refs.cropper.cropper.getImageData();

		/* istanbul ignore next */
		this.setState({ imageLoaded: true, scale: imgData.naturalWidth / imgData.width });
		this.updateFields();
	};

	closeCropper = () => {
		this.setState({ imageLoaded: false });
		useModalStore.getState().closeCropper();
	};

	onChange = (type, value) => {
		let cropBoxData = { ...this.refs.cropper.cropper.getCropBoxData() };

		switch (type) {
			case CropperModalFields.cropWidth:
				cropBoxData.width = parseInt(value) / this.state.scale;
				break;
			case CropperModalFields.cropHeight:
				cropBoxData.height = parseInt(value) / this.state.scale;
				break;
		}

		this.setState(
			{
				cropWidth: cropBoxData.width * this.state.scale,
				cropHeight: cropBoxData.height * this.state.scale,
			},
			() => {
				this.refs.cropper.cropper.setCropBoxData(cropBoxData);
			},
		);
	};

	updateFields = () => {
		let cropBoxData = this.refs.cropper.cropper.getCropBoxData();

		/* istanbul ignore next */
		this.setState({
			cropWidth: cropBoxData.width * this.state.scale,
			cropHeight: cropBoxData.height * this.state.scale,
		});
	};
}

export default CropperModal;
