import config from '../../../../../config/config';
import domHelper from '../../../../util/domHelper';
import { getShadowValFromProps, setAttributeInRef } from '../../../../util/helper';
import Element from './Element.react';
import { ComponentDefaults, AllComponentTypes } from '../../../../util/resources';

import ElementComponent from './ElementComponent.react';
import { Text } from '@chakra-ui/react';

export class SocialFollow extends ElementComponent {
	static defaultProps = {
		background_color: ComponentDefaults[AllComponentTypes.social_follow].background_color,
		type: ComponentDefaults[AllComponentTypes.social_follow].type,
		style: ComponentDefaults[AllComponentTypes.social_follow].style,
		spacing: ComponentDefaults[AllComponentTypes.social_follow].spacing,
		alignment: ComponentDefaults[AllComponentTypes.social_follow].alignment,
		facebook: null,
		twitter: null,
		instagram: null,
		googleplus: null,
		tumblr: null,
		pinterest: null,
		linkedin: null,
		youtube: null,
		box_shadow: ComponentDefaults[AllComponentTypes.social_follow].box_shadow,
	};

	render() {
		let passableIcons = config.possible_social_follow_icons;
		let folderNames = config.social_follow_style_folder_names;

		let style = folderNames.includes(this.props.style) ? this.props.style : 'default';

		let { spacing } = this.props;

		let iconList = passableIcons.filter((icon) => {
			//can not check for truthy of falsy here because empty string is considered falsy
			return icon in this.props && this.props[icon] !== null && this.props[icon] !== undefined;
		});

		let socialIcons = iconList.map((icon, i) => {
			const assetUrl = new URL(`/images/social_icons/social_follow/${style}/${icon}.png`, location.origin).href;

			let realSpacing = isNaN(spacing) ? 0 : spacing / 2;

			let spacingTd = <td className="social-icon-spacing" width={spacing / 2} height="10" style={{ display: 'inline-block' }} />;

			return [
				(realSpacing && spacingTd) || null,
				<td key={i} className="social-icon-td" style={{ display: 'inline-block' }}>
					<img style={{ verticalAlign: 'middle' }} className={icon} src={assetUrl} alt="" ref={setAttributeInRef('border', '0')} />
				</td>,
				(realSpacing && spacingTd) || null,
			];
		});

		let noSocialIcons = socialIcons.every((e) => {
			return !e;
		});

		let placeholder = noSocialIcons && (
			<Text variant="subtle" display="flex" alignItems="center" justifyContent="center">
				No social icons enabled. Click the gear icon to open this component's settings and enable them.
			</Text>
		);

		const shadowSettings = getShadowValFromProps(this.props.box_shadow);

		return (
			<table
				className="component"
				data-icon-style={this.props.style}
				data-component-type="social_follow"
				cellSpacing="0"
				cellPadding="0"
				width={'100%'}
				style={{ clear: 'both' }}
				ref={(rootTable) => {
					this.setAttributeInRef('align', 'center')(rootTable);
					domHelper.setAttributeList(rootTable, this.props.rootTableAttributes);

					domHelper.applyStyles(rootTable, { backgroundColor: this.props.background_color, boxShadow: shadowSettings });
					this.setAttributeInRef('data-icon-style', this.props.style)(rootTable);
				}}
			>
				<tbody>
					<tr className="content-main-content">
						<td style={{ padding: this.props.padding }}>
							<table
								cellSpacing="0"
								cellPadding="0"
								style={{ textAlign: this.props.alignment || 'center' }}
								ref={this.setAttributeInRef('align', this.props.alignment)}
								width="100%"
							>
								<tbody>
									<tr>{socialIcons}</tr>
								</tbody>
							</table>
							{placeholder}
						</td>
					</tr>
				</tbody>
			</table>
		);
	}
}

export default Element(SocialFollow);
