import React from 'react';
import Element from './Element.react';
import { Checkbox } from './Checkbox.react';
import { getFormFieldFontStyles, getPadding, getShadowValFromProps } from '../../../../util/helper';
import { AllComponentTypes } from '../../../../util/resources';
import { Text } from '@chakra-ui/react';

export const CheckboxGroup = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		labelWidth,
		asteriskColor,
		options,
		formId,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		box_shadow,
		background_color,
		optionsSpacing,
	} = props;

	const hiddenStyles = props.hidden
		? {
				opacity: 0.7,
			}
		: {};

	return (
		<div
			id={`${props.name.replace(/ /g, '')}-${formId}`}
			style={{
				width: '100%',
				backgroundColor: background_color,
				position: 'relative',
				pointerEvents: 'none',
				display: 'flex',
				flexDirection: 'column',
				...getPadding(props),
				...hiddenStyles,
			}}
			className="form-input-wrapper lp-flex-1"
		>
			<span
				style={{
					userSelect: 'none',
					whiteSpace: 'pre-wrap',
					wordBreak: 'break-word',
					color: labelFontColour,
					fontSize: labelFontSize,
					fontWeight: labelFontWeight,
					fontStyle: labelFontStyle,
					textDecoration: labelTextDecoration,
					display: labelHide ? 'none' : '',
					minHeight: labelWidth,
					...getFormFieldFontStyles(labelFont),
				}}
				className="form-field-label lp-flex-container"
				onClick={(e) => props.onClick && props.onClick(FormFieldTypes.label)}
			>
				{props.label}
				{props.required && (
					<span
						className="form-field-required"
						style={{
							color: asteriskColor,
							textDecoration: 'none',
							fontWeight: 'normal',
							fontStyle: 'normal',
							fontFamily: 'inherit',
						}}
					>
						{' '}
						*
					</span>
				)}
			</span>
			{options && options.length ? (
				<div>
					{options.map((item, i) => {
						return (
							<div key={item.value} style={{ marginBottom: i === options.length - 1 ? 0 : optionsSpacing }}>
								<Checkbox
									{...props}
									labelHide={false}
									type={AllComponentTypes.checkbox}
									value={item.value}
									label={item.label}
									descriptionHide
									required={false}
									padding={0}
									multiPadding={{
										allow: false,
										paddingTop: 0,
										paddingRight: 0,
										paddingBottom: 0,
										paddingLeft: 0,
									}}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<Text p={2.5} display="flex" alignItems="center" justifyContent="center">
					No options available
				</Text>
			)}
			{props.description && (
				<div
					style={{
						color: descriptionFontColour,
						fontSize: descriptionFontSize,
						fontFamily: descriptionFontFamily,
						marginTop: descriptionSpacing,
						fontStyle: descriptionFontStyle,
						fontWeight: descriptionFontWeight,
						textDecoration: descriptionTextDecoration,
						display: props.descriptionHide ? 'none' : 'block',
					}}
				>
					{props.description}
				</div>
			)}
		</div>
	);
};

export default Element(CheckboxGroup);
