import Moment from 'moment';

import useContentStore from '../stores/ContentStore';
import useLeftSidebarStore from '../stores/LeftSidebarStore';
import config from '../../config/config';

import { showAutosaveNotification } from '../stores/NotificationStore';

let autosaveInterval;

export function initializeAutosaves() {
	autosaveInterval = setInterval(() => {
		const contentStore = useContentStore.getState();

		let draft = {
			title: 'Autosave - ' + Moment().format(),
			description: 'Autosaves have no description',
			json: contentStore.content,
			generalSettings: contentStore.general_settings,
		};

		let successCb = () => {
			showAutosaveNotification();
		};

		useLeftSidebarStore
			.getState()
			.saveNewDraft(
				draft,
				true,
				useLeftSidebarStore.getState().allowProdSave,
				useLeftSidebarStore.getState().versions.latestDraft,
				successCb,
				null,
				null
			);
	}, config.default_autosave_timer);
}

export function clearAutosaves() {
	clearInterval(autosaveInterval);
}
