import React, { memo } from 'react';
import { findDOMNode } from 'react-dom';
import { browserHistory } from 'react-router';
import Slot from './Slot.react';
import {
	GridOptions,
	SlotSpacingTypes,
	slotSubstractor,
	contentViewMenuItemConstants as ViewTypes,
	slotDividers,
} from '../../../util/resources';
import { DragSource, DropTarget } from 'react-dnd';
import itemTypes from '../../../util/itemTypes';
import Moment from 'moment';
import { shallow } from 'zustand/shallow';

import {
	Box,
	Button,
	IconButton,
	Spinner,
	Text,
	Input,
	Popover,
	PopoverTrigger,
	PopoverContent,
	InputRightElement,
	InputGroup,
	Tooltip,
} from '@chakra-ui/react';

import { getEmptyImage } from 'react-dnd-html5-backend';

import { getPadding, pathPrefix, findRow } from '@/util/helper';

import AddStructuresBox from './helper/AddStructuresBox.react';

import useCommentStore from '../../../stores/CommentStore';
import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import useContentStore from '../../../stores/ContentStore';
import useContentViewStore from '../../../stores/ContentViewStore';

import { isEqual } from 'lodash';
import { getUser } from '@/util/auth/auth';
import {
	mdiCursorMove,
	mdiClose,
	mdiCog,
	mdiCommentText,
	mdiContentCopy,
	mdiContentSave,
	mdiTrashCan,
	mdiTrashCanOutline,
	mdiPencilOutline,
	mdiReplyOutline,
	mdiSend,
} from '@mdi/js';
import { Icon } from '@/components/gui/shared/Icon';
import { rowSourceSpec, rowTargetSpec } from '@/components/gui/content/RowHelpers';

class Row extends React.PureComponent {
	row;

	constructor() {
		super();

		this.state = {
			iconHover: false,
			menu: false,
		};
	}

	componentDidMount() {
		this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
	}

	render() {
		const isScriptMode = location.pathname === '/script-menu';

		let slotComponents = 0;
		const { isInvisible, connectDragSource, connectDropTarget, mailingLists, activeElement, active } = this.props;

		let centerSpacing = !this.props.isMobileView ? this.props.slot_spacing_center : 0,
			sideSpacing = !this.props.isMobileView ? this.props.slot_spacing_side : 0;

		const comments = this.props.comments.filter((item) => {
			return item.type === 'row' && item.targetId === this.props.uniqueId;
		});

		const structureWidth = this.props.structureWidth >= this.props.rowWidth ? this.props.rowWidth : this.props.structureWidth;

		let slots = this.props.slots.map((item, i) => {
			let lastItem = null,
				firstItem = null,
				centerItem = null;

			if (sideSpacing && this.props.slots.length === i + 1) {
				lastItem = <SlotSpacing spacing={sideSpacing} type={SlotSpacingTypes.SIDE} />;
			}

			if (sideSpacing && i === 0) {
				firstItem = <SlotSpacing spacing={sideSpacing} type={SlotSpacingTypes.SIDE} />;
			}

			if (centerSpacing && i !== 0) {
				centerItem = <SlotSpacing spacing={centerSpacing} type={SlotSpacingTypes.CENTER} />;
			}

			let slotWidth = this.getSlotWidth(item);

			if (slotComponents === 0) {
				slotComponents = item.components.length;
			}

			// TEMP
			// const index = this.props.isMobileView ? item.mobileProps.index : i;
			const index = i;

			// to force render slots when first row because of the image not being displayed bug when dragging a saved row on an empty content
			// const forceUpdate = this.props.index === 0 && !isCurrentlyBeingDraggedRow ? generateGuid() : i;

			return [
				firstItem ? firstItem : centerItem,
				<Slot
					showResolvedComments={this.props.showResolvedComments}
					teamMembers={this.props.teamMembers}
					key={i}
					index={index}
					lastPage={this.props.lastPage}
					pageIndex={this.props.pageIndex}
					comments={this.props.comments}
					// forceUpdate={forceUpdate}
					rowId={this.props.id}
					active_addElement={this.props.active_addElement}
					grid={this.props.grid}
					isMobileView={this.props.isMobileView}
					isScriptMode={isScriptMode}
					general_styles={this.props.general_styles}
					mailingLists={mailingLists}
					slotWidth={slotWidth}
					responsive={this.props.responsive}
					customElements={this.props.customElements}
				/>,
				lastItem,
			];
		});

		if (this.props.loading) {
			return (
				<Box h="24" maxWidth={structureWidth} w={`100%`} margin={'0 auto'} display="flex" alignItems="center" justifyContent="center">
					<Spinner thickness="4px" emptyColor="neutral.200" color="primary" size="lg" />
				</Box>
			);
		}

		return (
			<Box
				display={(!isInvisible && 'block') || 'none'}
				maxW={structureWidth}
				w="100%"
				onMouseEnter={() => this.setState({ menu: true })}
				onMouseLeave={() => this.setState({ menu: false })}
				data-testid="editor-row"
				mx="auto"
			>
				<AddStructureGridBtn {...this.props} first={true} onClickAddStructure={this.onClickAddStructure} />
				{connectDropTarget(
					<div style={{ position: 'relative' }} className={`row row${this.props.id}`}>
						{(!isScriptMode && (this.state.menu || this.props.active) && (
							<HoverMenu
								active={active}
								activeElement={activeElement}
								teamMembers={this.props.teamMembers}
								showResolvedComments={this.props.showResolvedComments}
								mouseEnter={this.mouseEnter}
								mouseLeave={this.mouseLeave}
								toggleSettings={this.toggleSettings}
								duplicateRow={this.duplicateRow}
								removeRow={this.removeRow}
								hideRow={this.hideRow}
								saveCustomRow={this.saveCustomRow}
								connectDragSource={connectDragSource}
								isMobileView={this.props.isMobileView}
								onSubmitComment={this.onSubmitComment}
								onEditComment={this.onEditComment}
								onDeleteComment={this.onDeleteComment}
								showComments={this.props.active === this.props.id}
								comments={comments}
								targetId={this.props.uniqueId}
								// style={hoverMenuAlignment}
							/>
						)) ||
							null}
						{this.renderDesignerRow(slots)}
					</div>,
				)}
				<AddStructureGridBtn {...this.props} first={false} onClickAddStructure={this.onClickAddStructure} />
			</Box>
		);
	}

	renderDesignerRow = (slots) => {
		const rowOverlay = this.renderRowOverlay();
		const hiddenRow = this.props.isMobileView && this.props.hidden ? { opacity: 0.3 } : {};
		const responsiveStyles = this.props.isMobileView && this.props.responsive ? { direction: 'column' } : {};
		const border = this.props.borderWidth ? `${this.props.borderWidth}px solid ${this.props.borderColor}` : '';
		const padding = getPadding(this.props);
		const bgSize = this.props.rowWidth ? this.props.rowWidth : this.props.structureWidth;

		return (
			<Box
				className="row-div"
				position="relative"
				borderRadius={this.props.borderRadius}
				backgroundImage={this.props.background_image ? `url('${this.props.background_image}')` : ''}
				backgroundRepeat={this.props.background_repeat === 'full' ? 'no-repeat' : this.props.background_repeat}
				backgroundSize={
					this.props.background_repeat === 'full' ? '100%' : (this.props.general_styles.retinaImages ? bgSize * 2 : bgSize) + 'px'
				}
				backgroundPosition={this.props.background_position}
				backgroundColor={this.props.bg_color}
				{...hiddenRow}
				style={{
					...padding,
					border,
					borderCollapse: 'collapse',
				}}
			>
				{rowOverlay}
				<Box display="flex" flexDirection="row" {...responsiveStyles} className="row-slot-wrapper">
					{slots}
				</Box>
			</Box>
		);
	};

	getSlotWidth = (slot) => {
		const { padding, multiPadding, borderWidth, responsive, rowWidth, isMobileView, contentBorderWidth } = this.props;

		const structureWidth = this.props.structureWidth >= rowWidth ? rowWidth : this.props.structureWidth;
		const calculatedStructureWidth = this.props.isMobileView ? (structureWidth > 450 ? 450 : structureWidth) : structureWidth;
		const deductPadding = multiPadding && multiPadding.allow ? multiPadding.paddingRight + multiPadding.paddingLeft : padding * 2;

		return isMobileView && responsive
			? calculatedStructureWidth - deductPadding - borderWidth * 2 - contentBorderWidth * 2
			: calculatedStructureWidth / slotSubstractor[slot.type] -
					deductPadding / slotDividers[slot.type] -
					(borderWidth * 2) / slotDividers[slot.type];
	};

	removeRow = () => {
		const contentStore = useContentStore.getState();

		contentStore.unsetClickedElement();
		contentStore.unsetClickedRow();
		contentStore.deactivateAddStructureBoxHelper();
		contentStore.deactivateAddElementBoxHelper();
		contentStore.removeRow(this.props.id);
	};

	duplicateRow = () => {
		useContentStore.getState().duplicateRow(this.props.id);
	};

	saveCustomRow = () => {
		useLeftSidebarStore.getState().setCustomRowToSave(this.props);
		browserHistory.push(pathPrefix() + '/save-custom-row' + location.search);
	};

	mouseEnter = () => {
		this.setState({ iconHover: true });
	};

	mouseLeave = () => {
		this.setState({ iconHover: false });
	};

	//'first' parameter is true if a row must be prepended to the content and not added at a position
	onClickAddStructure = (first) => {
		useContentStore.getState().activateAddStructureBoxHelper({ rowId: this.props.id, first });
	};

	onClickRow = () => {
		useContentStore.getState().setClickedRow(this.props.id);
	};

	deselectRow = () => {
		const contentStore = useContentStore.getState();

		contentStore.unsetClickedElement();
		contentStore.unsetClickedRow();
		contentStore.deactivateAddStructureBoxHelper();
		contentStore.deactivateAddElementBoxHelper();
	};

	onSubmitComment = (text, parentId) => {
		const details = {
			status: 'unresolved',
			content: text,
			parentId,
			type: 'row',
			targetId: this.props.uniqueId,
		};
		useCommentStore.getState().saveComment(details);
	};

	onEditComment = (details) => {
		useCommentStore.getState().editComment(details);
	};

	onDeleteComment = (id) => {
		useCommentStore.getState().deleteComment(id);
	};
	6;

	toggleSettings = () => {
		useLeftSidebarStore.getState().toggleRowSettings(this.props.id);
		this.onClickRow();
	};

	renderRowOverlay = () => {
		if (this.state.iconHover || this.props.active) {
			return (
				<React.Fragment>
					<Box
						zIndex={1}
						position="absolute"
						borderTopWidth={3}
						borderTopColor="cyan.400"
						borderTopStyle="solid"
						top={0}
						left={0}
						right={0}
					/>
					<Box
						zIndex={1}
						position="absolute"
						borderBottomColor="cyan.400"
						borderBottomStyle="solid"
						borderBottomWidth={3}
						bottom={0}
						left={0}
						right={0}
					/>
					<Box
						zIndex={1}
						position="absolute"
						borderRightWidth={3}
						borderRightColor="cyan.400"
						borderRightStyle="solid"
						top={0}
						right={0}
						bottom={0}
					/>
					<Box
						zIndex={1}
						position="absolute"
						borderLeftWidth={3}
						borderLeftColor="cyan.400"
						borderLeftStyle="solid"
						top={0}
						left={0}
						bottom={0}
					/>
				</React.Fragment>
			);
		}
		return null;
	};

	hideRow = () => {
		// let settings = cloneDeep(this.props);
		const settings = {
			id: this.props.id,
			hidden: !this.props.hidden,
		};

		useContentStore.getState().toggleRowHideStatus(settings);
	};
}

const SlotSpacing = (props) => {
	<div className={`slot-spacing ${props.type}`} style={{ width: props.spacing }} />;
};

function sourceCollect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	};
}

function targetCollect(connect) {
	return {
		connectDropTarget: connect.dropTarget(),
	};
}

const AddStructureGridBtn = (props) => {
	let addStructureButton;

	if (
		props.active_addStructure.id !== props.id ||
		(props.active_addStructure.id === props.id && props.active_addStructure.first === !props.first)
	) {
		addStructureButton = (
			<Box
				className="add-structure-grid add-structure-bottom"
				onClick={() => props.onClickAddStructure(props.first)}
				py={5}
				display="flex"
				alignItems="center"
				justifyContent="center"
				borderStyle="solid"
				borderColor="borderColors.editorRow"
				borderWidth={3}
			>
				<Button onClick={() => props.onClickAddStructure(props.first)}>
					{/*<ButtonIcon children="add" mr={5} />*/}
					<Box boxAs="span" fontSize="xs" fontWeight="semibold" ml="1" verticalAlign="baseline">
						Add layout
					</Box>
				</Button>
			</Box>
		);
	}

	let addStructureBox = props.active_addStructure.id === props.id && props.active_addStructure.first === props.first && (
		<AddStructuresBox helperGrid {...props} />
	);

	let conditionBottom = props.grid === GridOptions.ENABLED;
	let conditionTop = props.grid === GridOptions.ENABLED && props.index === 0;
	let condition = props.first ? conditionTop : conditionBottom;

	if (condition) {
		return (
			<React.Fragment>
				{addStructureButton}
				{addStructureBox}
			</React.Fragment>
		);
	}

	return null;
};

const HoverMenu = (props) => {
	const [isOverlapped, setIsOverlapped] = React.useState(false);

	const id = `hover-menu-${props.targetId}`;

	React.useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById(id);
			const rightSideMenu = document.getElementById('right-sidebar-menu');

			const menuLeft = element && element.getBoundingClientRect().left;
			const sidebarLeft = rightSideMenu && rightSideMenu.getBoundingClientRect().left;

			setIsOverlapped(sidebarLeft && menuLeft && sidebarLeft - 40 <= menuLeft);
		}, 400);
	}, [id, props.active, props.activeElement]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			position="absolute"
			right={!isOverlapped ? '-24px' : 0}
			w="6"
			// overflow="hidden"
			borderRadius="base"
			top="0"
			id={id}
			zIndex={2000}
			backgroundColor="cyan.400"
			data-testid="hover-menu"
			onMouseEnter={props.mouseEnter}
			onMouseLeave={() => {
				props.mouseLeave && props.mouseLeave();
			}}
		>
			<HoverExtended {...props} type="row">
				{props.connectDragSource(
					<div style={{ width: 35 }}>
						<Tooltip placement="right" label="Drag to move">
							<IconButton
								p={1}
								color="chakra-inverse-text"
								icon={<Icon path={mdiCursorMove} />}
								variant="unstyled"
								size="xs"
								display="flex"
								alignItems="center"
								cursor="move"
								aria-label={'Drag to move'}
							/>
						</Tooltip>
					</div>,
				)}
				<Tooltip placement="right" label="Settings">
					<IconButton
						p={1}
						color="chakra-inverse-text"
						icon={<Icon path={mdiCog} />}
						onClick={props.toggleSettings}
						variant="unstyled"
						size="xs"
						display="flex"
						alignItems="center"
						aria-label={'Settings'}
					/>
				</Tooltip>
				{!props.isMobileView && (
					<Tooltip placement="right" label="Duplicate">
						<IconButton
							p={1}
							color="chakra-inverse-text"
							icon={<Icon path={mdiContentCopy} />}
							onClick={props.duplicateRow}
							variant="unstyled"
							size="xs"
							display="flex"
							alignItems="center"
							aria-label={'Duplicate'}
						/>
					</Tooltip>
				)}
				{props.isMobileView && (
					<Tooltip placement="right" label="Hide">
						<IconButton
							p={1}
							fontSize="lg"
							color="chakra-inverse-text"
							icon={<Icon path={mdiTrashCan} />}
							onClick={props.hideRow}
							variant="unstyled"
							size="xs"
							display="flex"
							alignItems="center"
							aria-label={'Hide'}
						/>
					</Tooltip>
				)}
				{!props.isMobileView && (
					<Tooltip placement="right" label="Remove">
						<IconButton
							p={1}
							color="chakra-inverse-text"
							icon={<Icon path={mdiTrashCan} />}
							onClick={props.removeRow}
							variant="unstyled"
							size="xs"
							display="flex"
							alignItems="center"
							aria-label={'Remove'}
						/>
					</Tooltip>
				)}
				{!props.isMobileView && (
					<Tooltip placement="right" label="Save custom layout">
						<IconButton
							p={1}
							color="chakra-inverse-text"
							icon={<Icon path={mdiContentSave} />}
							onClick={props.saveCustomRow}
							variant="unstyled"
							size="xs"
							display="flex"
							alignItems="center"
							aria-label={'Save custom layout'}
						/>
					</Tooltip>
				)}
			</HoverExtended>
		</Box>
	);
};

export const HoverExtended = (props) => {
	const [comment, setComment] = React.useState({ value: '' });
	const [reply, setReply] = React.useState({ value: '' });
	const [parentId, setParentId] = React.useState('');
	const [editComment, setEditComment] = React.useState('');
	const [editReply, setEditReply] = React.useState('');
	const [editObject, setEditObject] = React.useState(undefined);

	const onClick = () => {
		props.toggleSettings();
	};

	const submit = (value) => {
		if (!value.value) {
			return;
		}
		if (editObject) {
			props.onEditComment({ ...editObject, content: value /* , date: Moment().format() */ });
		} else {
			props.onSubmitComment(comment, parentId);
		}
		onClose();
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 13) submit(comment);
	};

	const onClose = () => {
		setComment({ value: '' });
		setReply({ value: '' });
		setEditReply('');
		setParentId('');
		setEditComment('');
		setEditObject(undefined);
	};

	React.useEffect(() => {
		if (props.showComments) {
			useCommentStore.getState().markCommentsAsSeen(props.targetId);
		}
	}, [props.showComments, props.targetId]);

	const userData = getUser();
	const currentMemberId = userData && userData.sub;

	const teamMembers =
		props.teamMembers &&
		props.teamMembers
			.filter((item) => {
				return item.Id !== currentMemberId;
			})
			.map((item) => {
				return {
					id: item.Id,
					display: item.Name,
					// display: `${item.Name} (${item.Email})`
				};
			});
	return location.pathname === pathPrefix() + '/comment-mode' ? (
		<Box position="relative">
			<Popover className="comments-menu" placement="bottom-start" isOpen={props.showComments}>
				<PopoverTrigger>
					<Tooltip placement="right" label="Comments">
						<IconButton
							icon={<Icon path={mdiCommentText} color="chakra-inverse-text" />}
							variant="unstyled"
							size="xs"
							display="flex"
							alignItems="center"
							aria-label="Comments"
							onClick={onClick}
							data-testid="popover-comments-button"
						/>
					</Tooltip>
				</PopoverTrigger>
				<PopoverContent w="lg">
					<Box pt={3.5} px={3.5}>
						{props.comments.map((item, i) => {
							// if (!props.showResolvedComments && item.status === 'resolved') {
							// 	return null;
							// }
							if (item.status === 'resolved') {
								return null;
							}
							return (
								<Box
									key={item.id}
									display="flex"
									mb={2.5}
									pb={2.5}
									flexDirection="column"
									borderBottomWidth={i === props.comments.length - 1 ? 0 : 1}
									borderBottomStyle="solid"
									borderBottomColor="chakra-border-color"
									data-testid="editor-comment"
								>
									<Box mb={2.5} display="flex" alignItems="center" justifyContent="space-between">
										<Text fontSize="lg" fontWeight="semibold">
											{item.user}
										</Text>
										<Text variant="small">{Moment(item.date).format(`MMM, DD HH:mm`)}</Text>
									</Box>
									{editComment === item.id ? (
										<CommentsInput
											value={comment.value}
											onChange={(e) => {
												setComment({ value: e.target.value });
											}}
											onKeyDown={onKeyDown}
											placeholder="Edit comment…"
											data={teamMembers}
											onSubmit={() => submit(comment)}
											onClose={onClose}
										/>
									) : (
										// <Text whiteSpace="pre-wrap" fontWeight="semibold" fontSize="md" mb={item.replies && item.replies.length ? 10 : 0}>
										// 	{item.content.valuePlain}
										// </Text>
										<CommentPreview value={item.content.value} />
									)}
									{item.replies &&
										item.replies.map((replyItem, ri) => {
											return (
												<Box
													key={ri}
													borderBottomWidth={ri === item.replies.length - 1 ? 0 : 1}
													py={2.5}
													borderBottomStyle="solid"
													borderBottomColor="chakra-border-color"
													ml={4}
													data-testid="editor-comment-reply"
												>
													<Box borderLeftWidth={3} pl={1.5} borderLeftStyle="solid" borderLeftColor="primary">
														<Box mb={2.5} display="flex" alignItems="center" justifyContent="space-between">
															<Text fontSize="lg" fontWeight="semibold">
																{replyItem.user}
															</Text>
															<Text variant="small">{Moment(replyItem.date).format(`MMM, DD HH:mm`)}</Text>
														</Box>
														<Box>
															{editReply === replyItem.id ? (
																<CommentsInput
																	placeholder="Edit reply…"
																	value={reply.value}
																	onChange={(e) => {
																		setReply({ value: e.target.value });
																	}}
																	onKeyDown={onKeyDown}
																	data={teamMembers}
																	onSubmit={() => submit(reply)}
																	onClose={onClose}
																/>
															) : (
																// <Text whiteSpace="pre-wrap" fontWeight="semibold" fontSize="md">
																// 	{replyItem.content.valuePlain}
																// </Text>
																<Box display="flex" alignItems="flex-start">
																	<CommentPreview value={replyItem.content.value} />
																	{replyItem.teamMemberId === currentMemberId && !parentId && !editObject && (
																		<Box display="flex" alignItems="center" justifyContent="flex-end" mt={2.5}>
																			<Tooltip label="Edit reply">
																				<IconButton
																					variant="ghost"
																					size="sm"
																					icon={<Icon path={mdiPencilOutline} />}
																					aria-label="Edit reply"
																					data-testid="edit-reply"
																					onClick={() => {
																						setEditReply(replyItem.id);
																						setReply(replyItem.content);
																						setEditObject(replyItem);
																					}}
																				/>
																			</Tooltip>
																			<Tooltip label="Delete reply">
																				<IconButton
																					variant="ghostColorOnHover"
																					size="sm"
																					icon={<Icon path={mdiTrashCanOutline} />}
																					data-testid="delete-reply"
																					colorScheme="danger"
																					aria-label="Delete reply"
																					onClick={() => props.onDeleteComment(replyItem.id)}
																				/>
																			</Tooltip>
																		</Box>
																	)}
																</Box>
															)}
														</Box>
													</Box>
												</Box>
											);
										})}
									{parentId === item.id && (
										<Box mt={2.5}>
											<CommentsInput
												placeholder="Add a reply…"
												value={comment.value}
												onChange={(e) => {
													setComment({ value: e.target.value });
												}}
												onKeyDown={onKeyDown}
												data={teamMembers}
												onSubmit={() => submit(comment)}
												onClose={onClose}
											/>
										</Box>
									)}
									{!parentId && !editObject && (
										<Box display="flex" alignItems="center" justifyContent="flex-end" mt={2.5}>
											<Button
												size="sm"
												variant="ghost"
												mr="auto"
												onClick={() => props.onEditComment({ ...item, status: item.status === 'resolved' ? 'unresolved' : 'resolved' })}
											>
												Resolve
											</Button>
											<Tooltip label="Reply">
												<IconButton
													size="sm"
													variant="ghost"
													data-testid="reply-comment"
													icon={<Icon path={mdiReplyOutline} />}
													aria-label={'Reply'}
													onClick={() => {
														setEditObject(undefined);
														setParentId(item.id);
													}}
												/>
											</Tooltip>
											{item.teamMemberId === currentMemberId && (
												<React.Fragment>
													<Tooltip label="Edit comment">
														<IconButton
															variant="ghost"
															icon={<Icon path={mdiPencilOutline} />}
															aria-label={'Edit comment'}
															onClick={() => {
																setEditComment(item.id);
																setComment(item.content);
																setEditObject(item);
															}}
															size="sm"
															data-testid="edit-comment"
														/>
													</Tooltip>
													<Tooltip label="Delete comment">
														<IconButton
															variant="ghostColorOnHover"
															data-testid="delete-comment"
															colorScheme="danger"
															icon={<Icon path={mdiTrashCanOutline} />}
															aria-label={'Delete comment'}
															onClick={() => props.onDeleteComment(item.id)}
															size="sm"
														/>
													</Tooltip>
												</React.Fragment>
											)}
										</Box>
									)}
								</Box>
							);
						})}
					</Box>
					{!parentId && !editObject && (
						<Box p={3.5}>
							<CommentsInput
								value={comment.value}
								onChange={(e) => {
									setComment({ value: e.target.value });
								}}
								onKeyDown={onKeyDown}
								data={teamMembers}
								onSubmit={() => submit(comment)}
								onClose={onClose}
							/>
						</Box>
					)}
				</PopoverContent>
			</Popover>
		</Box>
	) : (
		props.children
	);
};

export const CommentsInput = (props) => {
	return (
		<InputGroup>
			<Input
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder || 'Add a comment…'}
				autoFocus
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				onKeyDown={props.onKeyDown}
				data-testid="comment-input"
			/>
			<InputRightElement right="4">
				<Tooltip label="Clear">
					<IconButton
						size="sm"
						icon={<Icon path={mdiClose} />}
						onClick={props.onClose}
						variant="ghost"
						aria-label="Clear"
						data-testid="clear-comment-input"
					/>
				</Tooltip>
				<Tooltip label="Send">
					<IconButton
						size="sm"
						icon={<Icon path={mdiSend} />}
						onClick={props.onSubmit}
						colorScheme="primary"
						variant="ghost"
						aria-label="Send"
						data-testid="send-comment"
					/>
				</Tooltip>
			</InputRightElement>
		</InputGroup>
	);
};

export const CommentPreview = (props) => {
	return (
		<Box data-testid="comment-preview" flex={1}>
			<Text>{props.value}</Text>
		</Box>
	);
};

const RowDnD = DropTarget(
	itemTypes.STRUCTURE,
	rowTargetSpec,
	targetCollect,
)(DragSource(itemTypes.STRUCTURE, rowSourceSpec, sourceCollect)(Row));

export const RowComp = (props) => <RowDnD {...props} />;

const RowWrapper = (props) => {
	const { comments, showResolvedComments, teamMembers } = useCommentStore(
		(state) => ({ comments: state.comments, teamMembers: state.teamMembers, showResolvedComments: state.showResolvedComments }),
		shallow,
	);

	const isMobileView = useContentViewStore((state) => state.currentView === ViewTypes.MOBILE_VIEW);

	const generalStyles = useContentStore((state) => {
		const settings = isMobileView ? { ...state.content, ...state.content.mobileProps } : state.content;

		const generalStyles = {
			text_line_height: settings.text_line_height || '1',
			font_size: settings.font_size,
			font_family: settings.font_family,
			color: settings.color === 'transparent' ? '#000000' : settings.color,
			background_image: settings.background_image,
			background_repeat: settings.background_repeat,
			bg_color: settings.bg_color,
			title: settings.title,
			borderWidth: settings.borderWidth,
			borderColor: settings.borderColor,
			structureWidth: settings.structureWidth,
			retinaImages: settings.retina_images,
		};
		return generalStyles;
	}, isEqual);

	const { active_addElement, active_addStructure } = useContentStore((state) => {
		return {
			active_addElement: state.helper.active_addElement,
			active_addStructure: state.helper.active_addStructure,
		};
	}, shallow);

	const rowProps = useContentStore((state) => {
		const row = findRow(state.content.rows, props.id).row;

		if (isMobileView) {
			return { ...row, ...row.mobileProps };
		}

		return row;
	}, shallow);

	const { customRows, customElements } = useLeftSidebarStore((state) => {
		return { customRows: state.customRows, customElements: state.customElements };
	}, shallow);

	return (
		<RowComp
			comments={comments}
			showResolvedComments={showResolvedComments}
			teamMembers={teamMembers}
			active_addElement={active_addElement}
			active_addStructure={active_addStructure}
			customRows={customRows}
			customElements={customElements}
			general_styles={generalStyles}
			{...rowProps}
			{...props}
			isMobileView={isMobileView}
		/>
	);
};

RowWrapper.displayName = 'RowWrapper';

export default memo(RowWrapper);
