import React from 'react';

import { Link } from 'react-router';
import { Box, Button, Image, Text, Heading } from '@chakra-ui/react';

export const EmptyPage = (props) => {
	return (
		<Box
			className={props.className}
			style={props.style}
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			pt={24}
			hideBelow="sm"
			data-testid="empty-page"
		>
			<Image src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus-neutral" boxSize="28" />
			<Heading my={3} size="sm">
				{props.title}
			</Heading>
			{props.description && <Text mb={3}>{props.description}</Text>}
			{props.route ? (
				<Link to={props.route} onClick={props.onClick}>
					{props.label && <Button>{props.label}</Button>}
				</Link>
			) : (
				props.label && <Button onClick={props.onClick}>{props.label}</Button>
			)}
		</Box>
	);
};

export const MobileEmptyPage = (props) => {
	return (
		<Box
			p={2.5}
			display="flex"
			flexDirection="column"
			alignItems="center"
			pt={12}
			className={props.className}
			style={props.style}
			hideFrom={'md'}
		>
			<Image w="100%" maxW="sm" src={props.src} />
			<Text display="block" my={8} color="chakra-subtle-text" textAlign="center">
				{props.title}
			</Text>
		</Box>
	);
};
