import moment from 'moment';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import useLeftSidebarStore from './LeftSidebarStore';

import useContentViewStore from '../stores/ContentViewStore';
import useModalStore from './ModalStore';
import useCommentStore from './CommentStore';

import {
	checkJsonValidity,
	createDelayedInterval,
	getLastPage,
	getLastPageForAll,
	hasLimitComps,
	hasLimitedComponents,
	pathPrefix,
} from '../util/helper';

import {
	simpleObjectEquals,
	findRow,
	generateGuid,
	extractRealRowProps,
	findElementIndexById,
	updateTemplateJson,
	getEditorType,
	checkBoolean,
} from '../util/helper';

import {
	SlotDefaultProperties,
	RowDefaultProperties,
	AllComponentTypes,
	SlotsNotAllowedForElementDesigner,
	emptyDefaultJson,
	GridOptions,
	RowTypes,
	SlotMappings,
	ComponentDefaults,
	defaultColorPresets,
	contentViewMenuItemConstants as ViewTypes,
	GeneralSettingsDefaultsLP,
	NotificationTypes,
	FieldComponentTypes,
	PagePlaceholderProperties,
	SpecialComponentTypes,
	FieldComponentNames,
	LimitedComponentTypes,
	FieldComponentGroups,
	BaseConditionProps,
	Modals,
	ModalDesigns,
	ModalTypes,
	FieldComponentGroupTypes,
} from '../util/resources';

import { cloneDeep } from 'lodash';

import { uploadImage, uploadImageByUrl } from '../util/apiUtils/fileUpload';
import { getDraft, getAutosaveDraft, getDraftFromRsp, getTemplate, getPreviewHtml } from '../util/apiUtils/template';
import { getContentHubData } from '../util/apiUtils/thirdParty';
import {
	authenticateContentHub,
	createContentHubMetadata,
	deleteContentHubMetaData,
	generateCroppedImageUrl,
} from '../util/apiUtils/image';

import { produce } from 'immer';
import useUndoStore from '../stores/UndoStore';
import useEntitiesStore from './EntitiesStore';
import { showNotification } from './NotificationStore';
import { EntityStatuses } from '@/components/Table/strings';
import { ModalMessages } from '../../config/messages';
import { browserHistory } from 'react-router';
import { deleteComponentsFromRows, searchForComponentsInRows } from '@/stores/utils';

import { getInvalidLogics, getInvalidLogic, isLogicsValid } from '@/util/ConditionsValidations/ConditionsValidations';
import { getStyle } from '../util/apiUtils/styling';
import WebStorage from '@/util/webStorage';

export const targetTypes = {
	content: 'content',
	mobileView: 'mobileView',
};

const delayedInterval = createDelayedInterval();
const delayedLogicValidationInterval = createDelayedInterval();

let defaultState = {
	general_settings: {
		general_styles: GeneralSettingsDefaultsLP.general_styles,
		slot: SlotDefaultProperties,
		components: cloneDeep(ComponentDefaults),
		responsive: true,
		link_settings: GeneralSettingsDefaultsLP.link_settings,
	},
	content: emptyDefaultJson[getEditorType()],
	preview_html: '',
	isDragging: false,
	isDraggingRow: false,
	dataLoading: true,
	previewLoading: false,
	allFields: [],
	validity: {
		errors: [],
		limitedComponents: {},
		hasErrors: false,
	},
	helper: {
		active_addStructure: {
			id: null,
			first: false,
		},
		active_addElement: {
			address: null,
			location: null,
		},

		touch_screen: true,
		grid: GridOptions.DISABLED,
		clicked_element: null,
		clicked_row: null,
	},
	logicsErrors: [],
	canBeModified: true,
	formModified: false,
};

const useContentStore = create(
	devtools(
		(set, get) => ({
			...defaultState,
			getLimitedComponents: () => get().validity.limitedComponents,
			getErrors: () => get().validity.errors,
			getErrorsCount: () => get().validity.hasErrors,
			removeGlobalStyles: () => {
				set(
					produce((state) => {
						state.content.customCss = '';
					}),
				);

				showNotification({ type: NotificationTypes.SUCCESS, text: 'The CSS style has been cleared successfully.' });
			},
			applyGlobalStyles: async (id) => {
				try {
					const getRsp = await getStyle(id);

					let styles = JSON.parse(getRsp.data.Content.Data);

					if (styles.isCss) {
						set(
							produce((state) => {
								state.content.customCss = styles.stylesString;
							}),
						);

						showNotification({
							type: NotificationTypes.SUCCESS,
							text: 'CSS style applied to all elements. See the applied style in Preview.',
						});
					} else {
						const settings = styles.settings;

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.field,
							group: FieldComponentGroupTypes.fields,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.checkbox,
							group: FieldComponentGroupTypes.checkboxes,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.radio,
							group: FieldComponentGroupTypes.radios,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.recaptcha,
							group: FieldComponentGroupTypes.other,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.spacer,
							group: FieldComponentGroupTypes.spacers,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.image,
							group: FieldComponentGroupTypes.images,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.social,
							group: FieldComponentGroupTypes.socials,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: settings.text,
							group: FieldComponentGroupTypes.texts,
						});

						get().applySettingsToAllFormElements({
							target: targetTypes.content,
							settings: { ...settings.submitButton, back_button: settings.backButton },
							group: FieldComponentGroupTypes.buttons,
						});

						set(
							produce((state) => {
								state.content.customCss = '';
								state.general_settings.components[AllComponentTypes.email_input] = {
									...state.general_settings.components[AllComponentTypes.email_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.longtext_input] = {
									...state.general_settings.components[AllComponentTypes.longtext_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.multiselect_input] = {
									...state.general_settings.components[AllComponentTypes.multiselect_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.number_input] = {
									...state.general_settings.components[AllComponentTypes.number_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.phone_input] = {
									...state.general_settings.components[AllComponentTypes.phone_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.text_input] = {
									...state.general_settings.components[AllComponentTypes.text_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.singleselect_input] = {
									...state.general_settings.components[AllComponentTypes.singleselect_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.date_input] = {
									...state.general_settings.components[AllComponentTypes.date_input],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.file_upload] = {
									...state.general_settings.components[AllComponentTypes.file_upload],
									...settings.field,
								};
								state.general_settings.components[AllComponentTypes.recaptcha] = {
									...state.general_settings.components[AllComponentTypes.recaptcha],
									...settings.recaptcha,
								};
								state.general_settings.components[AllComponentTypes.radio] = {
									...state.general_settings.components[AllComponentTypes.radio],
									...settings.radio,
								};
								state.general_settings.components[AllComponentTypes.checkbox] = {
									...state.general_settings.components[AllComponentTypes.checkbox],
									...settings.checkbox,
								};
								state.general_settings.components[AllComponentTypes.checkbox_group] = {
									...state.general_settings.components[AllComponentTypes.checkbox_group],
									...settings.checkbox,
								};
								state.general_settings.components[AllComponentTypes.gdpr] = {
									...state.general_settings.components[AllComponentTypes.gdpr],
									...settings.checkbox,
								};
								state.general_settings.components[AllComponentTypes.spacer] = {
									...state.general_settings.components[AllComponentTypes.spacer],
									...settings.spacer,
								};
								state.general_settings.components[AllComponentTypes.social_follow] = {
									...state.general_settings.components[AllComponentTypes.social_follow],
									...settings.social,
								};
								state.general_settings.components[AllComponentTypes.image] = {
									...state.general_settings.components[AllComponentTypes.image],
									...settings.image,
								};
								state.general_settings.components[AllComponentTypes.text] = {
									...state.general_settings.components[AllComponentTypes.text],
									...settings.text,
								};
								state.general_settings.components[AllComponentTypes.submit_button] = {
									...state.general_settings.components[AllComponentTypes.submit_button],
									...settings.submitButton,
									back_button: {
										...state.general_settings.components[AllComponentTypes.submit_button].back_button,
										...settings.backButton,
									},
								};
							}),
						);

						showNotification({ type: NotificationTypes.SUCCESS, text: 'Styles applied to all elements.' });

						get().setFormModified(true);
					}
				} catch (e) {
					showNotification({ type: NotificationTypes.ERROR, text: 'Failed to apply global styles.' });
					console.error(e);
				}
			},
			authenticateContentHub: async ({ data, successCb, errorCb }) => {
				if (!data.username || !data.password || !data.domain) {
					return errorCb('All fields are required.');
				} else {
					return authenticateContentHub(data)
						.then((resp) => {
							const token = resp.data.AuthToken;
							const key = generateGuid();
							const metadataEntry = {
								Attributes: [
									{ Key: 'username', Value: data.username },
									{ Key: 'token', Value: token },
									{ Key: 'domain', Value: data.domain },
								],
								AuthorisationMode: ApiAuthorisationModes.Private,
								Category: 'CONTENT_HUB_DATA',
								Content: { ContentType: 'application/json', Data: '{}' },
								Key: key,
								Name: key,
							};

							return createContentHubMetadata(metadataEntry)
								.then((metadataRsp) => {
									const newData = { token, username: data.username, metadataKey: key, domain: data.domain };
									set((state) => ({ contentHubData: newData }));
									successCb && successCb(newData);
								})
								.catch((error) => {
									console.error(error);
									errorCb('Failed to save authentication data');
								});
						})
						.catch((error) => {
							console.error(error);
							errorCb('Failed to authenticate');
						});
				}
			},
			disconnectContentHub: async ({ data, successCb, errorCb }) => {
				return deleteContentHubMetaData(data)
					.then((resp) => {
						set((state) => ({ contentHubData: undefined }));
						successCb && successCb();
					})
					.catch((error) => {
						console.error(error);
						errorCb && errorCb('Failed to disconnect');
					});
			},
			getContentHubData: async () => {
				return getContentHubData()
					.then((resp) => {
						if (resp.data && resp.data.length) {
							const data = resp.data[0];
							const contentHubData = { metadataKey: data.Key };
							data.Attributes.forEach((item) => {
								if (item.Key === 'filters') {
									contentHubData[item.Key] = JSON.parse(item.Value);
								} else {
									contentHubData[item.Key] = item.Value;
								}
							});
							set((state) => ({ contentHubData }));
						}
					})
					.catch((error) => {
						console.error(error);
					});
			},
			uploadImage: async ({ address, files, progress }, successCb, errorCb) => {
				let config = { onUploadProgress: progress || null };

				return uploadImage(files, config).then((data) => {
					if (data && !data.error) {
						get().saveElementSettings({
							address,
							settings: {
								src: data.newSrc,
								originalSrc: data.newSrc,
								currentSrc: data.newSrc,
								resizeWidth: null,
								resizeHeight: null,
								resized: false,
								imageType: data.type,
							},
						});

						useLeftSidebarStore.getState().updateElementSettings({ address });

						successCb && successCb();
					} else {
						errorCb && errorCb();
					}
				});
			},
			uploadImageByUrl: async ({ address, url, progress }, successCb, errorCb) => {
				return uploadImageByUrl(url).then((data) => {
					if (data && !data.error) {
						get().saveElementSettings({
							address,
							settings: {
								src: data.newSrc,
								originalSrc: data.newSrc,
								currentSrc: data.newSrc,
								resizeWidth: null,
								resizeHeight: null,
								resized: false,
								imageType: data.type,
							},
						});

						useLeftSidebarStore.getState().updateElementSettings({ address });

						successCb && successCb();
					} else {
						errorCb && errorCb();
					}
				});
			},
			setDraftAsContentJson: async (draft, draftMetadata, successCb, errorCb) => {
				const draftMetadataConst = draftMetadata; //redeclared as const here becase of flow

				if (draftMetadataConst) {
					let getDraftFunc = checkBoolean(draftMetadataConst.autosave) ? getAutosaveDraft : getDraft;
					return getDraftFunc(draftMetadataConst)
						.then((response) => {
							try {
								if (response && response.data && response.data.Content) {
									let draftRsp = getDraftFromRsp(response);
									get().updateSetDraftAsContentJson(draftRsp);
									successCb && successCb(response);

									get().runPostActions();
									useCommentStore.getState().updateComments();
								}
							} catch (e) {
								console.log(e);
							}
						})
						.catch((error) => {
							console.error(error, true);
							errorCb && errorCb(error);
						});
				} else {
					get().updateSetDraftAsContentJson(draft);

					get().runPostActions();
					useCommentStore.getState().updateComments();
				}
			},
			updateSetDraftAsContentJson: (draft) => {
				const json = updateTemplateJson(draft.json, draft.mobile, true);
				get().setContentJson(json);

				if (draft.generalSettings) {
					get().setGeneralSettings(draft.generalSettings);
				}

				useLeftSidebarStore.getState().setDraftAsContentJson(draft);
			},
			setPreviewHtml: async (html, successCb, errorCb) => {
				set((state) => ({ previewLoading: true }));

				return getPreviewHtml(html)
					.then((response) => {
						set((state) => ({ preview_html: response.data }));
						successCb && successCb(response);
						set((state) => ({ previewLoading: false }));
					})
					.catch((error) => {
						console.error(error, true);
						errorCb && errorCb(error);
						set((state) => ({ previewLoading: false }));
					});
			},
			cropImage: (element) => {
				let { address, cropData, originalSrc, type } = element;

				let url = generateCroppedImageUrl(originalSrc, type, cropData);

				get().saveElementSettings({
					address,
					settings: {
						src: url,
						currentSrc: url,
					},
				});
			},
			loadAndSetTemplate: async (template, templateMetadata, successCb, errorCb) => {
				const tMetadata = templateMetadata;

				if (tMetadata) {
					try {
						const response = await getTemplate(tMetadata);

						if (response.data.Content) {
							let templateJson = JSON.parse(response.data.Content.Data);

							get().updateLoadAndSetTemplate(templateJson);

							successCb && successCb(response);
						}
					} catch (error) {
						console.error(error, true);
						errorCb && errorCb(error);
					}
				} else {
					return get().updateLoadAndSetTemplate(template);
				}

				get().runPostActions();
				useCommentStore.getState().updateComments();
			},
			updateLoadAndSetTemplate: (template) => {
				if (Object.keys(template).length > 0) {
					//strip attributes because some old templates have saved attributes from code view in them that cause bugs now
					const templateJson = updateTemplateJson(
						{ ...template.json, templateId: template.id },
						template.mobile ? { ...template.mobile, templateId: template.id } : undefined,
						true,
					);

					get().setContentJson(stripAttributesFromContent(templateJson));
				}
			},
			resetState: () => set((state) => ({ ...defaultState })),
			activateTouchScreenHelper: (bool) => {
				set(
					produce((state) => {
						state.helper.touch_screen = bool;
					}),
				);
			},
			activateAddStructureBoxHelper: ({ rowId, first }) => {
				set(
					produce((state) => {
						state.helper.active_addStructure = { id: rowId, first };
					}),
				);
			},
			deactivateAddStructureBoxHelper: () => {
				set(
					produce((state) => {
						state.helper.active_addStructure = { id: null, first: false };
					}),
				);
			},
			activateAddElementBoxHelper: ({ address, location }) => {
				set(
					produce((state) => {
						state.helper.active_addElement = { address, location };
					}),
				);
			},
			deactivateAddElementBoxHelper: () => {
				set(
					produce((state) => {
						state.helper.active_addElement = { address: null, location: null };
					}),
				);
			},
			moveNewRow: ({ rowId, newIndex, pageIndex }) => {
				get().moveRow({ rowId, newIndex, pageIndex });
			},
			moveRow: ({ rowId, newIndex, pageIndex }) => {
				if (rowId === undefined || newIndex === undefined) {
					throw new Error(
						'The onMoveRow function takes an object with the following properties as a parameter: {rowId:123, newIndex:123} ',
					);
				}

				const { row, index } = findRow(get().content.rows, rowId);

				if (pageIndex !== row.pageIndex && hasLimitedComponents(row, get().validity.limitedComponents[pageIndex])) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				if (useContentViewStore.getState().currentView !== ViewTypes.MOBILE_VIEW) {
					const newRow = row.mobileProps.mobileChanged
						? {
								...row,
								pageIndex,
							}
						: {
								...row,
								pageIndex,
								mobileProps: {
									...row.mobileProps.mobileChanged,
									index: newIndex,
								},
							};

					set(
						produce((state) => {
							state.content.rows.splice(index, 1);
							state.content.rows.splice(newIndex, 0, newRow);
						}),
					);
				} else {
					const otherRowIndex = get().content.rows.findIndex((item) => item.mobileProps.index === newIndex);

					set(
						produce((state) => {
							state.content.rows[otherRowIndex].mobileProps.index = row.mobileProps.index;
							state.content.rows[otherRowIndex].mobileProps.mobileChanged = true;
							state.content.rows[index].mobileProps.index = newIndex;
							state.content.rows[index].mobileProps.mobileChanged = true;
						}),
					);
				}

				get().setFormModified(true);

				get().runPostActions();
			},
			duplicateRow: (rowId) => {
				let { row, index } = findRow(get().content.rows, rowId);

				let { newRow, hasLimitedComponents } = duplicateRow(row, get().validity.limitedComponents[row.pageIndex]);

				if (hasLimitedComponents) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				set(
					produce((state) => {
						state.content.rows.splice(index + 1, 0, newRow);
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			appendRowByDrag: ({ rowType, id }) => {
				return get().appendRow({ rowType, id });
			},
			appendRow: ({ rowType, id }) => {
				if (rowType in RowTypes) {
					let slots = SlotMappings[rowType].map((slotType, i) => {
						const slotId = { id: generateGuid() };
						return {
							type: slotType,
							...get().general_settings.slot,
							...slotId,
							mobileProps: {
								index: i,
							},
						};
					});

					const rowId = id ? id : generateGuid();

					const row = {
						...RowDefaultProperties,
						uniqueId: generateGuid(),
						id: rowId,
						type: rowType,
						slots,
						mobileProps: {
							responsive: get().general_settings.responsive,
							index: get().content.rows.length + 1,
						},
					};

					set(
						produce((state) => {
							state.content.rows.push(row);
						}),
					);

					get().setFormModified(true);

					get().runPostActions();

					return row;
				} else {
					throw new Error(`'${rowType}' RowType does not exist`);
				}
			},
			addRowAfterPosition: ({ rowType, rowId, newRowId }) => {
				if (rowType in RowTypes) {
					let slots = SlotMappings[rowType].map((slotType, i) => {
						const slotId = { id: generateGuid() };
						return {
							type: slotType,
							...get().general_settings.slot,
							...slotId,
							mobileProps: {
								index: i,
							},
						};
					});

					let { index, row } = findRow(get().content.rows, rowId);

					let newRow = {
						...RowDefaultProperties,
						id: newRowId ? newRowId : generateGuid(),
						uniqueId: generateGuid(),
						type: rowType,
						slots,
						mobileProps: {
							responsive: get().general_settings.responsive,
							index: row.mobileProps.index + 1,
						},
					};

					set(
						produce((state) => {
							state.content.rows.splice(index + 1, 0, newRow);
						}),
					);

					get().setFormModified(true);

					get().runPostActions();
				} else {
					throw new Error(`'${rowType}' RowType does not exist`);
				}
			},
			prependCustomRow: (row) => {
				let { newRow, hasLimitedComponents } = duplicateRow(extractRealRowProps(row), get().validity.limitedComponents[row.pageIndex]);

				if (hasLimitedComponents) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				set(
					produce((state) => {
						state.content.rows.unshift({ ...newRow, mobileProps: { ...newRow.mobileProps, index: 0 } });
					}),
				);

				get().setFormModified(true);
			},
			addCustomRowAfterPosition: ({ row, rowId }) => {
				let { index } = findRow(get().content.rows, rowId);
				let newIndex = index + 1;

				let { newRow, hasLimitedComponents } = duplicateRow(extractRealRowProps(row), get().validity.limitedComponents[row.pageIndex]);

				if (hasLimitedComponents) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				set(
					produce((state) => {
						state.content.rows.splice(newIndex, 0, {
							...newRow,
							mobileProps: { ...newRow.mobileProps, index: newIndex },
						});
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			prependRow: ({ rowType, id }) => {
				if (rowType in RowTypes) {
					let slots = SlotMappings[rowType].map((slotType, i) => {
						const slotId = { id: generateGuid() };
						return {
							type: slotType,
							...get().general_settings.slot,
							...slotId,
							mobileProps: {
								index: i,
							},
						};
					});

					const rowId = id ? id : generateGuid();

					const row = {
						...RowDefaultProperties,
						id: rowId,
						uniqueId: generateGuid(),
						type: rowType,
						slots,
						mobileProps: {
							responsive: get().general_settings.responsive,
							index: 0,
						},
					};

					set(
						produce((state) => {
							state.content.rows.unshift(row);
						}),
					);

					get().setFormModified(true);

					get().runPostActions();
				} else {
					throw new Error(`'${rowType}' RowType does not exist`);
				}
			},
			appendCustomRow: (row) => {
				let { newRow, hasLimitedComponents } = duplicateRow(extractRealRowProps(row), get().validity.limitedComponents[row.pageIndex]); //duplicate row here just in case a custom row is added twice

				if (hasLimitedComponents) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				set(
					produce((state) => {
						state.content.rows.push({
							...newRow,
							mobileProps: { ...newRow.mobileProps, index: state.content.rows.length + 1 },
						});
					}),
				);

				get().runPostActions();

				return newRow;
			},
			removeRow(rowId) {
				const { index, row } = findRow(get().content.rows, rowId);

				const rowComponents = get()
					.content.rows[index].slots.map((slot) => slot.components.map((component) => component.uniqueId))
					.flat();

				const logics = cloneDeep(get().content.logics);

				const existingAddress = searchForComponentsInRows(rowComponents, logics);

				const deleteRow = () => {
					set(
						produce((state) => {
							state.content.rows.splice(index, 1);
						}),
					);

					const newLogics = deleteComponentsFromRows(existingAddress, logics);

					get().setLogics(newLogics);

					get().setFormModified(true);

					useCommentStore.getState().updateComments();
					get().runPostActions();
					get().unsetClickedRow();

					const hasPage = get().content.rows.find((item) => row.pageIndex === item.pageIndex);

					if (!hasPage && get().content.rows.length > 0) {
						get().insertPage(row.pageIndex);
					}
				};

				if (existingAddress.length > 0) {
					useModalStore.getState().showGeneralModal({
						modal: Modals.ERROR,
						type: 'checkPreview',
						title: ModalMessages.DELETE_LAYOUT_TITLE,
						message: ModalMessages.DELETE_MODAL_WITH_LOGIC('layout'),
						onOk: () => {
							deleteRow();
						},
						okLabel: 'Delete',
						cancelLabel: 'Cancel',
					});
				} else {
					if (useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.row) {
						deleteRow();
					} else {
						let onOk = () => {
							deleteRow();
						};

						let message = ModalMessages.REMOVE_ROW;

						useModalStore.getState().showDontAskModal({ type: 'row', message, onOk });
					}
				}
			},
			toggleDragging: (id) => set((state) => ({ isDragging: state.isDragging ? false : id ? id : !state.isDragging })),
			toggleRowDragging: (id) => set((state) => ({ isDraggingRow: !state.isDraggingRow })),
			userRemoveComponent: (data) => {
				get().removeComponent(data.address);

				if (get().content.disableAutoComponents.find((item) => item.type === data.type && item.pageIndex === data.pageIndex)) {
					return;
				}

				if (data.type === AllComponentTypes.submit_button) {
					set(
						produce((state) => {
							state.content.disableAutoComponents.push({ type: data.type, pageIndex: data.pageIndex });
						}),
					);

					get().setFormModified(true);
				}
			},
			removeComponent: (componentAddress) => {
				let rowIndex = findRow(get().content.rows, componentAddress.rowId).index;

				const uniqueId = get().content.rows[rowIndex].slots[componentAddress.slot].components[componentAddress.component].uniqueId;

				let logics = cloneDeep(get().content.logics);

				let existingAddress = [];
				logics.forEach((logic, logicIndex) => {
					logic.settings.some((item, itemIndex) => {
						if (item.type === 'group') {
							return item.settings.some((condition, conditionIndex) => {
								if (condition.field && condition.field.id === uniqueId) {
									existingAddress = [logicIndex, itemIndex, conditionIndex];
									return true;
								}

								return false;
							});
						} else if (item.field && item.field.id === uniqueId) {
							existingAddress = [logicIndex, itemIndex];
							return true;
						}
						return false;
					});
				});

				const hasConditionalLogic = existingAddress.length > 1;

				const removeComponentFromSlot = () => {
					set(
						produce((state) => {
							state.content.rows[rowIndex].slots[componentAddress.slot].components.splice(componentAddress.component, 1);
						}),
					);
				};

				if (hasConditionalLogic) {
					useModalStore.getState().showGeneralModal({
						modal: Modals.ERROR,
						type: 'checkPreview',
						title: ModalMessages.FIELD_HAS_CONDITION,
						message: ModalMessages.DELETE_MODAL_WITH_LOGIC('field'),
						onOk: () => {
							removeComponentFromSlot();

							existingAddress.length === 3
								? logics[existingAddress[0]].settings[existingAddress[1]].settings.splice(existingAddress[2], 1)
								: logics[existingAddress[0]].settings.splice(existingAddress[1], 1);

							get().setLogics(logics);

							get().setFormModified(true);

							get().runPostActions();
							useCommentStore.getState().updateComments();
						},
						okLabel: 'Delete',
						cancelLabel: 'Cancel',
					});
				} else {
					const callRemoveActions = () => {
						removeComponentFromSlot();
						get().setLogics(logics);

						get().setFormModified(true);

						get().runPostActions();
						useCommentStore.getState().updateComments();
					};

					if (useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.element) {
						callRemoveActions();
					} else {
						let onOk = () => {
							callRemoveActions();
						};

						let message = ModalMessages.REMOVE_ELEMENT;

						useModalStore.getState().showDontAskModal({ type: 'element', message, onOk });
					}
				}
			},
			insertComponentByHelper: ({ insertAddress, componentData, additionalData }) => {
				get().insertComponent({ insertAddress, componentData, additionalData });

				get().addSubmitButton({ ...insertAddress, component: insertAddress.component + 1 }, additionalData);
			},
			insertComponent: ({ insertAddress, componentData, additionalData }) => {
				let { index, row } = findRow(get().content.rows, insertAddress.rowId);
				let slot = row.slots[insertAddress.slot];

				const slotsNotAllowed = SlotsNotAllowedForElementDesigner;

				//check if this type of component is allowed in this type of slot
				if (componentData && componentData.type && slotsNotAllowed[componentData.type].includes(slot.type)) {
					return;
				}

				componentData = { ...ComponentDefaults[componentData.type], ...componentData };

				if (componentData && componentData.type) {
					componentData = get().general_settings.components[componentData.type]
						? {
								...componentData,
								...get().general_settings.components[componentData.type],
								id: componentData.id,
								loading: componentData.loading,
							}
						: { ...componentData, id: componentData.id, loading: componentData.loading };
				}

				const id = componentData.id ? componentData.id : generateGuid();
				const uniqueId = generateGuid();

				if (componentData.type === AllComponentTypes.submit_button && additionalData.pageIndex !== get().content.lastPage) {
					componentData.text = 'Next';
				}

				if (FieldComponentTypes[componentData.type]) {
					componentData.name = `${FieldComponentNames[componentData.type]}_${uniqueId.substring(0, 5)}`;
				}

				componentData = {
					...componentData,
					id,
					loading: componentData.loading ? true : false,
					uniqueId,
				};

				set(
					produce((state) => {
						state.content.rows[index].slots[insertAddress.slot].components.splice(insertAddress.component, 0, componentData);
					}),
				);

				// set limited component immediately until the complete check is done
				if (LimitedComponentTypes.includes(componentData.type)) {
					set(
						produce((state) => {
							state.validity.limitedComponents = {
								...get().getLimitedComponents(),
								[row.pageIndex]: [
									...get().getLimitedComponents()[row.pageIndex],
									{ id: componentData.id, pageIndex: row.pageIndex, type: componentData.type },
								],
							};
						}),
					);
				}

				get().setFormModified(true);

				get().runPostActions();
				useModalStore.getState().insertComponent({ row, componentData });
			},
			moveComponent: ({ fromAddress, toAddress }) => {
				if (!simpleObjectEquals(fromAddress, toAddress)) {
					let { index, row } = findRow(get().content.rows, fromAddress.rowId);

					let toSlot = findRow(get().content.rows, toAddress.rowId).row.slots[toAddress.slot];
					let fromComponent = row.slots[fromAddress.slot].components[fromAddress.component];

					let newRowIndex = findRow(get().content.rows, toAddress.rowId).index;

					const slotsNotAllowed = SlotsNotAllowedForElementDesigner;

					if (fromComponent && fromComponent.type && slotsNotAllowed[fromComponent.type].includes(toSlot.type)) {
						return;
					}

					// let component = row.slots[fromAddress.slot].components[fromAddress.component];

					set(
						produce((state) => {
							state.content.rows[index].slots[fromAddress.slot].components.splice(fromAddress.component, 1);
							state.content.rows[newRowIndex].slots[toAddress.slot].components.splice(toAddress.component, 0, fromComponent);
						}),
					);

					if (simpleObjectEquals(fromAddress, get().helper.clicked_element)) {
						get().setClickedElement(toAddress);
					}

					useLeftSidebarStore.getState().moveComponent({ fromAddress, toAddress });
					useModalStore.getState().moveComponent({ row, toAddress });

					get().setFormModified(true);

					get().runPostActions();
				}
			},
			// moveSlot: ({ fromAddress, toAddress }) => {
			// 	let rowIndex = findRow(get().mobileView.rows, fromAddress.rowId).index;
			// 	const toRowIndex = findRow(get().mobileView.rows, toAddress.rowId).index;

			// 	let slot = get().mobileView.rows[rowIndex].slots[fromAddress.slot];
			// 	const toSlot = get().mobileView.rows[toRowIndex].slots[toAddress.slot];

			// 	set(
			// 		produce((state) => {
			// 			state.mobileView.rows[rowIndex].slots.splice(fromAddress.slot, 1, toSlot);
			// 			state.mobileView.rows[rowIndex].slots.splice(toAddress.slot, 1, slot);
			// 			state.mobileView.rows[rowIndex].mobileChanged = true;
			// 		})
			// 	);

			// 	useLeftSidebarStore.getState().updateRowSettings({ fromAddress, toAddress });
			// 	get().runPostActions();
			// },
			addSubmitButton: (insertAddress, data) => {
				if (
					get().content.disableAutoComponents.find(
						(item) => item.type === FieldComponentTypes.submit_button && item.pageIndex === data.pageIndex,
					)
				) {
					return;
				}

				if (
					Object.keys(FieldComponentTypes)
						.filter((item) => item !== FieldComponentTypes.submit_button)
						.includes(data.componentType)
				) {
					const limitedComponents = get().getLimitedComponents()[data.pageIndex];
					if (!limitedComponents || !limitedComponents.find((item) => item.type === FieldComponentTypes.submit_button)) {
						const componentData = get().general_settings.components[AllComponentTypes.submit_button]
							? get().general_settings.components[AllComponentTypes.submit_button]
							: ComponentDefaults[AllComponentTypes.submit_button];
						get().insertComponent({
							insertAddress,
							componentData,
							additionalData: data,
						});

						get().setFormModified(true);
					}
				}
			},
			saveInitialElementSettings: ({ address, settings }) => {
				get().saveElementSettings({ address, settings });
			},
			saveElementSettings: ({ address, settings }) => {
				let { index, row } = findRow(get().content.rows, address.rowId);

				if (settings) {
					const isMobileView = useContentViewStore.getState().currentView === ViewTypes.MOBILE_VIEW;

					if (!isMobileView) {
						set(
							produce((state) => {
								state.content.rows[index].slots[address.slot].components[address.component] = {
									...state.content.rows[index].slots[address.slot].components[address.component],
									...settings,
								};
							}),
						);
					} else {
						set(
							produce((state) => {
								state.content.rows[index].slots[address.slot].components[address.component].mobileProps = {
									...state.content.rows[index].slots[address.slot].components[address.component].mobileProps,
									...settings,
									mobileChanged: true,
								};
							}),
						);
					}

					get().setFormModified(true);

					useLeftSidebarStore.getState().updateElementSettings({ address });
					get().runPostActions();
				} else {
					throw new Error('Expected settings in saveElementSettings to be object. Received: ' + settings);
				}
			},
			saveForAllFormElementSettings: (settings, group) => {
				if (settings) {
					const isMobileView = useContentViewStore.getState().currentView === ViewTypes.MOBILE_VIEW;

					if (!isMobileView) {
						get().applySettingsToAllFormElements({ target: targetTypes.content, settings, group });
					} else {
						get().applySettingsToAllFormElements({ target: targetTypes.mobileView, settings, group });
					}

					get().setFormModified(true);

					// useLeftSidebarStore.getState().updateElementSettings({ address });
					get().runPostActions();
				} else {
					throw new Error('Expected settings in saveElementSettings to be object. Received: ' + settings);
				}
			},
			applySettingsToAllFormElements: ({ target, settings, group }) => {
				let recurse = (data) => {
					Object.keys(data).forEach((key, i) => {
						if (key === 'components') {
							data[key].forEach((component) => {
								if (FieldComponentGroups[group].includes(component.type)) {
									if (target === targetTypes.mobileView) {
										Object.keys(settings).forEach((settingKey) => {
											if (settingKey === 'back_button') {
												component.mobileProps[settingKey] = { ...component.mobileProps[settingKey], ...settings[settingKey] };
											} else {
												component.mobileProps[settingKey] = settings[settingKey];
											}
										});
										component.mobileProps.mobileChanged = true;
									} else {
										Object.keys(settings).forEach((settingKey) => {
											if (settingKey === 'back_button') {
												component[settingKey] = { ...component[settingKey], ...settings[settingKey] };
											} else {
												component[settingKey] = settings[settingKey];
											}
										});
									}
								}
							});
						} else if (data[key] && typeof data[key] === 'object' && !key.endsWith('Attributes')) {
							recurse(data[key]);
						}
					});
				};

				set((state) => {
					const json = cloneDeep(state.content);
					recurse(json);
					return { content: json };
				});
			},
			saveContentSettings: ({ settings, mobileChanged }) => {
				if (!settings) {
					throw new Error('Settings empty');
				}
				if (useContentViewStore.getState().currentView !== ViewTypes.MOBILE_VIEW) {
					set(
						produce((state) => {
							state.content = { ...state.content, ...settings };
						}),
					);
				} else {
					set(
						produce((state) => {
							state.content = {
								...state.content,
								mobileProps: { ...state.content.mobileProps, ...settings, mobileChanged: true },
							};
						}),
					);
				}

				useLeftSidebarStore.getState().updateContentSettings();
				get().runPostActions();
			},
			revertMobileView: () => {
				set(
					produce((state) => {
						state.content = {
							...state.content,
							mobileProps: {},
							rows: [...state.content.rows].map((row, rowIndex) => {
								return {
									...row,
									mobileProps: { index: rowIndex },
									slots: row.slots.map((slot, slotIndex) => {
										return {
											...slot,
											mobileProps: { index: slotIndex },
											components: slot.components.map((component, componentIndex) => {
												return {
													...component,
													mobileProps: { index: componentIndex },
												};
											}),
										};
									}),
								};
							}),
						};
					}),
				);
				get().runPostActions();
			},
			revertGeneralSettings: () => {
				set(
					produce((state) => {
						state.content.mobileProps = {};
					}),
				);
				get().runPostActions();
			},
			revertAllRowSettings: () => {
				set(
					produce((state) => {
						state.content = {
							...state.content,
							mobileProps: {},
							rows: [...state.content.rows].map((row) => {
								return {
									...row,
									mobileProps: { index: row.mobileProps.index },
								};
							}),
						};
					}),
				);
				get().runPostActions();
			},
			revertAllRowOrder: () => {
				set(
					produce((state) => {
						state.content = {
							...state.content,
							mobileProps: {},
							rows: [...state.content.rows].map((row, rowIndex) => {
								return {
									...row,
									mobileProps: { ...row.mobileProps, index: rowIndex },
								};
							}),
						};
					}),
				);
				get().runPostActions();
			},
			revertRowSettings: (rowId) => {
				const { row, index } = findRow(get().content.rows, rowId);

				set(
					produce((state) => {
						state.content.rows[index] = { ...row, mobileProps: { index: row.mobileProps.index } };
					}),
				);

				useLeftSidebarStore.getState().updateRowSettings(rowId);
				get().runPostActions();
			},
			revertRowOrder: (rowId) => {
				const { index, row } = findRow(get().content.rows, rowId);

				set(
					produce((state) => {
						state.content.rows[index] = {
							...row,
							mobileProps: { ...row.mobileProps, index },
						};
					}),
				);

				get().runPostActions();
			},
			revertElementSettings: (element) => {
				const { index } = findRow(get().content.rows, element.rowId);

				set(
					produce((state) => {
						state.content.rows[index].slots[element.slot].components[element.component] = {
							...state.content.rows[index].slots[element.slot].components[element.component],
							mobileProps: {},
						};
					}),
				);

				useLeftSidebarStore.getState().updateElementSettings({ address: element });
				get().runPostActions();
			},
			toggleRowHideStatus: (settings) => {
				get().saveRowSettings(settings, true);
			},
			saveRowSettings: (settings, skipLeftSidebarUpdate) => {
				let { index } = findRow(get().content.rows, settings.id);
				const isMobileView = useContentViewStore.getState().currentView === ViewTypes.MOBILE_VIEW;

				const newSettings = { ...settings };
				delete newSettings.id;

				if (!isMobileView) {
					set(
						produce((state) => {
							state.content.rows[index] = {
								...state.content.rows[index],
								...settings,
							};
						}),
					);
				} else {
					set(
						produce((state) => {
							state.content.rows[index].mobileProps = {
								...state.content.rows[index].mobileProps,
								...settings,
								mobileChanged: true,
							};
						}),
					);
				}

				!skipLeftSidebarUpdate && useLeftSidebarStore.getState().updateRowSettings(settings.id);
				get().runPostActions();
			},
			saveSlotSettings: ({ rowId, settings, slotIndex }) => {
				let { index } = findRow(get().content.rows, rowId);
				const isMobileView = useContentViewStore.getState().currentView === ViewTypes.MOBILE_VIEW;

				if (!isMobileView) {
					set(
						produce((state) => {
							state.content.rows[index].slots[slotIndex] = {
								...state.content.rows[index].slots[slotIndex],
								...settings,
							};
						}),
					);
				} else {
					set(
						produce((state) => {
							state.content.rows[index].slots[slotIndex].mobileProps = {
								...state.content.rows[index].slots[slotIndex].mobileProps,
								...settings,
								mobileChanged: true,
							};
						}),
					);
				}

				useLeftSidebarStore.getState().updateRowSettings(rowId);
				get().runPostActions();
			},
			saveContentHelper: (settings) => {
				if (settings) {
					set((state) => ({ helper: { ...state.helper, ...settings } }));
					useLeftSidebarStore.getState().updateContentHelper();
				} else {
					throw new Error('Expected settings in onSaveContentHelper to be object. Received: ' + settings);
				}
			},
			setContentJson: (json) => {
				let newJson = !json ? emptyDefaultJson[getEditorType()] : checkAndAssignComponentUids(json); //to prevent null json

				set((state) => ({
					content: newJson,
				}));
			},
			setJsons: (json) => {
				let newJson = !json ? emptyDefaultJson[getEditorType()] : checkAndAssignComponentUids(json); //to prevent null json
				const convertedJson = newJson;
				set((state) => ({ content: convertedJson }));
			},
			setGeneralSettings: (generalSettings) => {
				set((state) => ({ general_settings: generalSettings }));
			},
			setBlueprints: (json) => {
				// LEGACY
				const content = updateTemplateJson(json.newsletter ? json.newsletter : json.content, json.mobile, true);
				set((state) => ({ content }));
				if (json.generalSettings) {
					get().setGeneralSettings(json.generalSettings);
				}

				get().runPostActions();
			},
			duplicateElement: ({ address }) => {
				let rowIndex = findRow(get().content.rows, address.rowId).index;

				let component = cloneDeep(get().content.rows[rowIndex].slots[address.slot].components[address.component]);

				if (LimitedComponentTypes.includes(component.type)) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				component.id = generateGuid();
				component.uniqueId = generateGuid();

				if (FieldComponentTypes[component.type]) {
					component.name = `${FieldComponentNames[component.type]}_${component.uniqueId.substring(0, 5)}`;
				}

				set(
					produce((state) => {
						state.content.rows[rowIndex].slots[address.slot].components.splice(address.component + 1, 0, component); // + 1 so it is added after
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			setDesignerScript: (settings) => {
				set(
					produce((state) => {
						state.content.script = { ...state.content.script, ...settings };
					}),
				);
			},
			clearContent: () => {
				set(
					produce((state) => {
						state.content = { ...emptyDefaultJson[getEditorType()] };
						state.general_settings.general_styles = GeneralSettingsDefaultsLP.general_styles;
						state.general_settings.components = cloneDeep(ComponentDefaults);
						state.general_settings.slot = cloneDeep(SlotDefaultProperties);
					}),
				);

				get().setFormModified(true);

				useLeftSidebarStore.getState().updateContentSettings();
				get().runPostActions();
			},
			undoContentStoreState: (data) => {
				set((state) => ({ ...state, ...data }));
			},
			redoContentStoreState: (data) => {
				set((state) => ({ ...state, ...data }));
			},
			setClickedElement: (address) => {
				set(
					produce((state) => {
						state.helper.clicked_element = address;
						state.helper.clicked_row = null;
					}),
				);
			},
			unsetClickedElement: () => {
				set(
					produce((state) => {
						state.helper.clicked_element = null;
					}),
				);
			},
			setClickedRow: (id) => {
				set(
					produce((state) => {
						state.helper.clicked_row = id;
						state.helper.clicked_element = null;
					}),
				);
			},
			unsetClickedRow: () => {
				set(
					produce((state) => {
						state.helper.clicked_row = null;
						state.helper.clicked_element = null;
					}),
				);

				useLeftSidebarStore.getState().unsetClickedRow();
			},
			setResponsive: (value) => {
				get().content.rows.forEach((row) => {
					get().saveRowSettings({ responsive: value, id: row.id }, true);
				});

				set(
					produce((state) => {
						state.general_settings.responsive = value;
					}),
				);

				get().runPostActions();
			},
			//General settings
			saveContentGeneralSettings: ({ settings }) => {
				if (useContentViewStore.getState().currentView !== ViewTypes.MOBILE_VIEW) {
					set(
						produce((state) => {
							state.content = { ...state.content, ...settings };
						}),
					);
				} else {
					set(
						produce((state) => {
							state.content = {
								...state.content,
								mobileProps: { ...state.content.mobileProps, ...settings, mobileChanged: true },
							};
						}),
					);
				}

				useLeftSidebarStore.getState().updateContentSettings();
				get().runPostActions();
			},
			saveElementGeneralSettings: ({ elementType, settings }) => {
				set(
					produce((state) => {
						state.general_settings.components[elementType] = { ...state.general_settings.components[elementType], ...settings };
					}),
				);

				get().runPostActions();
			},
			saveSlotGeneralSettings: ({ settings }) => {
				set(
					produce((state) => {
						state.general_settings.slot = { ...state.general_settings.slot, ...settings };
					}),
				);

				get().runPostActions();
			},
			saveLinkDecoration: (data) => {
				let key = Object.keys(data)[0];
				set(
					produce((state) => {
						state.general_settings.link_settings[key] = data[key];
					}),
				);

				get().runPostActions();
			},
			saveLinkStyles: (data) => {
				set(
					produce((state) => {
						state.general_settings.link_settings[data.key] = data.value;
					}),
				);

				get().runPostActions();
			},
			applyLinkStyles: () => {
				if (useContentViewStore.getState().currentView !== ViewTypes.MOBILE_VIEW) {
					set(
						produce((state) => {
							state.content = setLinkStyleToAllTextComponents(cloneDeep(get().content), get().general_settings.link_settings);
						}),
					);

					get().setFormModified(true);
				}

				get().runPostActions();
			},
			addUsedColor: (color) => {
				const usedColors = [...get().content.usedColors] || defaultColorPresets;

				if (usedColors.includes(color)) {
					const index = usedColors.indexOf(color);
					usedColors.splice(index, 1);
				}

				usedColors.unshift(color);

				if (usedColors.length > 16) {
					usedColors.pop();
				}

				set(
					produce((state) => {
						state.content.usedColors = usedColors;
					}),
				);
			},
			addCustomFont: (font) => {
				if (get().content.customFonts) {
					const isAddedFont = get().content.customFonts.find((item) => {
						return item.label === font.label;
					});

					if (isAddedFont) {
						const customFonts = [...get().content.customFonts];

						const index = customFonts.findIndex((item) => {
							item.label === font.label;
						});

						customFonts.splice(index, 1, font);

						get().setCustomFonts(customFonts);
					} else {
						set(
							produce((state) => {
								state.content.customFonts.push(font);
							}),
						);
					}
				} else {
					get().setCustomFonts([font]);
				}
			},
			setCustomFonts: (fonts) => {
				set(
					produce((state) => {
						state.content.customFonts = fonts;
					}),
				);
			},
			setCampaignCustomFields: (campaign_custom_fields) => {
				const newCustomFields = [...get().campaign_custom_fields, ...campaign_custom_fields];

				set((state) => ({ campaign_custom_fields: newCustomFields }));
			},
			setImage: ({ type, url, imageType, details }) => {
				switch (type) {
					case 'element':
						get().saveElementSettings({
							address: details,
							settings: {
								imageType,
								src: url,
								originalSrc: url,
								currentSrc: url,
								resizeWidth: null,
								resizeHeight: null,
								resized: false,
							},
						});
						break;

					case 'row':
						get().saveRowSettings({
							background_image: url,
							background_image_type: imageType,
							id: details,
						});
						break;

					case 'background':
						const settings = { background_image: url, background_image_type: imageType };
						get().saveContentGeneralSettings({ settings });
						break;

					default:
						break;
				}

				useLeftSidebarStore.getState().setImage({ type, url, imageType, details });
				useUndoStore.getState().saveContentStoreState();
			},

			setCampaignData: ({ senderName, subject }) => {
				set((state) => ({ subject, senderName }));
			},
			setSiteDetails: (siteDetails) => {
				set((state) => ({ siteDetails }));
			},
			replaceRow: ({ rowId, row }) => {
				const { row: existingRow, index } = findRow(get().content.rows, rowId);

				let { newRow, hasLimitedComponents } = duplicateRow(
					extractRealRowProps(row),
					get().validity.limitedComponents[existingRow.pageIndex],
				);

				if (hasLimitedComponents) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					get().removeRow(rowId);
					return;
				}

				set(
					produce((state) => {
						state.content.rows.splice(index, 1, {
							...existingRow,
							...newRow,
							mobileProps: { ...row.mobileProps, index },
							loading: false,
							pageIndex: existingRow.pageIndex,
						});
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			updateReplaceRow: (target, index, row) => {
				set(
					produce((state) => {
						state[target].rows.splice(index, 1, row);
					}),
				);
			},
			setPermissions: (permissions) => {
				set((state) => ({ permissions }));
			},
			setTemplateId: (templateId) => {
				set(
					produce((state) => {
						state.content = { ...state.content, templateId };
					}),
				);

				get().setFormModified(true);
			},
			setPalette: (palette) => {
				set((state) => ({ palette }));
			},
			setData: (data) => {
				set((state) => ({ ...state, ...data }));
			},
			setSuccessAction: (data) => {
				set(
					produce((state) => {
						state.content.successAction = data;
					}),
				);

				get().setFormModified(true);
			},
			setFailAction: (data) => {
				set(
					produce((state) => {
						state.content.failAction = data;
					}),
				);

				get().setFormModified(true);
			},
			dropRow: () => {
				useUndoStore.getState().saveContentStoreState();
			},
			checkJsonValidity: () => {
				const rows = get().content.rows;

				const { validity, rows: newRows, allFields } = checkJsonValidity(rows, get().content.lastPage);

				set(
					produce((state) => {
						state.validity = validity ? validity : state.validity;
						state.content.rows = newRows ? newRows : state.rows;
						state.allFields = allFields;
					}),
				);
			},
			updateCheckingJsonValidity: (isChecking) => {
				set(
					produce((state) => {
						state.checkingValidity = isChecking;
					}),
				);
			},
			runPostActions: () => {
				get().updateCheckingJsonValidity(true);

				delayedInterval(() => {
					get().checkJsonValidity();

					get().updateCheckingJsonValidity(false);

					get().checkLogicsValidity(!!get().logicsErrors.length);

					useUndoStore.getState().saveContentStoreState();
				}, 1000);
			},
			insertRowInPage: (rowId, rowType) => {
				if (rowType in RowTypes) {
					const { row, index } = findRow(get().content.rows, rowId);

					let slots = SlotMappings[rowType].map((slotType, i) => {
						const slotId = { id: generateGuid() };
						return {
							type: slotType,
							...get().general_settings.slot,
							...slotId,
							mobileProps: {
								index: i,
							},
						};
					});

					const rows = [...get().content.rows];

					const newRow = {
						...RowDefaultProperties,
						...row,
						type: rowType,
						responsive: get().general_settings.responsive,
						slots,
						mobileProps: {
							index: rows[index - 1] ? rows[index - 1].mobileProps.index : 0,
						},
					};

					rows.splice(index, 1, newRow);

					set(
						produce((state) => {
							state.content.rows = rows;
							state.content.lastPage = getLastPage(rows).pageIndex;
							state.content.lastPageAll = getLastPageForAll(rows).pageIndex;
						}),
					);

					get().setFormModified(true);

					return newRow;
				} else {
					throw new Error(`'${rowType}' RowType does not exist`);
				}
			},
			insertPage: (index) => {
				const rows = [...get().content.rows];

				const placeholder = {
					...PagePlaceholderProperties,
					slots: [],
					pageIndex: index,
					id: generateGuid(),
					uniqueId: generateGuid(),
					mobileProps: {},
				};

				rows.push(placeholder);

				set(
					produce((state) => {
						state.content.rows = rows;
						state.content.lastPage = getLastPage(rows).pageIndex;
						state.content.lastPageAll = getLastPageForAll(rows).pageIndex;
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			duplicatePage: (index) => {
				let buttonAddress;

				const rows = get().content.rows.map((row) => ({
					...row,
					pageIndex: row.pageIndex >= index + 1 ? row.pageIndex + 1 : row.pageIndex,
				}));

				const rowsToBeDuplicated = get().content.rows.filter((row) => row.pageIndex == index);

				const newRows = rowsToBeDuplicated.map((item) => ({
					...duplicatePageRow(item),
					pageIndex: item.pageIndex + 1,
				}));

				const allRows = [...rows, ...newRows];

				const lastPage = getLastPage(allRows).pageIndex;
				const lastPageAll = getLastPageForAll(allRows).pageIndex;

				rowsToBeDuplicated.forEach((row, rowIndex) => {
					row.slots.forEach((slot, slotIndex) => {
						slot.components.forEach((component, componentIndex) => {
							if (component.type === AllComponentTypes.submit_button) {
								buttonAddress = {
									rowIndex: findRow(allRows, row.id).index,
									slotIndex,
									componentIndex,
									textChanged: component.textChanged,
								};
							}
						});
					});
				});

				if (!buttonAddress) {
					const lastDuplicatedRow = rowsToBeDuplicated[rowsToBeDuplicated.length - 1];

					let slots = SlotMappings[RowTypes.FULL].map((slotType, i) => {
						const slotId = { id: generateGuid(), uniqueId: generateGuid() };
						const buttonId = generateGuid();
						const buttonUniqueId = generateGuid();
						const buttonName = `${FieldComponentNames.submit_button}_${buttonUniqueId.substring(0, 5)}`;
						return {
							type: slotType,
							...get().general_settings.slot,
							...slotId,
							components: [
								{
									...ComponentDefaults.submit_button,
									id: buttonId,
									uniqueId: buttonUniqueId,
									name: buttonName,
									mobileProps: { index: 0 },
									text: lastDuplicatedRow.pageIndex + 1 === lastPage ? ComponentDefaults.submit_button.text : 'Next',
								},
							],
							mobileProps: {
								index: i,
							},
						};
					});

					const newRow = {
						...RowDefaultProperties,
						type: RowTypes.FULL,
						responsive: get().general_settings.responsive,
						slots,
						uniqueId: generateGuid(),
						id: generateGuid(),
						pageIndex: lastDuplicatedRow.pageIndex + 1,
						mobileProps: {
							responsive: get().general_settings.responsive,
							index: lastDuplicatedRow ? lastDuplicatedRow.mobileProps.index + 1 : 0,
						},
					};

					allRows.push(newRow);
				}

				set(
					produce((state) => {
						state.content.rows = allRows;
						state.content.lastPage = lastPage;
						state.content.lastPageAll = lastPageAll;
					}),
				);

				get().setFormModified(true);

				if (buttonAddress && !buttonAddress.textChanged) {
					set(
						produce((state) => {
							state.content.rows[buttonAddress.rowIndex].slots[buttonAddress.slotIndex].components[buttonAddress.componentIndex].text =
								'Next';
						}),
					);
				}

				get().runPostActions();
			},
			removePage: (index) => {
				const rows = get()
					.content.rows.filter((row) => row.pageIndex != index)
					.map((row) => ({
						...row,
						pageIndex: row.pageIndex > index ? row.pageIndex - 1 : row.pageIndex,
					}));

				const rowComponents = get()
					.content.rows.filter((row) => row.pageIndex === index)
					.map((row) => row.slots.map((slot) => slot.components.map((component) => component.uniqueId)).flat())
					.flat();

				const logics = cloneDeep(get().content.logics);

				const existingAddress = searchForComponentsInRows(rowComponents, logics);

				const deletePage = () => {
					const newLogics = deleteComponentsFromRows(existingAddress, logics);

					get().setLogics(newLogics);

					set(
						produce((state) => {
							state.content.rows = rows;
							state.content.lastPage = getLastPage(rows).pageIndex;
							state.content.lastPageAll = getLastPageForAll(rows).pageIndex;
						}),
					);

					get().setFormModified(true);

					get().runPostActions();
				};

				if (existingAddress.length > 0) {
					useModalStore.getState().showGeneralModal({
						modal: Modals.ERROR,
						type: 'checkPreview',
						title: ModalMessages.DELETE_PAGE_TITLE,
						message: ModalMessages.DELETE_MODAL_WITH_LOGIC('page'),
						onOk: () => {
							deletePage();
						},
						okLabel: 'Delete',
						cancelLabel: 'Cancel',
					});
				} else {
					if (useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.page) {
						deletePage();
					} else {
						let onOk = () => {
							deletePage();
						};

						let message = ModalMessages.REMOVE_PAGE;

						useModalStore.getState().showDontAskModal({ type: 'page', message, onOk });
					}
				}
			},
			movePage: (oldIndex, newIndex) => {
				const rows = get().content.rows.map((item) => {
					if (item.pageIndex === oldIndex) {
						return { ...item, pageIndex: newIndex };
					} else if (item.pageIndex === newIndex) {
						return { ...item, pageIndex: oldIndex };
					}
					return item;
				});

				set(
					produce((state) => {
						state.content.rows = rows;
					}),
				);

				get().setFormModified(true);

				get().runPostActions();
			},
			moveRowToPage: ({ pageIndex, rowId }) => {
				const { row } = findRow(get().content.rows, rowId);

				if (hasLimitedComponents(row, get().validity.limitedComponents[pageIndex])) {
					showNotification({ type: NotificationTypes.WARNING, text: 'You can only have one item of this type.' });
					return;
				}

				let rows = get().content.rows.map((item) => {
					if (item.id === rowId) {
						return { ...item, pageIndex: pageIndex };
					}
					return item;
				});

				const hasPage = rows.find((item) => row.pageIndex === item.pageIndex);

				if (!hasPage) {
					rows = rows.map((item) => {
						return { ...item, pageIndex: item.pageIndex < row.pageIndex ? item.pageIndex : item.pageIndex - 1 };
					});
				}

				set(
					produce((state) => {
						state.content.rows = rows;
						state.content.lastPage = getLastPage(rows).pageIndex;
						state.content.lastPageAll = getLastPageForAll(rows).pageIndex;
					}),
				);

				get().setFormModified(true);
			},
			changeConditionSetting: (address, settings) => {
				const isActiveEntity = useEntitiesStore.getState().activeEntity?.Entity.Status === EntityStatuses.active;
				if (isActiveEntity && !get().canBeModified) return;

				set(
					produce((state) => {
						let obj = state.content.logics;

						address.slice(0, -1).forEach((key) => {
							obj = obj[key].settings;
						});

						const lastAddress = address[address.length - 1];

						obj[lastAddress] = { ...obj[lastAddress], ...settings };
					}),
				);

				get().updateLogicValidity(address[0]);

				get().setFormModified(true);
			},
			changeLogicActions: (logicIdx, actions) => {
				const isActiveEntity = useEntitiesStore.getState().activeEntity?.Entity.Status === EntityStatuses.active;
				if (isActiveEntity && !get().canBeModified) return;

				set(
					produce((state) => {
						state.content.logics[logicIdx].actions = actions || [];
					}),
				);

				get().updateLogicValidity(logicIdx);

				get().setFormModified(true);
			},
			changeLogicAction: (logicIdx, actionIdx, action) => {
				const isActiveEntity = useEntitiesStore.getState().activeEntity?.Entity.Status === EntityStatuses.active;
				if (isActiveEntity && !get().canBeModified) return;

				set(
					produce((state) => {
						state.content.logics[logicIdx].actions[actionIdx] = action;
					}),
				);

				get().updateLogicValidity(logicIdx);

				get().setFormModified(true);
			},
			setLogics: (logics) => {
				set(
					produce((state) => {
						state.content.logics = logics;
					}),
				);

				if (get().logicsErrors.length) get().checkLogicsValidity();

				get().setFormModified(true);
			},
			checkLogicsValidity: (updateLogicsErrors = true) => {
				let invalidLogics;
				let isValid;

				set(
					produce((state) => {
						invalidLogics = getInvalidLogics(state.content.logics, state.allFields);
						isValid = isLogicsValid(invalidLogics);

						if (updateLogicsErrors) {
							state.logicsErrors = isValid ? [] : invalidLogics;
						}
					}),
				);

				return { isValid, logicsErrors: isValid ? [] : invalidLogics };
			},
			updateLogicValidity: (logicIdx) => {
				if (!get().logicsErrors.length) return;

				delayedLogicValidationInterval(() => {
					if (!get().logicsErrors.length) return;

					set(
						produce((state) => {
							state.logicsErrors[logicIdx] = getInvalidLogic(state.content.logics[logicIdx], state.allFields);
						}),
					);
				}, 150);
			},
			setCanBeModified: (canBeModified) =>
				set(
					produce((state) => {
						if (canBeModified) WebStorage.set('canBeModified', canBeModified);
						else WebStorage.remove('canBeModified');

						state.canBeModified = canBeModified;
					}),
				),
			setFormModified: (isModified) =>
				set(
					produce((state) => {
						state.formModified = isModified;
					}),
				),
		}),
		{ enabled: import.meta.env.MODE !== 'production' && import.meta.env.MODE !== 'test', name: 'Content Store' },
	),
);

export function duplicateRow(row, limitedComponents) {
	let newRow = cloneDeep(row);

	//generate new id for newly duplicated row
	newRow.id = generateGuid();
	newRow.uniqueId = generateGuid();
	newRow.mobileProps.index = typeof newRow.mobileProps.index !== 'undefined' ? newRow.mobileProps.index : 0;

	let hasLimitedComponents = false;

	//generate new component ids for the newly duplicated row components
	newRow.slots.forEach((slot) => {
		slot.id = generateGuid();
		slot.components.forEach((component) => {
			component.id = generateGuid();
			component.uniqueId = generateGuid();

			if (FieldComponentTypes[component.type]) {
				component.name = `${FieldComponentNames[component.type]}_${component.uniqueId.substring(0, 5)}`;
			}

			if (limitedComponents && limitedComponents.find((item) => item.type === component.type)) hasLimitedComponents = true;
		});
	});

	return { newRow, hasLimitedComponents };
}

export function duplicatePageRow(row) {
	let newRow = cloneDeep(row);

	//generate new id for newly duplicated row
	newRow.id = generateGuid();
	newRow.uniqueId = generateGuid();
	newRow.mobileProps.index = typeof newRow.mobileProps.index !== 'undefined' ? newRow.mobileProps.index : 0;

	//generate new component ids for the newly duplicated row components
	newRow.slots.forEach((slot) => {
		slot.id = generateGuid();
		slot.components.forEach((component) => {
			component.id = generateGuid();
			component.uniqueId = generateGuid();
			if (FieldComponentTypes[component.type] && component.type !== FieldComponentTypes.submit_button) {
				component.type = SpecialComponentTypes.fieldPlaceholder;
			}
		});
	});

	return newRow;
}

export function checkAndAssignComponentUids(contentJson) {
	let recurse = (data) => {
		Object.keys(data).forEach((key, i) => {
			if (key === 'components') {
				data[key].forEach((component) => {
					if (!component.id) {
						component.id = generateGuid();
					}
				});
			} else if (data[key] && typeof data[key] === 'object' && !key.endsWith('Attributes')) {
				recurse(data[key]);
			}
		});
	};

	recurse(contentJson);

	return contentJson;
}

export function setLinkStyleToAllTextComponents(contentJson, styles) {
	let domParser = new DOMParser();

	let recurse = (data) => {
		Object.keys(data).forEach((key, i) => {
			if (key === 'components') {
				data[key].forEach((component, componentIndex) => {
					if (component.type === AllComponentTypes.text || component.type === AllComponentTypes.article) {
						component.text = setLinkStyles(component.text, styles, domParser);
					}
				});
			} else if (data[key] && typeof data[key] === 'object' && !key.endsWith('Attributes')) {
				recurse(data[key]);
			}
		});
	};

	recurse(contentJson);

	return contentJson;
}

function setLinkStyles(text, styles, domParser) {
	let html = domParser.parseFromString(text, 'text/html');
	let body = html.querySelector('body');

	// {
	//     link_color: "#337ab7",
	//     link_font_family: "Arial, Helvetica, sans-serif",
	//     link_font_size: 12,
	//     link_font_style: "normal",
	//     link_font_weight: "normal",
	//     link_text_decoration: "none"
	// }

	// let links = body && body.querySelectorAll('a');

	// if(links && links.length > 0){
	//     for(let i = 0; i < links.length; i++){

	//         const parent = links[i].parentElement;
	//         const linkElement = links[i];
	//         if (linkElement.firstChild && linkElement.firstChild.className === 'custom-style-link'){
	//             linkElement.firstChild.removeAttribute('style');
	//             linkElement.firstChild.removeAttribute('class');
	//         }

	//         if (linkElement.firstChild && linkElement.firstChild.className === 'link-color'){
	//             linkElement.firstChild.style.color = styles.link_color.toString();
	//         } else {
	//             linkElement.innerHTML = `<span class="link-color" style="color: ${styles.link_color.toString()};">${linkElement.innerHTML}</span>`
	//         }

	//         let newLink;
	//         if (parent.className === 'link-wrapper'){
	//             parent.style.fontFamily = styles.link_font_family.toString();
	//             parent.style.fontSize = styles.link_font_size.toString();
	//             // parent.style.color = styles.link_color.toString();
	//             linkElement.style.removeProperty('color');
	//         } else {
	//             newLink = `<span class="link-wrapper" style="font-family: ${styles.link_font_family.toString().replace(/"/g, "'")}; font-size: ${styles.link_font_size.toString()}px;">${linkElement.outerHTML}</span>`;
	//             parent.innerHTML = parent.innerHTML.replace(links[i].outerHTML, newLink);
	//         }
	//     }
	// }

	let links = body && body.querySelectorAll('a, a *');

	if (links && links.length > 0) {
		for (let i = 0; i < links.length; i++) {
			styles.link_color && (links[i].style.color = styles.link_color.toString());
			styles.link_font_family && (links[i].style.fontFamily = styles.link_font_family.toString());
			styles.link_font_size && (links[i].style.fontSize = styles.link_font_size.toString());
		}
	}

	return (body && body.innerHTML) || text;
}

export function stripAttributesFromContent(contentJson) {
	let recurse = (data) => {
		Object.keys(data).forEach((key, i) => {
			if (key.endsWith('Attributes')) {
				delete data[key];
			} else if (data[key] && typeof data[key] === 'object' && !key.endsWith('Attributes')) {
				recurse(data[key]);
			}
		});
	};

	recurse(contentJson);

	return contentJson;
}

export default useContentStore;
