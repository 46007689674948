import React from 'react';

import useContentStore from '../../../stores/ContentStore';
import { RowTypes, ApiAuthorisationModes, PublicCustomRowImg } from '../../../util/resources';

import useLeftSidebarStore from '../../../stores/LeftSidebarStore';

import strings from '../../../resources/strings/en-us';

//components
import DraggableStructure from './DraggableStructure.react';

import fullIcon from 'assets/images/left_structures/menuarea_1.svg';
import halfIcon from 'assets/images/left_structures/menuarea_1-1.svg';
import twoThirdsLeftIcon from 'assets/images/left_structures/menuarea_2-1.svg';
import twoThirdsRightIcon from 'assets/images/left_structures/menuarea_1-2.svg';
import oneThirdIcon from 'assets/images/left_structures/menuarea_1-1-1.svg';
import oneFourthIcon from 'assets/images/left_structures/menuarea_1-1-1-1.svg';

//custom normal row icons
import fullIconCustom from 'assets/images/left_structures/menuarea_1.svg';
import halfIconCustom from 'assets/images/left_structures/menuarea_1-1.svg';
import twoThirdsLeftIconCustom from 'assets/images/left_structures/menuarea_2-1.svg';
import twoThirdsRightIconCustom from 'assets/images/left_structures/menuarea_1-2.svg';
import oneThirdIconCustom from 'assets/images/left_structures/menuarea_1-1-1.svg';
import oneFourthIconCustom from 'assets/images/left_structures/menuarea_1-1-1-1.svg';

//preview images
import fullRowPreview from 'assets/images/drag_previews/full_row_preview.png';
import halfRowPreview from 'assets/images/drag_previews/half_row_preview.png';
import twoThirdsLeftPreview from 'assets/images/drag_previews/two_thirds_left_row_preview.png';
import twoThirdsRightPreview from 'assets/images/drag_previews/two_thirds_right_row_preview.png';
import threeOneThirdsPreview from 'assets/images/drag_previews/three_one_thirds_row_preview.png';

import { SimpleGrid } from '@chakra-ui/react';

import { transformBlockTitle, generateGuid } from '../../../util/helper';
import { shallow } from 'zustand/shallow';

export class StructureMenu extends React.PureComponent {
	render() {
		let normalRowItems = _normalMenuItems.map((item, i) => {
			return (
				<DraggableStructure
					iconSrc={item.iconSrc}
					labelFirst={item.label_first}
					previewIcon={item.previewIcon}
					key={i}
					onDoubleClick={this.onDoubleClickNormalItem(item.type)}
					type={item.type}
				/>
			);
		});

		//custom row items

		let customRowItems = this.props.rows.map((item, i) => {
			let iconSrc;
			if (item.authorisationMode === ApiAuthorisationModes.Public) {
				const transformedTitle = transformBlockTitle(item.title);
				iconSrc = PublicCustomRowImg[transformedTitle];
			} else {
				iconSrc = item.row ? this.getRowIcon(item.row.type) : this.getRowIcon(item.type);
			}

			return (
				<DraggableStructure
					className="notranslate"
					authorisationMode={item.authorisationMode}
					structure={item.row}
					rowId={item.id}
					iconSrc={iconSrc}
					labelFirst={item.title}
					previewIcon={fullIcon}
					key={i}
					onDoubleClick={() => this.onDoubleClick(item)}
					type={item.row ? item.row.type : item.type}
				/>
			);
		});

		return (
			<SimpleGrid columns={3} spacing={2}>
				{normalRowItems}
				{customRowItems}
			</SimpleGrid>
		);
	}

	onDoubleClick = (item) => {
		if (item.row) {
			this.onDoubleClickOldCustomItem(item.row);
		} else {
			this.onDoubleClickCustomItem(item);
		}
	};

	onDoubleClickNormalItem(type) {
		return (e) => {
			useContentStore.getState().appendRow({ rowType: type });
		};
	}

	onDoubleClickOldCustomItem(row) {
		useContentStore.getState().appendCustomRow(row);
	}

	onDoubleClickCustomItem(row) {
		const contentStore = useContentStore.getState();

		const newId = generateGuid();

		contentStore.appendRow({ rowType: row.type, id: newId });
		contentStore.saveRowSettings({ loading: true, id: newId });

		const onError = () => {
			contentStore.removeRow(newId);
		};

		const onSuccess = (customRow) => {
			const newRow = JSON.parse(customRow);
			contentStore.replaceRow({ rowId: newId, row: newRow });
		};

		useLeftSidebarStore.getState().getCustomRowData(row, onSuccess, onError);
	}

	getRowIcon = (type) => {
		return customRowData[type] ? customRowData[type].normalIconSrc : '';
	};
}

const customRowData = {
	[RowTypes.FULL]: {
		normalIconSrc: fullIconCustom,
	},
	[RowTypes.HALF]: {
		normalIconSrc: halfIconCustom,
	},
	[RowTypes.TWO_THIRDS_LEFT]: {
		normalIconSrc: twoThirdsLeftIconCustom,
	},
	[RowTypes.TWO_THIRDS_RIGHT]: {
		normalIconSrc: twoThirdsRightIconCustom,
	},
	[RowTypes.THREE_ONE_THIRDS]: {
		normalIconSrc: oneThirdIconCustom,
	},
	[RowTypes.FOUR_ONE_FOURTHS]: {
		normalIconSrc: oneFourthIconCustom,
	},
};

const _normalMenuItems = [
	{
		label_first: strings.full_width_container_first,
		iconSrc: fullIcon,
		previewIcon: fullRowPreview,
		type: RowTypes.FULL,
	},
	{
		label_first: strings.half_width_container_first,
		iconSrc: halfIcon,
		previewIcon: halfRowPreview,
		type: RowTypes.HALF,
	},
	{
		label_first: strings.two_thirds_right_container_first,
		iconSrc: twoThirdsRightIcon,
		previewIcon: twoThirdsRightPreview,
		type: RowTypes.TWO_THIRDS_RIGHT,
	},
	{
		label_first: strings.two_thirds_left_container_first,
		iconSrc: twoThirdsLeftIcon,
		previewIcon: twoThirdsLeftPreview,
		type: RowTypes.TWO_THIRDS_LEFT,
	},
	{
		label_first: strings.three_one_thirds_container_first,
		iconSrc: oneThirdIcon,
		previewIcon: threeOneThirdsPreview,
		type: RowTypes.THREE_ONE_THIRDS,
	},
	{
		label_first: strings.four_one_fourths_container_first,
		iconSrc: oneFourthIcon,
		previewIcon: threeOneThirdsPreview,
		type: RowTypes.FOUR_ONE_FOURTHS,
	},
];

const StructureMenuWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return { rows: state.custom_rows.rows };
	}, shallow);
	return <StructureMenu {...leftSidebarData} {...props} />;
};

export default StructureMenuWrapper;
