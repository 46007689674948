import React from 'react';

import useContentStore from '../../../stores/ContentStore';

import { shallow } from 'zustand/shallow';

import { Box, IconButton, Text, UnorderedList, ListItem, List, Tooltip } from '@chakra-ui/react';

import { contentViewMenuItemConstants as ViewTypes, contentViewMenuItemConstants as itemConstants } from '../../../util/resources';

import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import useContentViewStore, { changeView } from '../../../stores/ContentViewStore';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiClose, mdiAlertCircleOutline } from '@mdi/js';

const ErrorsList = (props) => {
	const [showErrors, setShowErrors] = React.useState(false);

	const isMobileView = useContentViewStore((state) => state.currentView === ViewTypes.MOBILE_VIEW);

	const { errors, errorsCount } = useContentStore((state) => {
		return {
			errors: state.validity.errors,
			errorsCount: state.validity.errorsCount,
		};
	}, shallow);

	const renderMobileVersion = () => {
		return (
			<List listStyleType="none">
				<Error
					margin={4}
					anchor="Desktop"
					leftMessage="Switch to"
					rightMessage="to fix the form errors."
					onScroll={props.onScroll}
					onClick={() => changeView(itemConstants.DESIGN_VIEW)}
				/>
			</List>
		);
	};

	const renderDesktopVersion = () => {
		return (
			<>
				{Object.keys(errors).map((key) => {
					if (errors[key].length) {
						return <Errors onScroll={() => setShowErrors(!showErrors)} errors={errors[key]} pageIndex={parseInt(key, 10)} />;
					}
				})}

				<IconButton
					position="absolute"
					top={2.5}
					right={2.5}
					aria-label="Close"
					variant="ghost"
					size="sm"
					onClick={() => setShowErrors(false)}
					icon={<Icon path={mdiClose} />}
				/>
			</>
		);
	};

	return (
		<Box position="relative">
			<Box position="relative" onClick={() => setShowErrors(!showErrors)}>
				<Tooltip label="Errors">
					<IconButton
						variant="ghost"
						size="sm"
						aria-label="Errors"
						icon={<Icon path={mdiAlertCircleOutline} />}
						data-testid="errors-list-open-btn"
					/>
				</Tooltip>

				<Box
					borderRadius="full"
					bgColor="red"
					color="chakra-inverse-text"
					fontSize="xs"
					fontWeight="medium"
					position="absolute"
					h={3.5}
					w={3.5}
					top={-1}
					right={-1}
					display="flex"
					alignItems="center"
					justifyContent="center"
					lineHeight="1px"
				>
					{errorsCount}
				</Box>
			</Box>

			{showErrors ? (
				<Box borderRadius="md" bgColor="danger-bg" position="absolute" right={0} zIndex={9999}>
					{isMobileView ? renderMobileVersion() : renderDesktopVersion()}
				</Box>
			) : null}
		</Box>
	);
};

const Errors = React.memo((props) => {
	return (
		<Box display="flex" justifyContent="center" whiteSpace="nowrap" m={4} flexDirection="column">
			<Text cursor="pointer" fontWeight="medium">
				Page {props.pageIndex + 1}
			</Text>

			<UnorderedList m={0} pl={4}>
				{props.errors.map((item) => (
					<Error {...item} onScroll={props.onScroll} />
				))}
			</UnorderedList>
		</Box>
	);
});

const Error = React.memo((props) => {
	const onClick = () => {
		if (props.address) {
			useLeftSidebarStore.getState().toggleElementSettings({ address: props.address });
			useContentStore.getState().setClickedElement(props.address);
		}

		if (typeof props.pageIndex !== 'undefined') {
			const container = document.getElementById('main-area-container'),
				target = document.getElementById(`content-page-${props.pageIndex}`);

			if (container && target) {
				container.scrollTo({ top: target.offsetTop - 50, behavior: 'smooth' });

				props.onScroll && props.onScroll();
			}
		}

		props.onClick && props.onClick();
	};

	const { anchor, leftMessage, rightMessage } = props;

	return (
		<ListItem whiteSpace="nowrap" m={props.margin || 1.5}>
			<Box display="flex">
				{leftMessage && <Text fontWeight="medium">{leftMessage}</Text>}
				<Text
					onClick={onClick}
					color="purple.400"
					cursor="pointer"
					fontWeight="medium"
					ml={leftMessage ? 1.5 : 0}
					mr={rightMessage ? 1.5 : 0}
				>
					{anchor}
				</Text>

				{rightMessage && <Text fontWeight="medium">{rightMessage}</Text>}
			</Box>
		</ListItem>
	);
});

export default ErrorsList;
