import QueryString from 'query-string';

import { spring } from 'react-flip-toolkit';

import { SearchTermMinLength, WebhookTypes } from './strings';

export const getQueryStrings = (search) => {
	return QueryString.parse(search);
};

export const getQuerySearch = (params) => {
	return QueryString.stringify(params);
};

export const getQueryFilters = () => {
	if (location.search) {
		const search = getQueryStrings(location.search);
		if (search.tags && !Array.isArray(search.tags)) {
			search.tags = [search.tags];
		}
		if (search.status && !Array.isArray(search.status)) {
			search.status = [search.status];
		}
		if (search.includeArchived === 'true') {
			search.includeArchived = true;
		}
		return search;
	}
	return {};
};

export const generateDesignerUrl = (entityId, isActive) => {
	return designerUrl(entityId, isActive);
};

const designerUrl = (entityId, isActive) => `/design${isActive ? '/preview' : ''}?entityId=${entityId}`;

/* c8 ignore next 22 */
export const baseFlippedProps = {
	onAppear: (el, index) => {
		spring({
			onUpdate: (val) => {
				el.style.opacity = val;
			},
		});
	},
	onExit: (el, index, removeElement) => {
		spring({
			onUpdate: (val) => {
				el.style.opacity = 1 - val;
			},
			onComplete: removeElement,
		});

		return () => {
			// el.style.opacity = '';
			removeElement();
		};
	},
};

export function getWebhookFiltersFromQuery(queryString) {
	let authType = queryString?.authType || '';
	let inUse = queryString?.inUse || '';
	let searchTerm = queryString?.name || '';

	if (authType && !WebhookTypes.map((_, idx) => idx + 1).includes(parseInt(authType))) {
		authType = '';
	}

	if (inUse && !['true', 'false'].includes(inUse)) {
		inUse = '';
	}

	if (searchTerm && searchTerm.length < SearchTermMinLength) {
		searchTerm = '';
	}

	return { authType, inUse, searchTerm };
}
