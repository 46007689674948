import { generateGuid } from '../util/helper';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const useNotificationStore = create(
	devtools(
		(set, get) => ({
			notifications: [],
			text: '',
			type: null,
			blockScreen: false,
			autosave: false,
			description: '',
			resetState: () =>
				set((state) => {
					return {
						notifications: [],
						text: '',
						type: null,
						blockScreen: false,
						autosave: false,
						description: '',
					};
				}),
			showNotification: ({ type, text, ms = 5000, description, okLabel, cancelLabel, id: notificationId }) => {
				const id = notificationId || generateGuid();

				return set((state) => {
					const notifications = [...state.notifications];
					notifications.push({
						type,
						text,
						id,
						description,
						okLabel,
						cancelLabel,
						duration: ms,
						isClosable: true,
					});
					return { notifications };
				});
			},
			hideNotification: (id) => {
				return set((state) => {
					const newNotifications = state.notifications.filter((item) => {
						return item.id !== id;
					});
					return {
						notifications: newNotifications,
					};
				});
			},
			showAutosaveNotification: () => {
				setTimeout(() => get().hideAutosaveNotification(), 3000); //close notification after the given amount of time
				return set((state) => ({ autosave: true }));
			},
			hideAutosaveNotification: () => {
				return set((state) => ({ autosave: false }));
			},
		}),
		{ enabled: import.meta.env.MODE !== 'production' && import.meta.env.MODE !== 'test', name: 'Notifications Store' },
	),
);

export const showNotification = useNotificationStore.getState().showNotification;
export const showAutosaveNotification = useNotificationStore.getState().showAutosaveNotification;

export default useNotificationStore;
