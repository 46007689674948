import React, { Component } from 'react';
import useContentStore from '../../../stores/ContentStore';
import useEntitiesStore from '../../../stores/EntitiesStore';
import { showNotification } from '../../../stores/NotificationStore';

import useModalStore from '../../../stores/ModalStore';

import { Box, IconButton, Spinner, Text, Button, ButtonGroup } from '@chakra-ui/react';
import useContentViewStore from '../../../stores/ContentViewStore';
import { Modals, contentViewMenuItemConstants, NotificationTypes } from '../../../util/resources';
import { shallow } from 'zustand/shallow';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-clouds_midnight';
import { ModalMessages } from '../../../../config/messages';
import { browserHistory } from 'react-router';
import { pathPrefix } from '../../../util/helper';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiCellphone, mdiTablet, mdiMonitor } from '@mdi/js';

export class Preview extends React.Component {
	state;
	iframe = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			clicked: 'desktop',
			payloadData: undefined,
		};
	}

	componentDidMount() {
		if (!this.props.previewLoading && this.props.preview_html) {
			this.renderIframePreview();
		}

		useModalStore.getState().closeDontAskModal({ checkPreview: true });

		if (this.props.currentView === contentViewMenuItemConstants.MOBILE_VIEW) {
			this.setState({ clicked: 'mobile' });
		}

		window.addEventListener('message', this.messageListener);
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.messageListener);
	}

	componentDidUpdate(prevProps) {
		if (!this.props.previewLoading && this.props.previewLoading !== prevProps.previewLoading && this.props.preview_html) {
			this.renderIframePreview();
		}
	}

	messageListener = (event) => {
		if (event.origin !== location.origin) return;
		if (event.data.event === 'previewData') {
			// showNotification({ type: NotificationTypes.SUCCESS, text: 'HEYYYYYYYYY YOO, Your test was successful!!!!!' });

			if (useEntitiesStore.getState().activeEntity && !useEntitiesStore.getState().activeEntity.WebhookSettings) {
				useModalStore.getState().showGeneralModal({
					modal: Modals.INFO,
					type: 'checkPreview',
					message: ModalMessages.REQUIRED_WEBHOOK,
					// onCancel: (dont_ask_again) => {
					// 	dont_ask_again && useModalStore.getState().closeDontAskModal({ checkPreview: dont_ask_again });
					// },
					onOk: () => browserHistory.push(pathPrefix() + '/settings' + location.search),
					okLabel: 'Set up a webhook',
					cancelLabel: 'Cancel',
				});
				return;
			}
			const formSignature = `signature_${useEntitiesStore.getState().activeEntity?.Entity.Id}`;
			delete event.data.formData[formSignature];

			const data = JSON.stringify({ ...event.data.formData, test: true }, null, 4);
			this.setState({ payloadData: data });
		}
	};

	testWebhook = (webhookId, payload) => {
		const successCb = () => {
			showNotification({ type: NotificationTypes.SUCCESS, text: 'Webhook test successful' });

			this.setState({ payloadData: undefined }, () => {
				this.iframe.contentWindow.postMessage({ message: 'pass', event: 'webhookTest' });
			});
		};

		const failCb = () => {
			showNotification({ type: NotificationTypes.ERROR, text: 'Negative response from the webhook.' });
			this.setState({ payloadData: undefined }, () => {
				this.iframe.contentWindow.postMessage({ message: 'fail', event: 'webhookTest' });
			});
		};

		this.props.testWebhook(webhookId, payload, this.props.activeEntity.Entity.Id, this.props.activeEntity.Entity.Name, successCb, failCb);
	};

	resizeIframe = (event) => {
		this.iframe.style.height = (this.iframe.contentWindow && this.iframe.contentWindow.document.body.scrollHeight + 'px') || 'auto';
		this.iframe.contentWindow &&
			this.iframe.contentWindow.document.documentElement &&
			(this.iframe.contentWindow.document.documentElement.style.overflow = 'auto');

		this.iframe.contentWindow.document.body.style.margin = 0;
	};

	render() {
		let view = {
			mobile: false,
			tablet: false,
			desktop: false,
		};

		view[this.state.clicked] = true;

		let iframeWidth;

		switch (this.state.clicked) {
			case 'mobile':
				iframeWidth = 450;
				break;
			case 'tablet':
				iframeWidth = 650;
				break;
			case 'desktop':
				iframeWidth = '100%';
				break;
		}

		return (
			<>
				{!this.props.hideViewModes && (
					<Box
						bgColor="chakra-subtle-bg"
						borderBottom="1px solid"
						borderColor="chakra-border-color"
						h={'12'}
						display="flex"
						alignItems="center"
						justifyContent="center"
						px={20}
					>
						<ViewModes iconClick={this.iconClick} view={view} />
					</Box>
				)}

				<Box flex={1} display="flex" alignItems="center" justifyContent="center" position="relative" h="100%" overflowY={'auto'} minH="sm">
					<WebhookTest
						testWebhook={this.testWebhook}
						payloadData={this.state.payloadData}
						webhooks={this.props.webhooks.items}
						activeEntity={this.props.activeEntity}
						entityForm={this.props.entityForm}
						setPayloadData={(data) => this.setState({ payloadData: data })}
					/>

					{!this.props.previewLoading ? (
						<iframe
							style={{ height: 'calc(100vh - 6.5rem)', display: !this.state.payloadData ? 'block' : 'none' }}
							frameBorder="0"
							className="preview-iframe notranslate"
							ref={(iframe) => (this.iframe = iframe)}
							width={iframeWidth}
							data-testid="preview-iframe"
							onLoad={this.props.resizeIframe && this.resizeIframe}
						/>
					) : (
						<Spinner ref="loader" />
					)}
				</Box>
			</>
		);
	}

	iconClick = (viewIcon) => {
		return (e) => {
			this.setState({ clicked: viewIcon });
		};
	};

	renderIframePreview = () => {
		if (this.iframe) {
			let doc = this.iframe.contentDocument;
			if (doc) {
				doc.open();
				doc.write('<!DOCTYPE html>');
				doc.write(this.props.preview_html);
				doc.close();
			}
		}
	};
}

const ViewModes = (props) => {
	const { iconClick } = props;

	return (
		<ButtonGroup variant="ghost" size="sm" colorScheme="neutral">
			<Button data-testid="desktop-view" isActive={props.view.desktop} leftIcon={<Icon path={mdiMonitor} />} onClick={iconClick('desktop')}>
				Desktop
			</Button>

			<Button data-testid="tablet-view" isActive={props.view.tablet} leftIcon={<Icon path={mdiTablet} />} onClick={iconClick('tablet')}>
				Tablet
			</Button>

			<Button isActive={props.view.mobile} data-testid="mobile-view" leftIcon={<Icon path={mdiCellphone} />} onClick={iconClick('mobile')}>
				Mobile
			</Button>
		</ButtonGroup>
	);
};

const WebhookTest = (props) => {
	const getActiveWebhook = () => {
		const active = props.webhooks.find((item) => {
			return props.activeEntity && props.activeEntity.WebhookSettings && props.activeEntity.WebhookSettings.WebhookId === item.Id;
		});

		return active;
	};

	const onClose = () => {
		props.setPayloadData(undefined);
	};

	const onClick = () => {
		props.testWebhook(activeWebhookId, props.payloadData);
		onClose();
	};

	const activeWebhook = getActiveWebhook();
	let activeWebhookId = null;
	if (activeWebhook) {
		activeWebhookId = activeWebhook.Id;
	}

	if (!props.payloadData) {
		return;
	}

	const payloadHeaderData = JSON.stringify(
		{
			Date: '<timestamp when the data was submitted>',
			'X-Forwarded-For': '<the address of the end user, if available>',
			'User-Agent': '<browser where the data was submitted from, if available>',
			Referer: '<request referer, if available>',
			'X-FormId': props.activeEntity.Entity.Id,
			'X-FormName': props.activeEntity.Entity.Name,
		},
		null,
		2,
	);

	return (
		<Box w="100%" maxW="3xl">
			<Box fontSize="xl" fontWeight="medium" mb={5}>
				Test form submission
			</Box>

			<Text>This is the payload that will be sent to your webhook: </Text>

			<Text variant="strong" fontStyle="italic">
				{activeWebhook?.Settings?.EndPoint}
			</Text>

			<Box w="100%" h="2xs" mt={1.5}>
				<AceEditor
					name="WEBHOOK_PREVIEW"
					mode="json"
					theme="clouds_midnight"
					value={props.payloadData}
					height="100%"
					width="100%"
					fontSize="lg"
					showGutter={false}
					readOnly
				/>
			</Box>

			<Text mt={5}>These are the request headers that will be sent:</Text>
			<Box w="100%" h="2xs" mt={1.5}>
				<AceEditor
					name="WEBHOOK_PREVIEW_HEADERS"
					mode="json"
					theme="clouds_midnight"
					value={payloadHeaderData}
					height="100%"
					width="100%"
					fontSize="lg"
					showGutter={false}
					readOnly
				/>
			</Box>

			<ButtonGroup display="flex" justifyContent="flex-end" mt={2.5}>
				<Button variant="ghost" onClick={onClose}>
					Cancel
				</Button>

				<Button onClick={onClick}>Submit</Button>
			</ButtonGroup>
		</Box>
	);
};

const PreviewWrapper = (props) => {
	const { currentView } = useContentViewStore();
	const { previewLoading, preview_html } = useContentStore();

	const webhookStuff = useEntitiesStore((state) => {
		return {
			webhooks: state.webhooks,
			activeEntity: state.activeEntity,
			testWebhook: state.testWebhook,
			entityForm: state.entityForm,
		};
	}, shallow);

	return <Preview previewLoading={previewLoading} preview_html={preview_html} currentView={currentView} {...webhookStuff} {...props} />;
};

export default PreviewWrapper;
