import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import useContentStore from '../../../../stores/ContentStore';
import { showNotification } from '../../../../stores/NotificationStore';
import {
	AllComponentTypes,
	ComponentDefaults,
	ElementLabels,
	ElementMenuIcons,
	FieldComponentNames,
	FieldComponentTypes,
	NotificationTypes,
	SlotsNotAllowedForElementDesigner,
} from '../../../../util/resources';
import { findRow } from '../../../../util/helper';
import { Element } from '../helper/AddElementsBox.react';

export const _elementItems = Object.keys(FieldComponentTypes)
	.filter((item) => item !== FieldComponentTypes.submit_button)
	.map((comp) => {
		return {
			label: ElementLabels[comp],
			iconSrc: ElementMenuIcons[comp],
			type: FieldComponentTypes[comp],
		};
	});

class FieldPlaceholderSettings extends React.PureComponent {
	render() {
		const slotType = findRow(useContentStore.getState().content.rows, this.props.address.rowId).row.slots[this.props.address.slot].type;

		let elements = _elementItems
			.filter((item) => {
				return !SlotsNotAllowedForElementDesigner[item.type].includes(slotType) && item.type in AllComponentTypes;
				//filter out the components that are not allowed in certain slot types
			})
			.map((item, key) => {
				return <Element {...item} key={key} onClick={this.onClick} />;
			});

		return (
			<SimpleGrid columns={2} spacing={2}>
				{elements}
			</SimpleGrid>
		);
	}

	onClick = (type) => {
		return () => {
			if (type === AllComponentTypes.recaptcha && this.props.lastPage !== this.props.pageIndex) {
				showNotification({ type: NotificationTypes.WARNING, text: 'reCAPTCHA should be always added at the last page of your form.' });
				return;
			}

			this.onChange({
				...ComponentDefaults[type],
				...useContentStore.getState().general_settings.components[type],
				name: `${FieldComponentNames[type]}_${this.props.uniqueId.substring(0, 5)}`,
				id: this.props.id,
			});
		};
	};

	onChange = (settings) => {
		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default FieldPlaceholderSettings;
