import React, { useEffect } from 'react';
import useNotificationStore from '../../../../stores/NotificationStore';
import BaseComponent from '../../../BaseComponent.react';

import { useToast } from '@chakra-ui/react';

const AutosaveNotificationPopup = () => {
	const autosave = useNotificationStore((state) => state.autosave);
	const toast = useToast();

	useEffect(() => {
		if (autosave)
			toast({
				description: 'Autosaved',
				status: 'success',
				styleConfig: { 'data-testid': 'autosave-toast' },
			});
	}, [autosave]);

	return null;
};

export default AutosaveNotificationPopup;
