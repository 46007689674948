import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
	assignWebhook,
	createEntity,
	createWebhook,
	deleteEntity,
	deleteManyEntities,
	deleteWebhook,
	copyToEnvs,
	duplicateEntity,
	getEntities,
	getEntity,
	getTags,
	getWebhooks,
	publishEntity,
	publishManyEntities,
	unassignWebhook,
	searchWebhooks,
	setManyTags,
	setTags,
	unpublishEntity,
	unpublishManyEntities,
	reassignWebhook,
	updateEntity,
	updateSites,
	updateWebhook,
	testWebhook,
	getAllBlueprints,
	updateBlueprint,
	archiveEntities,
	unarchiveEntities,
} from '../util/apiUtils/entities';
import { Modals, NotificationTypes } from '../util/resources';

import { EntityStatuses, NotificationType, OrderByTypes, WebhookOrderByTypes, getRoute } from '../components/Table/strings';

import { showNotification } from './NotificationStore';

import { produce } from 'immer';
import { browserHistory } from 'react-router';
import { getQueryFilters, getQuerySearch } from '../components/Table/helpers';
import transform from '../components/transformer/transformer';
import { ErrorMessages, ModalMessages, SuccessMessages } from '../../config/messages';
import useContentStore from './ContentStore';
import useStylingStore from './StylingStore';
import useModalStore from './ModalStore';
import { updateTemplateJson } from '@/util/helper';

export const initialState = {
	webhooks: {
		items: [],
		totalPages: 0,
		totalItems: 0,
	},
	allTags: [],
	activeEntity: undefined,
	entities: [],
	analytics: [],
	entityForm: {
		name: '',
		type: 1,
		subType: 2,
		tags: [],
		webhookId: null,
		show: false,
	},
	formFilters: {
		name: '',
		orderBy: OrderByTypes[3],
		status: [],
		tags: [],
		includeArchived: false,
		...getQueryFilters(),
	},
	loaders: {
		general: false,
		table: false,
		tableDataUpdate: false,
		webhooks: false,
		webhooksDataUpdate: false,
		entity: false,
	},
	totalFilteredEntities: 0,
	totalFilteredPagination: 0,
	selectedEntitiesIds: [],
};

const useEntitiesStore = create(
	devtools(
		(set, get) => ({
			...initialState,
			changeEntityData: (payload) => {
				set((state) => {
					return { ...state, ...payload };
				});
			},
			getEntity: async (entityId) => {
				return getEntity(entityId)
					.then((resp) => {
						set(
							produce((state) => {
								state.activeEntity = resp.data;
								state.entityForm.name = resp.data.Entity.Name;
								state.entityForm.webhookId = resp.data.WebhookSettings ? resp.data.WebhookSettings.WebhookId : null;
								state.entityForm.show = true;
							}),
						);
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to fetch entity details' });
					});
			},
			deleteEntity: (data) => {
				const { callback, entityId, name, type } = data;

				const errorType = NotificationType[type].single;

				const onOk = async () => {
					return deleteEntity(entityId)
						.then((resp) => {
							const selectedEntities = [...get().selectedEntitiesIds];
							const selectedEntitiesIds =
								selectedEntities && selectedEntities.length ? selectedEntities.filter((item) => item !== data.entityId) : [];
							set(
								produce((state) => {
									state.selectedEntitiesIds = selectedEntitiesIds;
								}),
							);
							callback && callback();
							get().getTags(type);
						})
						.catch((e) => {
							showNotification({
								type: NotificationTypes.ERROR,
								text: `Failed to delete ${errorType}`,
							});
						});
				};

				return useModalStore.getState().showGeneralModal({
					modal: Modals.ERROR,
					title: `Delete ${errorType.toLowerCase()}?`,
					message: `Are you sure you want to permanently delete ${errorType.toLowerCase()} '${name}'?`,
					okLabel: 'Delete',
					onOk,
				});
			},
			deleteManyEntities: (data) => {
				const { callback, entityIds, type } = data;

				const errorType = NotificationType[type].multi;

				const onOk = async () => {
					return deleteManyEntities(entityIds)
						.then((resp) => {
							const isInvalid = checkInvalidDeleteMany(resp, type);

							callback && callback();
							if (resp.data && resp.data.EntityIds) {
								const selectedEntitiesIds = [];
								const entitiesIds = resp.data.EntityIds;
								for (const props in entitiesIds) {
									if (entitiesIds[props] === false) selectedEntitiesIds.push(props);
								}
								set(
									produce((state) => {
										state.selectedEntitiesIds = selectedEntitiesIds;
									}),
								);
							}

							if (isInvalid) {
								if (typeof isInvalid === 'number') {
									showNotification({
										text: `${isInvalid} of your ${errorType.toLowerCase()} could not be deleted. Select only Draft and Inactive forms.`,
										type: NotificationTypes.ERROR,
									});
								} else {
									showNotification({
										text: isInvalid,
										type: NotificationTypes.ERROR,
									});
								}
							} else {
								showNotification({
									text: `${errorType} have been deleted successfully`,
									type: NotificationTypes.SUCCESS,
								});
								get().getTags(type);
							}

							// yield put(EntitiesActions.deleteManyEntitiesSuccess({ deleteResponse: resp.data }));
						})
						.catch((e) => {
							showNotification({
								type: NotificationTypes.ERROR,
								text: `Failed to delete ${errorType}`,
							});
						});
				};

				return useModalStore.getState().showGeneralModal({
					modal: Modals.ERROR,
					title: `Delete ${errorType.toLowerCase()}?`,
					message: `Are you sure you want to permanently delete the ${entityIds.length} selected ${errorType.toLowerCase()}?`,
					okLabel: 'Delete',
					onOk,
				});

				// AFTER CALL IS MADE
				// const { deleteResponse } = action.payload;
				// const selectedEntitiesIds = [];
				// deleteResponse &&
				// 	deleteResponse.length &&
				// 	deleteResponse.forEach((item) => {
				// 		if (item.Success === false) {
				// 			selectedEntitiesIds.push(item.EntityId);
				// 		}
				// 	});
				// state.selectedEntitiesIds = selectedEntitiesIds;
			},
			createEntity: async (data) => {
				const { formData, callback } = data;

				return createEntity(formData)
					.then((resp) => {
						get().changeEntityData({ entityForm: initialState.entityForm });
						callback && callback(resp.data.Id, { Entity: resp.data });
					})
					.catch((e) => {
						const validationError =
							e.response &&
							e.response.data &&
							e.response.data.ValidationErrors &&
							e.response.data.ValidationErrors.length &&
							e.response.data.ValidationErrors[e.response.data.ValidationErrors.length - 1];

						showNotification({
							type: NotificationTypes.ERROR,
							text: validationError ? validationError.Message : 'Failed to create new entity',
						});
					});
			},
			updateEntity: async (data, resetEntityForm = true) => {
				const { formData, activeEntity, callback } = data;
				return updateEntity({ entity: formData, entityId: activeEntity.Entity.Id })
					.then((resp) => {
						resetEntityForm && get().changeEntityData({ entityForm: initialState.entityForm, activeEntity: undefined });
						callback && callback();
					})
					.catch((e) => {
						const validationError =
							e.response &&
							e.response.data &&
							e.response.data.ValidationErrors &&
							e.response.data.ValidationErrors.length &&
							e.response.data.ValidationErrors[e.response.data.ValidationErrors.length - 1];

						showNotification({
							type: NotificationTypes.ERROR,
							text: validationError ? validationError.Message : 'Failed to update entity details',
						});
					});
			},
			setTags: async (data) => {
				const { entityId, tags, type } = data;

				if (checkTagLength(tags)) {
					showNotification({
						text: `Labels must consist of 3 to 20 characters.`,
						type: NotificationTypes.ERROR,
					});
				} else if (tags.length > 6) {
					showNotification({
						text: `Can't have more than 6 entity labels`,
						type: NotificationTypes.ERROR,
					});
				} else {
					return setTags({ entityId, tags })
						.then((resp) => {
							get().getTags(type);
							const entityIndex = get().entities.findIndex((item) => {
								return item.Entity.Id === entityId;
							});

							if (entityIndex !== -1) {
								set(
									produce((state) => {
										state.entities[entityIndex].Tags = tags.map((tag) => {
											return { Tag: tag, EntityId: entityId };
										});
									}),
								);
							}
						})
						.catch((e) => {
							showNotification({ type: NotificationTypes.ERROR, text: 'Failed to set label values' });
						});
				}
			},
			setManyTags: async (data) => {
				const { type, newTags } = data;
				return setManyTags(newTags)
					.then((resp) => {
						get().getTags(type);
						const newEntities = get().entities.map((item) => {
							const changed = newTags.find((tagObj) => {
								return tagObj.entityId === item.Entity.Id;
							});

							if (changed) {
								const uniqueTags = changed.tags.filter(onlyUnique);
								return {
									...item,
									Tags: uniqueTags.map((tag) => {
										return {
											EntityId: changed.entityId,
											Tag: tag,
										};
									}),
								};
							}

							return item;
						});

						set(
							produce((state) => {
								state.entities = newEntities;
							}),
						);
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to set label values' });
					});
			},
			archiveEntity: async (data) => {
				const { callback, entityId, name, isActive } = data;

				return archiveEntities([entityId])
					.then((resp) => {
						showNotification({
							type: NotificationTypes.SUCCESS,
							text: `Form "${name}" is archived ${isActive ? 'but live pages using the form are not affected' : ''}`,
						});
						callback && callback();
					})
					.catch((e) => {
						showNotification({
							type: NotificationTypes.ERROR,
							text: `Failed to archive entity`,
						});
					});
			},
			archiveManyEntities: async (data) => {
				const { callback, entityIds, type } = data;

				const errorType = NotificationType[type].multi;

				archiveEntities(entityIds)
					.then(() => {
						if (callback) callback();
					})
					.catch(() => {
						showNotification({
							type: NotificationTypes.ERROR,
							text: `Failed to archive entities`,
						});
					});
			},
			unarchiveEntity: async (data) => {
				const { callback, entityId, name } = data;

				return unarchiveEntities([entityId])
					.then((resp) => {
						showNotification({
							type: NotificationTypes.SUCCESS,
							text: `Form "${name}" unarchived`,
						});
						callback && callback();
					})
					.catch((e) => {
						showNotification({
							type: NotificationTypes.ERROR,
							text: `Failed to unarchive entity`,
						});
					});
			},
			unarchiveManyEntities: async (data) => {
				const { callback, entityIds, type } = data;

				const errorType = NotificationType[type].multi;

				unarchiveEntities(entityIds)
					.then(() => {
						if (callback) callback();
					})
					.catch(() => {
						showNotification({
							type: NotificationTypes.ERROR,
							text: `Failed to unarchive entities`,
						});
					});
			},
			publishEntity: async (data) => {
				const { callback, entityId, type } = data;
				const errorType = NotificationType[type].single;

				return publishEntity(entityId)
					.then((resp) => {
						if (resp.data) {
							callback && callback(resp.data.Id);
							showNotification({
								text: `${errorType} activated successfully`,
								type: NotificationTypes.SUCCESS,
							});
						}
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to activate the ${errorType.toLowerCase()}` });
					});
			},
			publishManyEntities: async (data) => {
				const { entityIds, callback, type } = data;
				const errorType = NotificationType[type].multi;

				return publishManyEntities(entityIds)
					.then((resp) => {
						const isInvalid = checkInvalidEntity(resp, type);

						if (!isInvalid) {
							showNotification({
								text: `${errorType} published successfully`,
								type: NotificationTypes.SUCCESS,
							});
						} else {
							showNotification({
								text: isInvalid,
								type: NotificationTypes.ERROR,
							});
						}

						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to activate selected ${errorType.toLowerCase()}` });
					});
			},
			unpublishEntity: async (data) => {
				const { callback, entityId, type } = data;
				const errorType = NotificationType[type].single;

				return unpublishEntity(entityId)
					.then((resp) => {
						if (resp.data) {
							callback && callback(resp.data.Id);
							showNotification({
								text: `${errorType} deactivated successfully`,
								type: NotificationTypes.SUCCESS,
							});
						}
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to activate the ${errorType.toLowerCase()}` });
					});
			},
			unpublishManyEntities: async (data) => {
				const { entityIds, callback, type } = data;
				const errorType = NotificationType[type].multi;

				return unpublishManyEntities(entityIds)
					.then((resp) => {
						const isInvalid = checkInvalidEntity(resp, type);

						if (!isInvalid) {
							showNotification({
								text: `${errorType} published successfully`,
								type: NotificationTypes.SUCCESS,
							});
						} else {
							showNotification({
								text: isInvalid,
								type: NotificationTypes.ERROR,
							});
						}

						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to deactivate selected ${errorType.toLowerCase()}` });
					});
			},
			copyToEnvs: (entityIds, tenantIds, callback) => {
				return copyToEnvs(entityIds, tenantIds)
					.then(({ data: eventName }) => {
						callback && callback(eventName);
					})
					.catch(() => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to copy forms' });
					});
			},
			duplicateEntity: async (data) => {
				const { callback, entityId, type } = data;
				const errorType = NotificationType[type].single;

				return duplicateEntity(entityId)
					.then((resp) => {
						if (resp.data) {
							// REFRESH THE CURRENT PAGE
							callback && callback(resp.data.Id);
							showNotification({
								text: `${errorType} duplicated successfully`,
								type: NotificationTypes.SUCCESS,
							});
						}
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to duplicate the ${errorType.toLowerCase()}` });
					});
			},
			getTags: async (type) => {
				set(
					produce((state) => {
						state.loaders.tableFilters = true;
					}),
				);

				return getTags(type)
					.then((resp) => {
						const allTags = [...resp.data.Tags];
						const formFilterTags = [...get().formFilters.tags];

						const newFormFilterTags = formFilterTags.filter((item) => allTags.includes(item));

						set(
							produce((state) => {
								state.allTags = allTags;
								state.formFilters.tags = newFormFilterTags;
							}),
						);
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to retrieve all labels.' });
					})
					.finally(() => {
						set(
							produce((state) => {
								state.loaders.tableFilters = false;
							}),
						);
					});
			},
			getAllEntities: async (filters) => {
				set(
					produce((state) => {
						state.loaders.table = filters.withLoader;
						state.loaders.tableDataUpdate = filters.isUpdating;
						state.loaders.general = true;
					}),
				);

				const { pagination, type, subType, tags, status, name, orderBy, routeParam, includeArchived } = filters;

				return getEntities({
					pagination,
					type,
					subType,
					tags,
					status,
					name,
					orderBy: OrderByTypes.findIndex((item) => item === orderBy),
					routeParam,
					includeArchived,
				})
					.then((entitiesResponse) => {
						set(
							produce((state) => {
								state.entities = entitiesResponse.data.Items;
								state.analytics = entitiesResponse.data.Analytics;
								state.totalFilteredEntities = entitiesResponse.data.TotalItems;
								state.totalFilteredPagination = entitiesResponse.data.TotalPages;
								state.loaders.table = false;
								state.loaders.tableDataUpdate = false;
								state.loaders.general = false;
							}),
						);

						const searchObject = {
							name: name ? name : undefined,
							orderBy: orderBy !== OrderByTypes[3] ? orderBy : undefined,
							status: status ? status : status === EntityStatuses.draft ? 0 : undefined,
							subType: subType ? subType : undefined,
							tags: tags ? tags : undefined,
							includeArchived: includeArchived || undefined,
						};

						const search = getQuerySearch(searchObject);

						browserHistory.push(getRoute.root(routeParam, 'list', pagination) + (search ? `?${search}` : ''));

						if (
							(!entitiesResponse.data.Items || (entitiesResponse.data.Items && !entitiesResponse.data.Items.length)) &&
							pagination &&
							pagination > 1
						) {
							get().getAllEntities({ ...filters, pagination: 1, withLoader: false });
						}
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: `Failed to retrieve ${NotificationType[type].multi.toLowerCase()}` });
						set(
							produce((state) => {
								state.loaders.table = false;
								state.loaders.tableDataUpdate = false;
								state.loaders.general = false;
							}),
						);
					});
			},
			updateSites: async (entityId, sites, callback) => {
				return updateSites(entityId, sites)
					.then(() => {
						callback && callback();
					})
					.catch(() => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to update sites' });
					});
			},
			getWebhooks: async (page = 1, orderBy, pageSize = 100, successCb, withLoader = true) => {
				set(
					produce((state) => {
						state.loaders.webhooks = withLoader;
						state.loaders.webhooksDataUpdate = true;
					}),
				);
				return getWebhooks(page, orderBy, pageSize)
					.then((resp) => {
						set(
							produce((state) => {
								state.webhooks.items = resp.data.Webhooks;
								state.webhooks.totalPages = resp.data.TotalPages;
								state.webhooks.totalItems = resp.data.TotalItems;
								state.loaders.webhooks = false;
								state.loaders.webhooksDataUpdate = false;
							}),
						);
						successCb && successCb();

						if (
							(!resp.data.Webhooks || (resp.data.Webhooks && !resp.data.Webhooks.length)) &&
							resp.data.TotalPages &&
							resp.data.TotalItems > 1
						) {
							get().getWebhooks(1, 10, () => browserHistory.push(getRoute.root('forms', 'webhook', 1)));
						}
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to retrieve saved webhooks.' });
						set(
							produce((state) => {
								state.loaders.webhooks = false;
								state.loaders.webhooksDataUpdate = false;
							}),
						);
					});
			},
			searchWebhooks: async (filters = {}, orderBy, page = 1, pageSize = 10) => {
				set(
					produce((state) => {
						state.loaders.webhooksDataUpdate = true;
					}),
				);

				const orderByNumber = WebhookOrderByTypes.findIndex((item) => item === orderBy);
				return searchWebhooks(filters, orderByNumber, page, pageSize)
					.then((resp) => {
						set(
							produce((state) => {
								state.webhooks.items = resp.data.Webhooks;
								state.webhooks.totalPages = resp.data.TotalPages;
								state.webhooks.totalItems = resp.data.TotalItems;
							}),
						);
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to retrieve webhooks by name.' });
					})
					.finally(() => {
						set(
							produce((state) => {
								state.loaders.webhooksDataUpdate = false;
							}),
						);
					});
			},
			createWebhook: async (data, callback) => {
				return createWebhook(data)
					.then((resp) => {
						callback && callback(resp.data.WebhookId);
					})
					.catch((e) => {
						let message;
						if (e.response.data && e.response.data.status && e.response.data.status === 409) message = 'Webhook name already exists.';
						else message = 'Failed to create webhook.';
						showNotification({ type: NotificationTypes.ERROR, text: message });
					});
			},
			updateWebhook: async (webhookId, data, callback) => {
				return updateWebhook(webhookId, data)
					.then((resp) => {
						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Failed to update webhook.' });
					});
			},
			assignWebhook: async (webhookId, entityId, callback) => {
				return assignWebhook(webhookId, entityId)
					.then((resp) => {
						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Could not assign this webhook to the form.' });
					});
			},
			reassignWebhook: async (webhookId, entityId, callback) => {
				return reassignWebhook(webhookId, entityId)
					.then((resp) => {
						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Could not update the form webhook.' });
					});
			},
			unassignWebhook: async (entityId, callback) => {
				return unassignWebhook(entityId)
					.then((resp) => {
						callback && callback();
					})
					.catch((e) => {
						showNotification({ type: NotificationTypes.ERROR, text: 'Could not remove the form webhook.' });
					});
			},
			deleteWebhook: async (webhookId, callback) => {
				const onOk = async () => {
					return deleteWebhook(webhookId)
						.then((resp) => {
							callback && callback();
						})
						.catch((e) => {
							showNotification({ type: NotificationTypes.ERROR, text: 'Could not delete this webhook.' });
						});
				};

				return useModalStore.getState().showGeneralModal({
					modal: Modals.ERROR,
					title: 'Delete Webhook?',
					message: `Are you sure to delete Webhook?`,
					okLabel: 'Delete',
					onOk,
				});
			},
			testWebhook: async (webhookId, payload, formId, formName, successCb, errorCb) => {
				return testWebhook(webhookId, payload, formId, formName)
					.then((resp) => {
						if (resp.data) {
							successCb && successCb();
						} else {
							errorCb && errorCb();
						}
					})
					.catch((e) => {
						errorCb && errorCb();
					});
			},
			refreshEntity: async (entityId) => {
				return getAllBlueprints(entityId)
					.then((res) => {
						const jsonParsed = JSON.parse(res.data.Blueprint.JsonContent);
						jsonParsed && useContentStore.getState().setBlueprints(jsonParsed);
						// LEGACY
						const content = updateTemplateJson(jsonParsed.newsletter ? jsonParsed.newsletter : jsonParsed.content, undefined, true);
						let html = transform(content, false, [], entityId);
						updateBlueprint(
							{ html, json: JSON.stringify({ content, generalSettings: jsonParsed.generalSettings }) },
							content.structureWidth,
							entityId,
						).then((res) => {
							showNotification({ type: NotificationTypes.SUCCESS, text: 'Design has been refreshed.' });
						});
					})
					.catch((err) => {
						console.log(err);
						showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.ACTION_FAILED });
					});
			},
		}),
		{ enabled: import.meta.env.MODE !== 'production' && import.meta.env.MODE !== 'test', name: 'Entities Store' },
	),
);

const checkInvalidEntity = (publishResponse, type) => {
	const isError =
		publishResponse &&
		publishResponse.data &&
		publishResponse.data.findIndex((item) => {
			return item.Success === false;
		});

	if (isError > -1) {
		return `The publish status of draft ${NotificationType[type].multi.toLowerCase()} cannot be changed`;
	}
};

export const checkInvalidDeleteMany = (deleteResponse, type) => {
	let count = 0;

	if (deleteResponse && deleteResponse.data && deleteResponse.data.EntityIds) {
		const entityIds = deleteResponse.data.EntityIds;
		for (const prop in entityIds) {
			if (entityIds[prop] === false) count += 1;
		}
	}

	return count;
};

export const checkTagLength = (tags) => {
	return tags.find((tag) => {
		return tag.length < 3 || tag.length > 25;
	});
};

export const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export default useEntitiesStore;
