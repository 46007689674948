import React from 'react';

import ImageSetting from '../../shared/settings/ImageSetting.react';

class ImagePickerModal extends React.PureComponent {
	widget = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	/* istanbul ignore next */
	componentDidUpdate(prevProps, prevState) {
		if (this.state.loading && !prevState.loading) {
			const loader = document.createElement('i');
			loader.className = 'fa fa-circle-o-notch fa-spin fa-4x';
			loader.id = 'image-picker-loader';
			loader.style.position = 'fixed';
			loader.style.top = '50%';
			loader.style.left = '58%';
			loader.style.zIndex = 9999999999;
			loader.style.margin = '-45px';
			const body = document.getElementsByTagName('body');
			document.body.append(loader);
		} else {
			const loader = document.getElementById('image-picker-loader');
			loader && loader.parentNode.removeChild(loader);
		}
	}

	render() {
		return (
			<div className="image-picker-modal" style={{ display: this.props.show ? 'block' : 'none' }}>
				<ImageSetting {...this.props} setLoader={this.setLoader} />
			</div>
		);
	}

	setLoader = (loading) => {
		this.setState({ loading });
	};
}

export default ImagePickerModal;
