import React from 'react';
//components
import NumberSetting from '../../shared/settings/NumberSetting.react';

import useContentStore from '../../../../stores/ContentStore';
import { ElementLimits, AllComponentTypes } from '../../../../util/resources';

import { Box } from '@chakra-ui/react';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';

export const SpacerSettingsFields = {
	height: 'height',
	borderWidth: 'borderWidth',
	borderRadius: 'borderRadius',
	borderColor: 'borderColor',
};

export const SpacerSettingComponents = React.memo((props) => {
	return (
		<SettingsBox blockId={props.blockId}>
			<NumberSetting
				type={SpacerSettingsFields.height}
				inputType="number"
				onChange={props.onChange}
				text={props.height || ElementLimits[AllComponentTypes.spacer].height.min}
				label="Height"
				ariaLabel="Set height"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderWidth}
				onChange={props.onChange}
				text={props.borderWidth}
				label="Border width"
				ariaLabel="Border width in pixels"
			/>
			<ColorPickerSetting
				type={SpacerSettingsFields.borderColor}
				onChange={props.onChange}
				selectedColor={props.borderColor || 'transparent'}
				label="Border color"
			/>
			<NumberSetting
				type={SpacerSettingsFields.borderRadius}
				onChange={props.onChange}
				text={props.borderRadius}
				label="Border radius"
				ariaLabel="Border radius in pixels"
			/>
		</SettingsBox>
	);
});

class SpacerSettings extends React.PureComponent {
	render() {
		return (
			<Box className="spacer-settings">
				<SpacerSettingComponents {...this.props} onChange={this.onChange} />
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default SpacerSettings;
