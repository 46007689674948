import { memo } from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';

const MultiSettings = ({ children }) => (
	<SimpleGrid columns={2} spacing={2.5}>
		{children}
	</SimpleGrid>
);

export default memo(MultiSettings);
