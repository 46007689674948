import config from '../../../config/config';
import { generateUrlWithPathParams, makeApiGatewayCall } from '../helper';

export const getSites = () => {
	const url = generateUrlWithPathParams(config.xmc_apps.base, config.xmc_apps.getSites);

	return makeApiGatewayCall(url, 'get');
};

export const getSiteCollections = () => {
	const url = generateUrlWithPathParams(config.xmc_apps.base, config.xmc_apps.getCollections);

	return makeApiGatewayCall(url, 'get');
};
