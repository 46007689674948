import React from 'react';

import useContentStore from '../../../../stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const LongTextInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class LongTextInputSettings extends React.PureComponent {
	render() {
		return (
			<div className="text-input-settings">
				<LongTextInputSettingComponents {...this.props} onChange={this.onChange} settings={{ maxLength: { active: true } }} />
			</div>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default LongTextInputSettings;
