import React from 'react';

import { Checkbox } from '@chakra-ui/react';

class CheckBoxSetting extends React.PureComponent {
	render() {
		return (
			<Checkbox
				isChecked={this.props.checked}
				isDisabled={this.props.disabled}
				onChange={this.onClick}
				size={'md'}
				mb={5}
				data-testid={`${this.props.type}-checkbox`}
				{...this.props.styleProps}
			>
				{this.props.label}
			</Checkbox>
		);
	}

	onClick = (event) => {
		this.props.onClick && this.props.onClick({ event, type: this.props.type, value: !this.props.checked });
	};
}

export default CheckBoxSetting;
