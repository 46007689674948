import { Box, Card, CardBody, Text } from '@chakra-ui/react';

import Element from './Element.react';

export const FieldPlaceholder = (props) => {
	if (props.isMobileView) {
		return null;
	}
	return (
		<Box p="4">
			<Card variant="outline" borderStyle="dashed" bg="neutral.50" cursor="pointer">
				<CardBody>
					<Text variant="strong" color="chakra-placeholder-color" noOfLines={1} align="center">
						Field placeholder
					</Text>
				</CardBody>
			</Card>
		</Box>
	);
};

export default Element(FieldPlaceholder);
