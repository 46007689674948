import Imgix from 'imgix.js';
import axios from 'axios';

import config, { importedConfig } from '../../../config/config';

import { generateUrlWithPathParams, getXMCUrl, makeApiGatewayCall } from '../../util/helper';
import { getMediaLibrarySearchQuery, itemDetailsQuery } from '@/util/apiUtils/mediaLibrary';

export const ImgixService = {
	applyParametersToSrc(src, type, parameters, forceImgixGifs) {
		if (!src) {
			return '';
			// throw new Error('src must be present!');
		}

		//create a detached <a> element for ez url parsing
		let dummyLink = document.createElement('a');
		dummyLink.href = src;

		let url = config.imgix_prefix + dummyLink.pathname + dummyLink.search;

		let imgixParams = { ...parameters, auto: 'format,compress' };

		if (type === 'image/gif' && !forceImgixGifs) {
			imgixParams = {};
		} else if (type === 'image/png') {
			// imgixParams = { ...parameters, fm: 'png8' };
		}

		let imgxUrl = new Imgix.URL(url, imgixParams);

		return imgxUrl.getURL();
	},

	//this takes a src and a width and returns an Imgix url, width defaults to 600 because it is the max width of the content
	getUrlWithWidth(src, type, width, retina = false) {
		//width * 2 here becauuse of responsive image quality on smaller slots
		let newWidth = width ? { w: width >= 400 ? width : width * 2 } : {};
		let dpr = 1;

		if (retina) {
			dpr = 2;
		}

		return this.applyParametersToSrc(src, type, { ...newWidth, fit: 'clip', dpr });
	},

	//this takes a src and a width and returns an Imgix url, width defaults to 600 because it is the max width of the content
	applyCropParameters(src, type, cropData) {
		const x = cropData.x > 0 ? cropData.x : 0;
		const y = cropData.y > 0 ? cropData.y : 0;
		const width = cropData.width > 0 ? cropData.width : 0;
		const height = cropData.height > 0 ? cropData.height : 0;
		return this.applyParametersToSrc(src, type, { q: 100, rect: `${x},${y},${width},${height}` });
	},
};

const resolveProvider = () => {
	return ImgixService; //just imgix atm
};

export const generateImageContentUrlByProvider = (provider, src, type, width, retina = false) => {
	return provider.getUrlWithWidth(src, type, width, retina);
};

export const generateCroppedImageUrlByProvider = (provider, src, type, cropData) => {
	return provider.applyCropParameters(src, type, cropData);
};

export const generateImageContentUrl = (src, type, width, retina = false) => {
	const provider = resolveProvider();

	return generateImageContentUrlByProvider(provider, src, type, width, retina);
};

export const generateCroppedImageUrl = (src, type, cropData) => {
	const provider = resolveProvider();

	return generateCroppedImageUrlByProvider(provider, src, type, cropData);
};

// API call wrapper
export const makeConfApiCall = (url, method, data, conf) => {
	let promise;

	switch (method.toLowerCase()) {
		case 'get':
			promise = axios.get(url, conf);
			break;
		case 'post':
			promise = axios.post(url, data, conf);
			break;
		case 'put':
			promise = axios.put(url, data, conf);
			break;
		case 'delete':
			promise = axios.delete(url, conf);
			break;
		case 'patch':
			promise = axios.patch(url, data, conf);
			break;
		default:
			promise = Promise.reject('Method not supported');
	}

	return promise;
};

/**
 *  UNSPLASH
 */
export const getUnsplashImages = (page, successCb, errorCb) => {
	const url = generateUrlWithPathParams(config.unsplash.api, config.unsplash.getPhotos, {
		page,
	});

	return makeUnsplashApiCall(url, 'get')
		.then((response) => {
			successCb && successCb(response.data);
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

export const getUnsplashImagesBySearch = (search, page, successCb, errorCb) => {
	const url = generateUrlWithPathParams(config.unsplash.api, config.unsplash.getPhotosSearch, {
		page,
		search,
	});

	return makeUnsplashApiCall(url, 'get')
		.then((response) => {
			successCb && successCb(response.data.results);
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

export const downloadUnsplashImage = (url, successCb, errorCb) => {
	return makeUnsplashApiCall(url, 'get')
		.then((response) => {
			if (response && response.data) {
				successCb && successCb(response.data.url);
			}
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

export const makeUnsplashApiCall = (url, method, data) => {
	const conf = {
		headers: {
			Authorization: `Client-ID ${config.unsplash.publicKey}`,
		},
	};

	return makeConfApiCall(url, method, data, conf);
};

/**
 *  Giphy
 */
export const getGiphyBySearch = (search, page, successCb, errorCb) => {
	const url = generateUrlWithPathParams(config.giphy.api, config.giphy.search, {
		apiKey: config.giphy.apiKey,
		page,
		search,
	});

	return axios
		.get(url)
		.then((response) => {
			successCb && successCb(response.data.data);
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

export const getGiphyByTrending = (page, successCb, errorCb) => {
	const url = generateUrlWithPathParams(config.giphy.api, config.giphy.trending, {
		apiKey: config.giphy.apiKey,
		page,
	});

	return axios
		.get(url)
		.then((response) => {
			successCb && successCb(response.data.data);
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

export const getMediaLibrary = (search, pageIndex, successCb, errorCb) => {
	const graphqlURL = getXMCUrl('sitecore/api/authoring/graphql/v1');

	return makeApiGatewayCall(graphqlURL, 'POST', {
		query: getMediaLibrarySearchQuery(pageIndex, search),
	})
		.then(async (response) => {
			const filteredIds = response.data.data.search.results.map((item) => item.itemId);
			const total = response.data.data.search.totalCount;

			const promises = filteredIds.map((itemId) =>
				makeApiGatewayCall(graphqlURL, 'POST', {
					query: itemDetailsQuery,
					variables: { input: { mediaItemId: itemId, database: 'master', language: 'en' } },
				}),
			);

			await Promise.all(promises).then((results) => {
				successCb({
					TotalPages: total,
					Items: results.map((res) => res.data.data.mediaItem),
				});
			});
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb();
		});
};

/**
 * Content Hub
 */

export const authenticateContentHub = (data) => {
	const postData = {
		username: data.username,
		password: data.password,
	};

	const extraHeaders = {
		'X-Endpoint': data.domain,
	};

	return makeApiGatewayCall('temp' + config.contentHub.auth, 'post', postData, extraHeaders);
};

export const createContentHubMetadata = (data) => {
	const url = config.metadata_service.url + config.metadata_service.paths.CreateMetadataRequest;
	return makeApiGatewayCall(url, 'post', data);
};

export const deleteContentHubMetaData = (key) => {
	const params = `?Category=CONTENT_HUB_DATA`;
	const url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.DeleteMetadataRequest + params, {
		key,
	});
	return makeApiGatewayCall(url, 'delete');
};

export const getContentHubBySearch = (search, filters, pagination, successCb, errorCb, contentHubData) => {
	const url = 'temp' + config.contentHub.search;

	const data = {
		Text: search,
		Filters: filters,
		Page: pagination,
		PageSize: 16,
		// Filter: contentHubData.filters
	};

	makeApiGatewayCall(url, 'post', data, {
		'X-Endpoint': contentHubData.domain,
		'X-Auth-Token': contentHubData.token,
	})
		.then((response) => {
			successCb && successCb(response.data);
		})
		.catch((error) => {
			console.error(error);
			errorCb && errorCb(error.response);
		});
};
