import { useEffect } from 'react';
import useNotificationStore from '@/stores/NotificationStore';
import { useShallow } from 'zustand/react/shallow';

import { Box, Alert, AlertDescription, AlertIcon, AlertTitle, useToast, CloseButton, ButtonGroup, Button, Flex } from '@chakra-ui/react';

const CustomNotification = ({ notification, onClose }) => (
	<Alert flexDirection="column" status={notification.type.toLowerCase()} shadow="lg" data-testid="notification-popup">
		{notification.isClosable && <CloseButton onClick={onClose} pos="absolute" size="sm" insetEnd={1} top={1} />}

		<Flex alignItems="start" gap={3.5}>
			<AlertIcon />

			<Box pr={notification.isClosable ? 4 : 0}>
				<AlertTitle>{notification.text}</AlertTitle>

				{notification.description && <AlertDescription w="100%">{notification.description}</AlertDescription>}
			</Box>
		</Flex>
	</Alert>
);

const NotificationPopup = () => {
	const toast = useToast();
	const { notifications, hideNotification } = useNotificationStore(
		useShallow((state) => ({
			notifications: state.notifications,
			hideNotification: state.hideNotification,
		})),
	);

	useEffect(() => {
		(notifications || [])
			.filter((notification) => !toast.isActive(notification.id))
			.forEach((notification) => {
				const position = notification.position || 'bottom-left';

				toast({
					id: notification.id,
					status: notification.type.toLowerCase(),
					duration: notification.duration,
					position,
					containerStyle: ['top', 'top-left', 'top-right'].includes(position) ? { mt: 1 } : undefined,
					onCloseComplete: () => hideNotification(notification.id),
					isClosable: typeof notification.isClosable === 'boolean' ? notification.isClosable : true,
					render: (props) => <CustomNotification notification={notification} {...props} />,
				});
			});
	}, [notifications]);

	return null;
};

export default NotificationPopup;
