import React from 'react';
//components

import useContentStore from '../../../../stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const TextInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class TextInputSettings extends React.PureComponent {
	render() {
		return (
			<div className="text-input-settings">
				<TextInputSettingComponents
					{...this.props}
					onChange={this.onChange}
					settings={{ pattern: { active: true }, maxLength: { active: true } }}
				/>
			</div>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default TextInputSettings;
