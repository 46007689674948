import React from 'react';
import { convertSize, getShadowValFromProps, getTransformerFormFieldFontStyles } from '../../../util/helper';
import { getPadding } from '../CssGenerators/CssClassGenerators';

const getId = (id) => id?.toString()?.replace(/-/g, '') || '';

export const DefaultAcceptedFileTypes = ['.jpg, .jpeg', '.pdf', '.png', '.gif'];

function imageUrl(relativePath) {
	return new URL(relativePath, location.origin).href;
}

const FileUpload = ({ type, label, description, name, required, maxFiles, fileSizeLimit, fileSizeLimitUnit, ...rest }) => {
	const id = getId(rest.id);
	const acceptedFileTypes = rest.acceptedFileTypes?.length ? rest.acceptedFileTypes : DefaultAcceptedFileTypes;

	const getDescription = () => {
		return description
			.replaceAll('[count]', maxFiles)
			.replaceAll('[size]', convertSize(fileSizeLimit, 'Bytes', fileSizeLimitUnit) + fileSizeLimitUnit);
	};

	return (
		<div
			id={id}
			className={`component shared-input-field file-upload-field ${required ? 'required-component' : ''} ${type}-component ${type}-wrapper ${type}${id}`}
		>
			<div className={`global-input-wrapper ${type}-dropzone`}>
				<input
					type="file"
					data-type={type}
					className={`form-input-element ${type}-input`}
					accept={acceptedFileTypes.join(',').replace(/,\s/g, ',')}
					multiple={maxFiles > 1}
					data-max-files={maxFiles}
					data-file-size-limit={fileSizeLimit}
				/>
				<input
					type="hidden"
					data-type={type}
					data-id={id}
					required={required}
					data-required={required}
					className={`form-input-element ${type}-input-value`}
					name={name}
				/>

				<div className={`${type}-box`}>
					<img src={imageUrl('/images/file_upload/plus_outlined.png')} alt="" />
					{label && (
						<span className="global-input-label form-field-label ">
							{label}
							<span className="global-field-required form-field-required "> *</span>
						</span>
					)}
					{description && <div className="global-input-description form-input-description  ">{getDescription()}</div>}
				</div>
			</div>

			<div className={`${type}-error-container component-error-container`} />

			<div className={`${type}-files`}>
				{Array.from(Array(maxFiles).keys()).map((idx) => (
					<div key={idx} className={`${type}-file-wrapper`}>
						<div className={`${type}-file`}>
							<img src={imageUrl('/images/file_upload/file.png')} alt="" />
							<span className={`${type}-file-name`} />
							<span className={`${type}-file-size`} />

							<div className={`${type}-file-actions`}>
								<button type="button" className={`${type}-remove`} />
								<div className={`${type}-loader`} />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export function generateFileUploadClass(component) {
	const { id, type } = component;

	const uid = getId(id);
	const uniqClass = `${type}${uid}`;

	const wrapperStyle = `
		.${type}-wrapper.${uniqClass} {
			${getPadding(component)}
			background-color: ${component.background_color};
			display: ${(component.hiddenField || component.hidden) && !component.required ? 'none' : 'block'};
		}
	`;

	const dropzoneStyle = `
		.${uniqClass} .${type}-dropzone {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: ${component.fieldPadding}px;
			background-color: ${component.fieldBgColor};
			border: ${component.fieldBorderWidth}px dashed ${component.fieldBorderColour};
			border-radius: ${component.fieldBorderRadius}px;
			cursor: pointer;
			box-shadow: ${getShadowValFromProps(component.box_shadow)};
		}

		.${uniqClass} .${type}-dropzone.has-drag {
			background-color: #f7f6ff;
			border-color: #463abc;
		}

		.${uniqClass} .${type}-dropzone.disabled {
			opacity: 0.5;
		}
	`;

	const dropzoneInputStyle = `
		.${uniqClass} .${type}-input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0;
			cursor: pointer;
		}

		.${uniqClass} .${type}-input:disabled {
			cursor: not-allowed;
		}
	`;

	const dropzoneBoxStyle = `
		.${uniqClass} .${type}-box {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	`;

	const dropzoneImgStyle = `
		.${uniqClass} .${type}-box img {
			max-width: 36px;
			height: auto;
			margin-bottom: 12px;
		}
	`;

	const dropzoneLabelStyle = `
		.${uniqClass} .form-field-label {
			color: ${component.labelFontColour};
			font-size: ${component.labelFontSize}px;
			font-weight: ${component.labelFontWeight};
			font-style: ${component.labelFontStyle};
			text-decoration: ${component.labelTextDecoration};
			display: ${component.labelHide ? 'none' : 'block'};
			${getTransformerFormFieldFontStyles(component.labelFont)}
		}
	`;

	const dropzoneDescriptionStyle = `
		.${uniqClass} .form-input-description {
			font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
			color: ${component.descriptionFontColour};
			font-family: ${component.descriptionFontFamily};
			font-size: ${component.descriptionFontSize}px;
			margin-top: ${component.descriptionSpacing}px;
			display: ${component.descriptionHide ? 'none' : 'block'};
		}

		.${uniqClass} .${type}-description-spacer {
			height: 6px;
		}
	`;

	const errorContainerStyle = `
		.${uniqClass} .${type}-error-container {
			position: relative;
			display: none;
			margin-top: 8px;
			padding-left: 20px;
		}

		.${uniqClass} .${type}-error-container:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 14px;
			height: 14px;
			margin-right: 6px;
			background-image: url(${imageUrl('/images/file_upload/error.png')});
			background-repeat: no-repeat;
			background-size: contain;
		}

		.${uniqClass} .form-input-error-message {
			position: static;
			margin: 0;
			font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
			font-family: ${component.descriptionFontFamily};
			font-size: ${component.descriptionFontSize}px;
		}
	`;

	const fileWrapperStyle = `
		.${uniqClass} .${type}-file-wrapper {
			display: none;
			margin-top: 8px;
		}

		.${uniqClass} .${type}-file-wrapper[style*="block"] ~ .${type}-file-wrapper[style*="block"] {
			margin-top: 4px;
		}
	`;

	const fileStyle = `
		.${uniqClass} .${type}-file {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			background-color: rgba(0, 0, 0, 0.03);
			border-radius: 4px;
		}
	`;

	const fileImgStyle = `
		.${uniqClass} .${type}-file > img {
			max-width: 18px;
			margin-right: 12px;
		}
	`;

	const fileNameStyle = `
		.${uniqClass} .${type}-file-name {
			margin-right: 8px;
			color: #404040;
			font-size: 16px;
			${getTransformerFormFieldFontStyles(component.labelFont)}
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	`;

	const fileSizeStyleStyle = `
		.${uniqClass} .${type}-file-size {
			margin-right: 8px;
			color: rgba(0, 0, 0, 0.55);
			font-size: 14px;
			${getTransformerFormFieldFontStyles(component.labelFont)}
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.${uniqClass} .${type}-file-size:before {
			content: '(';
		}

		.${uniqClass} .${type}-file-size:after {
			content: ')';
		}
	`;

	const fileActionsStyle = `
		.${uniqClass} .${type}-file-actions {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	`;

	const fileRemoveStyle = `
		.${uniqClass} .${type}-remove {
			width: 22px;
			height: 22px;
			background-color: transparent;
			background-image: url(${imageUrl('/images/file_upload/minus_circle_outline.png')});
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			border: 0;
			padding: 0;
			cursor: pointer;
		}

		.${uniqClass} .${type}-file-wrapper.loading .${type}-remove {
			display: none;
		}
	`;

	const loaderStyle = `
		.${uniqClass} .${type}-loader {
			display: none;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			border: 2px solid transparent;
			border-top-color: rgba(0, 0, 0, 0.94);
			border-right-color: rgba(0, 0, 0, 0.94);
		}

		.${uniqClass} .${type}-file-wrapper.loading .${type}-loader {
			display: block;
			animation: ${type}-loader 1s infinite linear;
		}

		@keyframes ${type}-loader {
			to {
				transform: rotate(360deg);
			}
		}
	`;

	const asteriskStyle = `
		.${uniqClass} .form-field-required {
			color: ${component.asteriskColor};
		}
	`;

	return [
		wrapperStyle,
		dropzoneStyle,
		dropzoneInputStyle,
		dropzoneBoxStyle,
		dropzoneImgStyle,
		component.label && dropzoneLabelStyle,
		component.description && dropzoneDescriptionStyle,
		errorContainerStyle,
		fileWrapperStyle,
		fileStyle,
		fileImgStyle,
		fileNameStyle,
		fileSizeStyleStyle,
		fileActionsStyle,
		fileRemoveStyle,
		loaderStyle,
		asteriskStyle,
	]
		.filter(Boolean)
		.join('');
}

export default FileUpload;
