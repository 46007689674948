import * as React from 'react';

import { Box, Checkbox } from '@chakra-ui/react';

import {
	EntitiesTableItemActions,
	EntitiesTableItemDetails,
	EntitiesTableItemLabels,
	EntitiesTableItemSelect,
	EntitiesTableItemStatus,
} from './TableItems';

import { FormsTableLoader } from './skeletons/FormsTableLoader';
import { FormsDefaultSorting, OrderByTypes } from '../strings';
import BaseTable from '@/components/Table/components/BaseTable';
import { useDeepCompareMemo } from 'use-deep-compare';

const orderCategories = {
	Checkbox: { filter: '', label: '' },
	Name: { filter: 'Name', label: 'Name' },
	Views: { filter: 'Views', label: 'Views' },
	Interactions: { filter: 'Interactions', label: 'Interactions' },
	Submissions: { filter: 'Submissions', label: 'Submissions' },
	Status: { filter: 'Status', label: 'Status' },
	Labels: { filter: 'Labels', label: 'Labels' },
	Action: { filter: '', label: '' },
};

export const FormsTable = (props) => {
	const selectEntity = (data) => {
		props.selectEntity && props.selectEntity(data);
	};

	const takeEntityAction = (action, entityId) => {
		props.takeEntityAction && props.takeEntityAction(action, entityId);
	};

	const onTagsSave = (tags, entityId) => {
		props.onTagsSave && props.onTagsSave(tags, entityId);
	};

	const onTagsRemove = (data) => {
		props.onTagsRemove && props.onTagsRemove(data);
	};

	const onTagClick = (tag) => {
		props.onTagClick && props.onTagClick(tag);
	};

	const onToggleAll = (e) => {
		props.onToggleAll && props.onToggleAll(e.target.checked);
	};

	const orderDirection = () => {
		if (props.order.includes(orderCategories.Status.filter)) {
			if (props.order.includes('Ascending')) {
				return true;
			}
			return false;
		}
		return undefined;
	};

	const formattedColumns = useDeepCompareMemo(() => {
		return props.entities.map((item) => {
			const { Entity } = item;
			const analytics = props.analytics && props.analytics.length && props.analytics.find((item) => item.EntityId === Entity.Id);
			return {
				Checkbox: {
					item: (
						<EntitiesTableItemSelect entity={item} checked={props.selectedEntitiesIds.includes(Entity.Id)} selectEntity={selectEntity} />
					),
					restProps: { className: 'notranslate' },
				},
				Name: { item: <EntitiesTableItemDetails entityType={props.entityType} entity={item} takeEntityAction={takeEntityAction} /> },
				Views: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsViewedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-views` },
				},
				Interactions: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsInteractedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-interactions` },
				},
				Submissions: {
					item: <>{analytics ? analytics.Statistics.FormAnalyticsSubmittedCount : 0}</>,
					restProps: { 'data-testid': `entity-${item.Entity.Id}-submissions` },
				},
				Status: {
					item: <EntitiesTableItemStatus entityType={props.entityType} entity={item} takeEntityAction={takeEntityAction} />,
				},
				Labels: {
					item: (
						<EntitiesTableItemLabels
							entityType={props.entityType}
							entity={item}
							activeTags={props.activeTags}
							allTags={props.allTags}
							onTagsSave={onTagsSave}
							onTagsRemove={onTagsRemove}
							onTagClick={onTagClick}
						/>
					),
				},
				Action: {
					item: <EntitiesTableItemActions entity={item} entityType={props.entityType} takeEntityAction={takeEntityAction} />,
				},
			};
		});
	}, [
		props.activeTags,
		props.allTags,
		props.analytics,
		props.entities,
		props.entityType,
		props.selectedEntitiesIds,
		selectEntity,
		takeEntityAction,
	]);

	const formattedHeaders = useDeepCompareMemo(
		() => ({
			Checkbox: {
				label: '',
				width: '4%',
				restHeaderProps: { onChange: onToggleAll },
				item: <Checkbox isChecked={props.allSelected} data-testid="table-head-select-checkbox" />,
			},
			Name: {
				label: 'Name',
				isSortable: true,
				width: ['25%', '25%', '25%', '25%', '25%', '35%'],
				restHeaderProps: { cursor: 'pointer' },
			},
			Views: {
				label: 'Views',
				width: '7%',
			},
			Interactions: {
				label: 'Interactions',
				width: '7%',
			},
			Submissions: {
				label: 'Submissions',
				width: '7%',
			},
			Status: {
				label: 'Status',
				isSortable: true,
				width: '9%',
				restHeaderProps: { cursor: 'pointer' },
			},
			Labels: {
				label: 'Labels',
				width: ['38%', '38%', '38%', '38%', '38%', '31%'],
			},
			Action: {
				label: '',
				width: '4%',
			},
		}),
		[props.allSelected],
	);

	const renderDesktop = () => {
		return (
			<BaseTable
				headers={formattedHeaders}
				data={formattedColumns}
				order={props.order}
				orderByTypes={OrderByTypes}
				orderCategories={orderCategories}
				orderDirection={orderDirection}
				changeOrder={props.changeOrder}
				loaders={props.loaders}
				skeletonComponent={<FormsTableLoader />}
				defaultOrderBy={FormsDefaultSorting}
			/>
		);
	};

	const renderTable = () => {
		return <>{renderDesktop()}</>;
	};

	return renderTable();
};

FormsTable.defaultProps = {
	loaders: {
		table: false,
		tableDataUpdate: false,
	},
};
