import useTemplateStore from '@/stores/TemplateStore';

import { Tabs, Tab, TabList, Box } from '@chakra-ui/react';

const Filters = ({ availablePersonalFilters, currentPersonalFilter }) => {
	const { selectPersonalFilter } = useTemplateStore();

	const filterItems = availablePersonalFilters.map((filter) => {
		return (
			<Tab key={`${filter.id}-filter`} activeId={filter.id}>
				<Box onClick={() => selectPersonalFilter(filter.id)}>{filter.label}</Box>
			</Tab>
		);
	});

	return (
		<Tabs mb={5} activeItem={currentPersonalFilter}>
			<TabList>{filterItems}</TabList>
		</Tabs>
	);
};

export default Filters;
