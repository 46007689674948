import React from 'react';

//components
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import CheckBoxSetting from '../../shared/settings/CheckBoxSetting.react';
import DropDownSetting from '../../shared/settings/DropDownSetting.react';
import PaddingSetting from '../../shared/settings/PaddingSetting.react';
import NumberSetting from '../../shared/settings/NumberSetting.react';
import ToggleBlock from '../../shared/settings/ToggleBlock.react';
import ImagePickerButtons from '../../shared/settings/ImagePickerButtons.react';

import useContentStore from '../../../../stores/ContentStore';

import MultiSetting from '../../shared/settings/MultiSetting.react';

import { RowTypes, VerticalAlignOptions } from '../../../../util/resources';

import { BackgroundRepeatOptionsLP } from './SharedImageSettings.react';

import { SettingsBox } from '../../settingsContext/SettingsContext';

const RowSettingsFields = {
	slot1: 'slot1',
	slot2: 'slot2',
	slot3: 'slot3',
	slot4: 'slot4',
	slot5: 'slot5',
	slot6: 'slot6',
	responsive: 'responsive',
	slot_spacing_side: 'slot_spacing_side',
	slot_spacing_center: 'slot_spacing_center',
	background_image: 'background_image',
	background_repeat: 'background_repeat',
	background_position: 'background_position',
	background_repeat_wide: 'background_repeat_wide',
	background_position_wide: 'background_position_wide',
	sort_products: 'sort_products',
	repeat_count: 'repeat_count',
};

const BackgroundPositionOptions = [
	{
		value: 'initial',
		label: 'Initial',
	},
	{
		value: 'top',
		label: 'Top',
	},
	{
		value: 'bottom',
		label: 'Bottom',
	},
	{
		value: 'right',
		label: 'Right',
	},
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'center',
		label: 'Center',
	},
];

class RowSettings extends React.PureComponent {
	static defaultProps = {
		bg_color: 'transparent',
		background_color_wide: 'transparent',
		slot_spacing_side: 0,
		slot_spacing_center: 0,
		responsive: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
		};
	}

	render() {
		let slots = this.props.slots.map((slot, key) => {
			const label = `Slot ${key + 1}`;
			const slotSettings = this.props.isMobileView ? { ...slot, ...slot.mobileProps } : slot;
			return (
				<SlotSettings
					{...slotSettings}
					label={label}
					onChange={this.onSlotChange}
					types={{
						padding: 'padding',
						background_color: 'background_color',
						vertical_align: 'vertical_align',
						borderWidth: 'borderWidth',
						borderColor: 'borderColor',
						borderRadius: 'borderRadius',
						minHeight: 'minHeight',
						hidden: 'hidden',
					}}
					slotNumber={key}
					filterImmunity
					isMobileView={this.props.isMobileView}
					responsive={this.props.responsive}
				/>
			);
		});

		let { bg_color, responsive, borderColor, borderWidth, borderRadius } = this.props;

		return (
			<SettingsBox>
				<PaddingSetting
					multiPadding={this.props.multiPadding}
					padding={this.props.padding}
					type="multiPadding"
					secondaryType={'padding'}
					onChange={this.onChange}
					label="Padding"
				/>
				<ColorPickerSetting type="bg_color" onChange={this.onChange} selectedColor={bg_color} label="Layout color" />
				<NumberSetting
					type={'rowWidth'}
					onChange={this.onChange}
					text={this.props.rowWidth || this.props.structureWidth}
					label="Layout width"
					ariaLabel="Number of pixels"
				/>
				<MultiSetting label="Border width & border radius">
					<NumberSetting
						type={'borderWidth'}
						onChange={this.onChange}
						text={borderWidth}
						label="Border width"
						ariaLabel="Number of pixels"
					/>
					<NumberSetting
						type="borderRadius"
						onChange={this.onChange}
						text={borderRadius}
						label="Border radius"
						ariaLabel="Number of pixels"
					/>
				</MultiSetting>
				<ColorPickerSetting type="borderColor" onChange={this.onChange} selectedColor={borderColor} label="Layout border color" />
				{this.renderImageSettings()}
				{this.props.type !== RowTypes.FULL && this.props.isMobileView && (
					<CheckBoxSetting
						onClick={this.onResponsiveChange}
						label="Do not cascade blocks"
						checked={responsive}
						type={RowSettingsFields.responsive}
					/>
				)}
				{slots}
			</SettingsBox>
		);
	}

	renderImageSettings = () => {
		return (
			<>
				<RowBackgroundImageSettings
					label={this.props.background_image ? 'Change content background image' : 'Add content background image'}
					removeLabel="Remove content background image"
					id={this.props.id}
					settingType="row"
					onChange={this.onChange}
					background_image={this.props.background_image}
					background_repeat={this.props.background_repeat}
					background_position={this.props.background_position}
					progress={this.state.progress}
					type={{ repeat: RowSettingsFields.background_repeat, position: RowSettingsFields.background_position }}
				/>
			</>
		);
	};

	onSlotChange = (slotNumber) => {
		return (data) => {
			let slotSettings = {};

			if (Array.isArray(data)) {
				data.map((setting) => {
					slotSettings[setting.type] = setting.value;
				});
			} else {
				slotSettings[data.type] = data.value;
			}

			useContentStore.getState().saveSlotSettings({ rowId: this.props.id, settings: slotSettings, slotIndex: slotNumber });
		};
	};

	onResponsiveChange = (data) => {
		let settings = { id: this.props.id, responsive: data.value };
		useContentStore.getState().saveRowSettings(settings);
	};

	onChange = (data) => {
		let settings = { id: this.props.id };

		if (Array.isArray(data)) {
			data.map((setting) => {
				settings[setting.type] = setting.value;
			});
		} else {
			settings[data.type] = data.value;
		}

		useContentStore.getState().saveRowSettings(settings);
	};
}

export const RowBackgroundImageSettings = React.memo((props) => {
	return (
		<div>
			{!!props.background_image && (
				<DropDownSetting
					type={props.type.repeat}
					onChange={props.onChange}
					selected={props.background_repeat || 'no-repeat'}
					label="Background image repeat"
					options={BackgroundRepeatOptionsLP}
				/>
			)}
			{!!props.background_image && (
				<DropDownSetting
					type={props.type.position}
					onChange={props.onChange}
					selected={props.background_position || 'initial'}
					label="Background image position"
					options={BackgroundPositionOptions}
				/>
			)}
			<div>
				<ImagePickerButtons
					label={props.label}
					removeLabel={props.removeLabel}
					type={props.settingType}
					details={props.id}
					image={props.background_image}
				/>
			</div>
		</div>
	);
});

export const SlotSettings = React.memo((props) => {
	// let slotNumber = (props.slotNumber && props.slotNumber.toString()) || '';

	return (
		<ToggleBlock label={props.label}>
			<ColorPickerSetting
				type={props.types.background_color}
				onChange={props.onChange(props.slotNumber)}
				selectedColor={props.background_color || 'transparent'}
				label="Background color"
			/>
			<ColorPickerSetting
				type={'contentBgColor'}
				onChange={props.onChange(props.slotNumber)}
				selectedColor={props.contentBgColor || 'transparent'}
				label="Content background color"
			/>
			<MultiSetting label="Vertical align & minimum height">
				<DropDownSetting
					type={props.types.vertical_align}
					onChange={props.onChange(props.slotNumber)}
					selected={props.vertical_align || 'top'}
					label="Vertical align"
					options={VerticalAlignOptions}
				/>
				<NumberSetting
					type={props.types.minHeight}
					onChange={props.onChange(props.slotNumber)}
					text={props.minHeight}
					label="Minimum height"
					ariaLabel="Number of pixels"
				/>
			</MultiSetting>
			<PaddingSetting
				key={0}
				multiPadding={props.multiPadding}
				padding={props.padding}
				type="multiPadding"
				secondaryType={'padding'}
				onChange={props.onChange(props.slotNumber)}
				label="Padding"
			/>
			<MultiSetting key={1} label="Border width & border radius">
				<NumberSetting
					type={props.types.borderWidth}
					onChange={props.onChange(props.slotNumber)}
					text={props.borderWidth}
					label="Border width"
					ariaLabel="Number of pixels"
				/>
				<NumberSetting
					type={props.types.borderRadius}
					onChange={props.onChange(props.slotNumber)}
					text={props.borderRadius}
					label="Border radius"
					ariaLabel="Number of pixels"
				/>
			</MultiSetting>
			<ColorPickerSetting
				key={2}
				type={props.types.borderColor}
				onChange={props.onChange(props.slotNumber)}
				selectedColor={props.borderColor}
				label="Border color"
			/>
			{props.isMobileView && props.responsive && (
				<CheckBoxSetting
					onClick={props.onChange(props.slotNumber)}
					label="Hide in mobile devices"
					checked={props.hidden}
					type={props.types.hidden}
				/>
			)}
		</ToggleBlock>
	);
});

export default RowSettings;
