//libs
import React from 'react';

//stores
import useContentStore from '../../../stores/ContentStore';

// resources
import { ScriptTypes } from '../../../util/resources';

//code editor
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-clouds_midnight';
import { Box, Drawer, DrawerContent, DrawerOverlay, IconButton } from '@moosend/mooskin';

import { shallow } from 'zustand/shallow';

export class CodeViewContainer extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			canResize: false,
			bodyHeight: (window.innerHeight - 250) * 0.5,
			headHeight: (window.innerHeight - 250) * 0.5,
			containerTop: 0,
		};
	}

	render() {
		const { body, head } = this.props.script;

		return (
			<Drawer zIndex={1} placement="left" size="sm" isOpen className="script-view-container">
				<DrawerOverlay position="unset" bgColor="transparent !important" w={380} h={window.innerHeight - 71}>
					<DrawerContent
						position="unset"
						maxW="unset"
						h="100%"
						w="100%"
						d="flex"
						direction="column"
						bgColor="backgroundColors.menuDark"
						p={20}
					>
						<Box mb={20} fontWeight={600} fontSize={18} fontColor="fontColors.gray1">
							Custom script
						</Box>
						<Box mb={20} fontSize={14} fontColor="fontColors.medgray1">
							Use the text editing interface in the design area. Click on an element, to show useful Script information.
						</Box>
						<Box onMouseMove={this.onMouseMove} onMouseLeave={this.disableResize} className="code-editor-container notranslate">
							<AceEditor
								ref="head"
								mode="javascript"
								theme="clouds_midnight"
								name="SCRIPT_HEAD_VIEW"
								showPrintMargin={false}
								editorProps={{ $blockScrolling: true }}
								enableBasicAutocompletion={true}
								enableLiveAutocompletion={true}
								value={head}
								width="100%"
								height={this.state.headHeight + 'px'}
								onChange={(value) => this.onChange(value, ScriptTypes.head)}
							/>
							<Box
								style={{ userSelect: 'none' }}
								d="flex"
								align="center"
								justify="center"
								h={30}
								className="drag-handle-container"
								onMouseDown={this.enableResize}
								onMouseUp={this.disableResize}
							>
								<IconButton children="drag_handle" cursor="grab" />
							</Box>
							<AceEditor
								ref="body"
								mode="javascript"
								theme="clouds_midnight"
								name="SCRIPT_BODY_VIEW"
								showPrintMargin={false}
								editorProps={{ $blockScrolling: true }}
								enableBasicAutocompletion={true}
								enableLiveAutocompletion={true}
								value={body}
								width="100%"
								height={this.state.bodyHeight + 'px'}
								onChange={(value) => this.onChange(value, ScriptTypes.body)}
							/>
						</Box>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		);
	}

	onChange = (script, type) => {
		const settings = {
			[type]: script,
		};

		useContentStore.getState().setDesignerScript(settings);
	};

	onMouseMove = (e) => {
		if (this.state.canResize) {
			const headHeight = e.nativeEvent.clientY - 200;
			const bodyHeight = window.innerHeight - 250 - headHeight;
			if (bodyHeight > 50 && headHeight > 50) {
				this.setState({ headHeight, bodyHeight });
			}
		}
	};

	enableResize = () => {
		this.setState({ canResize: true });
	};

	disableResize = () => {
		this.setState({ canResize: false });
	};
}

const CodeViewContainerWrapper = (props) => {
	const script = useContentStore((state) => state.content.script);
	return <CodeViewContainer script={script} {...props} />;
};

export default CodeViewContainerWrapper;
