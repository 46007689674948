import React from 'react';
import Modal from '../../../gui/shared/modals/Modal.react';
import { ModalDesigns, ModalTypes, Modals } from '../../../../util/resources';

import { Box, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

//actions
import useModalStore from '../../../../stores/ModalStore';

class GeneralModal extends React.PureComponent {
	// keyListener;

	// componentDidUpdate() {
	// 	if (this.props.show) {
	// 		window.addEventListener('keyup', this.keyListener);
	// 	} else {
	// 		window.removeEventListener('keyup', this.keyListener);
	// 	}
	// }

	// componentDidMount() {
	// 	this.keyListener = DomHelper.onKeyUpModalListener(this.ok, this.close);
	// }

	// componentWillUnmount() {
	// 	window.removeEventListener('keyup', this.keyListener);
	// }

	render() {
		let { show, data, title } = this.props;

		let list =
			(data.list &&
				data.list.map((item, i) => {
					return (
						<Text mb={1.5} as="li" key={i}>
							- {item}
						</Text>
					);
				})) ||
			[];

		return (
			<Modal
				display={show}
				title={this.props.title ?? this.props.data.title}
				modal={this.props.data.modal || Modals.CONFIRM}
				modalType={ModalTypes.GENERAL_MODAL}
			>
				<ModalBody>
					{data.message && <Text>{data.message}</Text>}

					{list && list.length ? <Box>{list}</Box> : null}
				</ModalBody>
				<ModalFooter gap="2">
					{!this.props.data.ignoreCancelBtn && (
						<Button variant="ghost" children={this.props.data.cancelLabel || 'Cancel'} onClick={this.close} />
					)}

					<Button
						colorScheme={this.props.data.modal === Modals.ERROR ? 'danger' : 'primary'}
						children={this.props.data.okLabel || 'Yes'}
						onClick={this.ok}
					/>
				</ModalFooter>
			</Modal>
		);
	}

	ok = () => {
		this.props.data.onOk && this.props.data.onOk();
		useModalStore.getState().closeGeneralModal();
	};

	close = () => {
		this.props.data.onCancel && this.props.data.onCancel();
		useModalStore.getState().closeGeneralModal();
	};
}

export default GeneralModal;
