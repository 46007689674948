import React from 'react';

import useContentStore from '../../../stores/ContentStore';
import { showNotification } from '../../../stores/NotificationStore';

import { copyToClipboard, findRow } from '../../../util/helper';
import { Box, Drawer, DrawerContent, DrawerOverlay, IconButton, Text } from '@moosend/mooskin';

import { shallow } from 'zustand/shallow';

class ScriptMenu extends React.PureComponent {
	render() {
		const { activeElement, rows } = this.props;

		const foundRow =
			rows &&
			activeElement &&
			rows.find((row) => {
				return row.id === activeElement.rowId;
			});

		// to fix
		const row = foundRow && findRow(rows, activeElement.rowId).row;
		const slot = row && row.slots[activeElement.slot];
		const component = slot && slot.components[activeElement.component];

		const id = component && component.id && component.id.replace(/-/g, '');
		const slotId = slot && slot.id && slot.id.replace(/-/g, '');
		const rowId = row && row.id && row.id.replace(/-/g, '');

		return (
			<Drawer zIndex={1} className="script-view-container" size="sm" isOpen={this.props.activeElement}>
				<DrawerOverlay position="unset" bgColor="transparent !important" w={300} h={window.innerHeight - 71}>
					<DrawerContent
						position="unset"
						maxW="unset"
						h="100%"
						w="100%"
						d="flex"
						direction="column"
						bgColor="backgroundColors.menuDark"
						p={20}
					>
						<Box mb={20} fontWeight={600} fontSize={18} fontColor="fontColors.gray1">
							Element Information
						</Box>
						<Box mb={20}>
							<Box mb={7} fontColor="fontColors.gray1">
								Element ID:{' '}
							</Box>
							<Box borderRadius={3} d="flex" p={10} bgColor="backgroundColors.menuLight">
								<Box fontColor="fontColors.gray1" flex={1} overflow="hidden" textOverflow="ellipsis">
									{id}
								</Box>
								<IconButton
									fontColor="fontColors.primary1"
									ml={7}
									children="content_copy"
									onClick={() =>
										copyToClipboard(id, () => {
											showNotification({ type: 'Success', text: 'Copied.' });
										})
									}
								/>
							</Box>
						</Box>
						{component && component.type && (
							<Box mb={20}>
								<Box mb={7} fontColor="fontColors.gray1">
									Element Class:{' '}
								</Box>
								<Box borderRadius={3} d="flex" p={10} bgColor="backgroundColors.menuLight">
									<Box fontColor="fontColors.gray1" flex={1} overflow="hidden" textOverflow="ellipsis">
										{`${component.type}-component`}
									</Box>
									<IconButton
										fontColor="fontColors.primary1"
										ml={7}
										children="content_copy"
										onClick={() =>
											copyToClipboard(`${component.type}-component`, () => {
												showNotification({ type: 'Success', text: 'Copied.' });
											})
										}
									/>
								</Box>
							</Box>
						)}
						<Box mb={20}>
							<Box mb={7} fontColor="fontColors.gray1">
								Slot ID:{' '}
							</Box>
							<Box borderRadius={3} d="flex" p={10} bgColor="backgroundColors.menuLight">
								<Box fontColor="fontColors.gray1" flex={1} overflow="hidden" textOverflow="ellipsis">
									{slotId}
								</Box>
								<IconButton
									fontColor="fontColors.primary1"
									ml={7}
									children="content_copy"
									onClick={() =>
										copyToClipboard(slotId, () => {
											showNotification({ type: 'Success', text: 'Copied.' });
										})
									}
								/>
							</Box>
						</Box>
						<Box mb={20}>
							<Box mb={7} fontColor="fontColors.gray1">
								Structure ID:{' '}
							</Box>
							<Box borderRadius={3} d="flex" p={10} bgColor="backgroundColors.menuLight">
								<Box fontColor="fontColors.gray1" flex={1} overflow="hidden" textOverflow="ellipsis">
									{rowId}
								</Box>
								<IconButton
									fontColor="fontColors.primary1"
									ml={7}
									children="content_copy"
									onClick={() =>
										copyToClipboard(rowId, () => {
											showNotification({ type: 'Success', text: 'Copied.' });
										})
									}
								/>
							</Box>
						</Box>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		);
	}
}

const ScriptMenuWrapper = (props) => {
	const contentData = useContentStore((state) => {
		return {
			rows: state.content.rows,
			activeElement: state.helper.clicked_element,
		};
	}, shallow);
	return <ScriptMenu {...contentData} {...props} />;
};

export default ScriptMenuWrapper;
