import React from 'react';
import { getShadowValFromProps, getTransformerFormFieldFontStyles } from '../../../util/helper';

import Checkbox, { generateCheckboxClass } from './Checkbox.react';

import { AllComponentTypes } from '../../../util/resources';
import { getPadding } from '../CssGenerators/CssClassGenerators';

export const GDPRbox = (props) => {
	return (
		<Checkbox {...props} labelHide={false} type={AllComponentTypes.checkbox} value={props.value} label={props.label} descriptionHide />
	);
};

export const generateGDPRboxClass = (component) => {
	return `
		${generateCheckboxClass(component)}
	`;
};

export default GDPRbox;
