import React, { useMemo } from 'react';

import {
	Box,
	Button,
	ButtonGroup,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	FormControl,
	FormLabel,
	InputGroup,
	InputRightElement,
	Tooltip,
} from '@chakra-ui/react';

import { Select } from 'chakra-react-select';

import useEntitiesStore from '../../../stores/EntitiesStore';
import useContentStore from '../../../stores/ContentStore';
import useModalStore from '../../../stores/ModalStore';
import { showNotification } from '@/stores/NotificationStore';

import { shallow } from 'zustand/shallow';

import { copyToClipboard } from '@/util/helper';

import { FormFailActions, FormSuccessActions, FormSuccessLabels, Modals } from '@/util/resources';
import { EntityStatuses } from '../../Table/strings';
import { ModalMessages } from '../../../../config/messages';

import TextEdit from '../shared/text/TextEdit.react';

import PreviewWrapper from './Preview.react';
import { WebhooksDrawer } from '../../Table/containers/WebhooksContainer';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiContentCopy, mdiPencilOutline } from '@mdi/js';
import styled from 'styled-components';
import { GlobalStyleSetting } from '../shared/settings/GlobalStyleSetting.react';
import SiteManagement from '../shared/SiteManagement.react';

const successActionOptions = Object.keys(FormSuccessActions).map((action) => ({
	value: FormSuccessActions[action],
	label: FormSuccessLabels[action],
}));

export const SettingsPage = () => {
	const [showWebhooks, setShowWebhooks] = React.useState(false);
	const [editName, setEditName] = React.useState(false);
	const [testModal, setTestModal] = React.useState(false);

	const { webhooks, entityForm, changeEntityData, activeEntity, reassignWebhook, assignWebhook, updateEntity, getEntity, getWebhooks } =
		useEntitiesStore((state) => {
			return {
				webhooks: state.webhooks,
				assignWebhook: state.assignWebhook,
				searchWebhooks: state.searchWebhooks,
				deleteWebhook: state.deleteWebhook,
				activeEntity: state.activeEntity,
				reassignWebhook: state.reassignWebhook,
				unassignWebhook: state.unassignWebhook,
				getEntity: state.getEntity,
				entityForm: state.entityForm,
				changeEntityData: state.changeEntityData,
				updateEntity: state.updateEntity,
				getWebhooks: state.getWebhooks,
			};
		}, shallow);

	const { successAction, setSuccessAction, failAction, setFailAction, structureWidth, canBeModified } = useContentStore((state) => {
		return {
			failAction: state.content.failAction,
			successAction: state.content.successAction,
			setSuccessAction: state.setSuccessAction,
			setFailAction: state.setFailAction,
			structureWidth: state.content.structureWidth,
			canBeModified: state.canBeModified,
		};
	}, shallow);

	const onChange = (data) => {
		const input = {
			entityForm: {
				...entityForm,
				[data.type]: data.value,
			},
		};

		changeEntityData && changeEntityData(input);
	};

	const assignNewWebhook = (webhookId, cb = undefined) => {
		const entityId = activeEntity.Entity.Id;
		if (activeEntity) {
			if (activeEntity.WebhookSettings) {
				reassignWebhook(webhookId, entityId, cb);
			} else {
				assignWebhook(webhookId, entityId, cb);
			}
		}
	};

	const gotoTest = () => {
		setTestModal(true);
		// if (entityForm.webhookId === activeEntity.WebhookSettings.WebhookId) {
		// 	const path = pathPrefix() + '/preview';

		// 	path && browserHistory.push({ pathname: path, query });
		// 	return;
		// }

		// assignNewWebhook(entityForm.webhookId, () => {
		// 	getEntity(activeEntity.Entity.Id);
		// 	const path = pathPrefix() + '/preview';

		// 	path && browserHistory.push({ pathname: path, query });
		// });
	};

	const onChangeWebhook = (webhookId) => {
		if (webhookId === activeEntity?.WebhookSettings?.WebhookId) {
			return;
		}

		const callback = () => assignNewWebhook(webhookId, () => getEntity(activeEntity.Entity.Id));

		if (activeEntity.Entity.Status === EntityStatuses.active) {
			useModalStore.getState().showGeneralModal({
				modal: Modals.INFO,
				message: ModalMessages.CONFIRM_WEBHOOK,
				onOk: callback,
				okLabel: 'Yes',
				cancelLabel: 'Cancel',
			});
		} else {
			callback();
		}
	};

	const onUpdateEntity = () => {
		setEditName(false);
		updateEntity(
			{
				formData: entityForm,
				activeEntity: activeEntity,
				callback: () => {
					showNotification({ type: 'Success', text: 'Form name changed' });
					getEntity(activeEntity.Entity.Id);
				},
			},
			false,
		);
	};

	const { webhookOptions, selectedWebhookOption } = useMemo(() => {
		const webhookOptions = webhooks.items.map((item) => {
			return {
				value: item.Id,
				label: item.Settings.Name,
			};
		});

		const selectedWebhookOption = webhookOptions.find((opt) => opt.value === entityForm.webhookId);

		return { webhookOptions, selectedWebhookOption };
	}, [webhooks.items, entityForm?.webhookId]);

	const onCloseDrawer = () => {
		setShowWebhooks(false);
		getWebhooks();
	};

	if (!canBeModified) {
		return null;
	}

	return (
		<>
			<Modal isOpen={testModal} onClose={() => setTestModal(false)}>
				<ModalOverlay />
				<ModalContent maxW={structureWidth} w="100%" h="fit-content" minH="xl" maxH="95%" overflow="auto">
					<ModalCloseButton data-testid="preview-modal-close" />
					<ModalHeader>Test webhook</ModalHeader>
					<ModalBody position="relative" p={6} overflow="auto">
						<Box mb={10}>To test your webhook, please complete and submit the form.</Box>
						<PreviewWrapper hideViewModes resizeIframe />
					</ModalBody>
				</ModalContent>
			</Modal>
			<Box display="flex" alignItems="center" justifyContent="center" mt={10} pb={10}>
				<Box display="flex" flexDirection="column" maxW="2xl" w="100%" gap="7">
					{activeEntity && (
						<FormControl>
							<FormLabel>Form ID</FormLabel>
							<InputGroup>
								<Input isReadOnly value={activeEntity.Entity.Id} />
								<InputRightElement>
									<Tooltip label="Copy to clipboard">
										<IconButton
											variant="ghost"
											icon={<Icon path={mdiContentCopy} />}
											size="sm"
											aria-label={'Copy to clipboard'}
											onClick={() =>
												copyToClipboard(activeEntity.Entity.Id, () => {
													showNotification({ type: 'Success', text: 'Copied' });
												})
											}
										/>
									</Tooltip>
								</InputRightElement>
							</InputGroup>
						</FormControl>
					)}

					<Box>
						<Text fontWeight="medium" color={'neutral-fg'}>
							Name
						</Text>
						{!editName ? (
							<Box display="flex" alignItems="center">
								{activeEntity && <Text mr={2}>{activeEntity.Entity.Name}</Text>}
								<Tooltip label="Edit">
									<IconButton aria-label="Edit" variant="ghost" onClick={() => setEditName(true)} icon={<Icon path={mdiPencilOutline} />} />
								</Tooltip>
							</Box>
						) : (
							<Box>
								<Input
									mt={3}
									autoFocus
									value={entityForm.name}
									onChange={(e) => onChange({ type: 'name', value: e.target.value })}
									onInput={(e) => {
										e.target.value = e.target.value.replace(
											/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
											'lOL',
										);
									}}
								/>
								<Box display="flex" justifyContent="end" mt={2}>
									<Button
										variant="ghost"
										mr={3}
										onClick={() => {
											setEditName(false);
											onChange({ type: 'name', value: activeEntity.Entity.Name });
										}}
									>
										Cancel
									</Button>
									<Button onClick={onUpdateEntity}>Save</Button>
								</Box>
							</Box>
						)}
					</Box>
					<GlobalStyleSetting />
					<Box zIndex={4} data-testid="webhooks-select-container">
						<Text mb={3} fontWeight="medium" color={'neutral-fg'}>
							Choose webhook
						</Text>
						{webhooks.items.length ? (
							<Select
								onChange={(selectedOption) => onChangeWebhook(selectedOption.value)}
								value={selectedWebhookOption}
								data-testid="settings-webhooks-dropdown"
								placeholder="Select a webhook"
								options={webhookOptions}
								useBasicStyles
								selectedOptionStyle="check"
							/>
						) : (
							<Text>No webhooks available</Text>
						)}
						<ButtonGroup variant="outline" colorScheme="primary" size="sm" mt={3}>
							<Button onClick={gotoTest}>Test webhook</Button>
							<Button onClick={() => setShowWebhooks(true)}>Manage webhooks</Button>
						</ButtonGroup>
						<WebhooksDrawer isOpen={showWebhooks} onClose={onCloseDrawer} />
					</Box>
					<SiteManagement />
					<Box zIndex={3} data-testid="submit-action-wrapper">
						<Text mb={3} fontWeight="medium" color={'neutral-fg'}>
							Choose submit action
						</Text>
						<Select
							onChange={(selectedOption) => setSuccessAction({ ...successAction, type: selectedOption.value })}
							value={successAction?.type ? successActionOptions.find((a) => a.value === successAction.type) : ''}
							options={successActionOptions}
							placeholder="Select an action"
							useBasicStyles
							selectedOptionStyle="check"
						/>
					</Box>
					{successAction.type === FormSuccessActions.message && (
						<Box data-testid="submit-actions-success-message">
							<Text mb={3} fontWeight="medium" color={'neutral-fg'}>
								Success message
							</Text>
							<TextEdit
								inline={false}
								customToolbar={`newdocument cut copy pastetext outdent indent link unlink openlink anchor numlist bullist blockquote alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor lineheight fontfamily fontsize blocks spellchecker code`}
								id={`form-success-message`}
								text={successAction.message}
								onChange={(message) => setSuccessAction({ ...successAction, message })}
								type="message"
							/>
						</Box>
					)}
					{successAction.type === FormSuccessActions.redirectUrl && (
						<Box>
							<Text mb={3} fontWeight="medium" color={'neutral-fg'}>
								Redirect URL
							</Text>
							<Input
								placeholder="https://"
								value={successAction.redirectUrl}
								onChange={(e) => setSuccessAction({ ...successAction, redirectUrl: e.target.value })}
								data-testid="submit-actions-redirect-url"
							/>
						</Box>
					)}
					<Box data-testid="submit-actions-fail-message">
						<Text mb={3} fontWeight="medium" color={'neutral-fg'}>
							Fail message
						</Text>
						{failAction.type === FormFailActions.message && (
							<TextEdit
								inline={false}
								customToolbar={`newdocument cut copy pastetext outdent indent link unlink openlink anchor numlist bullist blockquote alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor lineheight fontfamily fontsize blocks spellchecker code`}
								id={`form-fail-message`}
								text={failAction.message}
								onChange={(message) => setFailAction({ ...failAction, message })}
								type="message"
							/>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
