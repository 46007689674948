import React from 'react';

import useContentStore from '../../../../stores/ContentStore';

import SharedInputSettings from './SharedInput-settings.react';

export const PhoneInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class PhoneInputSettings extends React.PureComponent {
	render() {
		return (
			<div className="phone-input-settings">
				<PhoneInputSettingComponents
					{...this.props}
					onChange={this.onChange}
					settings={{
						placeholder: {
							active: false,
						},
					}}
				/>
			</div>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default PhoneInputSettings;
