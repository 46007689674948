import React from 'react';
import { SimpleGrid, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';

import { AllComponentTypes, BasicComponentTypes, ElementLabels, ElementMenuIcons, FieldComponentTypes } from '../../../util/resources';

//components
import DraggableMenuItem from './DraggableElement.react';

import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import { shallow } from 'zustand/shallow';

const _fieldMenuItems = Object.keys(FieldComponentTypes).map((component) => {
	return {
		label: ElementLabels[component],
		iconSrc: ElementMenuIcons[component],
		type: AllComponentTypes[component],
	};
});

const _basicMenuItems = Object.keys(BasicComponentTypes).map((component) => {
	return {
		label: ElementLabels[component],
		iconSrc: ElementMenuIcons[component],
		type: AllComponentTypes[component],
	};
});

class ElementsMenu extends React.PureComponent {
	render() {
		const filteredFieldItems = this.getFilteredFieldItems();
		const filteredBasicItems = this.getFilteredBasicItems();

		const fieldItems = filteredFieldItems.map((item, i) => {
			return <DraggableMenuItem iconSrc={item.iconSrc} label={item.label} key={i} type={item.type} />;
		});
		const basicItems = filteredBasicItems.map((item, i) => {
			return <DraggableMenuItem iconSrc={item.iconSrc} label={item.label} key={i} type={item.type} />;
		});

		let customElements = this.props.elements.map((item, i) => {
			const iconSrc = ElementMenuIcons[item.type];

			return <DraggableMenuItem elementId={item.id} isCustomElement iconSrc={iconSrc} label={item.title} key={i} type={item.type} />;
		});

		return (
			<Accordion allowMultiple defaultIndex={[0, 1, 2]}>
				<AccordionItemBlock label="Fields" isFirst>
					<SimpleGrid columns={3} spacing={2} p="1">
						{fieldItems}
					</SimpleGrid>
				</AccordionItemBlock>

				<AccordionItemBlock label="Basic">
					<SimpleGrid columns={3} spacing={2} p="1">
						{basicItems}
					</SimpleGrid>
				</AccordionItemBlock>

				<AccordionItemBlock label="Custom" isLast>
					<SimpleGrid columns={3} spacing={2} p="1">
						{customElements}
					</SimpleGrid>
				</AccordionItemBlock>
			</Accordion>
		);
	}

	getFilteredFieldItems = () => {
		return _fieldMenuItems.filter((item) => {
			return item.type in AllComponentTypes;
		});
	};
	getFilteredBasicItems = () => {
		return _basicMenuItems.filter((item) => {
			return item.type in AllComponentTypes;
		});
	};
}

const AccordionItemBlock = ({ label, children, isFirst = false, isLast = false }) => {
	return (
		<AccordionItem borderTop={isFirst && 0} borderBottom={isLast && 0}>
			<AccordionButton>
				{label}
				<AccordionIcon ml="auto" />
			</AccordionButton>

			<AccordionPanel motionProps={{ unmountOnExit: true }}>{children}</AccordionPanel>
		</AccordionItem>
	);
};

const ElementsMenuWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return { elements: state.custom_elements.elements };
	}, shallow);
	return <ElementsMenu {...leftSidebarData} {...props} />;
};

export default ElementsMenuWrapper;
