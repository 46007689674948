//libs
import React from 'react';
import { CircularProgress, Text, Box } from '@chakra-ui/react';

import '@moosend/mooskin/lib/index/style.css';

//misc
import TopMenu from './gui/layout/TopMenu.react';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from '@silvain-sayduck/react-dnd-html5-backend';

import NotificationPopup from './gui/layout/modals/NotificationPopup.react';
import useModalStore from '../stores/ModalStore';
import useContentStore from '../stores/ContentStore';

//Modals
import GeneralModal from './gui/layout/modals/GeneralModal.react';
import DontAskModal from './gui/layout/modals/DontAskModal.react';
import TemplatePreviewModal from './gui/layout/modals/TemplatePreviewModal.react';
import ImagePickerModal from './gui/layout/modals/ImagePickerModal.react';
import CropperModal from './gui/layout/modals/CropperModal.react';

import SaveTemplateMenu from './gui/layout/SaveTemplateMenu.react';
import SaveCustomRowMenu from './gui/layout/SaveCustomRowMenu.react';
import SaveCustomElementMenu from './gui/layout/SaveCustomElementMenu.react';

import { ModalTypes } from '../util/resources';

import { shallow } from 'zustand/shallow';

export class App extends React.PureComponent {
	render() {
		let generalModal = this.props.modals[ModalTypes.GENERAL_MODAL];
		let dontAskModal = this.props.modals[ModalTypes.DONT_ASK];
		let templatePreviewModal = this.props.modals[ModalTypes.TEMPLATE_PREVIEW_MODAL];
		let imagePickerModal = this.props.modals[ModalTypes.IMAGE_PICKER];
		let cropModal = this.props.modals[ModalTypes.CROP_MODAL];

		return (
			<>
				<LoaderOverlay />
				<TemplatePreviewModal show={templatePreviewModal.show} data={templatePreviewModal.data} />
				<GeneralModal show={generalModal.show} data={generalModal.data} />
				<CropperModal show={cropModal.show} data={cropModal.data} />
				<ImagePickerModal {...imagePickerModal.data} />
				<SaveTemplateMenu location={this.props.location} />
				<SaveCustomRowMenu location={this.props.location} />
				<SaveCustomElementMenu location={this.props.location} />
				<NotificationPopup />
				<DontAskModal show={dontAskModal.show} data={dontAskModal.data} />
				<Box display="flex" flexDirection="column" h="full">
					<TopMenu location={this.props.location} />
					<Box h="full" overflowX="hidden" overflowY="auto">
						{this.props.children}
					</Box>
				</Box>
			</>
		);
	}
}

const LoaderOverlay = (props) => (
	<Box
		id="initial-overlay"
		zIndex={9999}
		display="flex"
		alignItems="center"
		flexDirection="column"
		position="fixed"
		top={0}
		left={0}
		right={0}
		bottom={0}
		backgroundColor="white"
		justifyContent="center"
	>
		<Text id="initial-error" fontSize="2xl" mb="4"></Text>
		<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" id="initial-loader" />
	</Box>
);

const Comp = DragDropContext(HTML5Backend)(App);

const AppWrapper = (props) => {
	const modalData = useModalStore((state) => {
		return state;
	}, shallow);
	const { palette } = useContentStore((state) => {
		return { palette: state.palette };
	}, shallow);
	return <Comp modals={modalData} palette={palette} {...props} />;
};

export default AppWrapper;
