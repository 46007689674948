import React from 'react';
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	FormLabel,
	IconButton,
	LinkBox,
	LinkOverlay,
	Card,
	CardBody,
	Text,
	Icon as ChakraIcon,
	Tooltip,
} from '@chakra-ui/react';

import HelpResources from '../../../resources/helpCenterResources';
import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import { shallow } from 'zustand/shallow';
import { mdiArrowRight, mdiHelp, mdiHelpCircleOutline, mdiBookOpenPageVariantOutline } from '@mdi/js';
import { Icon } from '@/components/gui/shared/Icon';
import styled from 'styled-components';

class HelpSection extends React.Component {
	toggleHelpSection = () => {
		this.props.setShowHelpSection(!this.props.showHelpSection);
	};

	renderHelpSection = () => {
		return (
			<Drawer size="sm" isOpen={this.props.showHelpSection} onClose={this.toggleHelpSection}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton display={['block', 'none', 'none', 'block']} />
					<DrawerHeader>Need help with Forms?</DrawerHeader>
					<DrawerBody>
						<Text variant="subtle">Here are some how-to articles to get you started.</Text>
						<HelpLinkContainer helpData={HelpResources.moosend.en.editor.drawerArticles} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		);
	};

	render() {
		return (
			<>
				<Tooltip label="Help">
					<IconButton
						aria-label="Help"
						onClick={this.toggleHelpSection}
						data-testid={'help-section-button'}
						variant="ghost"
						colorScheme="neutral"
						icon={<Icon path={mdiHelpCircleOutline} />}
					/>
				</Tooltip>
				{this.renderHelpSection()}
			</>
		);
	}
}

const HelpLinkContainer = (props) => {
	return (
		<Box my={5} d="flex" direction="column">
			{props.helpData.map((item, i) => {
				return <HelpLink key={item.url} {...item} withArrow={item.withArrow} />;
			})}
		</Box>
	);
};

const HelpLink = (props) => {
	return (
		<LinkBox
			as={Card}
			variant="filled"
			backgroundColor={props.isSupport ? 'primary-bg' : props.withArrow ? 'primary-bg' : undefined}
			mb="3"
			layerStyle="interactive.fill"
		>
			<CardBody
				display="flex"
				gap="6"
				data-testid={'help-section-button'}
				_hover={{ textDecoration: 'none' }}
				alignItems={'center'}
				{...props.boxProps}
			>
				{!props.withArrow && (
					<ChakraIcon layerStyle="icon.subtle" boxSize="icon.lg" color="neutral" m="-2">
						<path d={mdiBookOpenPageVariantOutline} />
					</ChakraIcon>
				)}

				<LinkOverlay target="_blank" href={props.url}>
					<Text fontWeight="medium">{props.label}</Text>
				</LinkOverlay>
				{props.withArrow && <Icon path={mdiArrowRight} />}
			</CardBody>
		</LinkBox>
	);
};

const HelpSectionWrapper = (props) => {
	const leftSidebarData = useLeftSidebarStore((state) => {
		return {
			accountCustomSettings: state.accountCustomSettings,
			showHelpSection: state.showHelpSection,
			setShowHelpSection: state.setShowHelpSection,
		};
	}, shallow);
	return <HelpSection {...leftSidebarData} {...props} />;
};

export default HelpSectionWrapper;
