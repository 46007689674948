import React from 'react';
import { Box } from '@chakra-ui/react';
import useContentStore from '../../../../stores/ContentStore';
import SharedInputSettings from './SharedInput-settings.react';

export const CheckboxComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class CheckboxSettings extends React.PureComponent {
	render() {
		return (
			<Box className="checkbox-settings">
				<CheckboxComponents
					{...this.props}
					onChange={this.onChange}
					settings={{
						placeholder: {
							active: false,
						},
						hiddenField: {
							active: false,
						},
						prefill: {
							active: false,
						},
						fieldGroup: {
							label: 'Checkbox styles',
							settings: {
								fieldBgColor: {
									label: 'Checkbox background color',
								},
								fieldFont: {
									active: false,
								},
								fieldFontSize: {
									active: true,
									label: 'Tick font size',
								},
								fieldFontColour: {
									label: 'Tick color',
								},
								fieldTextDecoration: {
									active: false,
								},
							},
						},
						labelGroup: {
							settings: {
								labelPosition: {
									active: false,
								},
								labelWidth: {
									active: false,
								},
							},
						},
						placeholderGroup: {
							active: false,
						},
					}}
				/>
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default CheckboxSettings;
