import { useMemo, useEffect, useState } from 'react';
import { EntityStatuses, manyActions, statusFilters } from '../strings';
import {
	Box,
	Text,
	Button,
	ButtonGroup,
	Skeleton,
	InputGroup,
	Input,
	InputLeftElement,
	IconButton,
	Tooltip,
	Fade,
	CloseButton,
	Tabs,
	TabList,
	Tab,
	MenuOptionGroup,
	MenuList,
	Badge,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import useUserStore from '@/stores/UserStore';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiMagnify, mdiArchiveOutline } from '@mdi/js';
import TableTagsModal from './TableTagsModal';

const SelectStyles = {
	valueContainer: (provided) => ({
		...provided,
		flexWrap: 'no-wrap',
		pr: 0,
	}),
	multiValue: (provided) => ({
		...provided,
		'&:not(:last-of-type):after': {
			content: '","',
		},
	}),
	multiValueRemove: (provided) => ({
		...provided,
		display: 'none',
	}),
};

function getFilteredManyActions(filters, activeOnlySelected, multipleTenantsExist) {
	return manyActions.filter((item) => {
		if (item.value === 'archive' && filters.includeArchived) return false;
		if (item.value === 'unarchive' && !filters.includeArchived) return false;
		if (item.value === 'publish' && activeOnlySelected) return false;
		if (item.value === 'copy' && (filters.includeArchived || !multipleTenantsExist)) return false;

		return true;
	});
}

export const TableFilters = (props) => {
	const [tagsModal, setTagsModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState(props.filters.name);
	const tenants = useUserStore((state) => state.tenants);

	const activeOnlySelected = useMemo(() => {
		let activeOnly = true;
		props.selectedEntities.forEach((item) => {
			if (item.Entity.Status !== EntityStatuses.active) {
				activeOnly = false;
			}
		});
		return activeOnly;
	}, [props.selectedEntitiesIds]);

	const debouncedSearch = useDebounce(searchTerm, 750);

	useEffect(() => onFilterChange({ dataLabel: 'name', value: searchTerm }), [debouncedSearch]);

	const { allTagsOptions, allTagsOptionValue } = useMemo(() => {
		const allTagsOptions = (props.allTags || []).map((value) => ({ label: value, value }));

		const allTagsOptionValue = (props.filters.tags || []).map((value) => ({ label: value, value }));

		return { allTagsOptions, allTagsOptionValue };
	}, [props.allTags, props.filters.tags]);

	const statusOptionValue = useMemo(
		() => (props.filters.status || []).map((value) => statusFilters.find((item) => item.value === value)),
		[props.filters.status],
	);

	const onFilterChange = (data) => {
		props.onFilterChange && props.onFilterChange(data);
	};

	const takeManyAction = (e, data) => {
		props.takeManyAction && props.takeManyAction(data.value);
	};

	const onSetManyTags = (e, data) => {
		if (data.value) {
			const remove = getTagStatus(data.value) === 'check';
			props.onSetManyTags && props.onSetManyTags(data.value, remove);
		}
	};

	const getTagStatus = (tag) => {
		let tagCount = 0;
		props.selectedEntities &&
			props.selectedEntities.forEach((item) => {
				item.Tags.forEach((tagItem) => {
					if (tagItem.Tag === tag) {
						tagCount += 1;
					}
				});
			});

		if (tagCount === props.selectedEntities.length) {
			return 'check';
		} else if (tagCount > 0 && tagCount < props.selectedEntities.length) {
			return 'remove';
		} else {
			return '';
		}
	};

	const onToggleArchivedClick = (e, archive) => {
		props.removeSelectedEntitiesIds();
		onFilterChange({ dataLabel: 'includeArchived', value: archive });
	};

	const renderManyActions = () => {
		const commonLabels = props.allTags.filter((item) => getTagStatus(item) === 'check');

		return (
			<>
				<Box pos="fixed" bottom={0} left={0} w="100%" zIndex={2}>
					<Fade in>
						<Box
							display="flex"
							alignItems="center"
							pos="absolute"
							bottom={16}
							left="50%"
							transform="translateX(-50%)"
							bgColor="primary"
							borderRadius="lg"
							paddingY={6}
							paddingX={7}
							maxW="4xl"
							boxShadow="md"
						>
							<Text alignSelf="center" mr={5} color="white" whiteSpace="nowrap" fontWeight={600}>
								{props.selectedEntities.length} selected:
							</Text>

							<ButtonGroup variant="ghost" colorScheme="whiteAlpha" ml="auto" spacing={2} size="sm" alignItems="center">
								{getFilteredManyActions(props.filters, activeOnlySelected, tenants.length > 1).map((item) => (
									<Button
										onClick={(e) => {
											if (item.value === 'tags') {
												setTagsModal(true);
											} else {
												takeManyAction(e, { dataLabel: '', value: item.value });
											}
										}}
										alignSelf="center"
										key={item.value}
										color="white"
										leftIcon={<Icon path={item.icon} />}
										data-testid={`many-actions-${item.value}`}
									>
										{item.label}
									</Button>
								))}

								<CloseButton size="sm" color="white" onClick={() => props.removeSelectedEntitiesIds()} />
							</ButtonGroup>
						</Box>
					</Fade>
				</Box>

				<TableTagsModal
					isOpen={tagsModal}
					onClose={() => setTagsModal(false)}
					allTags={props.allTags}
					tags={commonLabels}
					onAddTag={(e, tagValue) => onSetManyTags(e, { value: tagValue })}
					onRemoveTag={props.onSetManyTags && ((e, tagValue) => props.onSetManyTags(tagValue, true))}
					tagsLabel={!!commonLabels.length && 'Common labels'}
					inputPlaceholder="Add label"
				/>
			</>
		);
	};

	const renderDesktop = () => (
		<Box hideBelow="md">
			<Box h={['auto', 'auto', '80px', '80px']} alignItems="center" display={props.hideWrapper ? 'none' : 'flex'}>
				<Box display="flex" flex={1} flexDirection="row" alignItems="top">
					{props.hasSelectedEntities && renderManyActions()}

					<InputGroup maxW="sm" flex={1.5} mx={1.5}>
						<Input
							autoFocus
							placeholder="Search by name"
							data-testid="table-filter-input"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<InputLeftElement pointerEvents="none">
							<Icon path={mdiMagnify} />
						</InputLeftElement>
					</InputGroup>

					<Box minW="3xs" maxW="lg" mx={1.5}>
						<Select
							zIndex={10}
							useBasicStyles
							selectedOptionStyle="check"
							colorScheme="primary"
							isMulti
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder="Filter by status"
							value={statusOptionValue}
							options={statusFilters}
							onChange={(data) => {
								const value = data.map((item) => item.value);
								onFilterChange({ dataLabel: 'status', value: value.includes(undefined) ? [] : value });
							}}
						/>
					</Box>
					<Box minW="3xs" maxW="3xl" mx={1.5}>
						<Select
							zIndex={10}
							isLoading={props.loaders.tableFilters}
							useBasicStyles
							selectedOptionStyle="check"
							colorScheme="primary"
							isMulti
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder={props.allTags && props.allTags.length ? 'Filter by label' : 'No labels found'}
							isDisabled={!props.allTags?.length}
							value={allTagsOptionValue}
							options={allTagsOptions}
							onChange={(data) => onFilterChange({ dataLabel: 'tags', value: data.map((item) => item.value) })}
						/>
					</Box>

					<ButtonGroup
						variant="toggle"
						size="sm"
						border="1px solid"
						borderColor="chakra-border-color"
						rounded="full"
						p="0.1875rem"
						spacing="1"
						mx={1.5}
						maxH="40px"
					>
						<Button
							isActive={!props.filters.includeArchived}
							onClick={(e) => onToggleArchivedClick(e, false)}
							data-testid="not-archived-button"
						>
							Not archived
						</Button>
						<Button isActive={props.filters.includeArchived} onClick={(e) => onToggleArchivedClick(e, true)} data-testid="archived-button">
							Archived
						</Button>
					</ButtonGroup>
				</Box>
			</Box>
		</Box>
	);

	const renderMobile = () => (
		<Box hideFrom="md">
			<Filters active={props.filters.status || []} onClick={(data) => onFilterChange(data)} dataLabel="status" list={statusFilters} />

			<Box display="flex" alignItems="center" mb={2}>
				<SearchField onChange={(value) => onFilterChange({ dataLabel: 'name', value })} value={props.filters.name} />
				<IconButton
					variant="ghost"
					icon={<Icon path={mdiArchiveOutline} />}
					isActive={Boolean(props.filters.includeArchived)}
					ml={2}
					onClick={() => onFilterChange({ dataLabel: 'includeArchived', value: !props.filters.includeArchived })}
				/>
			</Box>
		</Box>
	);

	return (
		<>
			{renderDesktop()}
			{renderMobile()}
		</>
	);
};

export const Filters = ({ active, list, onClick, dataLabel }) => {
	return (
		<Box boxShadow="md" display={['flex', 'flex', 'none']} borderRadius="sm" mb={2}>
			{list.map((item, index) => {
				const isActive = item.value ? active.includes(item.value) : !active.length;

				return (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						flex={1}
						minH="7"
						fontSize="xs"
						key={`table-filter-${index}`}
						color={isActive ? 'chakra-inverse-text' : 'chakra-subtle-text'}
						fontWeight="semibold"
						onClick={() => onClick({ dataLabel, value: item.value ? [item.value] : [] })}
						bgColor={isActive ? 'primary' : 'chakra-body-bg'}
					>
						{item.label}
					</Box>
				);
			})}
		</Box>
	);
};

export const SearchField = ({ value, onChange, id, placeholder, containerStyle }) => {
	return (
		<InputGroup borderRadius="sm" display={['block', 'block', 'none']} {...containerStyle}>
			<Input placeholder={placeholder} id={id} value={value} onChange={(e) => onChange(e.target.value)} />
			<InputLeftElement pointerEvents="none">
				<Icon path={mdiMagnify} />
			</InputLeftElement>
		</InputGroup>
	);
};

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
};
