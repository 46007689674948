import React from 'react';
import { cloneDeep } from 'lodash';

//components
import TextInputSetting from '../../shared/settings/TextInputSetting.react';
import DropDownSetting from '../../shared/settings/DropDownSetting.react';
import ToggleButtonSetting from '../../shared/settings/ToggleButtonSetting.react';
import NumberSetting from '../../shared/settings/NumberSetting.react';
import AlignmentSetting from '../../shared/settings/AlignmentSetting.react';

import useContentStore from '../../../../stores/ContentStore';

import config from '../../../../../config/config';

import { Box, FormLabel, useChakra } from '@chakra-ui/react';
import { toggleButtonsSvg } from './SocialIconsSvgs.react';
import { SettingsBox } from '../../settingsContext/SettingsContext';

const SOCIAL_MEDIA_MAP = {
	facebook: 'Facebook',
	twitter: 'Twitter',
	instagram: 'Instagram',
	pinterest: 'Pinterest',
	youtube: 'YouTube',
	linkedin: 'LinkedIn',
	tumblr: 'Tumblr',
	soundcloud: 'SoundCloud',
	tiktok: 'TikTok',
	snapchat: 'Snapchat',
	custom: 'Custom',
};

export const SocialFollowSettingsFields = {
	background_color: 'background_color',
	type: 'type',
	style: 'style',
	spacing: 'spacing',
	alignment: 'alignment',
	facebook: 'facebook',
	twitter: 'twitter',
	instagram: 'instagram',
	googleplus: 'googleplus',
	linkedin: 'linkedin',
	pinterest: 'pinterest',
	tumblr: 'tumblr',
	youtube: 'youtube',
	soundcloud: 'soundcloud',
	tiktok: 'tiktok',
	snapchat: 'snapchat',
	custom: 'custom',
};

const AlignOptions = [
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'center',
		label: 'Center',
	},
	{
		value: 'right',
		label: 'Right',
	},
];

const SocialStyleOptions = config.social_follow_style_folder_names.map((folder) => {
	return { value: folder, label: folder.replace(/\-/g, ' ') };
});

export const SocialFollowSettingComponents = React.memo((props) => {
	const { theme } = useChakra();

	let onSocialToggle = React.useCallback((data) => {
		let newData = { ...data, value: data.value ? '' : null };
		props.onChange(newData);
	}, []);

	let icons = config.possible_social_follow_icons.map((icon, i) => {
		const label = SOCIAL_MEDIA_MAP[icon];
		return (
			<Box className={'social-follow-setting-icon ' + icon} key={i} display="flex" alignItems="center" mt={5}>
				<Box className="toggle-input" w="17%">
					<ToggleButtonSetting
						type={SocialFollowSettingsFields[icon]}
						svg={toggleButtonsSvg[icon](
							props[icon] != null || props[icon] != undefined ? theme.colors.primary[500] : theme.colors.blackAlpha[500],
						)}
						onChange={onSocialToggle}
						on={props[icon] != null || props[icon] != undefined}
						width="8"
						height="8"
					/>
				</Box>

				<Box className="url-input" w="83%" display={props[icon] != null || props[icon] != undefined ? 'block' : 'none'}>
					<TextInputSetting
						type={SocialFollowSettingsFields[icon]}
						onChange={props.onChange}
						text={props[icon] || ''}
						placeholder={`${label === 'Twitter' ? 'X' : label} URL`}
						validateURL={true}
						customStyle={{ customRoot: { mb: 0 } }}
					/>
				</Box>
			</Box>
		);
	});

	return (
		<SettingsBox blockId={props.blockId}>
			<NumberSetting
				type={SocialFollowSettingsFields.spacing}
				onChange={props.onChange}
				text={props.spacing}
				label="Spacing"
				ariaLabel="Number of pixels"
			/>
			<AlignmentSetting
				type={SocialFollowSettingsFields.alignment}
				onChange={props.onChange}
				selected={props.alignment}
				options={AlignOptions}
				label="Alignment"
			/>
			<DropDownSetting
				type={SocialFollowSettingsFields.style}
				onChange={props.onChange}
				selected={props.style || 'default'}
				label="Icon style"
				options={SocialStyleOptions}
			/>
			{!props.isMobileView && (
				<Box
					filterId="Social settings"
					className="icon-settings"
					label="Facebook, LinkedIn, Twitter, Instagram, Pinterest, YouTube, Tumblr, Button, Toggle"
				>
					<FormLabel>Social settings</FormLabel>
					{icons}
				</Box>
			)}
		</SettingsBox>
	);
});

class SocialFollowSettings extends React.PureComponent {
	render() {
		return (
			<Box className="social-follow-settings">
				<SocialFollowSettingComponents {...this.props} onChange={this.onChange} />
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default SocialFollowSettings;
