import React from 'react';
import Modal from '../../../gui/shared/modals/Modal.react';
import CheckBoxSetting from '../../../gui/shared/settings/CheckBoxSetting.react';
import { ModalDesigns, ModalTypes, Modals } from '../../../../util/resources';

//actions
import useModalStore from '../../../../stores/ModalStore';

import { Box, ModalBody, ModalFooter, Button, Text, Image } from '@chakra-ui/react';

const DontAskAgain = (props) => {
	return (
		<CheckBoxSetting
			styleProps={{ mr: 'auto', mb: 0 }}
			onClick={props.onToggleCheckbox}
			label="Don’t show this again"
			checked={props.dont_ask_again}
			type="dont_ask_again"
		/>
	);
};

class DontAsktModal extends React.PureComponent {
	// keyListener;

	constructor(props) {
		super(props);

		this.state = {
			dont_ask_again: props.data.dont_ask_again[props.data.type],
		};
	}

	// componentDidUpdate() {
	// 	if (this.props.show) {
	// 		window.addEventListener('keyup', this.keyListener);
	// 	} else {
	// 		window.removeEventListener('keyup', this.keyListener);
	// 	}
	// }

	// componentDidMount() {
	// 	this.keyListener = DomHelper.onKeyUpModalListener(this.ok, this.close);
	// }

	// componentWillUnmount() {
	// 	window.removeEventListener('keyup', this.keyListener);
	// }

	componentWillReceiveProps(nextProps) {
		this.setState({ dont_ask_again: nextProps.data.dont_ask_again[nextProps.data.type] });
	}

	render() {
		let { show, data } = this.props;

		let list =
			data.list &&
			data.list.map((item, i) => {
				return (
					<Text mb={1.5} as="li" key={i}>
						- {item}
					</Text>
				);
			});

		return (
			<Modal display={show} modal={this.props.data.modal || Modals.CONFIRM} modalType={ModalTypes.DONT_ASK} closeCallback={this.close}>
				<ModalBody>
					{data.message && <Text>{data.message}</Text>}

					<Box display="flex">
						<Box flex={1}>
							{data.message2 && <Text>{data.message2}</Text>}

							{list && list.length ? <Box>{list}</Box> : null}
						</Box>

						{data.image && (
							<Box flex={1}>
								<Image src={data.image} />
							</Box>
						)}
					</Box>
				</ModalBody>
				<ModalFooter>
					<DontAskAgain dont_ask_again={this.state.dont_ask_again} onToggleCheckbox={this.onToggleCheckbox} />

					{!this.props.data.ignoreCancelBtn && (
						<Button variant="outline" colorScheme="neutral" children={this.props.data.cancelLabel || 'Cancel'} onClick={this.close} />
					)}

					<Button
						colorScheme={this.props.data.modal === Modals.ERROR ? 'danger' : 'primary'}
						ml={5}
						children={this.props.data.okLabel || 'Yes'}
						onClick={this.ok}
					/>
				</ModalFooter>
			</Modal>
		);
	}

	ok = () => {
		this.props.data.onOk && this.props.data.onOk(this.state.dont_ask_again);
		useModalStore.getState().closeDontAskModal({ [this.props.data.type]: this.state.dont_ask_again });
	};

	close = () => {
		const stateCopy = { ...this.state };
		this.setState({ dont_ask_again: false });
		useModalStore.getState().closeDontAskModal({ [this.props.data.type]: false });
		this.props.data.onCancel && this.props.data.onCancel(stateCopy.dont_ask_again);
	};

	onToggleCheckbox = () => {
		this.setState({ dont_ask_again: !this.state.dont_ask_again });
	};
}

export default DontAsktModal;
