import React from 'react';
import { SketchPicker } from 'react-color';
import TextInputSetting from './TextInputSetting.react';

import { Box, Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react';

import useContentStore from '../../../../stores/ContentStore';

class ColorPickerSetting extends React.PureComponent {
	static defaultProps = {
		label: 'Color Picker',
		disabled: false,
	};

	render() {
		return (
			<Popover placement="bottom-end" onClose={this.onClose}>
				<PopoverContent w="initial">
					<SketchPicker
						ref="color_picker"
						onChangeComplete={this.onChange}
						color={this.props.selectedColor}
						presetColors={useContentStore.getState().content.usedColors || []}
					/>
				</PopoverContent>
				<TextInputSetting
					disabled={this.props.disabled}
					// customCss={{ mb: 10, ...this.props.inputCustomCss }}
					customStyle={this.props.inputCustomCss}
					text={this.props.selectedColor}
					label={this.props.label}
					placeholder={this.props.placeholder || this.props.label || undefined}
					onChange={this.onInputChange}
					renderColor={this.renderColor}
				/>
			</Popover>
		);
	}

	renderColor = () => {
		const colorStyles =
			this.props.selectedColor && this.props.selectedColor !== 'transparent'
				? { bgColor: this.props.selectedColor }
				: {
						bg: 'unset',
						bgColor: 'white',
						bgSize: '7px 7px',
						css: {
							backgroundImage: `linear-gradient(45deg, #000 25%, transparent 25%, transparent 50%, #000 50%, #000 75%, transparent 75%, #fff)`,
						},
					};

		return (
			<PopoverTrigger>
				<Box
					className="color-container"
					display="flex"
					alignItems="center"
					justifyContent="center"
					boxSize="5"
					cursor="pointer"
					boxShadow="rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px"
					data-testid="color-picker-indicator"
					{...colorStyles}
				/>
			</PopoverTrigger>
		);
	};

	onClose = (color) => {
		this.props.onOverlayClick && this.props.onOverlayClick();
		useContentStore.getState().addUsedColor(this.props.selectedColor);
	};

	onChange = (color) => {
		let value;

		const opacity = this.props.selectedColor === 'transparent' ? 1 : color.rgb.a;
		value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${opacity})`;

		this.props.onChange({ type: this.props.type, value: value.toLowerCase() });
	};

	onInputChange = ({ value }) => {
		let returnValue;

		if (this.props.hex && value.toLowerCase().includes('rgb')) {
			returnValue = this.rgbHex(value);
		} else {
			returnValue = value;
		}

		this.props.onChange({ type: this.props.type, value: returnValue.toLowerCase() });
	};

	rgbHex = (color) => {
		color = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		return color && color.length === 4
			? '#' +
					('0' + parseInt(color[1], 10).toString(16)).slice(-2) +
					('0' + parseInt(color[2], 10).toString(16)).slice(-2) +
					('0' + parseInt(color[3], 10).toString(16)).slice(-2)
			: '';
	};
}

export default ColorPickerSetting;
