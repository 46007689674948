import { init } from '@elastic/apm-rum';
import { interpolateString } from './helper';
import config from '../../config/config';

let apm = null;

const regions = ['euw', 'use', 'usw', 'jpe', 'aue', 'sg'];

const getDistributedTracingOrigins = () => {
	const services = [
		'https://forms-entities-{regionCode}.sitecorecloud.io',
		'https://forms-metadata-{regionCode}.sitecorecloud.io',
		'https://forms-blob-{regionCode}.sitecorecloud.io',
		'https://forms-publisher-{regionCode}.sitecorecloud.io',
	];

	const servicesWithRegions = regions
		.map((code) => {
			return services.map((svc) => interpolateString(svc, { regionCode: code }));
		})
		.flat();
	return servicesWithRegions;
};

const getApmEnv = () => {
	if (location.origin.includes('localhost')) {
		return 'local';
	} else {
		return import.meta.env.MODE;
	}
};
const getSampleRate = () => 0.1;

export const initApm = () => {
	apm = init({
		serviceName: 'xmc-forms-editor',
		serverUrl: 'https://0d08758518974b43a37df8bc68048289.apm.eu-west-1.aws.cloud.es.io:443',
		environment: getApmEnv(),
		transactionSampleRate: getSampleRate(),
		distributedTracingOrigins: getDistributedTracingOrigins(),
		breakdownMetrics: false,
		propagateTracestate: true,
		active: import.meta.env.MODE === 'production',
		disableInstrumentations: ['eventtarget'], // disabled click events until we have proper names in HTML elements
	});
	return apm;
};
