import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { debounce } from 'lodash';
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	InputGroup,
	Input,
	InputRightElement,
	Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiMagnify } from '@mdi/js';
import useXmcAppsStore from '@/stores/XmcAppsStore';
import BaseTable from '@/components/Table/components/BaseTable';
import { SitesTableLoader } from '../components/skeletons/SitesTableLoader';
import { EmptyPage } from '../components/EmptyPage';
import { useForceUpdate } from '@/util/useForceUpdate';

const orderCategories = {
	IsSelected: { filter: '', label: '' },
	NameAndId: { filter: 'Name', label: '' },
	Collection: { filter: 'Collection', label: '' },
	Path: { filter: 'Path', label: '' },
};

const orderTypes = [
	'NameAscending',
	'NameDescending',
	'CollectionAscending',
	'CollectionDescending',
	'PathAscending',
	'PathDescending',
	'',
];

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: '220px',
	}),
};

const selectionOptions = [
	{ label: 'Selected', value: true },
	{ label: 'Unselected', value: false },
];

const SitesContainer = () => {
	const prRef = useRef(null);
	const forceUpdate = useForceUpdate();

	// Unfortunately this is the only way to make sure that the ref required for Table Virtualization will be
	// assigned correctly by the time the IntersectionObserver is initialised
	useLayoutEffect(() => {
		forceUpdate();
	}, [prRef]);

	const [selectAll, setSelectAll] = useState(false);
	const [filters, setFilters] = useState({ collection: null, type: null, searchTerm: '', selection: null });
	const [order, setOrder] = useState(orderTypes[orderTypes.length - 1]);

	const { sites, noOfSelectedSites, loaders, searchSites, selectSiteAndSearch, selectAllSitesAndSearch } = useXmcAppsStore(
		(state) => ({
			sites: state.sites,
			noOfSelectedSites: state.sites.dataset.filter((site) => site.isSelected).length,
			loaders: state.loaders,
			searchSites: state.searchSites,
			selectSiteAndSearch: state.selectSiteAndSearch,
			selectAllSitesAndSearch: state.selectAllSitesAndSearch,
		}),
		shallow,
	);

	const debouncedSearchSites = useMemo(() => debounce(searchSites, 500), [searchSites]);

	const onFilterChange = (type, value) => {
		const newFilters = { ...filters, [type]: value ?? null };

		setFilters(newFilters);

		if (type === 'searchTerm') {
			debouncedSearchSites(newFilters, order);
		} else {
			searchSites(newFilters, order);
		}
	};

	const onChangeOrder = (newOrder) => {
		setOrder(newOrder);
		searchSites(filters, newOrder);
	};

	const onSelectSite = (idx, isSelected) => {
		selectSiteAndSearch(idx, isSelected, filters, order);
	};

	const onSelectAllSites = (isSelected) => {
		setSelectAll(isSelected);
		selectAllSitesAndSearch(isSelected, filters, order);
	};

	const headers = {
		IsSelected: {
			label: '',
			width: '1%',
			restHeaderProps: { onChange: (event) => onSelectAllSites(event.target.checked) },
			item: <Checkbox size="md" isChecked={selectAll} data-testid="sites-table-head-select-checkbox" />,
		},
		NameAndId: {
			label: 'Site name and ID',
			width: '33%',
			isSortable: true,
			restHeaderProps: { cursor: 'pointer' },
		},
		Collection: {
			label: 'Site collection',
			width: '33%',
			isSortable: true,
			restHeaderProps: { cursor: 'pointer' },
		},
		Path: {
			label: 'Path',
			width: '33%',
			isSortable: true,
			restHeaderProps: { cursor: 'pointer' },
		},
	};

	const columnData = sites.items.map((site) => ({
		IsSelected: {
			item: (
				<Checkbox
					isChecked={site.isSelected}
					onChange={() => {
						onSelectSite(site.idx, !site.isSelected);
					}}
					size="md"
				/>
			),
		},
		NameAndId: {
			item: (
				<>
					<Text fontSize={13}>{site.name}</Text>
					<Text fontSize={12} color="chakra-subtle-text">
						{site.id}
					</Text>
				</>
			),
		},
		Collection: {
			item: <Text fontSize={13}>{site.collection}</Text>,
		},
		Path: {
			item: <Text fontSize={13}>{site.path}</Text>,
		},
	}));

	const collectionOptions = useMemo(() => {
		return sites.collections.map((collection) => ({
			label: collection,
			value: collection,
		}));
	}, [sites.collections]);

	const typeOptions = useMemo(
		() =>
			sites.types.map((type) => ({
				label: type,
				value: type,
			})),
		[sites.types],
	);

	useEffect(() => {
		setSelectAll(sites.items.every((site) => site.isSelected));
	}, [sites.items]);

	return (
		<Box height="full" display="flex" flexDirection="column" gap={4}>
			{Boolean(sites.dataset.length) && (
				<Box display="flex" alignItems="center" justifyContent="start" gap={4}>
					<InputGroup flex={1} maxW="340px">
						<Input
							autoFocus
							placeholder="Search by name"
							value={filters.searchTerm}
							onChange={(e) => {
								onFilterChange('searchTerm', e.target.value);
							}}
						/>
						<InputRightElement pointerEvents="none">
							<Icon path={mdiMagnify} />
						</InputRightElement>
					</InputGroup>

					<Select
						placeholder="Filter by collection"
						isClearable
						chakraStyles={selectStyles}
						options={collectionOptions}
						onChange={(data) => {
							onFilterChange('collection', data?.value);
						}}
						value={filters.collection ? collectionOptions.find((option) => option.value === filters.collection) : null}
						useBasicStyles
					/>

					<Select
						placeholder="Filter by type"
						isClearable
						chakraStyles={selectStyles}
						options={typeOptions}
						onChange={(data) => {
							onFilterChange('type', data?.value);
						}}
						value={filters.type ? typeOptions.find((option) => option.value === filters.type) : null}
						useBasicStyles
					/>

					<Select
						placeholder="Filter by selection"
						isClearable
						chakraStyles={selectStyles}
						options={selectionOptions}
						onChange={(data) => {
							onFilterChange('selection', data?.value);
						}}
						value={selectionOptions.find((option) => option.value === filters.selection)}
						useBasicStyles
					/>
				</Box>
			)}

			<Box overflow="auto" ref={prRef}>
				{(Boolean(sites.dataset.length) && Boolean(sites.items.length)) || loaders.sites.table ? (
					<BaseTable
						headers={headers}
						data={columnData}
						order={order}
						orderByTypes={orderTypes}
						orderCategories={orderCategories}
						defaultOrderBy={orderTypes[orderTypes.length - 1]}
						changeOrder={onChangeOrder}
						loaders={{ table: loaders.sites.table, tableDataUpdate: loaders.sites.tableDataUpdate }}
						skeletonComponent={<SitesTableLoader />}
						isVirtualized
						parentRef={prRef}
						data-testid="site-management-table"
					/>
				) : Boolean(sites.dataset.length) ? (
					<Text my={8} textAlign="center" fontSize="xl">
						No sites match your criteria
					</Text>
				) : (
					<EmptyPage title="No sites yet" />
				)}
			</Box>

			{Boolean(sites.items.length) && (
				<Box display="flex" alignItems="center" gap={4} data-testid="site-management-table-actions">
					<Text fontWeight={'semibold'}>
						{noOfSelectedSites === 0
							? 'All sites'
							: `${noOfSelectedSites} out of ${sites.dataset.length} ${sites.dataset.length > 1 ? 'sites' : 'site'}`}
					</Text>
				</Box>
			)}
		</Box>
	);
};

export const SitesDrawer = ({ isOpen, showFooter, onClose, onSave }) => (
	<Drawer isOpen={isOpen} onClose={onClose} size="full">
		<DrawerOverlay />
		<DrawerContent>
			<DrawerCloseButton />
			<DrawerHeader>Select the sites where the form is available</DrawerHeader>
			<DrawerBody>{isOpen && <SitesContainer />}</DrawerBody>
			{showFooter && (
				<DrawerFooter>
					<ButtonGroup data-testid="site-management-drawer-actions">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button onClick={onSave}>Save</Button>
					</ButtonGroup>
				</DrawerFooter>
			)}
		</DrawerContent>
	</Drawer>
);
