export const helpCenter = {
	editor: {
		drawerArticles: [
			{
				label: 'Work with Forms',
				url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/work-with-forms.html',
			},
			{
				label: 'Create a form',
				url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/create-a-form.html',
			},
			{ label: 'Designing forms', url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/designing-forms.html' },
			{
				label: 'Work with webhooks',
				url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/work-with-webhooks.html',
			},
			{
				label: 'Activate a form',
				url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/activate-a-form.html',
			},
			{ label: 'Learn more about Forms', url: 'https://doc.sitecore.com/xmc/en/users/xm-cloud/sitecore-xm-cloud.html', withArrow: true },
			{ label: 'Submit support ticket', url: 'https://support.sitecore.com/csm', withArrow: true, isSupport: true },
		],
		title: 'Need help?',
		description: 'Our reading material might be just what you need!',
	},
};
