export const getMediaLibrarySearchQuery = (pageIndex = 0, searchTerm = '*') =>
	`query {
  search(
    query: {
      index: "sitecore_master_index"
      paging: { pageSize: 16, skip: ${pageIndex} }
      searchStatement: {
        criteria: [
          { criteriaType: CONTAINS, operator: MUST, field: "_name", value: "${searchTerm}" }
          {
            operator: MUST
            field: "_path"
            value: "3d6658d8a0bf4e75b3e2d050fabcf4e1"
          }
          {
            operator: SHOULD
            field: "_template"
            value: "f1828a2c7e5d4bbd98ca320474871548"
          }
          {
            operator: SHOULD
            field: "_template"
            value: "c97ba92380094858bdd5d8Be5fccecf7"
          }
          {
            operator: SHOULD
            field: "_template"
            value: "daf085e8602e43a68299038ff171349f"
          }
          {
            operator: SHOULD
            field: "_template"
            value: "eb3fb96cd56b4ac997f8f07b24bb9bf7"
          }
        ]
      }
      sort: {
      	field: "_name"
      	direction: ASCENDING
      }
    }
  ) {
    totalCount
    results {
      itemId
      name
      innerItem {
        template {
          templateId
        }
      }
    }
  }
}
`;

export const itemDetailsQuery = `
      query GetMediaData($input: MediaItemQueryInput!) {
          mediaItem(where: $input) @include(if: true) {
            url(options: { alwaysIncludeServerUrl: true, thumbnail: true })
            fullImageUrl: url(options: { alwaysIncludeServerUrl: true })
            alt
            extension
            size
            title
            innerItem {
              displayName
            }
          }          
        }
  `;
