import React from 'react';
//components
import NumberSetting from '../../shared/settings/NumberSetting.react';

import useContentStore from '../../../../stores/ContentStore';
import { ElementLimits, AllComponentTypes } from '../../../../util/resources';

import { Box } from '@chakra-ui/react';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import SharedInputSettings from './SharedInput-settings.react';

export const EmailInputSettingComponents = React.memo((props) => {
	return <SharedInputSettings {...props} />;
});

class EmailInputSettings extends React.PureComponent {
	render() {
		return (
			<Box className="email-input-settings">
				<EmailInputSettingComponents {...this.props} onChange={this.onChange} settings={{ pattern: { active: true } }} />
			</Box>
		);
	}

	onChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};
}

export default EmailInputSettings;
