import React from 'react';

import { Editor } from '@tinymce/tinymce-react';

import useTextEditorStore from '../../../../stores/TextEditorStore';
import useContentStore from '../../../../stores/ContentStore';

import config from '../../../../../config/config';

import useModalStore from '../../../../stores/ModalStore';

import { shallow } from 'zustand/shallow';

let isMobileView = false;

export const ToolbarOptions = `newdocument cut copy pastetext outdent indent link unlink openlink anchor numlist bullist blockquote tags alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor lineheight blocks fontfamily fontsize spellchecker code`;

export const MobileToolbarOptions = `numlist bullist outdent indent blockquote alignleft alignright aligncenter alignjustify bold italic strikethrough underline removeformat forecolor backcolor fontfamily fontsize`;

export class TextEdit extends React.PureComponent {
	editor = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			shouldRender: this.props.isDraggingElement ? false : true,
			toolbarOptions: this.props.customToolbar ? this.props.customToolbar : this.props.isMobileView ? MobileToolbarOptions : ToolbarOptions,
		};
	}

	componentDidMount() {
		isMobileView = this.props.isMobileView;
	}

	componentDidUpdate(prevProps) {
		isMobileView = this.props.isMobileView;

		if (prevProps.fontNames !== this.props.fontNames) {
			// this.setState({ toolbarCategory: 'content' });
			// reset editor
			this.setState({ shouldRender: false }, () => {
				this.setState({ shouldRender: true });
			});
		}

		if (prevProps.activeElement !== this.props.activeElement) {
			if (!this.props.activeElement) {
				this.setState({ shouldRender: false }, () => {
					this.setState({ shouldRender: true });
				});
			}
		}

		if (
			this.props.isDraggingElement &&
			this.props.isDraggingElement === this.props.id &&
			prevProps.isDraggingElement !== this.props.isDraggingElement
		) {
			this.setState({ shouldRender: false });
		} else if (!this.props.isDraggingElement && prevProps.isDraggingElement !== this.props.isDraggingElement) {
			setTimeout(() => {
				this.setState({ shouldRender: true });
			}, 300);
		}
	}

	render() {
		if (!this.state.shouldRender) {
			return (
				<div
					style={{ color: !this.props.text && 'gray' }}
					dangerouslySetInnerHTML={{ __html: this.props.text || 'Type your text here.' }}
				/>
			);
		}
		return <div id={`${this.props.id}-text-editor-wrapper`}>{this.state.shouldRender && this.renderEditor(this.state.toolbarOptions)}</div>;
	}

	renderEditor = (toolbar) => {
		const { general_styles } = this.props;

		const initialStyles = general_styles
			? `
			#${this.props.id}-text-editor-wrapper { font-family: ${general_styles.font_family}; color: ${general_styles.color}; line-height: ${
				general_styles.text_line_height
			}; font-size: ${parseInt(general_styles.font_size, 10)}px }
			`
			: `#${this.props.id}-text-editor-wrapper { font-family: Arial, Helvetica, sans-serif; color: #000000 }`;

		return (
			<TextEditor
				className="notranslate"
				id={`${this.props.id}-texteditor`}
				apiKey={config.tinymce.apiKey}
				inline={this.props.inline ?? true}
				disabled={this.props.isDraggingRow || this.props.isDraggingElement}
				onInit={(editor) => {
					this.editor.current = editor;
				}}
				init={{
					paste_block_drop: true,
					placeholder: 'Type your text here.',
					text_patterns: [],
					content_style: `
						${initialStyles} p { margin: 0 } em, strong, .mce-spellchecker-word {font-family: inherit}
					`,
					font_size_formats: '8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px 48px 72px',
					font_family_formats: this.props.fontNames,
					browser_spellcheck: true,
					contextmenu: false,
					relative_urls: false,
					remove_script_host: false,
					spellchecker_active: false,
				}}
				onBlur={this.onBlur}
				onPaste={this.onPaste}
				onFocus={this.onFocus}
				value={this.props.text}
				onEditorChange={this.onEditorChange}
				onKeyDown={this.onKeyDown}
				toolbar={toolbar}
				plugins={[
					'lists',
					'tinymcespellchecker',
					'anchor',
					'autolink',
					'autoresize',
					'charmap',
					'code',
					'directionality',
					'emoticons',
					'fullscreen',
					'help',
					'insertdatetime',
					'link',
					'nonbreaking',
					'preview',
					'searchreplace',
					'table',
					'tableofcontents',
					'powerpaste',
					'visualblocks',
					'wordcount',
				]}
			/>
		);
	};

	onKeyDown = (e) => {
		if (this.props.isMobileView) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}
	};

	onEditorChange = (value) => {
		this.props.onChange(value);
	};

	onBlur = (e) => {
		const value = this.props.text;

		this.props.changeCanDrag && this.props.changeCanDrag(true);
	};

	onFocus = () => {
		setTimeout(() => {
			this.editor.current.focus();
		}, 250);

		this.props.changeCanDrag && this.props.changeCanDrag(false);
	};

	checkCustomFonts = (html) => {
		const foundFonts = [];

		const wrapper = document.createElement('div');
		wrapper.innerHTML = html;
		const elements = Array.from(wrapper.querySelectorAll('*'));
		elements &&
			elements.length &&
			elements.forEach((item) => {
				if (item.style.fontFamily) {
					const customFonts = [...this.props.customFonts];
					const font = customFonts.find((customFont) => {
						return customFont.value === item.style.fontFamily.replace(/"/g, '');
					});
					font && foundFonts.push(font);
				}
			});

		return foundFonts;
	};

	onPaste = (e) => {
		if (this.props.isMobileView) {
			e.preventDefault();
			return;
		}
	};
}

export const TextEditor = (props) => {
	return (
		<Editor
			{...props}
			onInit={(evt, editor) => props.onInit && props.onInit(editor)}
			init={{
				...props.init,
				menubar: props.menubar,
				selector: props.selector,
				icons_url: 'assets/tinymce/icons/blok/icons.js',
				icons: 'blok',
			}}
		/>
	);
};

TextEditor.defaultProps = {
	// className: '',
	disabled: false,
	init: {},
	inline: false,
	menubar: false,
};

const TextEditWrapper = (props) => {
	const { custom_fonts, font_names } = useTextEditorStore((state) => {
		return { custom_fonts: state.custom_fonts, font_names: state.font_names };
	}, shallow);
	const { isDraggingRow, isDraggingElement } = useContentStore((state) => {
		return {
			isDraggingRow: state.isDraggingRow,
			isDraggingElement: state.isDragging,
		};
	}, shallow);
	return (
		<TextEdit
			customFonts={custom_fonts}
			isDraggingRow={isDraggingRow}
			isDraggingElement={isDraggingElement}
			fontNames={font_names}
			{...props}
		/>
	);
};

export default TextEditWrapper;
