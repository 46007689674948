import React from 'react';

import { EntitiesRoutes } from '../strings';

import { Route, Router, browserHistory } from 'react-router';

import { TableTopBar } from '../components/TableTopBar';
import TableContainer from './TableContainer';
import WebhooksContainer from './WebhooksContainer';
import StylingEdit from './StylingEdit';
import StylingList from './StylingList';
import NotificationPopup from '../../gui/layout/modals/NotificationPopup.react';
import GeneralModal from '../../gui/layout/modals/GeneralModal.react';
import useModalStore from '../../../stores/ModalStore';
import { ModalTypes } from '../../../util/resources';
import { Box } from '@chakra-ui/react';

const routes = [
	{ path: EntitiesRoutes.formList, component: TableContainer },
	{ path: EntitiesRoutes.webhook, component: WebhooksContainer },
	{ path: EntitiesRoutes.stylingList, component: StylingList },
	{ path: EntitiesRoutes.stylingEdit, component: StylingEdit },
];

export const TableRoot = () => {
	const generalModal = useModalStore((state) => state[ModalTypes.GENERAL_MODAL]);
	return (
		<>
			<TableTopBar />
			<NotificationPopup />
			<GeneralModal show={generalModal.show} data={generalModal.data} />
			<Box h="full" overflowX="hidden" overflowY="auto">
				<Router history={browserHistory} routes={routes}></Router>
			</Box>
		</>
	);
};

export default TableRoot;
