import { useEffect, useRef, memo, useState } from 'react';

import {
	Box,
	Image,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
	FormControl,
	FormLabel,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Button,
} from '@chakra-ui/react';

import { getBoxShadowValue } from '../../../../util/helper';
import ColorPickerSetting from './ColorPickerSetting.react';

import { settingsLabelStyle } from './Settings.styles';
import { createPortal } from 'react-dom';

const ShadowsSettings = ({ onChange, selectedValue, label }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	return (
		<Box position="relative" mb={5}>
			<Text {...settingsLabelStyle} mb={1}>
				{label}
			</Text>
			<Button size="sm" variant="outline" colorScheme="primary" onClick={onOpen}>
				Set box shadow
			</Button>

			<ShadowsSettingsModal
				isOpen={isOpen}
				onClose={onClose}
				onApply={(data) => onChange({ type: 'box_shadow', value: data })}
				selectedValue={selectedValue}
			/>
		</Box>
	);
};

const SimpleSlider = ({ label, value, min, max, onChange }) => (
	<FormControl mb={4}>
		<FormLabel {...settingsLabelStyle}>{label}</FormLabel>

		<Slider aria-label={label} value={value} min={min} max={max} size="lg" onChange={onChange}>
			<SliderTrack>
				<SliderFilledTrack />
			</SliderTrack>
			<SliderThumb />
		</Slider>
	</FormControl>
);

export const ShadowsSettingsModal = memo(({ onApply, selectedValue, isOpen, onClose }) => {
	const [settings, setSettings] = useState({
		offsetx: 1,
		offsety: 1,
		blur: 10,
		spread: 1,
		color: '#000',
	});

	useEffect(() => {
		const { offsetx, offsety, blur, spread, color } = selectedValue;
		setSettings({ offsetx, offsety, blur, spread, color });
	}, [isOpen]);

	const onChange = (type, value) => setSettings({ ...settings, [type]: value });

	const targetElement = document.getElementById('close-stuff');

	const settingModal = (
		<Modal size="4xl" onClose={onClose} isOpen={isOpen}>
			<ModalOverlay className="shadow-setting-overlay" />

			<ModalContent>
				<ModalHeader>Set box shadow</ModalHeader>
				<ModalCloseButton />

				<ModalBody display="flex">
					<Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" mt={-5}>
						<Box
							boxSize="48"
							borderRadius="md"
							borderWidth={1}
							borderStyle="solid"
							borderColor="chakra-border-color"
							boxShadow={getBoxShadowValue(settings.offsetx, settings.offsety, settings.blur, settings.spread, settings.color)}
						/>
					</Box>

					<Box w="30%">
						<SimpleSlider
							label="Horizontal length"
							min={-100}
							max={100}
							value={settings.offsetx}
							onChange={(value) => onChange('offsetx', value)}
						/>

						<SimpleSlider
							label="Vertical length"
							min={-100}
							max={100}
							value={settings.offsety}
							onChange={(value) => onChange('offsety', value)}
						/>

						<SimpleSlider label="Blur radius" min={0} max={100} value={settings.blur} onChange={(value) => onChange('blur', value)} />

						<SimpleSlider
							label="Spread radius"
							min={-50}
							max={50}
							value={settings.spread}
							onChange={(value) => onChange('spread', value)}
						/>

						<Box mb={5}>
							<ColorPickerSetting
								type="color"
								onChange={(data) => onChange(data.type, data.value)}
								selectedColor={settings.color || 'transparent'}
								label="Color"
							/>
						</Box>
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							onApply(settings);
							onClose();
						}}
					>
						Apply
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	if (!targetElement) {
		return settingModal;
	}

	return createPortal(settingModal, targetElement);
});

export default memo(ShadowsSettings);
