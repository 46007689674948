import React from 'react';

class DraggableElementPreview extends React.PureComponent {
	render() {
		let style = {
			width: 150,
			height: 30,
			border: '2px dashed grey',
		};

		return <div style={style} />;
	}
}

export default DraggableElementPreview;
