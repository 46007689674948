import * as React from 'react';
import { createRoot } from 'react-dom/client';
import Router from './routes';
import { loadInitialData, loadInitialMoosendData } from './util/apiUtils/initialData';
import * as Application from './application';
import config, { importedConfig, setupRegionBasedConfig } from '../config/config';
import DomHelper from './util/domHelper';
// import './util/logger';
import { initApm } from './util/apm';
import {
	checkAuthentication,
	claimsKeys,
	createClient,
	getTenant,
	handleRedirectCb,
	resolveAndSetRegionCode,
	setRegionCode,
	setUserData,
} from './util/auth/auth';

export const runEditor = async () => {
	let allDraftsMetadata, moosendData;

	try {
		let overlay = document.querySelector('#initial-overlay');
		const messageDiv = overlay && overlay.querySelector('#initial-error');
		messageDiv && (messageDiv.innerHTML = '');

		overlay && (overlay.style.display = 'flex');

		importedConfig.query = Application.fetchQueryStringConfig(); //add query string data to the imported config

		/* istanbul ignore next */
		if (import.meta.env.MODE !== 'test') {
			allDraftsMetadata = await loadInitialData();

			moosendData = await loadInitialMoosendData(allDraftsMetadata); //no await here, because this does not have to block
		}

		Application.afterInitialDataLoad();
	} catch (error) {
		console.log(error);
		DomHelper.handleInitialErrors(error);
	}
};

export const runMain = async () => {
	const apm = initApm();

	// Next lines deal with authentication, always needs to be the first thing that runs on the app
	let auth = await createClient();

	const redir = await handleRedirectCb(auth); // has side effects

	const isAuthenticated = await checkAuthentication(auth); // has side effects

	if (!isAuthenticated) return;

	const user = await setUserData(auth); // has side effects
	const regionCode = await resolveAndSetRegionCode(auth); // has side effects

	setupRegionBasedConfig({ regionCode }); // has side effects, interpolates {regionCode} in all string properties in the config object with the right region code

	apm.setUserContext({
		id: user.sub,
		email: user.email,
	});
	const root = createRoot(document.getElementById('react-content'));
	root.render(<Router />);
};

runMain();
