import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import { TestId } from '../../../../test/types';

interface IIconProps extends IconProps, TestId {
	path: string;
	className?: string;
	size?: number;
}

export const Icon: React.FC<IIconProps> = ({ path, size, dataTestId, className, ...rest }) => (
	<ChakraIcon className={className} color="currentColor" boxSize={size ?? 'icon.default'} data-testid={dataTestId} {...rest}>
		<path d={path} />
	</ChakraIcon>
);
