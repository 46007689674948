import React from 'react';
import { AllComponentTypes, SpecialComponentTypes } from '../../../util/resources';
import Spacer from './Spacer.react';
import Text from './Text.react';
import Image from './Image.react';
import Button from './Button.react';
import SocialFollow from './SocialFollow.react';
import EmailInput from './EmailInput.react';
import NumberInput from './NumberInput.react';
import DateInput from './DateInput.react';
import TextInput from './TextInput.react';
import PhoneInput from './PhoneInput.react';
import LongTextInput from './LongTextInput.react';
import Checkbox from './Checkbox.react';
import CheckboxGroup from './CheckboxGroup.react';
import RadioGroup from './Radio.react';
import SingleSelect from './SingleSelect.react';
import MultiSelect from './MultiSelect.react';
import GDPRbox from './GDPRbox.react';
import Recaptcha from './Recaptcha.react';
import FileUpload from './FileUpload';

import { findRow, getElementById, pathPrefix } from '../../../util/helper';

import useContentStore from '../../../stores/ContentStore';

class Slot extends React.PureComponent {
	render() {
		const { padding, multiPadding } = this.props;

		const deductPadding = multiPadding && multiPadding.allow ? multiPadding.paddingRight + multiPadding.paddingLeft : padding * 2;
		const slotWidth = this.props.slotWidth - deductPadding - this.props.borderWidth * 2 || 0;

		let components = [];

		if (this.props.components) {
			components = this.props.components.map((component, i) => {
				if (component.hidden) {
					return null;
				}

				component = {
					...component,
					slot_type: this.props.type,
					general_styles: this.props.general_styles,
					slotWidth,
					pageIndex: this.props.pageIndex,
					lastPage: this.props.lastPage,
					entityId: this.props.entityId,
				};

				switch (component.type) {
					case SpecialComponentTypes.fieldPlaceholder:
						return null;
					case AllComponentTypes.spacer:
						return <Spacer key={i} {...component} />;
					case AllComponentTypes.text:
						return <Text key={i} {...component} />;
					case AllComponentTypes.social_follow:
						return <SocialFollow key={i} {...component} />;
					case AllComponentTypes.image:
						return <Image key={i} {...component} />;
					case AllComponentTypes.submit_button:
						return <Button key={i} {...component} />;
					case AllComponentTypes.phone_input:
						return <PhoneInput key={i} {...component} />;
					case AllComponentTypes.email_input:
						return <EmailInput key={i} {...component} />;
					case AllComponentTypes.text_input:
						return <TextInput key={i} {...component} />;
					case AllComponentTypes.longtext_input:
						return <LongTextInput key={i} {...component} />;
					case AllComponentTypes.number_input:
						return <NumberInput key={i} {...component} />;
					case AllComponentTypes.date_input:
						return <DateInput key={i} {...component} />;
					case AllComponentTypes.radio:
						return <RadioGroup key={i} {...component} />;
					case AllComponentTypes.checkbox:
						return <Checkbox key={i} {...component} />;
					case AllComponentTypes.checkbox_group:
						return <CheckboxGroup key={i} {...component} />;
					case AllComponentTypes.singleselect_input:
						return <SingleSelect key={i} {...component} />;
					case AllComponentTypes.multiselect_input:
						return <MultiSelect key={i} {...component} />;
					case AllComponentTypes.gdpr:
						return <GDPRbox key={i} {...component} />;
					case AllComponentTypes.recaptcha:
						return <Recaptcha key={i} {...component} />;
					case AllComponentTypes.file_upload:
						return <FileUpload key={i} {...component} />;
					default:
						throw new Error(component.type, `Component does not exist`);
				}
			});
		}

		const id = this.props.id ? this.props.id.toString().replace(/-/g, '') : '';

		return (
			<div
				className={`landing-page-slot lp-flex-container lp-flex-direction-vertical slot${id} ${this.props.type} ${
					this.props.responsive ? 'responsive-slot' : ''
				} `}
				id={id}
				data-structure-type="slot"
			>
				<div className={`slot-container lp-flex-container lp-flex-direction-vertical lp-ie-flex-shrink`}>{components}</div>
			</div>
		);
	}
}

export default Slot;
