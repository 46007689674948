import React from 'react';
import { Box, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { Icon } from '@/components/gui/shared/Icon';
import useModalStore from '@/stores/ModalStore';
import { mdiArrowRight } from '@mdi/js';
import Moment from 'moment';
import { browserHistory } from 'react-router';

import useLeftSidebarStore from '../../../stores/LeftSidebarStore';
import useContentStore from '../../../stores/ContentStore';
import { showNotification } from '../../../stores/NotificationStore';
import useTemplateStore from '../../../stores/TemplateStore';

import { NotificationTypes } from '../../../util/resources';
import { checkBoolean, pathPrefix } from '../../../util/helper';
import { ErrorMessages, ModalMessages } from '../../../../config/messages';

export class DraftsMenu extends React.PureComponent {
	render() {
		let drafts = this.props.draftsMetadata.map((draftMetadata, key) => {
			return (
				<Draft
					current={draftMetadata.id === this.props.latestDraft.id}
					draftMetadata={draftMetadata}
					key={key}
					setDraftAsContent={this.setDraftAsContent}
					templates={this.props.templates}
				/>
			);
		});

		return (
			<Box
				w="sm"
				py={3}
				display="flex"
				flexDirection="column"
				gap={3}
				borderRight="1px solid"
				borderRightColor="chakra-border-color"
				className="left-sidebar-menu"
				backgroundColor="chakra-subtle-bg"
			>
				<Text px={3} fontSize="lg" fontWeight="semibold">
					Versions
				</Text>
				<Box flex={1} px={3} overflowY="auto" display="flex" flexDirection="column" gap={3}>
					{drafts}
				</Box>
			</Box>
		);
	}

	setDraftAsContent = (draftMetadata) => {
		const onOk = () => {
			const errorCb = () => showNotification({ type: NotificationTypes.ERROR, text: ErrorMessages.DRAFT_NOT_LOADED });
			useContentStore.getState().setDraftAsContentJson(null, draftMetadata, null, errorCb);
			browserHistory.push(pathPrefix() + location.search);
		};

		useModalStore.getState().showGeneralModal({
			title: 'Restore version?',
			message: ModalMessages.RESTORE_DRAFT,
			onOk,
			id: draftMetadata?.id,
			okLabel: 'Restore',
		});
	};
}

const Draft = ({ draftMetadata, setDraftAsContent, templates, current }) => {
	const draftTemplateId = draftMetadata.fromTemplateId;
	const draftUserName = draftMetadata.fromUser;

	// * 1000 here because js unix timestamps are in milliseconds
	let dateTitle = Moment(parseInt(draftMetadata.dateSaved) * 1000).format(`MMM, DD HH:mm`); // September 27th 2016,

	let templateData = draftTemplateId && templates.find((template) => template.id === draftTemplateId);
	let templateName = templateData ? templateData.title : 'Draft Template';

	return (
		<Box
			flexGrow={0}
			flexShrink={0}
			flexBasis={16}
			px={3}
			display="flex"
			border="1px solid"
			borderColor={current ? 'primary' : 'chakra-border-color'}
			borderRadius="base"
			backgroundColor="white"
		>
			<Box flex={1} display="flex" flexDirection="column" justifyContent="space-evenly">
				{draftTemplateId && (
					<Text fontSize="xs" fontWeight="semibold">
						From template: {templateName}
					</Text>
				)}
				<Text fontWeight="medium">
					{dateTitle}&nbsp;{checkBoolean(draftMetadata.autosave) ? '(Autosave)' : ''}
				</Text>
				{draftUserName && <Text variant="small">From: {draftUserName}</Text>}
			</Box>
			<Box h="full" display="flex" alignItems="center">
				<Tooltip label="Select version">
					<IconButton
						aria-label="Select version"
						variant="ghost"
						colorScheme="neutral"
						size="sm"
						icon={<Icon path={mdiArrowRight} />}
						data-testid="select-version"
						onClick={() => setDraftAsContent(draftMetadata)}
					/>
				</Tooltip>
			</Box>
		</Box>
	);
};

const DraftsMenuWrapper = (props) => {
	const templatesMetadata = useTemplateStore((state) => state.templatesMetadata);
	const versions = useLeftSidebarStore((state) => state.versions);
	return <DraftsMenu templates={templatesMetadata} {...versions} {...props} />;
};

export default DraftsMenuWrapper;
