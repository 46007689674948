import { Card, CardBody, Image, Text, IconButton, Tooltip } from '@chakra-ui/react';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiTrashCanOutline } from '@mdi/js';

export const DraggableBoxItem = ({ className, iconSrc, onRemove, labels = [], boxProps = {} }) => {
	return (
		<>
			<Card variant="flat" size="sm" layerStyle="interactive.raise" {...boxProps} h="20" cursor="move" className={className}>
				<CardBody display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" position="relative" gap="2">
					<Image src={iconSrc} boxSize="6" />
					{labels.filter(Boolean)?.length &&
						labels.filter(Boolean).map((label, idx) => (
							<Text
								key={label}
								display="block"
								w="100%"
								overflow="hidden"
								whiteSpace="nowrap"
								textOverflow="ellipsis"
								title={label}
								textAlign="center"
								fontSize="xs"
							>
								{label}
							</Text>
						))}

					{typeof onRemove === 'function' && (
						<Tooltip label="Delete">
							<IconButton
								variant="ghostColorOnHover"
								colorScheme="danger"
								color="chakra-placeholder-color"
								aria-label="Delete"
								size="xs"
								onClick={onRemove}
								position="absolute"
								icon={<Icon path={mdiTrashCanOutline} />}
								top={1}
								right={1}
								data-testid="draggable-box-item-remove-btn"
							/>
						</Tooltip>
					)}
				</CardBody>
			</Card>
		</>
	);
};
