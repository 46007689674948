import React, { memo } from 'react';

import { Box, Text } from '@chakra-ui/react';

import useContentStore from '../../../../stores/ContentStore';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import NumberSetting from '../../shared/settings/NumberSetting.react';
import DropDownSetting from '../../shared/settings/DropDownSetting.react';
import MultiSelectWithTagsSetting from '../../shared/settings/MultiSelectWithTagsSetting';

import { DefaultAcceptedFileTypes } from '@/components/transformer/landingPage/FileUpload';
import SharedInputSettings from './SharedInput-settings.react';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';
import { UnitsConfig } from '@/util/resources';
import { convertSize } from '@/util/helper';

const MaxFileSize = convertSize(10, 'MB', 'Bytes');
const MaxFiles = 10;

const fileUploadUnitOptions = Object.keys(UnitsConfig)
	.filter((unit) => UnitsConfig[unit].enabled)
	.map((unit) => ({ label: unit, value: unit }));

const acceptedFileTypeOptions = [
	'.csv',
	'.doc',
	'.docx',
	'.ics',
	'.jpg, .jpeg',
	'.json',
	'.pdf',
	'.png',
	'.ppsx',
	'.ppt',
	'.pptx',
	'.svg',
	'.txt',
	'.xls',
	'.xlsx',
	'.xml',
	'.webp',
	'.gif',
].map((opt) => ({ title: opt, value: opt }));

const SelectStyles = {
	clearIndicator: (provided) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided) => ({
		...provided,
		flexWrap: 'no-wrap',
		pr: 0,
		pl: 2,
		overflow: 'auto',
	}),
	singleValue: (provided) => ({
		...provided,
		px: 0,
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		ml: 0,
	}),
};

const FileUploadSharedSettings = {
	placeholder: { active: false },
	placeholderGroup: { active: false },
	prefill: { active: false },
	fieldGroup: {
		settings: {
			fieldFont: { active: false },
			fieldFontSize: { active: false },
			fieldFontColour: { active: false },
			fieldTextDecoration: { active: false },
		},
	},
	labelGroup: {
		settings: {
			labelPosition: {
				active: false,
			},
			labelWidth: {
				active: false,
			},
		},
	},
};

const FileUploadSettings = (props) => {
	const onChange = React.useCallback(
		(data) => {
			console.log(data);

			if (props.onChange) {
				return props.onChange(data);
			}

			const settings = {
				[data.type]: data.value,
			};

			if (data.type === 'fileSizeLimit') {
				// Convert unit back to bytes
				settings['fileSizeLimit'] = convertSize(data.value, props.fileSizeLimitUnit, 'Bytes');
			}

			useContentStore.getState().saveElementSettings({
				address: props.address,
				settings,
			});
		},
		[props.address, props.fileSizeLimit, props.fileSizeLimitUnit],
	);

	if (props.isMobileView) return null;

	return (
		<>
			<SettingsBox>
				<Box display="flex" alignItems="center" flexWrap="wrap" label="Max single file size" data-testid="file-upload-file-size">
					<Text w="100%" {...settingsLabelStyle} mb={0}>
						Max single file size
					</Text>

					<NumberSetting
						type="fileSizeLimit"
						max={convertSize(MaxFileSize, 'Bytes', props.fileSizeLimitUnit)}
						text={convertSize(props.fileSizeLimit, 'Bytes', props.fileSizeLimitUnit) || ''}
						onChange={onChange}
					/>

					<DropDownSetting
						type="fileSizeLimitUnit"
						data-testid="file-size-limit-unit"
						customStyle={{ maxWidth: 70, marginLeft: 8 }}
						onChange={onChange}
						selected={props.fileSizeLimitUnit}
						options={fileUploadUnitOptions}
						selectProps={{ useBasicStyles: true, chakraStyles: SelectStyles }}
					/>
				</Box>

				<NumberSetting type="maxFiles" onChange={onChange} text={props.maxFiles || ''} label="Max number of files" max={MaxFiles} />

				<MultiSelectWithTagsSetting
					type="acceptedFileTypes"
					label="Accept file types"
					tooltip={`Select file types to accept. If no file types are selected, the default types ${DefaultAcceptedFileTypes.join(', ')} will be accepted.`}
					placeholder="Accept file types"
					value={props.acceptedFileTypes}
					onChange={onChange}
					options={acceptedFileTypeOptions}
				/>
			</SettingsBox>

			<SharedInputSettings settings={FileUploadSharedSettings} {...props} onChange={onChange} />
		</>
	);
};

export default memo(FileUploadSettings);
