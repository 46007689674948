import React from 'react';
import itemTypes from '../../../util/itemTypes';
import { DragLayer } from 'react-dnd';

import DraggableStructurePreview from './DraggableStructurePreview.react';
import DraggableElementPreview from './DraggableElementPreview.react';
import DraggableSlotPreview from './DraggableSlotPreview.react';

const root = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

function getItemStyles(props) {
	const { currentOffset } = props;
	if (!currentOffset) {
		return {
			display: 'none',
		};
	}

	let { x, y } = currentOffset;

	props.itemType === itemTypes.STRUCTURE ? (x = x - 150) && (y = y - 25) : null;
	props.itemType === itemTypes.ELEMENT && (x = x - 75) && (y = y - 15);
	props.itemType === itemTypes.FORM_FIELD_OPTION && (x = x - 175) && (y = y - 25);
	props.itemType === itemTypes.SLOT && (x = x - 75) && (y = y - 50);

	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform: transform,
		WebkitTransform: transform,
	};
}

function collect(monitor) {
	return {
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	};
}

export class CustomDragLayer extends React.PureComponent {
	renderItem(type, name) {
		switch (type) {
			case itemTypes.STRUCTURE:
				return <DraggableStructurePreview />;
			case itemTypes.ELEMENT:
				return <DraggableElementPreview />;
			case itemTypes.SLOT:
				return <DraggableSlotPreview />;
			default:
				return null;
		}
	}

	render() {
		const { itemType, isDragging, item } = this.props;

		if (!isDragging) {
			return null;
		}

		const fieldName = itemType === itemTypes.FORM_FIELD_OPTION ? item.option.label : '';

		return (
			<div style={root} className="custom-drag-layer">
				<div style={getItemStyles(this.props)}>{this.renderItem(itemType, fieldName)}</div>
			</div>
		);
	}
}

export default DragLayer(collect)(CustomDragLayer);
