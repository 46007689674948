import {
	FormControl,
	Input,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Modal,
	ModalFooter,
	Button,
	FormLabel,
	ButtonGroup,
} from '@chakra-ui/react';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
	handleRename: (newName: string) => void;
	name: string;
}

const RenameModal = ({ isModalOpen, setIsModalOpen, handleRename, name }: Props) => {
	const [newName, setNewName] = useState(name);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSave = () => {
		handleRename(newName);
		handleClose();
	};

	useEffect(() => {
		if (!isModalOpen) setNewName(name);
	}, [isModalOpen, name]);

	return (
		<Modal isOpen={isModalOpen} onClose={handleClose}>
			<ModalOverlay />

			<ModalContent data-testid={`${name}-rename_modal`}>
				<ModalHeader>
					Rename Logic
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<FormControl>
						<FormLabel>Name</FormLabel>

						<Input
							autoFocus
							value={newName}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
							data-testid={`${name}-rename_modal_input`}
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup justifyContent="end">
						<Button variant="ghost" onClick={handleClose}>
							Cancel
						</Button>

						<Button onClick={handleSave}>Save</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default RenameModal;
