import { useShallow } from 'zustand/react/shallow';
import {
	Box,
	Button,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Heading,
	Card,
} from '@chakra-ui/react';
import useContentStore from '@/stores/ContentStore';
import { memo, useCallback, useState, ReactElement, useEffect, useMemo } from 'react';
import { ButtonContainer } from './Logic.styles';
import MoreMenu, { MenuActionItem } from '@/components/gui/shared/MoreMenu';
import { generateGuid } from '@/util/helper';
import { IConditionProps } from '../Condition';
import RenameModal from '@/components/Conditions/components/RenameModal';
import { Actions } from './Actions';
import { LogicAction } from '../models/model';
import cloneDeep from 'lodash/cloneDeep';
import { createBaseCondition, createBaseConditionGroup } from '@/components/Conditions/utils';
import { IGroupProps } from '@/components/Conditions/Group';
import { LogicErrors } from '@/util/resources';

export interface ILogicProps {
	address: number;
	id: string;
	index: number;
	name: string;
	type?: string;
	isOpen?: boolean;
	children: ReactElement<IConditionProps>;
	settings: IConditionProps[] | IGroupProps[];
	actions: LogicAction[];
	logicErrors?: string[];
}

const Logic = ({ actions = [], address, id, index: logicIndex, name, type, children, settings, isOpen, logicErrors }: ILogicProps) => {
	const { changeConditionSetting, canBeModified } = useContentStore(
		useShallow((state) => ({ changeConditionSetting: state.changeConditionSetting, canBeModified: state.canBeModified })),
	);
	const hasConditions = Boolean(settings.length);

	const logicAddress = useMemo(() => {
		return [address];
	}, [address]);

	const onChange = useCallback(
		(type, value) => {
			changeConditionSetting(logicAddress, { [type]: value });
		},
		[logicAddress],
	);

	const [isLogicOpen, setIsLogicOpen] = useState(false);
	const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

	useEffect(() => {
		setIsLogicOpen(isOpen);
	}, [isOpen]);

	const handleAccordionChange = (expandedIndex) => {
		setIsLogicOpen(expandedIndex === 0);
	};

	const handleDuplicate = () => {
		const logics = useContentStore.getState().content?.logics;

		const newLogic = cloneDeep({
			actions,
			id: import.meta.env.MODE !== 'test' ? generateGuid() : `${id}-duplicate`,
			name: `${name} (Copy)`,
			settings,
			type,
			isOpen: true,
		});

		const tempLogics = [...logics];

		tempLogics[logicIndex] = { ...tempLogics[logicIndex], isOpen: false };
		setIsLogicOpen(false);

		tempLogics.splice(logicIndex + 1, 0, newLogic);

		useContentStore.getState().setLogics(tempLogics);
	};

	const handleRename = (value: string) => {
		onChange('name', value);
	};

	const handleDelete = () => {
		const logics = useContentStore.getState().content?.logics;
		const newLogics = logics.filter((logic) => logic.id !== id);

		useContentStore.getState().setLogics(newLogics);
	};

	const availableActions: MenuActionItem[] = [
		{ action: handleDelete, label: 'Delete' },
		{ action: () => setIsRenameModalOpen(true), label: 'Rename' },
		{ action: handleDuplicate, label: 'Duplicate' },
	];

	const onAddCondition = useCallback(() => {
		const tempSettings = [...settings, createBaseCondition()];

		onChange('settings', tempSettings);
	}, [settings]);

	const onAddGroup = useCallback(() => {
		const tempSettings = [...settings, createBaseConditionGroup([createBaseCondition()])];

		onChange('settings', tempSettings);
	}, [settings]);

	return (
		<>
			<Accordion mb={4} allowToggle index={isLogicOpen ? 0 : -1} onChange={handleAccordionChange}>
				<AccordionItem as={Card} size="lg" border="none">
					<AccordionButton
						_expanded={{ bg: 'transparent' }}
						_hover={{ bg: 'transparent' }}
						data-testid="conditions-logic-toggle"
						px="6"
						py="4"
					>
						<Box
							flex={1}
							h={8}
							display="flex"
							alignItems="center"
							border={logicErrors?.includes(LogicErrors.missingName) && '1px solid'}
							borderColor={logicErrors?.includes(LogicErrors.missingName) && 'danger'}
							overflow="hidden"
						>
							<Heading size="md" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" pr={3}>
								{name}
							</Heading>
						</Box>
						<ButtonContainer>
							<MoreMenu actions={availableActions} disabled={!canBeModified} />
							<AccordionIcon />
						</ButtonContainer>
					</AccordionButton>
					<AccordionPanel p="6">
						<Text mb={5}>When the following conditions are met:</Text>
						<Card
							shadow="none"
							variant="filled"
							size="lg"
							p={4}
							bg={logicErrors?.includes(LogicErrors.missingSettings) ? 'danger-bg' : undefined}
						>
							{children}

							<Box display="flex" w="100%" justifyContent={hasConditions ? 'end' : 'center'}>
								<Button
									variant="ghost"
									color="primary-fg"
									size={hasConditions ? 'sm' : 'lg'}
									p={!hasConditions && 4}
									mt={hasConditions && 2}
									onClick={onAddCondition}
									isDisabled={!canBeModified}
									data-testid="add-condition-button"
								>
									Add condition
								</Button>
								<Button
									variant="ghost"
									color="primary-fg"
									size={hasConditions ? 'sm' : 'lg'}
									p={!hasConditions && 4}
									mt={hasConditions && 2}
									onClick={onAddGroup}
									isDisabled={!canBeModified}
									data-testid="add-group-button"
								>
									Add group
								</Button>
							</Box>
						</Card>
						<Actions logicIdx={logicIndex} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<RenameModal name={name} handleRename={handleRename} isModalOpen={isRenameModalOpen} setIsModalOpen={setIsRenameModalOpen} />
		</>
	);
};

export default memo(Logic);
