import Element from './Element.react';
import { getFormFieldFontStyles, getPadding, getShadowValFromProps } from '../../../../util/helper';
import { Text } from '@chakra-ui/react';

export const RadioGroup = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		labelWidth,
		asteriskColor,
		options,
		formId,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		box_shadow,
		background_color,
		optionsSpacing,
	} = props;

	const hiddenStyles = props.hidden
		? {
				opacity: 0.7,
			}
		: {};

	return (
		<div
			id={`${props.name.replace(/ /g, '')}-${formId}`}
			style={{
				width: '100%',
				backgroundColor: background_color,
				position: 'relative',
				pointerEvents: 'none',
				display: 'flex',
				...getPadding(props),
				flexDirection: 'column',
				...hiddenStyles,
			}}
			className="form-input-wrapper lp-flex-1"
		>
			<span
				style={{
					userSelect: 'none',
					whiteSpace: 'pre-wrap',
					wordBreak: 'break-word',
					color: labelFontColour,
					fontSize: labelFontSize,
					fontWeight: labelFontWeight,
					fontStyle: labelFontStyle,
					textDecoration: labelTextDecoration,
					display: labelHide ? 'none' : '',
					minHeight: labelWidth,
					...getFormFieldFontStyles(labelFont),
				}}
				className="form-field-label lp-flex-container"
				onClick={(e) => props.onClick && props.onClick(FormFieldTypes.label)}
			>
				{props.label}
				{props.required && (
					<span
						className="form-field-required"
						style={{
							color: asteriskColor,
							textDecoration: 'none',
							fontWeight: 'normal',
							fontStyle: 'normal',
							fontFamily: 'inherit',
						}}
					>
						{' '}
						*
					</span>
				)}
			</span>
			{options && options.length ? (
				<div>
					{options.map((item, i) => {
						return (
							<div key={i} style={{ marginBottom: i === options.length - 1 ? 0 : optionsSpacing }}>
								<Radio {...props} value={item.value} label={item.label} required={false} labelHide={false} />
							</div>
						);
					})}
				</div>
			) : (
				<Text p={2.5} display="flex" alignItems="center" justifyContent="center">
					No options available
				</Text>
			)}
			{props.description && (
				<div
					style={{
						color: descriptionFontColour,
						fontSize: descriptionFontSize,
						fontFamily: descriptionFontFamily,
						marginTop: descriptionSpacing,
						fontStyle: descriptionFontStyle,
						fontWeight: descriptionFontWeight,
						textDecoration: descriptionTextDecoration,
						display: props.descriptionHide ? 'none' : 'block',
					}}
				>
					{props.description}
				</div>
			)}
		</div>
	);
};

const Radio = (props) => {
	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFontSize,
		fieldFontColour,
		fieldBorderWidth,
		fieldBorderColour,
		fieldBorderRadius,
		fieldPadding,
		fieldBgColor,
		box_shadow,
	} = props;

	return (
		<label
			className={`form-input-wrapper-element form-checkbox-element lp-flex-container lp-flex-direction-horizontal lp-flex-align-center form-input-${props.formId}`}
			style={{
				width: '100%',
				WebkitBoxAlign: 'center',
				MsFlexAlign: 'center',
				alignItems: 'center',
				padding: 0,
				display: 'flex',
			}}
		>
			<div className="checkbox-wrapper" onClick={(e) => props.onClick && props.onClick(FormFieldTypes.field)}>
				<input
					title={props.placeholder}
					data-label={props.dataLabel ? props.dataLabel : props.label}
					data-primary={props.primary}
					data-type={props.type}
					data-required={props.required}
					// data-validate={props.validate || false}
					placeholder={props.placeholder}
					className="form-input-checkbox-element form-input-element"
					disabled={props.disabled}
					style={{
						opacity: 1,
						display: 'block',
						cursor: 'pointer',
						minWidth: 0,
						outline: 'none',
						margin: 0,
						appearance: 'none',
						background: fieldBgColor,
						padding: fieldPadding,
						color: fieldFontColour,
						fontSize: fieldFontSize,
						borderWidth: fieldBorderWidth,
						borderColor: fieldBorderColour,
						borderStyle: 'solid',
						borderRadius: fieldBorderRadius,
						fontWeight: fieldFontWeight,
						fontStyle: fieldFontStyle,
						textDecoration: fieldTextDecoration,
						boxShadow: getShadowValFromProps(box_shadow),
						// width: `0px`
					}}
					type={props.hidden ? 'hidden' : props.type}
					name={props.name}
					checked={props.value}
					data-readOnly={props.readOnly}
					readOnly={props.readOnly}
				/>
				<span
					style={{
						display: 'block',
						position: 'absolute',
						left: `calc(50% + 2px)`,
						top: `calc(50% + 2px)`,
						marginLeft: 0 - fieldFontSize / 2,
						marginTop: 0 - fieldFontSize / 2,
						borderRadius: fieldBorderRadius - 7,
						width: fieldFontSize - 4,
						height: fieldFontSize - 4,
						backgroundColor: fieldFontColour,
						cursor: 'pointer',
						lineHeight: 1,
					}}
				/>
			</div>
			<span
				style={{
					userSelect: 'none',
					whiteSpace: 'pre-wrap',
					wordBreak: 'break-word',
					color: labelFontColour,
					fontSize: labelFontSize,
					fontWeight: labelFontWeight,
					fontStyle: labelFontStyle,
					textDecoration: labelTextDecoration,
					display: labelHide ? 'none' : '',
					marginLeft: 10,
					...getFormFieldFontStyles(labelFont),
				}}
				className="form-field-label lp-flex-container"
				onClick={(e) => props.onClick && props.onClick(FormFieldTypes.label)}
			>
				{props.label}
			</span>
		</label>
	);
};

export default Element(RadioGroup);
