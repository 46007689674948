import { useState, useRef } from 'react';
import {
	Button,
	Tag,
	TagCloseButton,
	TagLabel,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Flex,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import TableTagsModal from './TableTagsModal';

import { Icon } from '@/components/gui/shared/Icon';
import { mdiCheck, mdiPlus } from '@mdi/js';

const tagStyle = {
	mr: 1,
};

const tagActiveStyle = {
	variant: 'solid',
	colorScheme: 'primary',
};

const EXTRA_LABELS_MAPPING = {
	xs: 2,
	sm: 2,
	md: 2,
	lg: 2,
	xl: 3,
};

export const TableLabels = (props) => {
	const breakpoint = useBreakpointValue(['xs', 'sm', 'md', 'lg', 'xl']);
	const [tagsModal, setTagsModal] = useState(false);
	const [tagsDropdown, setTagsDropdown] = useState(false);

	const { refName, fixedDropdown } = props;
	const dropdownRef = useRef(null);

	const onTagAdd = (tag) => {
		let tags = [...props.currentTags, tag];

		props.onTagsSave && props.onTagsSave(tags, props.itemId);
	};

	const onTagsRemove = (e, data) => {
		e.stopPropagation();
		props.onTagsRemove && props.onTagsRemove({ index: data.value, itemId: props.itemId });
	};

	const onTagClick = (tag) => {
		props.onTagClick && props.onTagClick(tag);
	};

	const onOpen = () => {
		setTagsModal(true);
	};

	const onClose = () => {
		setTagsModal(false);
	};

	const renderTagsModal = () => {
		return (
			<TableTagsModal
				isOpen={tagsModal}
				onClose={onClose}
				title={`Add ${refName?.plural.toLowerCase() ?? 'labels'}`}
				inputPlaceholder={`Add ${refName?.singular.toLowerCase() ?? 'label'}`}
				allTags={props.allTags}
				tags={props.currentTags}
				onAddTag={(_, tagValue) => onTagAdd(tagValue)}
				onRemoveTag={(e, _, tagIndex) => onTagsRemove(e, { value: tagIndex })}
				tagsLabel={!!props.currentTags.length && 'Added labels'}
				refName={refName}
				backspaceDeletesLastTag
			/>
		);
	};

	const renderDropdown = (tags) => {
		return (
			<Flex position="relative" alignItems="center" onMouseEnter={() => setTagsDropdown(true)} onMouseLeave={() => setTagsDropdown(false)}>
				<Menu isOpen={tagsDropdown} isLazy placement="bottom">
					<MenuButton
						as={Tag}
						{...tagStyle}
						{...(props.activeTags.some((item) => tags.includes(item)) ? tagActiveStyle : {})}
						title={`Add ${refName?.singular.toLowerCase() ?? 'label'}`}
						data-testid="additional-tags"
					>
						{tags.length}
					</MenuButton>

					<MenuList
						pos={fixedDropdown ? 'fixed' : 'absolute'}
						top={fixedDropdown && dropdownRef.current ? dropdownRef.current.getBoundingClientRect().top : 0}
						right={-50}
						minW="fit-content"
						maxW="36"
						pt={2}
					>
						{tags.map((tag, idx) => (
							<MenuItem
								key={idx}
								w="100%"
								icon={props.activeTags.includes(tag) && <Icon path={mdiCheck} />}
								onClick={() => onTagClick(tag)}
							>
								<Text>{tag}</Text>
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			</Flex>
		);
	};

	const renderTags = () => {
		const extraTags = [];
		return (
			<>
				<Flex alignItems="center" flexWrap="wrap">
					{props.currentTags.map((item, i) => {
						if (
							props.currentTags.length <= EXTRA_LABELS_MAPPING[breakpoint] ||
							(props.currentTags.length > EXTRA_LABELS_MAPPING[breakpoint] && i < EXTRA_LABELS_MAPPING[breakpoint] - 1)
						) {
							return (
								<Tag
									className="notranslate"
									my={0.5}
									key={i}
									onClick={() => onTagClick(item)}
									{...tagStyle}
									{...(props.activeTags.includes(item) ? tagActiveStyle : {})}
									cursor="pointer"
									data-testid="tags-collection-item"
								>
									<TagLabel
										textOverflow={'ellipsis'}
										maxW="20"
										whiteSpace={'nowrap'}
										display={'inline-block'}
										overflow={'hidden'}
										title={item}
									>
										{item}
									</TagLabel>
									<TagCloseButton color="inherit" onClick={(e) => onTagsRemove(e, { value: i })} data-testid="delete-tag-btn" />
								</Tag>
							);
						}

						extraTags.push(item);
					})}

					{extraTags.length > 1 && renderDropdown(extraTags)}

					<Button size="xs" variant="outline" mr={0.5} leftIcon={<Icon path={mdiPlus} />} onClick={onOpen}>
						{`Add ${refName?.singular.toLowerCase() ?? 'label'}`}
					</Button>
				</Flex>

				{renderTagsModal()}
			</>
		);
	};

	return renderTags();
};
