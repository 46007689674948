import React from 'react';

//components
import TextInputSetting from '../../shared/settings/TextInputSetting.react';
import PaddingSetting from '../../shared/settings/PaddingSetting.react';
import CheckBoxSetting from '../../shared/settings/CheckBoxSetting.react';
import NumberSetting from '../../shared/settings/NumberSetting.react';
import ColorPickerSetting from '../../shared/settings/ColorPickerSetting.react';
import ToggleButtonSetting from '../../shared/settings/ToggleButtonSetting.react';
import AlignmentSetting from '../../shared/settings/AlignmentSetting.react';

import MultiSetting from '../../shared/settings/MultiSetting.react';

import useContentStore from '../../../../stores/ContentStore';
import useTextEditorStore from '../../../../stores/TextEditorStore';

import { FieldComponentGroupTypes, FontFamilyOptions, IconTypes } from '../../../../util/resources';
import { compareFontLabels, findRow } from '../../../../util/helper';

import { Box, Text } from '@chakra-ui/react';
import { SettingsBox } from '../../settingsContext/SettingsContext';
import { ToggleBlock } from '../../shared/settings/ToggleBlock.react';
import ButtonSetting from '../../shared/settings/ButtonSetting.react';
import ShadowsSettings from '../../shared/settings/ShadowsSettings.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';
import { mdiCheckCircleOutline } from '@mdi/js';

export const ButtonSettingsFields = {
	color: 'color',
	text: 'text',
	newWindow: 'newWindow',
	text_color: 'text_color',
	url: 'url',
	alignment: 'alignment',
	padding: 'padding',
	margin: 'margin',
	fontFamily: 'fontFamily',
	fontWeight: 'fontWeight',
	textDecoration: 'textDecoration',
	distanceFrom: 'distanceFrom',
	fontStyle: 'fontStyle',
	fontSize: 'fontSize',
	width: 'width',
	customCss: 'customCss',
	borderRadius: 'borderRadius',
	customSize: {
		width: 'width',
		height: 'height',
	},
	borderWidth: 'borderWidth',
	borderColor: 'borderColor',
};

export const AlignOptions = [
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'center',
		label: 'Center',
	},
	{
		value: 'right',
		label: 'Right',
	},
];

export const ButtonWidthOptions = [
	{
		value: 'auto',
		label: 'Fit to text',
	},
	{
		value: '100%',
		label: 'Full-width',
	},
	{
		value: 'custom',
		label: 'Custom',
	},
];

export const ButtonSettingComponents = React.memo((props) => {
	const onDimensionChange = React.useCallback(
		(data) => {
			const input = {
				...props.customSize,
				[data.type]: data.value,
			};

			props.onChange && props.onChange({ event: data.event, type: 'customSize', value: input });
		},
		[props.customSize],
	);

	const renderDimensionSetting = () => {
		return (
			<MultiSetting label="Button width & height">
				<NumberSetting
					type={ButtonSettingsFields.customSize.width}
					onChange={onDimensionChange}
					text={props.customSize && props.customSize.width}
					min={props.customSize && props.customSize.minWidth}
					max={props.slotWidth && props.slotWidth}
					validateOnBlur={true}
					label="Button width"
					ariaLabel="Button width"
				/>
				<NumberSetting
					type={ButtonSettingsFields.customSize.height}
					onChange={onDimensionChange}
					text={props.customSize && props.customSize.height}
					min={props.customSize && props.customSize.minHeight}
					max={1000}
					validateOnBlur={true}
					label="Button height"
					ariaLabel="Button height"
				/>
			</MultiSetting>
		);
	};

	let decorations =
		(props.decorations && (
			<Box label="Button decorations Italic Bold Underline" mb={5}>
				<Text {...settingsLabelStyle}>Decorations</Text>
				<Box display="flex">
					<ToggleButtonSetting type="italics" onChange={props.onDecorationsChange} on={props.fontStyle === 'italic'} margin="0 20px 0 0" />
					<ToggleButtonSetting
						type="bold"
						onChange={props.onDecorationsChange}
						on={props.fontWeight === 'bold' || parseInt(props.fontWeight) >= 500}
						margin="0 20px 0 0"
					/>
					<ToggleButtonSetting
						type="underline"
						onChange={props.onDecorationsChange}
						on={props.textDecoration === 'underline'}
						margin="0 20px 0 0"
					/>
				</Box>
			</Box>
		)) ||
		null;

	const fontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts)
		.filter((item) => {
			return !item.value.includes(':');
		})
		.sort(compareFontLabels);

	return (
		<SettingsBox blockId={props.blockId}>
			<ColorPickerSetting
				type={ButtonSettingsFields.color}
				onChange={props.onChange}
				selectedColor={props.color || 'transparent'}
				label="Button color"
			/>
			<ColorPickerSetting
				type={ButtonSettingsFields.text_color}
				onChange={props.onChange}
				selectedColor={props.text_color || 'transparent'}
				label="Label color"
			/>
			<ColorPickerSetting
				type={ButtonSettingsFields.borderColor}
				onChange={props.onChange}
				selectedColor={props.borderColor || 'transparent'}
				label="Border color"
			/>
			{(!props.noLink && !props.isMobileView && !props.isFormButton && (
				<Box position="relative" filterId="link new window">
					<TextInputSetting
						key={0}
						type={ButtonSettingsFields.url}
						onChange={props.onChange}
						text={props.url}
						label="Button link"
						ariaLabel="Set a URL, including http: or https:"
						placeholder="https://…"
						validateURL={false} // false here because url might not be just http or https, could be mailto or whatever
					/>
					<CheckBoxSetting
						key={1}
						onClick={props.onChange}
						label="New window"
						checked={props.newWindow}
						type={ButtonSettingsFields.newWindow}
						styleProps={{ position: 'absolute', top: 0, right: 0, mb: 0 }}
					/>
				</Box>
			)) ||
				null}
			{(!props.isMobileView && !props.noLabel && (
				<TextInputSetting type={ButtonSettingsFields.text} onChange={props.onChange} text={props.text} label="Label" ariaLabel="Label" />
			)) ||
				null}
			<DropDownSetting
				type={ButtonSettingsFields.fontFamily}
				onChange={props.onChange}
				selected={props.fontFamily.replace(/'/g, '')}
				label="Font family"
				options={fontOptions}
				customStyle={{ width: '100%' }}
			/>
			<NumberSetting
				type={ButtonSettingsFields.fontSize}
				inputType="number"
				onChange={props.onChange}
				text={(props.fontSize === undefined && 16) || props.fontSize}
				label="Font size"
				ariaLabel="Font size"
				customStyle={{ customRoot: { width: '100%' } }}
			/>

			{decorations}

			{!props.hideAlignment && (
				<AlignmentSetting
					type={ButtonSettingsFields.alignment}
					onChange={props.onChange}
					selected={props.alignment}
					options={props.alignOptions || AlignOptions}
					label="Alignment"
					disabled={props.width === '100%'}
				/>
			)}
			<DropDownSetting
				type={ButtonSettingsFields.width}
				onChange={props.onChange}
				selected={props.width}
				label="Button width"
				options={ButtonWidthOptions}
				customStyle={{ width: '100%' }}
			/>
			{props.width === 'custom' && renderDimensionSetting()}
			{(props.width !== 'custom' && (
				<PaddingSetting
					multiPadding={props.multiPadding}
					padding={props.padding}
					type="multiPadding"
					label={'Label padding'}
					secondaryType={ButtonSettingsFields.padding}
					onChange={props.onChange}
				/>
			)) ||
				null}
			{!props.isFormButton && (
				<NumberSetting
					type={ButtonSettingsFields.margin}
					onChange={props.onChange}
					text={props.margin}
					max={500}
					label="Margin"
					ariaLabel="Number of pixels"
					disabled={props.width === '100%'}
				/>
			)}
			{props.isFormButton && (
				<NumberSetting
					type={ButtonSettingsFields.distanceFrom}
					onChange={props.onChange}
					text={props.distanceFrom}
					max={500}
					label="Distance"
					ariaLabel="Number of pixels"
				/>
			)}
			<MultiSetting label="Border width & border radius">
				<NumberSetting
					type={ButtonSettingsFields.borderWidth}
					onChange={props.onChange}
					text={props.borderWidth}
					max={10}
					label="Border width"
					ariaLabel="Number of pixels"
					customStyle={{ customRoot: { flex: 1 } }}
				/>
				<NumberSetting
					type={ButtonSettingsFields.borderRadius}
					onChange={props.onChange}
					text={props.borderRadius}
					label="Border radius"
					ariaLabel="Number of pixels"
					customStyle={{ customRoot: { flex: 1, paddingLeft: 2.5 } }}
				/>
			</MultiSetting>
			{props.lastPage ? (
				<ButtonSetting
					onClick={props.applyToAll}
					tooltip="Apply to all"
					label="Apply to all"
					testId="check_circle"
					icon={mdiCheckCircleOutline}
					customStyle={{ mb: 5, mt: 5 }}
				/>
			) : null}
		</SettingsBox>
	);
});

class ButtonSettings extends React.Component {
	render() {
		const BackButtonSettings = (
			<>
				<ShadowsSettings
					selectedValue={
						(this.props.back_button && this.props.back_button.box_shadow) || {
							offsetx: 1,
							offsety: 1,
							blur: 10,
							spread: 1,
							color: '#000',
						}
					}
					onChange={(data) => this.onChange(data, 'back_button')}
					label="Shadow settings"
				/>
				<ButtonSettingComponents
					// {...this.props}
					{...this.props.back_button}
					lastPage={this.props.lastPage}
					onChange={(data) => this.onChange(data, 'back_button')}
					onDecorationsChange={(data) => this.onDecorationsChange(data, 'back_button')}
					applyToAll={this.applyToAllBack}
					decorations={true}
					noLink={this.props.noLink}
					hideAlignment={this.props.lastPage > 0}
				/>
			</>
		);

		const SubmitButtonSettings = (
			<ButtonSettingComponents
				{...this.props}
				onChange={this.onChange}
				onDecorationsChange={this.onDecorationsChange}
				applyToAll={this.applyToAll}
				decorations={true}
				noLink={this.props.noLink}
				hideAlignment={this.props.lastPage > 0}
			/>
		);

		const BackBlock = this.props.pageIndex > 0 && <ToggleBlock label="Back button">{BackButtonSettings}</ToggleBlock>;

		return (
			<Box className="button-settings">
				<>
					{SubmitButtonSettings}
					{BackBlock}
				</>
			</Box>
		);
	}

	onChange = (data, subType) => {
		if (this.props.onChange) {
			return this.props.onChange(data);
		}

		let settings = {};

		if (Array.isArray(data)) {
			data.map((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		if (data.type === ButtonSettingsFields.width && data.value === '100%') {
			settings.margin = 0;
		}

		if (subType) {
			settings = {
				[subType]: {
					...this.props[subType],
					...settings,
				},
			};
		}

		if (data.type === 'text') {
			settings.textChanged = true;
		}

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};

	onDecorationsChange = (data, subType) => {
		if (this.props.onDecorationsChange) {
			return this.props.onDecorationsChange(data);
		}

		let settings = {};

		if (data.type === 'italics') {
			settings.fontStyle = (data.value && 'italic') || 'normal';
		} else if (data.type === 'bold') {
			settings.fontWeight = (data.value && 'bold') || 'normal';
		} else if (data.type === 'underline') {
			settings.textDecoration = (data.value && 'underline') || 'none';
		}

		if (subType) {
			settings = {
				[subType]: {
					...this.props[subType],
					...settings,
				},
			};
		}

		this.props.address &&
			useContentStore.getState().saveElementSettings({
				address: this.props.address,
				settings,
			});
	};

	applyToAll = () => {
		const settings = { ...this.props };
		delete settings.back_button;
		delete settings.uniqueId;
		delete settings.address;
		delete settings.id;
		delete settings.lastPage;
		delete settings.pageIndex;

		useContentStore.getState().saveForAllFormElementSettings(settings, [FieldComponentGroupTypes.buttons]);
	};

	applyToAllBack = () => {
		const settings = { back_button: { ...this.props.back_button } };

		useContentStore.getState().saveForAllFormElementSettings(settings, [FieldComponentGroupTypes.buttons]);
	};
}

export default ButtonSettings;
