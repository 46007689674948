import React from 'react';
import Element from './Element.react';
import ElementComponent from './ElementComponent.react';
import TextEdit from '../../shared/text/TextEdit.react';
import domHelper from '../../../../util/domHelper';
import { AllComponentTypes, ComponentDefaults } from '../../../../util/resources';
import { getPadding, getShadowValFromProps } from '../../../../util/helper';
import useContentStore from '../../../../stores/ContentStore';

export class Text extends ElementComponent {
	static defaultProps = { ...ComponentDefaults[AllComponentTypes.text] };

	render() {
		let { general_styles, box_shadow } = this.props;
		const shadowSettings = getShadowValFromProps(box_shadow);

		if (this.props.isMobileView && !this.props.text) {
			return null;
		}

		return (
			<table
				cellSpacing="0"
				cellPadding="0"
				style={{ clear: 'both', width: '100%' }}
				width="100%"
				className="text-element"
				ref={(rootTable) => {
					this.setAttributeInRef('align', 'center')(rootTable);
					domHelper.setAttributeList(rootTable, this.props.rootTableAttributes);
					//domHelper.applyStyles(rootTable);
				}}
			>
				<tbody>
					<tr>
						<td>
							<div
								className="text_container content-main-content content-text-container"
								// dangerouslySetInnerHTML={{ __html: this.props.text }}
								style={{
									...getPadding(this.props),
									backgroundColor: this.props.background_color,
									borderWidth: this.props.textBorderWidth,
									borderColor: this.props.textBorderColor,
									borderRadius: this.props.textBorderRadius,
									color: general_styles.color,
									fontFamily: general_styles.font_family,
									lineHeight: general_styles.text_line_height,
									fontSize: parseInt(general_styles.font_size, 10) + 'px',
									borderStyle: 'solid',
									wordWrap: 'break-word',
									wordBreak: 'break-word',
									boxShadow: shadowSettings,
								}}
							>
								<TextEdit
									activeElement={this.props.activeElement}
									changeCanDrag={this.props.changeCanDrag}
									isDragging={this.props.isDragging}
									id={this.props.id}
									text={this.props.text}
									isMobileView={this.props.isMobileView}
									onChange={this.onTextChange}
									type="text"
									general_styles={this.props.general_styles}
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	onTextChange = (value) => {
		if (value !== this.props.text) {
			this.props.address &&
				useContentStore.getState().saveElementSettings({
					address: this.props.address,
					settings: { text: value },
				});
		}
	};
}

export default Element(Text);
