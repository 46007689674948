import React from 'react';

class DraggableSlotPreview extends React.PureComponent {
	render() {
		let style = {
			width: '150px',
			height: '100px',
			border: '2px dashed grey'
		};

		return <div style={style} />;
	}
}

export default DraggableSlotPreview;
