import React from 'react';
import { FormControl, FormLabel, InputGroup, Input, InputRightElement, Box } from '@chakra-ui/react';
import { formatTestId } from '../../../../util/helper';
import { Tooltip } from '../../../gui/content/helper/Tooltip.react';

import { settingsLabelStyle } from './Settings.styles';

class TextInput extends React.PureComponent {
	static defaultProps = {
		disabled: false,
		maxChars: 2083, //2083 is the max number of chars allowed in the browser address bar in case the string is a URL
	};

	render() {
		let { customRoot, customLabel, customInput } = this.props.customStyle || { customRoot: null, customLabel: null, customInput: null };

		return (
			<FormControl
				id={`${this.props.type}-text-input-setting`}
				data-type={this.props.type}
				className="text-input-setting-container"
				isDisabled={this.props.disabled}
				mb={5}
				{...customRoot}
			>
				{this.props.label && (
					<FormLabel as="label" {...settingsLabelStyle} {...customLabel}>
						<Box display="inline-flex" pos="relative">
							{this.props.label}
							{this.props.tooltip && <Tooltip>{this.props.tooltip}</Tooltip>}
						</Box>
					</FormLabel>
				)}

				<InputGroup>
					<Input
						type={this.props.inputType || 'text'}
						value={this.props.text}
						spellCheck={!this.props.validateURL}
						aria-label={this.props.ariaLabel || this.props.label || undefined}
						placeholder={this.props.placeholder || undefined}
						data-testid={`input-${formatTestId(this.props.label || this.props.type)}`}
						{...customInput}
						onInput={this.onInput}
						onChange={this.onChange}
					/>
					{this.props.renderColor && <InputRightElement>{this.props.renderColor()}</InputRightElement>}
				</InputGroup>
			</FormControl>
		);
	}

	onInput = (event) => {
		if (this.props.disableEmoji) {
			event.target.value = event.target.value.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
				'',
			);
		}
	};

	onChange = (event) => {
		let value = this.fixURL(event.target.value);

		if (this.props.onChange && value.length < this.props.maxChars) {
			this.props.onChange({ event, type: this.props.type, value });
		}
	};

	fixURL(value) {
		let result = value;

		if (this.props.validateURL) {
			let val = (typeof value === 'string' && value) || '';
			let protocol = val.substring(0, 8);

			result = val;

			if (!(protocol === 'https://' || protocol.substring(0, 7) === 'http://')) {
				result = 'http://' + val.substring(8);
			}

			if (val.length <= 8 && val !== 'https://' && val.substring(0, 7) !== 'http://') {
				result = 'http://';
			}
		}

		return result;
	}
}

export default TextInput;
