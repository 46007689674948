export const helpCenter = {
	landingPage: {
		drawerArticles: [
			{
				label: 'Create a landing page',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/create-a-landing-page.html',
			},
			{
				label: 'Working with the landing page editor',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/working-with-the-landing-page-editor.html',
			},
			{
				label: 'Create and edit a landing page template ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/create-and-edit-a-landing-page-template.html',
			},
			{
				label: 'Track conversions for a landing page',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/track-conversions-for-a-landing-page.html',
			},
		],
		title: 'Need help with your landing page?',
		description: 'Here are some how-to articles to get you started.',
	},
	form: {
		drawerArticles: [
			{
				label: 'Create a subscription form',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/create-a-subscription-form.html',
			},
			{
				label: 'Working with the subscription form editor',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/working-with-the-subscription-form-editor.html',
			},
			{
				label: 'Add a T&C Checkbox to a subscription form',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/add-a-gdpr-check-box-to-a-subscription-form.html',
			},
			{
				label: 'Publish a subscription form',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/publish-a-subscription-form.html',
			},
		],
		title: 'Need help with your forms designer?',
		description: 'Here are some how-to articles to get you started.',
	},
	editor: {
		drawerArticles: [
			{
				label: 'Create a regular campaign ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/create-a-regular-campaign.html',
			},
			{
				label: 'Working with the campaign editor ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/working-with-the-campaign-editor.html',
			},
			{
				label: 'Work with the template library ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/work-with-the-template-library.html',
			},
			{
				label: 'Edit the mobile version of your content ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/edit-the-mobile-version-of-your-content.html',
			},
			{
				label: 'Create cart browse abandonment and product recommendation blocks ',
				url: 'https://doc.sitecore.com/send/en/users/sitecore-send/create-cart-browse-abandonment-and-product-recommendation-blocks.html',
			},
		],
		title: 'Need help?',
		description: 'Our reading material might be just what you need!',
	},
};
