import { Box, Skeleton, Tr, Td } from '@chakra-ui/react';

export const WebhooksTableLoader = () => (
	<>
		{[...Array(10)].map((_, i) => (
			<Tr h={16} key={i}>
				<Td w={'45%'}>
					<Skeleton display="inline-block" w={'45%'}>
						<Box h={4} />
					</Skeleton>
				</Td>
				<Td w={'30%'}>
					<Skeleton display="inline-block" w={'30%'}>
						<Box h={4} />
					</Skeleton>
				</Td>
				<Td w={'15%'}>
					<Skeleton display="inline-block" w={'20%'}>
						<Box h={4} />
					</Skeleton>
				</Td>
				<Td w={'4%'}>
					<Skeleton display="inline-block" w={'100%'}>
						<Box h={4} />
					</Skeleton>
				</Td>
			</Tr>
		))}
	</>
);
